import { Observable } from 'rxjs';
import { SETTINGS } from './../settings';
import { Injectable } from '@angular/core';
import { ServiceListResult, ServiceAlarmTriggerListResult } from '../classes/results.class';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ServiceService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient){
	}

	public getFreePatientServices(patient: number): Observable<ServiceAlarmTriggerListResult> {
		return this._http.get<ServiceAlarmTriggerListResult>(`${this.baseUrl}Patient/GetFreeServices?patientId=${patient}`);
	}

	public getServices(): Observable<ServiceListResult[]> {
		return this._http.get<ServiceListResult[]>(`${this.baseUrl}Service/GetServices`);
	}

	public setPatientServices(patient: number, sensors: number[]): Observable<ServiceListResult[]> {
		return this._http.post<ServiceListResult[]>(`${this.baseUrl}Patient/AddServicesToPatient?patientId=${patient}`, sensors);
	}
}
