import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { PagePatientsIndexComponent } from './patients-index.component';
import { PagePatientsDetailComponent } from './patients-detail.component';
import { PagePatientsEditComponent } from './patients-edit.component';
import { WebbeatModule } from '../../modules/webbeat/webbeat.module';
import { wbPropertyService } from '../../modules/webbeat/service/property.service';
import { HighchartModule } from '../../modules/highchart/highchart.module';
import { PatientGroupService } from 'app/services/patientgroups.service';
import { PatientResolver } from '../../resolvers/patient-resolver.service';
import { PatientsResolver } from '../../resolvers/patients-resolver.service';
import { PatientGroupsResolver } from 'app/resolvers/patient-groups-resolver';
import { ServicesResolver } from '../../resolvers/services-resolver.service';
import { PatientAlarmLogResolver } from '../../resolvers/patientAlarmLog-resolver';
import { AlarmTriggersResolver } from '../../resolvers/alarmTriggers-resolver';
import { MaintenanceGuard } from 'app/guards/maintenance-guard.service';
import { wbLoginGuard } from '../../guards/login-guard.service';
import { FormModule } from '../../forms/forms.module';
import { ContentModule } from '../../content/content.module';
import { GraphsModule } from '../../content/graphs/graphs.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IsNotManufacturerGuard } from '../../authorization/guards/not-manufacturer-guard.service';
import { UserRolesResolver } from '../../resolvers/userRoles-resolver';
import { isNotManufacturerOrDealerGuard } from 'app/authorization/guards/not-manufacturer-or-dealer-guard.service';
import { isManufacturerOrDealerGuard } from 'app/authorization/guards/dealer-or-manufacturer-guard.service';

@NgModule({
  imports: [
    WebbeatModule,
    RouterModule.forChild([
      {
        path: 'patients/edit/:id',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        component: PagePatientsEditComponent,
        resolve: {
          patient: PatientResolver,
          patientGroups: PatientGroupsResolver,
          alarmTriggers: AlarmTriggersResolver,
          rememberUserRoles: UserRolesResolver,
        },
      },
      {
        path: 'patients/edit',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        redirectTo: 'patients/edit/0',
      },
      {
        path: 'patients/:id',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        component: PagePatientsDetailComponent,
        data: { loadTesting: true },
        resolve: {
          patient: PatientResolver,
          alarmLog: PatientAlarmLogResolver,
          alarmTriggers: AlarmTriggersResolver,
          rememberUserRoles: UserRolesResolver,
        },
      },
      {
        path: 'patients',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        component: PagePatientsIndexComponent,
        resolve: {
          patients: PatientsResolver,
          services: ServicesResolver,
          rememberUserRoles: UserRolesResolver,
        },
      },
    ]),
    HighchartModule,
    SharedModule,
    FormModule,
    ContentModule,
    GraphsModule,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    PagePatientsIndexComponent,
    PagePatientsDetailComponent,
    PagePatientsEditComponent,
  ],
  providers: [
    UserRolesResolver,
    PatientResolver,
    PatientGroupService,
    PatientGroupsResolver,
    PatientAlarmLogResolver,
    PatientsResolver,
    ServicesResolver,
    IsNotManufacturerGuard,
    isNotManufacturerOrDealerGuard,
    isManufacturerOrDealerGuard,
    wbPropertyService,
    AlarmTriggersResolver,
    wbLoginGuard,
  ],
  exports: [FormModule],
})
export class PagePatientsModule {}
