import { Component, Input } from '@angular/core';
import { hcOptions } from '../../../modules/highchart/classes/hcOptions.class';
import { ChartType } from '../../../modules/highchart/classes/hcEnums.class';
import { hcSeries } from '../../../modules/highchart/classes/hcSeries.class';
import { hcDataPoint } from '../../../modules/highchart/classes/hcDataPoint.class';
import { AlarmHistory } from '../../../classes/alarm.class';
import { wbcToggler } from '../../../modules/webbeat/classes/wbcToggler.class';
import { wbcAny } from '../../../modules/webbeat/classes/wbcAny.class';
import { contentParent } from '../../content-parent';
import { Router } from '@angular/router';

@Component({
	selector: 'alarmHistory-graph',
	templateUrl: './alarmHistory-graph.html',
	styleUrls: [
		'../graph.css'
	]
})
export class AlarmHistoryGraphComponent extends contentParent {
	@Input('data')
	get data(): AlarmHistory[] { return this._data; }
	set data(value: AlarmHistory[]) { this._data = value; this.BuildGraph(); }

	@Input('minDateTime')
	set minDateTime(value: Date | number) {
		if (value != null) {
			if (value instanceof Date) {
				this._minDateTime = this.getDate((<Date>value).toJSON());
			}
			else {
				this._minDateTime = this.getDate((new Date()).toJSON(), 0 - value);
			}

			if (this._minDateTime < this.options.xAxis.min) {
				this.options.xAxis.min = this._minDateTime;
				this.update = new wbcToggler(true);
			}
		}
	}


	public options: hcOptions;
	public graph: hcSeries[];
	public update: wbcToggler;
	public chartId: wbcAny = new wbcAny('no id');


	private _data: AlarmHistory[];
	private _minDateTime?: number;


	constructor(private router: Router) {
		super();
		this.options = new hcOptions(ChartType.column, '', null, null, null, null, null, null, true);
		this.options.hasLegend = false;
		this.options.tooltipHtml = this.GetText('common.alarms') + ': {point.y}';
		this.options.chart.isAnimated = false;
		this.options.xAxis.type = 'datetime';
		this.options.xAxis.title = '';
		this.options.xAxis.max = this.getDate(new Date().toJSON());
		this.options.yAxis.title = '';
		this.options.yAxis.min = 0;
		this.options.yAxis.minRange = 5;
		this.options.yAxis.MinTickInterval = 1;

		this.checkRouteChange();
	}

	private checkRouteChange(): void {
		this.options.routeSubscription
			.subscribe(response => {
				if (response !== 'returnString') {
					this.router.navigateByUrl(response);
				}
			});
	}

	private BuildGraph() {
		var lastDate = this.getDate(new Date().toJSON());
		var firstDate = null;
		var serie = new hcSeries([], null, null, null, null, 1, null, 100);
		serie.initialAnimation = false;

		this.graph = [serie];

		if (this._minDateTime != null && this._minDateTime > 0) {
			firstDate = this._minDateTime;
		}

		for (var i in this._data) {
			var point = new hcDataPoint(this.getDate(this._data[i].timeStamp), this._data[i].amountPerMinuut);

			if (firstDate == null) {
				firstDate = point.x;
			}
			this.graph[0].data.push(point);
		}

		this.options.xAxis.min = firstDate;
		this.options.xAxis.max = lastDate;
		this.update = new wbcToggler(true);
	}

	private getDate(jsonTimeStamp: string, addSeconds?: number): number {
		var result = new Date(jsonTimeStamp).getTime();

		if (addSeconds != null) {
			result += (addSeconds * 1000);
		}

		var offset = new Date().getTimezoneOffset() * 60000;

		return result - offset;
	};
}