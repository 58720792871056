import { Pipe, PipeTransform } from '@angular/core'

import { wbcNumberText } from '../../../classes/wbcNumberText.class';


@Pipe({ name: 'numberText' })
export class wbNumberText implements PipeTransform {
	constructor() { }
	transform(value?: string | number, text?: string) {
		if (value == null || undefined) {
			value = '0';
		}

		value = '' + value;

		var result = value.replace(/[^\d]/g, '');

		if (text == null || text == undefined) {
			text = '';
		}

		return result + text;
	}
}