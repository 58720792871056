import { Injectable } from "@angular/core";
import { GlobalService } from "./global.service";
import { Router } from "@angular/router";

@Injectable()
export class LoadTestingService {
  public loading = false;
  public routeEnabledLoadTesting = false;

  private _loadStartInMS = null;
  private _timeout = null;
  private _timeoutAfterMS = 15000;

  get loadTestingEnabled(): boolean {
    return this.routeEnabledLoadTesting;
  }

  constructor(
    private _glService: GlobalService,
    private _router: Router
  ) {}

  startLoading(): void {
    this.loading = true;
    this._loadStartInMS = performance.now();
  }

  startTimeout(): void {
    this._timeout = setTimeout(() => {
      this.stopLoading(true);
    }, this._timeoutAfterMS);
  }

  stopLoading(timeout?: boolean): void {
    const ms = (performance.now() - this._loadStartInMS);

    if (this.loadTestingEnabled) {
      this._sendToMonitor(
        this._router.url,
        !timeout ? ms : `timeout (+ ${this._timeoutAfterMS} ms)`
      );
    }

    this.reset();
  }

  reset(): void {
    this._stopInterval();
    this.loading = false;
    this._loadStartInMS = null;
    this.routeEnabledLoadTesting = false;
  }

  private _stopInterval(): void {
    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
  }

  private _sendToMonitor(pageUrl: string, durationMS: any): void {
    console.log('da portal', pageUrl, durationMS, this._glService.versions ? this._glService.versions.functionalVersion : 'version-unknown');
  }
}
