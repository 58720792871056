import { Component, Input, ElementRef, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { GlobalService } from 'app/services/global.service';

@Component({
	selector: 'wb-grid-headerBlocks',
	templateUrl: './grid-headerBlocks.html',
	styleUrls: [
		'./grid-headerBlocks.css'
	],
})
export class wbGridHeaderBlocksComponent implements AfterViewInit {
	constructor(private _glService: GlobalService, private renderer2: Renderer2) {}

	@Input() gridWidth?: number;
	@Input() gridWidthXS?: number;
	@Input() gridWidthSM?: number;
	@Input() gridWidthMD?: number;
	@Input() gridWidthLG?: number;

	@Input() gridOffset?: number;
	@Input() gridOffsetXS?: number;
	@Input() gridOffsetSM?: number;
	@Input() gridOffsetMD?: number;
	@Input() gridOffsetLG?: number;

	@Input() hasFooter?: boolean = true;
	@Input() hasBody?: boolean = true;
  	@Input() fullscreenBody?: boolean = false;

  	@Input() loading?: boolean = false;

	@ViewChild('header') private _header: ElementRef;
	@ViewChild('body') private _body: ElementRef;
	@ViewChild('footer') private _footer: ElementRef;

	public isToggled: boolean = false;

	ngAfterViewInit() {
		if (this.fullscreenBody) {
			let header, body, footer, toFill;
			header = this._header.nativeElement.offsetHeight;
			body = this.hasBody ? this._body.nativeElement.offsetHeight : 0;
			footer = this.hasFooter ? this._footer.nativeElement.offsetHeight : 0;

			toFill = this._glService.mainBody.nativeElement.offsetHeight;
			toFill = toFill - (header + footer);
			if (this.hasBody) {
				this.renderer2.setStyle(this._body.nativeElement, 'height', `${toFill}px`);
			}
		} else {
			return;
		}
	}

	GetOffset(): string {
		if (this.gridOffset != null) {
			return 'col-xs-offset-' + this.gridOffset + ' col-sm-offset-' + this.gridOffset + ' col-md-offset-' + this.gridOffset + ' col-lg-offset-' + this.gridOffset;
		}

		var result = '';

		if (this.gridOffsetXS != null) {
			result += ' col-xs-offset-' + this.gridOffsetXS;
		}
		if (this.gridOffsetSM != null) {
			result += ' col-sm-offset-' + this.gridOffsetSM;
		}
		if (this.gridOffsetMD != null) {
			result += ' col-md-offset-' + this.gridOffsetMD;
		}
		if (this.gridOffsetLG != null) {
			result += ' col-lg-offset-' + this.gridOffsetLG;
		}

		return result;
	}

	GetWidth(): string {
		if (this.gridWidth != null) {
			return 'col-xs-' + this.gridWidth + ' col-sm-' + this.gridWidth + ' col-md-' + this.gridWidth + ' col-lg-' + this.gridWidth;
		}

		var result = '';

		if (this.gridWidthXS != null) {
			result += ' col-xs-' + this.gridWidthXS;
		}
		if (this.gridWidthSM != null) {
			result += ' col-sm-' + this.gridWidthSM;
		}
		if (this.gridWidthMD != null) {
			result += ' col-md-' + this.gridWidthMD;
		}
		if (this.gridWidthLG != null) {
			result += ' col-lg-' + this.gridWidthLG;
		}

		return result;
	}
}
