import { Injectable } from '@angular/core';

import { hcChart } from '../classes/hcChart.class';
import { hcOptions } from '../classes/hcOptions.class';
import { hcDataPoint } from '../classes/hcDataPoint.class';
import { hcSeries } from '../classes/hcSeries.class';

import { HighchartsLib } from '../lib/highchartsLib';
import * as HighchartsMore from 'highcharts-more'

@Injectable()
export class hcHighChartService {
	public version: string;

	private _highcharts: any;
	private _counter: number;

	constructor() {
		this._highcharts = new HighchartsLib().highcharts;
		HighchartsMore(this._highcharts);

		this.version = this._highcharts.version;
		this._counter = 0;
	}

	GetChartId() :string {
		this._counter ++;

		return 'hcChart_' + this._counter;
	}

	Chart(htmlId: string, options: hcOptions, series: hcSeries[], redrawOnUpdate?: boolean) :hcChart {
		var combined = options.ToJSON();

		combined.series = (() => {
			if (series == null) { return null; }

			var result = [];

			for (var i = 0; i < series.length; i ++) {
				result.push(series[i].ToJSON());
			}
			return result;
		})();

		return new hcChart(options, this._highcharts.chart(htmlId, combined));
	};

	ChartNormal(viewObj: any, options: any) {
    const chart = this._highcharts.chart(options, viewObj);
		return chart;
	}
}
