<div id="topMenu" class="row hxs hsm" [ngClass]="{ 'closed':isClosed, 'animated': animated }"> <!-- TODO: see class -->
	<div class="row">
		<div class="col-xs-6 col-md-5 col-lg-7" style="margin-top: 25px;">
			<div class="row">
				<div class="col-xs-12">
					<h1>{{glService.title.value}}</h1>
				</div>
				<div class="col-xs-12">
					<wb-crumblePath-basic [path]="glService.crumble"></wb-crumblePath-basic>
				</div>
			</div>
		</div>
		<div class="col-xs-6 col-md-7 col-lg-5 child_fl_right child_padded" style="margin-top: 39px;">
			<wb-actionButton id="mainButton"[button]="glService.mainButton"></wb-actionButton>
			<wb-actionButton id="secondbutton" [button]="glService.secondButton"></wb-actionButton>
		</div>
	</div>
	<!--<div class="row">
		<div class="col-xs-9">

		</div>
	</div>-->
</div>
