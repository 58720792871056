import { Component } from '@angular/core';
import { Page } from '../pages.parent';
import { GlobalService } from '../../services/global.service';

@Component({
	selector: 'page-error:not_found',
	template: `<p>{{GetText('error.not_found')}}</p>`
})
export class PageErrorNotFoundComponent extends Page {
	constructor(glService: GlobalService) {
		super(glService, 'NotFound');

		this.SetTitle(this.GetText('common.not_found'));// 'Not found';
	}
}