import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AccountService } from './services/account.service';
import { wbcDaHelper } from './modules/webbeat/classes/wbcDaHelper.class';
import { wbBrowserStorage } from './modules/webbeat/service/browserStorage.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private _refreshTokenInProgress = false;
  private _refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _accountService: AccountService,
    private _router: Router,
    private _wbStorage: wbBrowserStorage
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 503) {
          this._router.navigateByUrl('/maintenance');
        } else if (error.status === 403) {
          this._router.navigateByUrl('/no_access');
        }

        if (error.status === 401) {
          if (!req.url.includes('auth/token')) {
            if (this._refreshTokenInProgress) {
              return this._refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => {
                  const newReq = wbcDaHelper.addHeaders(req, true, this._wbStorage.getStorageItem('currentGroup.id'));
                  return next.handle(newReq);
                })
              );
            } else {
              this._refreshTokenInProgress = true;
              return this._accountService.refreshToken().pipe(
                switchMap((token: any) => {
                  this._refreshTokenInProgress = false;
                  this._refreshTokenSubject.next(token);
                  const newReq = wbcDaHelper.addHeaders(req, true, this._wbStorage.getStorageItem('currentGroup.id'));
                  return next.handle(newReq);
                }),
                catchError(err => {
                  this._refreshTokenInProgress = false;
                  this._accountService.logout(true);
                  return throwError(err);
                })
              );
            }
          }
        }
        return throwError(error);
      })
    ) as any;
  }
}
