import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ActiveAlarmListComponent } from './activeAlarm-list.component';
import { AlarmListComponent } from './alarm-list.component';
import { AlarmTriggerListComponent }  from './alarmTrigger-list.component';
import { AlarmTriggerEditListComponent }  from './alarmTriggerEdit-list.component';
import { EmployeeListComponent } from './employee-list.component';
import { PatientListComponent } from './patient-list.component';
import { BedPatientListComponent } from './bedPatient-list.component';
import { TennantListComponent } from './tenant-list.component';

import { InfoBlockComponent } from './info-block.component';

import { WebbeatModule } from '../modules/webbeat/webbeat.module';
import { OtherModule } from '../modules/other/other.module';
import { DisconnectedDeviceListComponent } from 'app/content/disconnected-device-list.component';

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		WebbeatModule,
		OtherModule,
		FormsModule
	],
	declarations: [
		ActiveAlarmListComponent,
		AlarmListComponent,
		AlarmTriggerListComponent,
		AlarmTriggerEditListComponent,
		EmployeeListComponent,
		PatientListComponent,
		TennantListComponent,
		BedPatientListComponent,
		DisconnectedDeviceListComponent,
		InfoBlockComponent
	],
	bootstrap: [
	],
	providers: [
	],
	exports: [
		CommonModule,
    ActiveAlarmListComponent,
		AlarmListComponent,
		AlarmTriggerListComponent,
		AlarmTriggerEditListComponent,
		EmployeeListComponent,
		PatientListComponent,
		TennantListComponent,
		BedPatientListComponent,
		DisconnectedDeviceListComponent,
		InfoBlockComponent
	]
})
export class ContentModule {
	constructor() {}
}
