import { Directive, EventEmitter, ElementRef, Renderer2, HostListener, Input, Output } from '@angular/core';

@Directive({selector: '[ngTableFiltering]'})
export class NgTableFilteringDirective {
  @Input() public ngTableFiltering:any = {
    filterString: '',
    columnName: 'name'
  };
  
  @Output() public tableChanged:EventEmitter<any> = new EventEmitter();
  
  @Input()
  public get config():any {
    return this.ngTableFiltering;
  }
  
  public set config(value:any) {
    this.ngTableFiltering = value;
  }
  
  @HostListener('input', ['$event.target.value'])
  public onChangeFilter(event:any):void {
    this.ngTableFiltering.filterString = event;
    this.tableChanged.emit({filtering: this.ngTableFiltering});
  }
  
  public constructor(private _element: ElementRef, private _renderer: Renderer2) {
    // Set default value for filter
    this._renderer.setProperty(this._element, 'value', this.ngTableFiltering.filterString);
  }
}
