import { Injectable } from '@angular/core';


@Injectable()
export class wbPropertyService {
	hasProperty(item: any, prop: string): boolean {
		var object = JSON.parse(JSON.stringify(item));

		if (prop.indexOf('.') == -1) {
			return object.hasOwnProperty(prop);
		}
		else {
			var props = prop.split('.');

			for (var i = 0; i < props.length; i ++) {
				if (!object.hasOwnProperty(props[i])) {
					return false;
				}

				object = object[props[i]];
			}

			return true;
		}
	}

	hasNotPropertyOrEmpty(item: any, prop: string): boolean {
		var object = JSON.parse(JSON.stringify(item));

		if (prop.indexOf('.') == -1) {
			if (object.hasOwnProperty(prop)) {
				if (object[prop] == null || typeof(object[prop]) == 'undefined' || object[prop] == '') {
					return true;
				}
			}
			else {
				return true;
			}
			
		}
		else {
			var props = prop.split('.');

			for (var i = 0; i < props.length; i ++) {
				if (!object.hasOwnProperty(props[i])) {
					return true;
				}

				if (object[prop] == null || typeof(object[prop]) == 'undefined' || object[prop] == '') {
					return true;
				}

				object = object[props[i]];
			}
		}

		return false;
	}

	getFromObject(item: any, prop: string): any {
		var result = null;

		if (prop != null) {
			try {
				if (prop.indexOf('.') == -1) {
					result = JSON.parse(JSON.stringify(item))[prop];
				}
				else {
					var props = prop.split('.');
					result = JSON.parse(JSON.stringify(item));

					for (var i = 0; i < props.length; i ++) {
						result = result[props[i]];

						if (result == null || typeof(result) === 'undefined') {
							break;
						}
					}
				}
			}
			catch(e) {} 
		}

		return result;
	}
}