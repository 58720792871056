export class wbcAny {
	public updateFunction?: Function;
	public id?: number;

	public get value() :any { return this._value; };
	public set value(value: any) { this._value = value; this.Trigger(); };


	private _value: any;


	constructor(value: any, updateFunction?: Function, id?: number) {
		this.value = value;

		this.id = id;
		this.updateFunction = updateFunction;
	};


	public Trigger() {
		if (this.updateFunction != undefined) {
			this.updateFunction(this.id);
		}
	}
}