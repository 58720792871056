<wb-grid-headerBlocks gridWidth="6" gridOffset="3">
	<div block-header>
		<div id="colorBlock" class="color_green"></div>
		<h2>Logout</h2>
		<p></p>
	</div>
	<div block-body>
		<br />
		<p>Close your browser.</p>
	</div>
</wb-grid-headerBlocks>