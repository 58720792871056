import { Pipe, PipeTransform } from '@angular/core'

import { wbcNumberText } from '../../../classes/wbcNumberText.class';


@Pipe({ name: 'timePipe' })
export class wbTimePipe implements PipeTransform {
	constructor() { }
	transform(value?: string | number) {
		if (value == null || undefined || value == '00:00') {
			value = '00:00';
		}

		var text = '' + value;
		var m = 0;
		var h = 0;

		if ( /^[0-9\-]+:[0-9\-]+$/.test(text) ) {
			var split = text.split(':');
			h = parseInt(split[0]);
			m = parseInt(split[1]);

			if (isNaN(h)) { h = 0; }
			if (isNaN(m)) { m = 0; }
		}
		else {
			var parsed = parseInt(text);
			if (isNaN(parsed)) {
				return '00:00';
			}

			m = parsed % 60;
			h = (parsed - m) / 60;
		}

		if (h > 23) {
			h = h % 24;
		}

		if (m > 59) {
			m = m % 60;
		}

		return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
	}
}