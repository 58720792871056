import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BaseForm } from '../classes/iforms.interface';
import { Patient } from '../classes/patient.class';
import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';
import { GlobalService } from '../services/global.service';
import { PatientGroup } from 'app/classes/patient-group.class';

@Component({
	selector: 'patient-form',
	templateUrl: './patient-form.html',
	styleUrls: ['./forms.css']
})
export class PatientFormComponent extends BaseForm {
  @Input() model: Patient;
  @Input() patientGroups: PatientGroup[];
	@Input() submitted?: Function;

	@Input('submit')
	get submit(): wbcToggler { return this._submit; };
	set submit(value: wbcToggler) { var a = (this._submit != null && this._submit.isToggled); this._submit = value; if (!a && value.isToggled) { this.onSubmit(); } };

	@Input('validate')
	get validate(): wbcToggler { return this._validate; };
	set validate(value: wbcToggler) { var a = (this._validate != null && this._validate.isToggled); this._validate = value; if (!a && value.isToggled) { this.onValidate(); } };


	public get updateTrigger(): Function { return () => { this.update(); }; }
	public selectedPatientGroups: Array<Number> = [];
	public isManufacturer: boolean = true;
	private canSubmit: boolean;
	private submitTimer: any = null;
	private inputTimer: any = null;
	private _submit: wbcToggler;
	private _validate: wbcToggler;


  constructor(
    private route: ActivatedRoute,
		private fb: FormBuilder,
		private glService: GlobalService) {
		super();

		this.canSubmit = true;

		this.submit = new wbcToggler(false);
  }

	onValidate() {
		this.onValidateFunc();
	};

	onSubmit(): boolean {
    if (!this.isManufacturer) {
      if (this.submitted != null && this.submitted != undefined) {
        this.submitted(null, false);
			}
			if (this.canSubmit) {
        if (super.onSubmit()) {
					this.canSubmit = false;
					if (this.submitted != null && this.submitted != undefined) {
						this.submitted(this.model, true, this.form.untouched);
					}
					this.canSubmit = true;
				}
				else {
					this.submitted(null, false, this.form.untouched);
				}
			}
			return false;
		} else {
			return;
		}
	}

	update() {
		if (this.inputTimer != null) {
			clearTimeout(this.inputTimer);
		}

		this.inputTimer = setTimeout(() => {
			this.inputTimer = null;
			this.onSubmit();
		}, 1);
	};


	ngOnInit() {
		this.form = this.fb.group({
			id: this.model.id,
			initials: this.model.initials,
			insertion: this.model.insertion,
			lastName: this.model.lastName,
      room: this.model.room,
      // patientGroups: this.model.patientGroups
		});

    this.isManufacturer = this.glService.isManufacturer();
  }

  onValidateFunc() {
    this.submitted(null, super.onSubmit(), this.form.untouched);
		this.validate = new wbcToggler(false);
  }
}
