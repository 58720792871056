import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TenantService } from '../services/tenant.service';

@Injectable()
export class TenantResolver implements Resolve<any> {
	constructor(
		private TenantService: TenantService,
	) { }

	resolve(): any {
		return this.TenantService.getTennants();
	}
}