import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PagePatientsModule } from './patients/patients.module';
import { PageEmployeesModule } from './employees/employees.module';
import { PageServicesModule } from './services/services.module';
import { PageBedModule } from './bed/bed.module';
import { PageDevicesModule } from './devices/devices.module';
import { PageAlarmHistoryModule } from './alarms/alarm.module';

import { PageHomeComponent } from './home.component';
import { PageLoginComponent } from './login.component';
import { PageLogoutComponent } from './logout.component';
import { PageDashboardComponent } from './dashboard.component';
import { PageInfoComponent } from './info.component';

import { PageErrorNotFoundComponent } from './error/not_found.component';
import { PageErrorNoAccessComponent } from './error/no_access.component';

import { WebbeatModule } from '../modules/webbeat/webbeat.module';

import { FormModule } from '../forms/forms.module';

import { ContentModule } from '../content/content.module';

import { GraphsModule } from '../content/graphs/graphs.module';
import { PageSwitchTennantComponent } from './switch-tenant.component';


@NgModule({
  imports: [
    WebbeatModule,
    RouterModule,
    PagePatientsModule,
    PageEmployeesModule,
    PageServicesModule,
    PageBedModule,
    PageDevicesModule,
    PageAlarmHistoryModule,
    FormModule,
    ContentModule,
    GraphsModule
  ],
  declarations: [
    PageHomeComponent,
    PageSwitchTennantComponent,
    PageLoginComponent,
    PageLogoutComponent,
    PageDashboardComponent,
    PageInfoComponent,
    PageErrorNotFoundComponent,
    PageErrorNoAccessComponent,
  ],
  bootstrap: [
  ],
  providers: [

  ],
  exports: [
    FormModule,
    ContentModule,
  ]
})
export class PagesModule { }
