import { Component, OnInit, Input } from '@angular/core';
import { hcHighChartService } from '../../services/highchart.service';
import { hcOptions } from '../../classes/hcOptions.class';
import { hcChart } from '../../classes/hcChart.class';
import { hcSeries } from '../../classes/hcSeries.class';
import { hcSeriesDataPoint } from '../../classes/hcDataPoint.class';
import { wbcToggler } from '../../../webbeat/classes/wbcToggler.class';
import { wbcAny } from '../../../webbeat/classes/wbcAny.class';

@Component({
	selector: 'hc-chart-base',
	templateUrl: './chart-base.html',
	styleUrls: [
		'./chart-base.css'
	],
})
export class hcChartBaseComponent implements OnInit {
	public chartService: hcHighChartService;
	public chart: hcChart;

	public _updateTime: any;
	public _update: wbcToggler = new wbcToggler(false);
	public _rebuild: wbcToggler = new wbcToggler(false);
	public _clear: wbcToggler = new wbcToggler(false);

	public _shiftPoint: hcSeriesDataPoint;
	public _shiftTime: any;

	public chartId: string;

	constructor(chartService: hcHighChartService) {
		this.chartService = chartService;

		this.chartId = this.chartService.GetChartId();

		var a = setInterval(()=>{
			if (this.chart_id != null) {
				clearInterval(a);
				this.chart_id.value = this.chartId;
			}
		}, 5);
	}

	@Input() options: hcOptions;
	@Input() series: hcSeries[];
	@Input() chart_id: wbcAny;

	@Input('clear')
	get clear(): wbcToggler { return this._clear; };
	set clear(value: wbcToggler) { this._clear = value; if (this.clear != null && this.clear.isToggled) {
		this.clear.isToggled = false;
		this.chart = this.chartService.Chart(this.chartId, this.options, []);
		this._updateTime = setTimeout(()=> {
			this.chart.Update(this.options, [], true);
		}, 1);
	} };
	
	@Input('rebuild')
	get rebuild() :wbcToggler { return this._rebuild; }
	set rebuild(value: wbcToggler) { this._rebuild = value; if (this._rebuild != null && this._rebuild.isToggled) {
		if (this._updateTime != null) {
			clearTimeout(this._updateTime);
		}

		this.chart = this.chartService.Chart(this.chartId, this.options, []);

		this._updateTime = setTimeout(()=> {
			this.chart.Update(this.options, this.series, true);
		}, 1);
	} }

	@Input('update')
	get update() :wbcToggler { return this._update; }
	set update(value: wbcToggler) {	this._update = value; if (this._update != null && this._update.isToggled) {
		if (this._updateTime != null) {
			clearTimeout(this._updateTime);
		}

		this._updateTime = setTimeout(()=> {
			if (this.chart != null) {
				this.chart.Update(this.options, this.series, true);
				if (this.chart_id != null) {
					this.chart_id.value = this.chartId;
				}
			}
		}, 1);
	} }

	@Input() set logChart(value: boolean) {
		//if (value === true) {
		//	
		//}
	}

	@Input() set reshift(point: hcSeriesDataPoint) {
		this._shiftPoint = point;

		if (this._shiftPoint !== null) {
			if (this._shiftTime != null) {
				clearTimeout(this._shiftTime);
			}

			this._shiftTime = setTimeout(()=> {
				this._shiftTime = null;
				this._shiftPoint = null;
				this.chart.ShiftPoint(point, true);
			}, 50);
		}
	}


	ngOnInit() {
		if (this.options == null || !(this.options instanceof hcOptions)) {
			console.warn('There are no options for the chart.');
			return;
		}

		setTimeout(()=>{
			this.chart = this.chartService.Chart(this.chartId, this.options, this.series);
		}, 0);
	}
}