import { Router } from '@angular/router';
import { Injectable, Inject, ElementRef } from '@angular/core';
import { wbBrowserStorage } from '../modules/webbeat/service/browserStorage.service';
import { Group } from '../classes/group.class';
import { LANGUAGE } from '../language';
import { wbcMenuButton } from '../modules/webbeat/classes/wbcButton.class';
import { wbcUrlLocation } from '../modules/webbeat/classes/wbcUrlLocation.class';
import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';
import { wbcAny } from '../modules/webbeat/classes/wbcAny.class';
import { Version } from '../classes/version';
import { CommonService } from './common.service';

@Injectable()
export class GlobalService {
	private storageService: wbBrowserStorage;
	private _router: Router;
	private _menuToggled: wbcToggler = new wbcToggler(true);
	private _menuHide: boolean;
	private _isManufacturer: boolean = true;
	private _benchmarkTimeout = null;
	
	public isProbing = false;
	public title: wbcAny = new wbcAny('');
	public mainButton: wbcMenuButton;
	public secondButton: wbcMenuButton;
	public crumble: wbcUrlLocation;
	public mainBody: ElementRef;
	public isRefreshingInGuard = false;
	public letManufacturerEditUsers = false;
	public letManufacturerCreateUsers = false;
	public versions: Version = null;
	public toSendAPIBenchmarks = [];
	public isCurrentlyResolving = false;

	public preload: boolean = false;

	public get menuToggled(): wbcToggler { return this._menuToggled; };
	public set menuToggled(value: wbcToggler) { this._menuToggled = value; };

	public get menuHide(): boolean { return this._menuHide; };
	public set menuHide(value: boolean) { this._menuHide = value; };

	public get LastRefresh(): string {
		var ms = parseInt('' + this.storageService.getStorageItem('lastRefresh'));

		if (ms != null && !isNaN(ms) && ms > 0) {
			return this.GetStringDate(new Date(ms), true);
		}

		return '';
	}

	public get groups(): Group[] { if (this._groups == null) { this._groups = this.storageService.getStorageItem('user.groups'); } return this._groups; }

	public get currentGroup(): Group { if (this._currentGroup == null) { this._currentGroup = Group.Convert(JSON.parse('' + this.storageService.getStorageItem('currentGroup'))); } return this._currentGroup; }
	public set currentGroup(value: Group) {
		this._currentGroup = Group.Convert(value);
		this.storageService.setStorageItem('currentGroup', this._currentGroup);
	}

	public get currentCompanyId(): number { if (this._currentCompanyId == null) { this._currentCompanyId = this.currentGroup.companyId; } return this._currentCompanyId; }

	public isScreenXS: boolean = true;
	public isScreenSM: boolean = false;
	public isScreenMD: boolean = false;
	public isScreenLG: boolean = false;


	public get Language(): string { return LANGUAGE.GetLanguage(); }
	public set Language(value: string) { LANGUAGE.SetLanguage(value); }



	private _groups: Group[];
	private _currentGroup: Group;
	private _currentCompanyId: number;


	constructor(@Inject(wbBrowserStorage) storageService: wbBrowserStorage, @Inject(Router) router: Router, private _commonService: CommonService) {
		this.storageService = storageService;
		this._router = router;
	};

	public IsParent(linkId: string): boolean {
		if (this.crumble != null) {
			if (this.crumble.id == linkId) {
				return true;
			}
		}
		return false;
	};

	public GetStringDate(date: Date, getTime?: boolean): string {
		var years = date.getFullYear();
		var months = date.getMonth() + 1;
		var days = date.getDate();
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var result = '' + (days <= 9 ? '0' + days : days) + '-' + (months <= 9 ? '0' + months : months) + '-' + years;

		if (getTime) {
			return result + ' ' + (hours <= 9 ? '0' + hours : hours) + ':' + (minutes <= 9 ? '0' + minutes : minutes);// + ':' + (seconds <= 9 ? '0' + seconds : seconds);
		}
		return result;
	};

	public SetIsManufacturer(value: boolean): void {
		this._isManufacturer = value;
	}

	public isManufacturer(): boolean {
		return this._isManufacturer;
	}

	public GetIconClass(iconCode: string) {
		if (iconCode == null || iconCode == '') {
			return '';
		}

		/* OLD hex-values */
		switch (iconCode.toLowerCase()) {
			case '25c0': return 'icon-chevron-left';
			case '25ba': return 'icon-chevron-right';
			case '2635': return 'icon-dashboard';
			case '1f6cc': return 'icon-clients';
			case '2318': return 'icon-services';
			case '1f3e5': return 'icon-employees';
			case '1f465': return 'icon-teams';
			case '1f493': return 'icon-heart';
			case '1f64c': return 'icon-posture';
			case '1f553': return 'icon-time';
			case '1f514': return 'icon-alarm';
			case '1f4c8': return 'icon-graph';
			case '1f511': return 'icon-logout';
			case '2714': return 'icon-check';
			case '2716': return 'icon-close';
			case '23f7': return 'icon-chevron-down';
			case '1f5d1': return 'icon-trash';
			default: return '';
		}
	}

	public addApiBenchmark(benchmarkItem: any): void {
		if (this._benchmarkTimeout) {
			clearInterval(this._benchmarkTimeout);
			this._benchmarkTimeout = null;
		}

		this.toSendAPIBenchmarks.push(benchmarkItem);
		
		if (!localStorage.getItem('token')) return;
		
		this._benchmarkTimeout = setTimeout(() => {
			if (localStorage.getItem('token')) {
				// const toSend = [...this.toSendAPIBenchmarks];
				this.toSendAPIBenchmarks = [];
				
				// if (toSend.length > 0) {
				// 	this._commonService.postBenchmarks(toSend).subscribe();
				// }
			}
			this._benchmarkTimeout = null;
		}, 2000);		
	}
}
