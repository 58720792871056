import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Login } from '../classes/login.class';
import { LoginResult } from '../classes/results.class';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SETTINGS } from '../settings';
import { Router } from '@angular/router';
import { wbBrowserStorage } from 'app/modules/webbeat/service/browserStorage.service';
import { GlobalService } from './global.service';
import { LANGUAGE } from 'app/language';
import { isObject } from 'util';

@Injectable()
export class AccountService {
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _wbStorage: wbBrowserStorage,
    private _glService: GlobalService
  ) {}

  private _baseUrl = SETTINGS.BASE_API_URL;

  public login(token: string): Observable<LoginResult | any> {
    return this._http.post(`${this._baseUrl}v1/auth/login`, { oAuthToken: token });
  }

  public saveLanguageSettings(id: number, selectedLanguageId: string): Observable<any> {
    return this._http.put(
      `${this._baseUrl}User/${id}/language/${selectedLanguageId}`,
      selectedLanguageId
    );
  }

  public refreshToken(isBackgroundCall: boolean = false): Observable<any> {
    const httpHeaders = new HttpHeaders().append('isBackgroundCall', isBackgroundCall.toString());
    const userExternalId = this._wbStorage.getStorageItem('user.externalId');
    const refreshToken = this._wbStorage.getStorageItem('refreshToken');

    return new Observable((observer) => {
      this._http
        .post<any>(
          `${this._baseUrl}v1/auth/token`,
          { userExternalId: userExternalId, refreshToken: refreshToken },
          { headers: httpHeaders }
        )
        .subscribe(
          (refreshResult) => {
            localStorage.setItem('token', refreshResult.accessToken);
            localStorage.setItem('refreshToken', refreshResult.refreshToken);
            localStorage.setItem('expiresOn', refreshResult.expiresOn);
            localStorage.setItem('tokenType', refreshResult.tokenType);
            observer.next(true);
            observer.complete();
          },
          (error) => {
            observer.next(false);
            observer.complete();
          }
        );
    });
  }

  public isTokenExpired(extraTimeInMS = 0): boolean {
    const currentDate = Date.now() + extraTimeInMS,
      tokenExpireDate = Date.parse(localStorage.getItem('expiresOn'));

    if (tokenExpireDate !== NaN && currentDate > tokenExpireDate) {
      return true;
    } else if (tokenExpireDate !== NaN && currentDate < tokenExpireDate) {
      return false;
    }
  }

  public clearLocalStorage(): void {
    localStorage.removeItem('expiresOn');
    localStorage.removeItem('lang');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('user');
  }

  public logout(redirectToNoAccess = false): void {
    this.clearLocalStorage();

    if (redirectToNoAccess) {
      this._router.navigateByUrl('/no_access');
    } else {
      this._router.navigateByUrl('');
    }
  }

  public updateSessionLanguage(language): void {
    localStorage['lang'] = language;
    this._glService.Language = language;
    LANGUAGE.SetLanguage(language);
  }
}
