export module LANGUAGE {
  var language = '';

  function languageSwitch(lang: string): string {
    switch (lang.toLowerCase()) {
      /* Dutch */
      case '1':
      case 'nl':
      case 'dutch':
      case 'nederlands':
        return 'nl';

      /* German */
      case '2':
      case 'de':
      case 'german':
      case 'deutsch':
        return 'de';

      /* English */
      case '0':
      case 'en':
      case 'english':
      default:
        return 'en';
    }
  }

  export function GetLanguage(): string {
    return language;
  }

  export function SetLanguage(lang: string) {
    language = languageSwitch(lang).toLowerCase();
  }

  export function GetText(key: string, lang?: string) {
    if (key == null || key == '') {
      return '';
    }

    key = key.toLocaleLowerCase();

    if (lang != null && lang != '') {
      lang = languageSwitch(lang);
    } else {
      lang = language;
    }

    if (key.indexOf('.') > -1) {
      var keys = key.split('.');
      var obj = texts[keys[0]];

      for (var i = 1; i < keys.length; i++) {
        if (obj != null) {
          obj = obj[keys[i]];
        } else {
          return key + '::' + lang;
        }
      }

      if (obj != null) {
        if (obj[lang] != null) {
          return obj[lang];
        }
      }

      return key + '::' + lang;
    }

    if (texts[key] != null && texts[key][lang] != null) {
      return texts[key][lang];
    }

    return key + '::' + lang;
  }

  var texts = {
    reports: {
      cfc: {
        report_name: {
          en: "Sleep behavior overview",
          nl: "Overzicht slaapgedrag",
          de: "Schlafverhaltensübersicht"
        },
        no_devices: {
          en: "Could't find any CFC devices.",
          nl: 'Er zijn geen CFC apparaten gevonden.',
          de: 'Es wurden keine CFC-Geräte gefunden.',
        },
        nightrest_start_time: {
          en: 'Start of night sleep',
          nl: 'Starttijd nachtrust',
          de: 'Startzeit der Nachtruhe',
        },
        nightrest_end_time: {
          en: 'End of night sleep',
          nl: 'Eindtijd nachtrust',
          de: 'Endzeit der Nachtruhe',
        },
        duration_nightrest: {
          en: 'Hours of sleep',
          nl: 'Duur nachtrust',
          de: 'Dauer der Nachtruhe',
        },
        distribution_nightrest: {
          en: 'Breakdown of sleep',
          nl: 'Verdeling nachtrust',
          de: 'Verteilung der Nachtruhe',
        },
        nightrest_interruptions: {
          en: 'Interruptions',
          nl: 'Onderbrekingen',
          de: 'Unterbrechungen',
        },
        nightrest_posture: {
          en: 'Posture',
          nl: 'Houding',
          de: 'Haltung',
        },
        time_in_bed: {
          en: 'Time in bed',
          nl: 'Tijd in bed',
          de: 'Uhrzeit im Bett',
        },
        time_out_of_bed: {
          en: 'Time out of bed',
          nl: 'Tijd uit bed',
          de: 'Zeit aus dem Bett',
        },
        total_time_nightrest: {
          en: 'Total time of nightrest',
          nl: 'Totale tijd nachtrust',
          de: 'Gesamtzeit der Nachtruhe',
        },
        total_time_in_bed: {
          en: 'Total time in bed',
          nl: 'Totale tijd in bed',
          de: 'Gesamtzeit im Bett',
        },
        total_time_out_of_bed: {
          en: 'Total time out of bed',
          nl: 'Totale tijd buiten bed',
          de: 'Gesamtzeit außerhalb des Betts',
        },
        amount: {
          en: 'Amount',
          nl: 'Aantal',
          de: 'Anzahl',
        },
        time_posture_back: {
          en: 'Time on the belly/back',
          nl: 'Tijd op de buik/rug',
          de: 'Zeit auf dem Rücken',
        },
        time_posture_belly: {
          en: 'Time on the bed edge',
          nl: 'Tijd op de bedrand',
          de: 'Zeit auf dem Bauch',
        },
        time_posture_leftside: {
          en: 'Time on the left side',
          nl: 'Tijd op de linker zij',
          de: 'Zeit auf der linken Seite',
        },
        time_posture_rightside: {
          en: 'Time on the right side',
          nl: 'Tijd op de rechter zij',
          de: 'Zeit auf der rechten Seite',
        },
      },
      reports: {
        en: 'Reports',
        nl: 'Rapportages',
        de: 'Berichte',
      },
      total_overview: {
        en: 'Total overview',
        nl: 'Totaal overzicht',
        de: 'Gesamtübersicht',
      },
      available_reports: {
        en: 'An overview of available reports',
        nl: 'Een overzicht van beschikbare rapportages',
        de: 'Eine öbersicht der verfügbaren Berichte',
      },
      response_time: {
        en: 'Response Time',
        nl: 'Reactietijd',
        de: 'Reaktionszeit',
      },
      status: {
        en: 'Statuses',
        nl: 'Statussen',
        de: 'Status',
      },
      last_24_hours: {
        en: 'Last 24 hours',
        nl: 'Afgelopen 24 uur',
        de: 'Letzte 24 Stunden',
      },
      generate_raport: {
        en: 'Generate a report',
        nl: 'Rapport genereren',
        de: 'Ein Bericht erstellen',
      },
      overview: {
        en: 'Summary',
        nl: 'Samenvatting',
        de: 'Zusammenfassung',
      },
      name: {
        en: 'Name',
        nl: 'Naam',
        de: 'Name',
      },
      print: {
        en: 'Print',
        nl: 'Print',
        de: 'Drucken',
      },
      alarm_overview_items: {
        totalalarms: {
          en: 'Total number of alarms',
          nl: 'Totaal aantal alarmmeldingen',
          de: 'Gesamtzahl Alarmmeldungen',
        },
        pickupmaximum: {
          en: 'Alarm pickup time maximum',
          nl: 'Maximale reactietijd alarm',
          de: 'Maximale Alarm-Abrufzeit',
        },
        pickupminimum: {
          en: 'Alarm pickup time minimum',
          nl: 'Minimale reactietijd alarm',
          de: 'Minimale Alarm-Abrufzeit',
        },
        pickupaverage: {
          en: 'Alarm pickup time average',
          nl: 'Gemiddelde reactietijd alarm',
          de: 'Durchschnittliche Alarm-Abrufzeit',
        },
        totaltookrepeated: {
          en: 'Total number of taken repeat alarms',
          nl: 'Totaal aantal herinneringsalarmen',
          de: 'Gesamtzahl abgerufener Alarmerinnerungen',
        },
        totalnotifiedrepeated: {
          en: 'Total notified repeat alarms',
          nl: 'Aantal gemelde herinneringsalarmen',
          de: 'Gesamtzahl gemeldeter Alarmerinnerungen',
        },
        pickupmaximumrepeated: {
          en: 'Repeat alarm pickup time maximum',
          nl: 'Maximale reactietijd herinneringsalarm',
          de: 'Maximale Alarmerinnerungs-Abrufzeit',
        },
        pickupminimumrepeated: {
          en: 'Repeat alarm pickup time minimum',
          nl: 'Minimale reactietijd herinneringsalarm',
          de: 'Minimale Alarmerinnerungs-Abrufzeit',
        },
        pickupaveragerepeated: {
          en: 'Repeat alarm pickup time average',
          nl: 'Gemiddelde reactietijd herinneringsalarm',
          de: 'Durchschnittliche Alarmerinnerungs-Abrufzeit',
        },
      },
    },
    biometrics: {
      weight: {
        en: 'Current weight',
        nl: 'Huidig gewicht',
        de: 'Aktuelles Gewicht',
      },
    },
    common: {
      configuration: {
        en: 'Configuration',
        nl: 'Configuratie',
        de: 'Aufbau',
      },
      english: {
        en: 'English',
        nl: 'Engels',
        de: 'Englisch',
      },
      dutch: {
        en: 'Dutch',
        nl: 'Nederlands',
        de: 'Niederländisch',
      },
      german: {
        en: 'German',
        nl: 'Duits',
        de: 'Deutsch',
      },
      description: {
        de: 'Beschreibung',
        nl: 'Beschrijving',
        en: 'Description',
      },
      no_filter: {
        de: 'Kein Filter',
        nl: 'Geen filter',
        en: 'No filter',
      },
      time_grouping: {
        en: 'Group (filter time)',
        nl: 'Groeperen (filter tijd)',
        de: 'Gruppieren (Filter Zeit)',
      },
      filter_options: {
        en: 'Report options',
        nl: 'Rapport opties',
        de: 'Berichtsoptionen',
      },
      delete: {
        en: 'Remove',
        nl: 'Verwijderen',
        de: 'Löschen',
      },
      timeframe: {
        en: 'Time frame',
        nl: 'Tijdsbestek',
        de: 'Zeitrahmen',
      },
      version: {
        en: 'Version',
        nl: 'Versie',
        de: 'Version',
      },
      bed: {
        en: 'In/out bed',
        nl: 'In/uit bed',
        de: 'Im/Nicht im Bett',
      },
      company: {
        en: 'Company',
        nl: 'Bedrijf',
        de: 'Unternehmen',
      },
      dashboard: {
        en: 'Dashboard',
        nl: 'Dashboard',
        de: 'Dashboard',
      },
      patient: {
        en: 'Client',
        nl: 'Cliënt',
        de: 'Klient',
      },
      patients: {
        en: 'Clients',
        nl: 'Cliënten',
        de: 'Klienten',
      },
      settings: {
        en: 'Settings',
        nl: 'Instellingen',
        de: 'Einstellungen',
      },
      analyze: {
        en: 'Analysis',
        nl: 'Analyse',
        de: 'Analyse',
      },
      alarm_status: {
        en: 'Alarm status',
        nl: 'Alarm status',
        de: 'Alarmstatus ',
      },
      connectivity: {
        en: 'Connectivity',
        nl: 'Connectiviteit',
        de: 'Konnektivität',
      },
      on: {
        en: 'On',
        nl: 'Aan',
        de: 'An ',
      },
      off: {
        en: 'Off',
        nl: 'Uit',
        de: 'Aus ',
      },
      status: {
        en: 'Status',
        nl: 'Status',
        de: 'Status',
      },
      services: {
        en: 'Services',
        nl: 'Diensten',
        de: 'Dienste',
      },
      employees: {
        en: 'Employees',
        nl: 'Medewerkers',
        de: 'Mitarbeiter',
      },
      employee: {
        en: 'Employee',
        nl: 'Medewerker',
        de: 'Mitarbeiter',
      },
      add_employee: {
        en: 'Add employee',
        nl: 'Medewerker toevoegen',
        de: 'Mitarbeiter hinzufügen',
      },
      edit_employee: {
        en: 'Edit employee',
        nl: 'Medewerker wijzigen',
        de: 'Mitarbeiter bearbeiten',
      },
      delete_employee: {
        en: 'Delete employee',
        nl: 'Medewerker verwijderen',
        de: 'Mitarbeiter löschen',
      },
      delete_employee_msg: {
        en: 'Are you sure you want to delete this employee?',
        nl: 'Weet u het zeker dat u deze medewerker wilt verwijderen?',
        de: 'Sind Sie sicher, dass Sie dieses employee löschen wollen?',
      },
      employee_name: {
        en: 'Employee name',
        nl: 'Medewerker naam',
        de: 'Mitarbeiter Name',
      },
      email: {
        en: 'Email',
        nl: 'Email',
        de: 'E-Mail',
      },
      role: {
        en: 'Role',
        nl: 'Rol',
        de: 'Rolle',
      },
      last_refresh: {
        en: 'Last refresh',
        nl: 'Laatste verversing',
        de: 'Letzte Refresh',
      },
      log_out: {
        en: 'Log out',
        nl: 'Uitloggen',
        de: 'Abmelden',
      },
      log_in: {
        en: 'Log in',
        nl: 'Inloggen',
        de: 'Log-in',
      },
      alarm_defs: {
        en: 'Alarm definitions',
        nl: 'Alarmdefinities',
        de: 'Alarmdefinitionen',
      },
      alarm: {
        en: 'Alarm',
        nl: 'Alarm',
        de: 'Alarm',
      },
      alarm_repeat: {
        en: 'Repeat alarm',
        nl: 'Herhaalalarm',
        de: 'Wiederholungsalarm',
      },
      alarm_show: {
        en: 'Show alarms',
        nl: 'Weergave alarmen',
        de: 'Alarme anzeigen',
      },
      alarm_hide: {
        en: 'Show no alarms',
        nl: 'Geen alarmen weergeven',
        de: 'Keine Alarme anzeigen',
      },
      alarms: {
        en: 'Alarms',
        nl: 'Alarmen',
        de: 'Alarme',
      },
      maintenance: {
        en: 'Maintenance',
        nl: 'Onderhoud',
        de: 'Wartung',
      },
      not_found: {
        en: 'Not found',
        nl: 'Niet gevonden',
        de: 'Nicht gefunden',
      },
      no_access: {
        en: 'No access',
        nl: 'Geen toegang',
        de: 'Keine Zugang',
      },
      alarm_history: {
        en: 'Alarm history',
        nl: 'Alarmhistorie',
        de: 'Alarm-Historie',
      },
      unknown: {
        en: 'Unknown',
        nl: 'Onbekend',
        de: 'Unbekannt',
      },
      cancel: {
        en: 'Cancel',
        nl: 'Annuleren',
        de: 'Annulieren',
      },
      teams: {
        en: 'Teams',
        nl: 'Teams',
        de: 'Teams',
      },
      active_graph: {
        en: 'Active',
        nl: 'Actief',
        de: 'Aktiv',
      },
      inactive_graph: {
        en: 'Not active',
        nl: 'Niet actief',
        de: 'Inaktiv',
      },
      active: {
        en: 'On',
        nl: 'Aan',
        de: 'Ein',
      },
      inactive: {
        en: 'Off',
        nl: 'Uit',
        de: 'Aus',
      },
      picked_up: {
        en: 'Picked up by',
        nl: 'Opgepakt door',
        de: 'Aufgenommen von',
      },
      size_alert: {
        en:
          'Some functionality is turned off due to the size of the screen.\nPlease consider using our app on mobile devices.',
        nl:
          'Sommige functionaliteiten staan uit wanwege de grootte van het scherm.\nWe raden aan om op mobiele apparaten de app te downloaden.',
        de:
          'Für einige Funktionen ist aufgrund der Größe des Bildschirms ausgeschaltet.\nBitte beachten Sie auf mobilen Geräten unsere App.',
      },
      tologin: {
        en: 'Login',
        nl: 'Log in',
        de: 'Log-in',
      },
      loggingin: {
        en: 'Logging in...',
        nl: 'Inloggen...',
        de: 'Anmeldung...',
      },
      value: {
        en: 'Value',
        nl: 'Waarde',
        de: 'Wert',
      },
      info: {
        en: 'Info',
        nl: 'Info',
        de: 'Info',
      },
      client_groups: {
        en: 'Client groups',
        nl: 'Cliëntgroepen',
        de: 'Kundengruppen',
      },
      client_group: {
        en: 'Client group',
        nl: 'Cliëntgroep',
        de: 'Kundengruppe',
      },
      client_group_name: {
        en: 'Client group name',
        nl: 'Cliëntgroep naam',
        de: 'Kundengruppename',
      },
      client_groups_settings: {
        en: 'Manage client groups',
        nl: 'Cliëntgroep(en) beheren',
        de: 'Kundengruppen verwalten',
      },
      locations: {
        en: 'Locations',
        nl: 'Locaties',
        de: 'Standorte',
      },
      manage: {
        en: 'Manage',
        nl: 'Beheren',
        de: 'Verwalten',
      },
      select: {
        en: 'Select',
        nl: 'Kiezen',
        de: 'Wählen',
      },
      deselect: {
        en: 'Deselect',
        nl: 'Deselecteren',
        de: 'Abwählen',
      },
      exit_page: {
        en: 'Back to settings',
        nl: 'Terug naar instellingen',
        de: 'Zurück zur Einstellungsseite',
      },
    },
    company: {
      settings_button_main: {
        en: 'Save settings',
        nl: 'Instellingen opslaan',
        de: 'Einstellungen speichern',
      },
      settings_title: {
        en: 'Company settings',
        nl: 'Bedrijfsinstellingen',
        de: 'Firmeneinstellungen',
      },
      settings_subtitle: {
        en: 'Adjust the settings of the company here',
        nl: 'Stel hier de instellingen van het bedrijf in',
        de: 'Passen Sie hier die Einstellungen des Unternehmens an',
      },
      settings_main_title: {
        en: 'Company settings',
        nl: 'Bedrijfsinstellingen',
        de: 'Firmeneinstellungen',
      },
      overview: {
        en: 'Organization overview',
        nl: 'Organisatie overzicht',
        de: 'Organisation-Übersicht',
      },
      overview_subtitle: {
        en: 'An overview of all organizations',
        nl: 'Een overzicht van alle organisaties',
        de: 'Ein Überblick über alle organisations',
      },
    },
    dashboard: {
      index_title_alarm: {
        en: 'Active alarms',
        nl: 'Actieve alarmen',
        de: 'Aktive Alarme',
      },
      index_subtitle_alarm: {
        en: 'All clients with an active alarm',
        nl: 'Alle cliënten met een actief alarm',
        de: 'Alle Klienten mit einem aktiven Alarm',
      },
      index_title_disconnected_devices: {
        en: 'Inactive services',
        nl: 'Niet actieve diensten',
        de: 'Inaktive Dienste',
      },
      index_title_connected_devices: {
				en: 'Active services',
				nl: 'Actieve diensten',
				de: 'Aktive Dienste',
			},
      index_title_checked: {
        en: 'Client overview',
        nl: 'Cliëntenoverzicht',
        de: 'Klient-Übersicht',
      },
      index_subtitle_checked: {
        en: 'An overview of all clients',
        nl: 'Een overzicht van alle cliënten',
        de: 'Ein Überblick über alle Klienten',
      },
      index_title_alarmhistory: {
        en: 'Alarm history',
        nl: 'Alarmhistorie',
        de: 'Alarm-Historie',
      },
      index_subtitle_alarmhistory: {
        en: 'A summary of all triggered alarms',
        nl: 'Een overzicht van alarmen die zijn afgegaan',
        de: 'Eine Zusammenfassung aller Alarme ausgelöst',
      },
      index_subtitle_employee: {
        en: 'A summary of all colleagues',
        nl: "Een overzicht van alle collega's",
        de: 'Eine Übersicht über alle Kollegen',
      },
    },
    settings: {
      action_successful: {
        en: 'Action completed successfully',
        nl: 'Actie is succesvol uitgevoerd.',
        de: 'Aktion erfolgreich abgeschlossen',
      },
      language_settings: {
        en: 'Language preference',
        nl: 'Taalvoorkeur',
        de: 'Spracheinstellung',
      },
      set_language_settings: {
        en: 'Select a language',
        nl: 'Kies een taal',
        de: 'Eine Sprache auswählen',
      },
      language: {
        en: 'Language',
        nl: 'Taal',
        de: 'Sprache',
      },
      language_settings_error: {
        en: 'Could not set new language',
        nl: 'Kon nieuwe taal niet instellen',
        de: 'Es ist ein Fehler aufgetreten',
      },
      picker_selected: {
        en: 'Selected',
        nl: 'Geselecteerd',
        de: 'Ausgewählt',
      },
      location_filter_placeholder: {
        en: 'Do not filter by location',
        nl: 'Niet filteren op locatie',
        de: 'Nicht nach Standort filtern',
      },
      teams_overview: {
        en: 'Teams overview',
        nl: 'Teams overzicht',
        de: 'Teamübersicht',
      },
      clientgroup_create: {
        en: 'Add client group',
        nl: 'Cliëntgroep toevoegen',
        de: 'Kundengruppe hinzufügen',
      },
      team_name: {
        en: 'Team name',
        nl: 'Team naam',
        de: 'Teamname',
      },
      teams_settings: {
        en: 'Manage teams',
        nl: 'Teams beheren',
        de: 'Team verwalten',
      },
      add_team: {
        en: 'Add team',
        nl: 'Team toevoegen',
        de: 'Team hinzufügen',
      },
      team_detail: {
        en: 'Team settings',
        nl: 'Team instellingen',
        de: 'Teameinstellungen',
      },
      delete_teams: {
        en: 'Delete client group',
        nl: 'Team verwijderen',
        de: 'Team entfernen',
      },
      delete_teams_confirm: {
        en: 'Are you sure you want to delete this team?',
        nl: 'Weet u het zeker dat u dit team wilt verwijderen?',
        de: 'Sind Sie sicher, dass Sie dieses Team löschen wollen?',
      },
      delete_teams_error: {
        en: 'An employee must be a member of at least one team.',
        nl: 'Een werknemer moet tenminste lid zijn van één team.',
        de: 'Ein Mitarbeiter muss Mitglied in mindestens einem Team sein.',
      },
      delete_group_error: {
        en: 'A client must be a member of at least one group.',
        nl: 'Een cliënt moet tenminste lid zijn van één groep.',
        de: 'Ein Kunde muss Mitglied mindestens einer Gruppe sein.',
      },
      patient_group_edit: {
        en: 'Client group settings',
        nl: 'Cliëntgroep instellingen',
        de: 'Kundengruppeneinstellungen',
      },
      patient_group_overview: {
        en: 'Client groups overview',
        nl: 'Cliëntgroepen overzicht',
        de: 'Kundengruppenübersicht',
      },
      delete_patient_group: {
        en: 'Delete client group',
        nl: 'Cliëntgroep verwijderen',
        de: 'Kundengruppe entfernen',
      },
      delete_patient_group_confirm: {
        en: 'Are you sure you want to delete this client group?',
        nl: 'Weet u het zeker dat u deze cliëntgroep wilt verwijderen?',
        de: 'Sind Sie sicher, dass Sie diese Kundengruppe löschen wollen?',
      },
      alarm_systems: {
        en: 'Alarm Systems',
        nl: 'Alarmsystemen',
        de: 'Alarmanlagen',
      },
      set_alarm_system_settings: {
        en: 'Configure alarm settings',
        nl: 'Configureer alarminstellingen',
        de: 'Konfigurieren Sie die Alarmeinstellungen',
      },
    },
    employee: {
      index_subtitle_employee: {
        en: 'A summary of all colleagues',
        nl: "Een overzicht van alle collega's",
        de: 'Eine Übersicht über alle Kollegen',
      },
      index_subtitle_alarm: {
        en: 'A summary of all alarm-groups',
        nl: 'Een overzicht van alle alarmeringsgroepen',
        de: 'Eine Zusammenfassung aller Alarmgruppen',
      },
      index_button_addgroup: {
        en: 'Add group',
        nl: 'Groep toevoegen',
        de: 'Gruppe hinzufügen',
      },
      index_button_deletegroup: {
        en: 'Delete group',
        nl: 'Groep verwijderen',
        de: 'Gruppe löschen',
      },
      index_title_modal: {
        en: 'Add alarm-group',
        nl: 'Alarmeringsgroepen toevoegen',
        de: 'Alarm hinzufügen',
      },
      index_subtitle_modal: {
        en: 'Add a new alarm-group here',
        nl: 'Voeg hier een nieuwe Alarmeringsgroep toe',
        de: 'Fügen Sie eine neue Alarmgruppe hier',
      },
      index_title_modal_update: {
        en: 'Update alarm-group',
        nl: 'Alarmeringsgroepen aanpassen',
        de: 'Alarmgruppe aktualisieren',
      },
      index_subtitle_modal_update: {
        en: 'Update the alarm-group here',
        nl: 'Pas hier de Alarmeringsgroep aan',
        de: 'Aktualisiere die Alarmgruppe hier',
      },
      index_deletemsg: {
        en: 'Are you sure you want to delete this group?',
        nl: 'Weet u zeker dat u deze groep wil verwijderen?',
        de: 'Sind Sie sicher, dass Sie diese Gruppe entfernen?',
      },
      role_team_lead: {
        en: 'Team lead',
        nl: 'Teamleider',
        de: 'Teamleiter',
      },
      role_administrator: {
        en: 'Administrator',
        nl: 'Beheerder',
        de: 'Administrator',
      },
      role_manufacturer: {
        en: 'Manufacturer',
        nl: 'Fabrikant',
        de: 'Hersteller',
      },
    },
    patient: {
      index_button_main: {
        en: 'Add client',
        nl: 'Cliënt toevoegen',
        de: 'Klient hinzugfügen',
      },
      index_nopatients: {
        en: 'There are no clients',
        nl: 'Er zijn geen cliënten',
        de: 'Es sind keine Kunden',
      },
      index_table_search: {
        en: 'Find client',
        nl: 'Cliënt zoeken',
        de: 'Klienten Suche',
      },
      index_table_search_text: {
        en: 'Search in all columns',
        nl: 'Zoeken in alle kolommen',
        de: 'Suche in allen Spalten',
      },
      index_table_previous: {
        en: 'Previous',
        nl: 'Vorige',
        de: 'Vorherige Seite',
      },
      index_table_next: {
        en: 'Next',
        nl: 'Volgende',
        de: 'Nächste Seite',
      },
      index_table_first: {
        en: 'First',
        nl: 'Eerst',
        de: 'Zuerst',
      },
      index_table_last: {
        en: 'Last',
        nl: 'Laatste',
        de: 'Zuletzt',
      },
      index_table_all: {
        en: 'All',
        nl: 'Alles',
        de: 'Alles',
      },
      index_table_name: {
        en: 'Name',
        nl: 'Naam',
        de: 'Name',
      },
      index_table_location: {
        en: 'Location',
        nl: 'Locatie',
        de: 'Standort',
      },
      index_table_status_update: {
        en: 'Last alarm update',
        nl: 'Laatste alarm update',
        de: 'Letzte Alarmaktualisierung',
      },
      index_table_status: {
        en: 'Status',
        nl: 'Status',
        de: 'Status',
      },
      detail_button_main: {
        en: 'Edit client',
        nl: 'Cliënt wijzigen',
        de: 'Bearbeiten Klient',
      },
      detail_subtitle_graph: {
        en: 'The posture of the client',
        nl: 'De ligging van de cliënt',
        de: 'Die Haltung des Klienten',
      },
      detail_subtitle_alarm: {
        en: 'A summary of all the alarms of the client',
        nl: 'Een overzicht van de alarmen van de cliënt',
        de: 'Eine Übersicht der Alarme des Klienten',
      },
      detail_title_alarmhistory: {
        en: 'Alarm history',
        nl: 'Alarmhistorie',
        de: 'Alarm-Historie',
      },
      detail_subtitle_alarmhistory: {
        en: 'A summary of all triggered alarms',
        nl: 'Een overzicht van alarmen die zijn afgegaan',
        de: 'Eine Übersicht aller ausgelösten Alarmmeldungen',
      },
      detail_button_case: {
        en: 'Report a fault',
        nl: 'Storing melden',
        de: 'Störung melden',
      },
      detail_casemodal_title: {
        en: 'Report a fault',
        nl: 'Storing melden',
        de: 'Störung melden',
      },
      detail_casemodal_subtitle: {
        en: 'Report a malfunction',
        nl: 'Geef een storing aan',
        de: 'Geben Sie einen Fehler',
      },
      detail_casemodal_textlabel: {
        en: 'Comment',
        nl: 'Opmerking',
        de: 'Bemerkung',
      },
      detail_casemodal_send: {
        en: 'Send',
        nl: 'Verstuur',
        de: 'Einreichen',
      },
      edit_maintitle_add: {
        en: 'Add client',
        nl: 'Cliënt toevoegen',
        de: 'Klient hinzufügen',
      },
      edit_maintitle_edit: {
        en: 'Update client',
        nl: 'Cliënt wijzigen',
        de: 'Ändern Klient',
      },
      edit_button_main: {
        en: 'Save client',
        nl: 'Cliënt opslaan',
        de: 'Speichern Klient',
      },
      edit_button_second: {
        en: 'Back to overview',
        nl: 'Terug naar overzicht',
        de: 'Zurück zur Übersicht',
      },
      edit_title_personalia: {
        en: 'Personalia',
        nl: 'Personalia',
        de: 'Personalia',
      },
      edit_subtitle_personalia: {
        en: "Enter the client's personal data here",
        nl: 'Voer hier de persoonsgegevens van de cliënt in',
        de: 'Geben Sie die persönlichen Daten des Kunden',
      },
      edit_subtitle_alarms: {
        en: 'Adjust the alarms for the client here',
        nl: 'Stel hier de alarmen in voor de cliënt',
        de: 'Einstellen der Alarme für den Klient',
      },
      edit_subtitle_servicemodal_add: {
        en: 'Add the services for the client here',
        nl: 'Voeg hier de diensten toe voor de cliënt',
        de: 'Fügen Sie die Dienstleistungen für die Kunden',
      },
      edit_subtitle_servicemodal_adjust: {
        en: 'Adjust the services for the client here',
        nl: 'Pas hier de diensten aan voor de cliënt',
        de: 'Passen Sie Dienstleistungen für die Kunden',
      },
      edit_subtitle_services: {
        en: 'The services that are connected to the client',
        nl: 'De diensten die aan de cliënt zijn gekoppeld',
        de: 'Die Dienste, die an den Klient gekoppelt sind',
      },
      edit_button_service_add: {
        en: 'Add services',
        nl: 'Diensten toevoegen',
        de: 'Hinzufügen von Dienstleistungen',
      },
      edit_button_service_adjust: {
        en: 'Adjust services',
        nl: 'Diensten aanpassen',
        de: 'Einstellen Dienstleistungen',
      },
      edit_message_noservice: {
        en: 'No service added',
        nl: 'Nog geen dienst toegevoegd',
        de: 'Kein Service hinzugefügt',
      },
      edit_button_delete: {
        en: 'Delete client',
        nl: 'Verwijder cliënt',
        de: 'Entfernen Klient',
      },
      edit_deletemsg: {
        en: 'Are you sure you want to delete this client?',
        nl: 'Weet u zeker dat u deze cliënt wil verwijderen?',
        de: 'Sind Sie sicher, dass Sie diesen Klient entfernen?',
      },
      edit_copy_alarm_modal_title: {
        en: 'Copy alarm values to other alarms',
        nl: 'Kopieer alarm-waardes naar andere alarmen',
        de: 'Kopieren Sie Alarmwerte in andere Alarme',
      },
      edit_copy_alarm_modal_subtitle: {
        en: 'Select the alarms that need to get the same values.',
        nl: 'Selecteer de alarmen die dezelfde waarden moeten krijgen.',
        de: 'Wählen Sie die Alarme aus, die dieselben Werte erhalten sollen.',
      },
      edit_button_confirm_copy: {
        en: 'Copy values',
        nl: 'Kopieer waardes',
        de: 'Werte kopieren',
      },
    },
    device: {
      device_key: {
        en: 'Serialnumber',
        nl: 'Serienummer',
        de: 'Seriennummer',
      },
      disconnected: {
        title: {
          en: 'Inactive services',
          nl: 'Niet actieve diensten',
          de: 'Inaktive Dienste',
        },
        table: {
          device_key: {
            en: 'Serialnumber',
            nl: 'Serienummer',
            de: 'Seriennummer',
          },
          client: {
            en: 'Name',
            nl: 'Naam',
            de: 'Name',
          },
          disconnect_time: {
            en: 'Time of day',
            nl: 'Tijdstip',
            de: 'Zeit',
          },
          disconnect_duration: {
            en: 'Length of time',
            nl: 'Tijdsduur',
            de: 'Dauer',
          },
          search_bar: {
            en: 'Search',
            nl: 'Zoeken',
            de: 'Suchen',
          },
          minute: {
            en: 'minutes',
            nl: 'minuten',
            de: 'Minuten',
          },
        },
      },
    },
    service: {
      index_noservices: {
        en: 'There are no services',
        nl: 'Er zijn geen services',
        de: 'Keine Dienste',
      },
    },
    error: {
      not_found: {
        en: "Oops! We couldn't find what you were looking for.",
        nl: 'Oeps! We konden niet vinden wat u zocht.',
        de: 'Ups! Wir konnten nicht finden, was Sie suchen.',
      },
      no_access: {
        en: 'Oops! You do not have access.',
        nl: 'Oeps! U heeft geen toegang.',
        de: 'Ups! Sie haben keinen Zugriff.',
      },
      not_send: {
        en:
          'Was unable to send. Please try again. If the problem persists, please contact your site administrator.',
        nl:
          'Kon niet versturen. Probeer het a.u.b. opnieuw. Als het probleem zich blijft voordoen, neem dan contact op met de admin.',
        de:
          'Kann nicht senden. Versuchen Sie es erneut bitte angeben. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Administrator.',
      },
      no_switch_tenant: {
        en:
          'At present, there are no users or devices for this tenant. At least one device must be added.',
        nl:
          'Op dit moment zijn er nog gebruikers of devices voor deze tenant. Er dient minimaal één device te zijn toegevoegd.',
        de:
          'Im Moment gibt es für diesen Pächter noch keine Benutzer oder Geräte. Es muss mindestens ein Gerät hinzugefügt werden.',
      },
      generic_error: {
        en: 'An error has occured, please try again later.',
        nl: 'Er is een probleem opgetreden, probeer het later opnieuw.',
        de: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.',
      },
    },
    content: {
      base: {
        no_results_filter: {
          en: 'No results, try another search term.',
          nl: 'Geen resultaten, probeer een andere zoekterm.',
          de: 'Keine Ergebnisse, versuchen Sie es mit einem anderen Suchbegriff.',
        },
        no_available_items: {
          en: 'There are no available items.',
          nl: 'Er zijn geen beschikbare items.',
          de: 'Es sind keine Artikel verfügbar.',
        },
        page_of: {
          en: 'out of',
          nl: 'van',
          de: 'von',
        },
        status_ok: {
          en: 'Okay',
          nl: 'In orde',
          de: 'Ordnung',
        },
        status_malfunction: {
          en: 'Not active',
          nl: 'Niet actief',
          de: 'Inaktiv',
        },
        status_pickedup: {
          en: 'Picked up',
          nl: 'Opgepakt',
          de: 'Verhaftet',
        },
        status_alarm: {
          en: 'Alarm',
          nl: 'Alarm',
          de: 'Alarme',
        },
        status_outbed: {
          en: 'Out of bed',
          nl: 'Uit bed',
          de: 'Nicht im Bett',
        },
        status_inbed: {
          en: 'In bed',
          nl: 'In bed',
          de: 'Im bett',
        },
        status_muted: {
          en: 'Muted',
          nl: 'Pauze',
          de: 'Pausiert',
        },
        add: {
          en: 'Add',
          nl: 'Toevoegen',
          de: 'Hinzufügen',
        },
        update: {
          en: 'Update',
          nl: 'Aanpassen',
          de: 'Einstellen',
        },
      },
      math: {
        greater: {
          en: 'Greater than',
          nl: 'Groter dan',
          de: 'größer als',
        },
        greater_or_equal: {
          en: 'Greater than or equal to',
          nl: 'Groter dan of gelijk aan',
          de: 'Größer als oder gleich wie',
        },
        equal: {
          en: 'Equal to',
          nl: 'Gelijk aan',
          de: 'Gleich',
        },
        less_or_equal: {
          en: 'Less than or equal to',
          nl: 'Minder dan of gelijk aan',
          de: 'Gleich oder kleiner als',
        },
        less: {
          en: 'less than',
          nl: 'minder dan',
          de: 'weniger als',
        },
        not_equal: {
          en: 'Not equal to',
          nl: 'Niet gelijk aan',
          de: 'Nicht gleichzusetzen mit',
        },
      },
      graph: {
        back: {
          en: 'Belly/Back',
          nl: 'Buik/Rug',
          de: 'Rück',
        },
        belly: {
          en: 'Edge',
          nl: 'Bedrand',
          de: 'Bauch',
        },
        leftside: {
          en: 'Left',
          nl: 'Linkerzijde',
          de: 'Links',
        },
        outofbed: {
          en: 'Out of bed',
          nl: 'Uit bed',
          de: 'Nicht im Bett',
        },
        weight: {
          en: 'Weight',
          nl: 'Gewicht',
          de: 'Gewicht',
        },
        rightside: {
          en: 'Right',
          nl: 'Rechterzijde',
          de: 'Rechts',
        },
        dropdown: {
          last_hour: {
            en: 'last hour',
            nl: 'afgelopen uur',
            de: 'letzte Stunde',
          },
          last_12_hours: {
            en: 'last 12 hours',
            nl: 'afgelopen 12 uur',
            de: 'letzte 12 Stunde',
          },
          last_24_hours: {
            en: 'last 24 hours',
            nl: 'afgelopen 24 uur',
            de: 'letzte 24 Stunde',
          },
          last_7_days: {
            en: 'last 7 days',
            nl: 'afgelopen 7 dagen',
            de: 'letzte 7 Tagen',
          },
        },
      },
      time: {
        day: {
          en: 'day',
          nl: 'dag',
          de: 'tag',
        },
        week: {
          en: 'week',
          nl: 'week',
          de: 'woche',
        },
        month: {
          en: 'month',
          nl: 'maand',
          de: 'monat',
        },
        hour: {
          en: 'hours',
          nl: 'uren',
          de: 'Stunden',
        },
        hour_small: {
          en: 'hrs.',
          nl: 'uren',
          de: 'Std.',
        },
        hour_tiny: {
          en: 'h.',
          nl: 'u.',
          de: 'S.',
        },
        minute: {
          en: 'minutes',
          nl: 'minuten',
          de: 'Minuten',
        },
        minute_small: {
          en: 'min.',
          nl: 'min.',
          de: 'Min.',
        },
        minute_tiny: {
          en: 'm.',
          nl: 'm.',
          de: 'M.',
        },
        second: {
          en: 'seconds',
          nl: 'seconden',
          de: 'Sekunden',
        },
        second_small: {
          en: 'sec.',
          nl: 'sec.',
          de: 'Sek.',
        },
        second_tiny: {
          en: 's.',
          nl: 's.',
          de: 'S.',
        },
        start_date: {
          en: 'Start date',
          nl: 'Start datum',
          de: 'Startdatum',
        },
        end_date: {
          en: 'End date',
          nl: 'Eind datum',
          de: 'Enddatum',
        },
        start_time: {
          en: 'Start time',
          nl: 'Start tijd',
          de: 'Startzeit',
        },
        end_time: {
          en: 'End time',
          nl: 'Eind tijd',
          de: 'Endzeit',
        },
        duration_time: {
          en: 'Duration time',
          nl: 'Doorlooptijd',
          de: 'Laufzeit',
        },
        time_of_day: {
          en: 'Time of day',
          nl: 'Tijdstip',
          de: 'Zeit',
        },
        date: {
          en: 'Date',
          nl: 'Datum',
          de: 'Datum',
        },
      },
      blocks: {
        alarmtrigger_editlist: {
          copy_value: {
            en: 'Copy value to other alarms',
            nl: 'Kopieer waarde naar andere alarmen',
            de: 'Kopiere den Wert in andere Alarme',
          },
          copy_timerange: {
            en: 'Copy all time frames to all alarms',
            nl: 'Kopieer alle tijdsbestekken naar alle alarmen',
            de: 'Kopiere alle Zeitrahmen auf alle Alarme',
          },
          add_timerange: {
            en: 'Add time frame',
            nl: 'Voeg tijdsbestek toe',
            de: 'Zeitrahmen hinzufügen',
          },
          remove_timerange: {
            en: 'Remove time frame',
            nl: 'Verwijder tijdsbestek',
            de: 'Zeitrahmen entfernen',
          },
        },
      },
      cases: {
        malfunction: {
          en: 'Malfunction',
          nl: 'Storing',
          de: 'Speicherung',
        },
      },
    },
    forms: {
      companysettings: {
        alarm_reminder_time: {
          en: 'Number of minutes before the repeat alarm goes off',
          nl: 'Aantal minuten voordat het herhaalalarm af gaat',
          de: 'Anzahl der Minuten, bevor der Wiederholungsalarm ausgelöst wird',
        },
        alarm_taken_time: {
          en: 'Number of minutes before the picked-up-repeat alarm goes off',
          nl: 'Aantal minuten voordat het opgepakt-herhalingsalarm af gaat',
          de: 'Anzahl der Minuten, bevor der Verhaftet-Wiederholungsalarm ausgelöst wird',
        },
        error: {
          alarm_reminder_time: {
            en: 'The value must be a number, greater than or equal to 1.',
            nl: 'De waarde moet een getal zijn, groter of gelijk aan 1.',
            de: 'Der Wert muss eine Zahl größer oder gleich 1 sein.',
          },
          alarm_taken_time: {
            en: 'The value must be a number, greater than or equal to 1.',
            nl: 'De waarde moet een getal zijn, groter of gelijk aan 1.',
            de: 'Der Wert muss eine Zahl größer oder gleich 1 sein.',
          },
        },
      },
      patient: {
        initials: {
          en: 'Initials',
          nl: 'Initialen',
          de: 'Initialen',
        },
        insertion: {
          en: 'Insertion',
          nl: 'Tussenvoegsel',
          de: 'Einfügung',
        },
        lastname: {
          en: 'Last name',
          nl: 'Achternaam',
          de: 'Nachname',
        },
        room: {
          en: 'Location',
          nl: 'Locatie',
          de: 'Ort',
        },
        error: {
          initials: {
            en: 'Please fill in the initials.',
            nl: 'U moet de initialen invullen.',
            de: 'Sie müssen in den Initialen füllen.',
          },
          insertion: {
            en: 'Please fill in the insertion.',
            nl: 'U moet valide tussenvoegsels invullen.',
            de: 'Sie müssen gültige Einsätze eingeben.',
          },
          lastname: {
            en: 'Please fill in the last name.',
            nl: 'U moet de achternaam invullen.',
            de: 'Sie müssen auf den Namen füllen.',
          },
          name: {
            en: 'Please fill in the name.',
            nl: 'U moet de naam invullen.',
            de: 'Sie müssen auf den Namen füllen.',
          },
          email: {
            en: 'Please fill in the email address.',
            nl: 'U moet het emailadres invullen.',
            de: 'Sie müssen die Email-Adresse füllen.',
          },
          language: {
            en: 'Please fill in the language.',
            nl: 'U moet de taal invullen.',
            de: 'Bitte Sprache auswählen',
          },
          team: {
            en: 'Please select at least one team.',
            nl: 'Selecteer minimaal één team.',
            de: 'Bitte mindestens ein Team auswählen',
          },
          role: {
            en: 'Please select a role.',
            nl: 'Selecteer alstublieft een rol.',
            de: 'Bitte eine Rolle auswählen.',
          },
          room: {
            en: 'Please fill in the location.',
            nl: 'U moet de locatie invullen.',
            de: 'Sie müssen den Speicherort eingeben.',
          },
        },
      },
    },
    messages: {
      titles: {
        info: {
          en: 'Info',
          nl: 'Info',
          de: 'Info',
        },
        success: {
          en: 'Thank you',
          nl: 'Bedankt',
          de: 'Vielen Dank',
        },
        warning: {
          en: 'Warning!',
          nl: 'Waarschuwing!',
          de: 'Warnung!',
        },
        error: {
          en: 'Error!',
          nl: 'Fout!',
          de: 'Error!',
        },
      },
      reportedcase_success: {
        en: 'The malfunction has been reported.',
        nl: 'De storing is gemeld.',
        de: 'Die Fehlfunktion wurde gemeldet.',
      },
      reportedcase_error: {
        en: 'The malfunction has not been reported.',
        nl: 'De storing is niet gemeld.',
        de: 'Die Fehlfunktion wurde nicht gemeldet.',
      },
    },
    modules: {
      webbeat: {
        toggler_present: {
          en: 'present',
          nl: 'aanwezig',
          de: 'Anwesend',
        },
        toggler_absent: {
          en: 'absent',
          nl: 'afwezig',
          de: 'Abwesend',
        },
      },
    },
    info_page: {
      title: {
        en: 'Service',
        nl: 'Info',
        de: 'Service',
      },
      white_block_title: {
        en: 'Visiting address digitalAngel',
        nl: 'Bezoekadres digitalAngel',
        de: 'Besuchsadresse digitalAngel',
      },
      header_block: {
        en:
          'If you have any questions, you can always contact us. E-mail us - your questions or suggestions are more than welcome. We will contact you in due course!',
        nl:
          'Als u vragen hebt kunt u natuurlijk altijd bij ons terecht. Mail ons - uw vragen of suggesties zijn meer dan welkom.  Wij nemen contact met u op.',
        de:
          'Falls Sie Fragen haben, helfen wir Ihnen natürlich jederzeit gern. Mailen Sie uns einfach – Ihre Fragen oder Vorschläge sind mehr als willkommen. Wir nehmen dann Kontakt mit Ihnen auf.',
      },
      portal_version: {
        en: 'Version number portal:',
        nl: 'Versienummer portaal:',
        de: 'Portal-Version Nr.:',
      },
      api_version: {
        en: 'Api Release version:',
        nl: 'Api release versie:',
        de: 'Api Release Version:',
      },
      production_date: {
        en: 'Production date:',
        nl: 'Productiedatum:',
        de: 'Erstellungsdatum:',
      },
      warning: {
        en: 'Please note:',
        nl: 'Let op:',
        de: 'Achtung:',
      },
      line_one: {
        en: 'this product requires a working internet connection.',
        nl: 'dit product is afhankelijk van een werkende internetverbinding.',
        de: 'Dieses Produkt benötigt eine funktionierende Internetverbindung.',
      },
      line_two: {
        en: 'Check the signal and functioning before each use.',
        nl: 'Controleer voorafgaand aan ieder gebruik het signaal en de werking.',
        de: 'Bitte vor jeder Nutzung Signal und Funktion prüfen.',
      },
      line_three: {
        en: 'Please read the manual before use.',
        nl: 'Lees voor gebruik de handleiding.',
        de: 'Bitte vor der Nutzung die Anleitung durchlesen.',
      },
      privacy: {
        en: 'Privacy',
        nl: 'Privacy',
        de: 'Datenschutz',
      },
      made_in: {
        en: '>> Made in the Netherlands <<',
        nl: '>> Made in the Netherlands <<',
        de: '>> In den Niederlanden hergestellt <<',
      },
    },
    maintenance: {
      title: {
        en: 'The application is currently down for maintenance',
        nl: 'De applicatie is in onderhoud',
        de: 'The application is currently down for maintenance',
      },
      message: {
        en: 'Try again later',
        nl: 'Probeer het over een paar minuten opnieuw',
        de: 'Try again later',
      },
    },
    documents: {
      privacy: {
        en: 'https://static.isatis.com/files/hallow/privacy.pdf',
        nl: 'https://static.isatis.com/files/hallow/privacy.pdf',
        de: 'https://static.isatis.com/files/hallow/privacy.pdf',
      },
    },
    graph: {
      alarm: {
        en: 'Alarm',
        nl: 'Alarm',
        de: 'Alarm',
      },
      repeatalarm: {
        en: 'Repeat alarm',
        nl: 'Herhaalalarm',
        de: 'Wiederholungsalarm',
      },
    },
  };
}
