<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false" [loading]="isLoading">
	<div block-header>
		<div class="color_green"></div>
		<div class="row">
			<div class="col-xs-12">
				<h2>{{GetText('common.services')}}</h2>
			</div>
		</div>
	</div>
	<div block-body>
		<div *ngIf="!data || data.length === 0" class="no-data">{{GetText('service.index_noservices')}}</div>
		<app-table *ngIf="data && data.length > 0" [rows]="rows" [columns]="columns"
		[config]="config" [data]="data"
		[length]="length"
		[placeholder]="placeholderTable"
		[allText]="allText"
		(tableCellClicked)="tableCellClicked($event)">
		</app-table>
	</div>
</wb-grid-headerBlocks>
