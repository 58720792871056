import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { AlarmService } from '../services/alarm.service';
import { PatientService } from '../services/patient.service';
import { EmployeeService } from '../services/employee.service';
import { Page } from '../pages/pages.parent';
import { ActiveAlarmResult } from '../classes/results.class';
import { Employee } from '../classes/employee.class';
import { Patient } from '../classes/patient.class';
import { AlarmHistory } from '../classes/alarm.class';
import { SETTINGS } from '../settings';
import { DeviceService } from 'app/services/device.service';
import { DisconnectedDevice } from 'app/classes/DisconnectedDevice';
import { LoadTestingService } from 'app/services/loadtesting.service';
import { forkJoin, Observable, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'page-dashboard',
  templateUrl: './dashboard.html',
})
export class PageDashboardComponent extends Page implements OnInit {
  public employees: Employee[];
  public alarmLogs: AlarmHistory[];
  public timer: number = 86400; //24hrs
  private _isDoneLoading = false;

  public get patients(): Patient[] {
    return this._patients;
  }
  public set patients(value: Patient[]) {
    this._patients = value;
    this.UpdatePatientStatus();
  }

  public get devices(): DisconnectedDevice[] {
    return this._disconnectedDevice;
  }
  public set devices(value: DisconnectedDevice[]) {
    this._disconnectedDevice = value;
  }

  public get activeAlarms(): ActiveAlarmResult[] {
    return this._activeAlarms;
  }
  public set activeAlarms(value: ActiveAlarmResult[]) {
    this._activeAlarms = this.FilterAlarms(value);
    this.UpdatePatientStatus();
  }

  public get alarmPercent(): number {
    if (this.patients == null || this.patients.length == 0) {
      return 0;
    }
    return Math.ceil((this._badPatients.length / this.patients.length) * 100);
  }
  public get finePercent(): number {
    if (this.patients == null || this.patients.length == 0) {
      return 0;
    }
    return 100 - this.alarmPercent;
  }

  private _patients: Patient[];
  private _disconnectedDevice: DisconnectedDevice[];
  private _activeAlarms: ActiveAlarmResult[];
  private _badPatients: number[];

  private graphTime: number = 86400;
  private interval: number;
  public isManufacturer: boolean = true;

  constructor(
    public glService: GlobalService,
    private _alarmService: AlarmService,
    private _patientService: PatientService,
    private _employeeService: EmployeeService,
    private _loadTestingService: LoadTestingService,
    private _deviceService: DeviceService
  ) {
    super(glService, 'Dashboard');

    this.SetTitle(this.GetText('common.dashboard'));
  }

  ngOnInit() {
    this._watchDataLoaded().subscribe();
    this._getDashboardData();
    this.isManufacturer = this.glService.isManufacturer();
  }

  private _getDashboardData(): void {
    forkJoin([this._alarmService.getActiveAlarms(), this._patientService.getPatients()]).subscribe(
      ([activeAlarms, patients]) => {
        for (var i in activeAlarms) {
          if (activeAlarms[i].alarmTrigger == null) {
            (activeAlarms[i].alarmTrigger as any) = {};
          }
        }

        this.activeAlarms = activeAlarms;
        this.patients = patients; // NEEDS TO BE AFTER ACTIVE_ALARMS, old comment...
        this._checkDoneLoading();
      }
    );

    this._alarmService.getAlarmHistory(this.graphTime).subscribe((res) => {
      this.alarmLogs = res;
      this._checkDoneLoading();
    });

    this._deviceService.getDisconnectedDevices().subscribe((res) => {
      this.devices = res;
      this._checkDoneLoading();
    });

    this._employeeService.getEmployees().subscribe((res) => {
      this.employees = this.OrderEmployees(res);
      this._checkDoneLoading();
    });
  }

  private _checkDoneLoading(): void {
    if (this._isDoneLoading) return;

    this._isDoneLoading =
      !!this.employees &&
      !!this.devices &&
      !!this.alarmLogs &&
      !!this.patients &&
      !!this.activeAlarms;
  }

  private _watchDataLoaded(): Observable<void> {
    if (!this._isDoneLoading) {
      return of(null).pipe(
        delay(500),
        mergeMap(() => {
          return this._watchDataLoaded();
        })
      );
    } else {
      this._loadTestingService.stopLoading();
      this.interval = SETTINGS.AddInterval(
        () => {
          this._getDashboardData();
        },
        15000,
        this.interval
      );
      return of(null);
    }
  }

  private UpdatePatientStatus() {
    if (this.patients != null && this.patients.length > 0) {
      this.patients.forEach((patient: Patient) => {
        if (!patient.isConnected) {
          patient.alarmStatus = 'malfunction';
        } else {
          if (patient.alarmStatusIconId === 130) {
            patient.alarmStatus = 'muted';
          } else if (patient.alarmStatusIconId === 120) {
            patient.alarmStatus = 'alarm';
          }

          if (this.activeAlarms != null && this.activeAlarms.length > 0) {
            var alarmStatus: Array<number> = [];
            this.activeAlarms
              .filter((a) => a.patient.id == patient.id)
              .forEach((a) => {
                alarmStatus.push(a.alarm.status);
              });

            if (alarmStatus.indexOf(30) >= 0) {
              patient.alarmStatus = 'taken';
            } else if (
              alarmStatus.indexOf(10) >= 0 ||
              alarmStatus.indexOf(20) >= 0 ||
              alarmStatus.indexOf(24) >= 0 ||
              alarmStatus.indexOf(25) >= 0 ||
              alarmStatus.indexOf(34) >= 0 ||
              alarmStatus.indexOf(35) >= 0
            ) {
              patient.alarmStatus = 'alarm';
            } else if (alarmStatus.indexOf(70) >= 0 || alarmStatus.indexOf(80) >= 0) {
              patient.alarmStatus = 'muted';
            }
          }
        }
      });
    }
  }

  private FilterAlarms(alarms: ActiveAlarmResult[]): ActiveAlarmResult[] {
    var result: ActiveAlarmResult[] = [];

    if (alarms !== null || alarms !== undefined) {
      for (var i in alarms) {
        switch (alarms[i].alarm.status) {
          case 10:
          case 20:
          case 24:
          case 25:
            result.push(alarms[i]);
            break;
          case 30:
          case 34:
          case 35:
            alarms[i].alarm.pickedUp = true;
            result.push(alarms[i]);
            break;
          case 40:
          case 50:
          case 60:
            /* no alarm */
            break;
          case 70:
            /* muted */
            alarms[i].alarm.muted = true;
            result.push(alarms[i]);
            break;
          case 130:
            alarms[i].alarm.muted = true;
            result.push(alarms[i]);
            break;
        }
      }
    }

    return result;
  }

  private OrderEmployees(employees: Employee[]): Employee[] {
    let result: Employee[] = [];

    result = employees.sort((a: Employee, b: Employee) => {
      let aName = a.name ? a.name : '';
      let bName = (b.name ? b.name : '').toLowerCase();

      const nameSort = aName.localeCompare(bName);

      if (nameSort === 0) {
        if (a.id > b.id) {
          return 1;
        } else if (a.id < b.id) {
          return -1;
        }
      }

      return nameSort;
    });

    return result;
  }
}
