import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SETTINGS } from '../settings';
import { wbBrowserStorage } from 'app/modules/webbeat/service/browserStorage.service';
import { PutPatientGroup } from 'app/classes/api-models/put-patient-group.class';


@Injectable()
export class PatientGroupService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient, private _wbStorage: wbBrowserStorage) {
	}

	public getPatientGroupByTeamId(teamId: number): Observable<any> {
		return this._http.get(`${this.baseUrl}PatientGroup/ByTeam/${teamId}`);
	}

	public getPatientGroupsByCompanyId(companyId?: number): Observable<any> {
		if (!companyId) {
			this._wbStorage.getStorageItem('user.groups[0].companyId');
		}
		return this._http.post(`${this.baseUrl}PatientGroup/GetByCompany?companyId=${companyId}`, {});
  }

  public getPatientsInPatientGroup(patientGroupId: number): Observable<any> {
    return this._http.get(`${this.baseUrl}PatientGroup/${patientGroupId}/Patients`);
  }

  public getAllPatientGroups(): Observable<any> {
    return this._http.get(`${this.baseUrl}PatientGroups`);
  }

  public getPatientGroupById(ptgId: number): Observable<any> {
    return this._http.get(`${this.baseUrl}PatientGroup/${ptgId}`);
  }

  public getPatientsByPatientGroupId(ptgId: number): Observable<any> {
    return this._http.get(`${this.baseUrl}PatientGroup/${ptgId}/Patients`);
  }

  public putPatientGroup(putModel: PutPatientGroup): Observable<any> {
    return this._http.put(`${this.baseUrl}PatientGroup/${putModel.patientGroupId}`, putModel);
  }

  public addPatientGroupToTeam(teamId: number, patientGroupId: number): Observable<any> {
    return this._http.post(`${this.baseUrl}PatientGroup/${patientGroupId}/Teams/${teamId}`, {})
  }

  public removePatientGroupFromTeam(teamId: number, patientGroupId: number): Observable<any> {
    return this._http.delete(`${this.baseUrl}PatientGroup/${patientGroupId}/Teams/${teamId}`)
  }

  public addPatientToPatientGroup(patientId: number, patientGroupId: number): Observable<any> {
    return this._http.post(`${this.baseUrl}PatientGroup/${patientGroupId}/Patients/${patientId}`, {})
  }

  public removePatientFromPatientGroup(patientId: number, patientGroupId: number): Observable<any> {
    return this._http.delete(`${this.baseUrl}PatientGroup/${patientGroupId}/Patients/${patientId}`)
  }

  public deletePatientGroup(ptgId: number): Observable<any> {
    return this._http.delete(`${this.baseUrl}PatientGroup/${ptgId}`);
  }

  public postPatientGroup(model: PutPatientGroup): Observable<any> {
    return this._http.post(`${this.baseUrl}PatientGroup`, model);
  }
}
