import { Component, Input, OnInit } from '@angular/core';

import { PagingExtension } from '../classes/extensions/paging.extension';
import { GlobalService } from '../services/global.service';
import { AlarmService } from '../services/alarm.service';
import { AlarmTrigger } from '../classes/alarm.class';
import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';
import { wbcNumberText } from '../modules/webbeat/classes/wbcNumberText.class';
import { wbcAny } from '../modules/webbeat/classes/wbcAny.class';
import { wbcOption } from '../modules/webbeat/classes/wbcOption.class';
import { RoleService } from '../authorization/services/role.service';
import { wbcMenuButton } from '../modules/webbeat/classes/wbcButton.class';

//import { DatePipe } from '@angular/common';


@Component({
	selector: 'alarmTriggerEdit-list',
	templateUrl: './alarmTriggerEdit-list.html',
	styleUrls: [
		'./alarm-list.css'
	]
})
export class AlarmTriggerEditListComponent extends PagingExtension implements OnInit {
	@Input() submitted?: Function;

	@Input('alarmtriggers')
	public get alarmtriggers(): AlarmTrigger[] { return <AlarmTrigger[]>this.pageItems; };
	public set alarmtriggers(value: AlarmTrigger[]) { this.items = this.tempFix(value); this.Build(); this.buildAlarmValues(); this.buildDropdowns(); }

	public alarmvalues: wbcNumberText[];
	public dropdownValues: wbcAny[];
	public dropdownOptions: wbcOption[];

	public typeOverride: (number | string)[];

	public copyAlarmModalShown: wbcToggler;
	public possibleAlarms: AlarmTrigger[];
	public possAlarmTogglers: wbcToggler[];
	public toBeCopiedAlarm: AlarmTrigger;


	public get updateAlarmActiveStartB(): Function {
		return (value: string, props: any) => {
			this.updateAlarmActiveStart(props.alarm_id, props.alarm, props.range_id, value);
		}
	};

	public get updateAlarmActiveEndB(): Function {
		return (value: string, props: any) => {
			this.updateAlarmActiveEnd(props.alarm_id, props.alarm, props.range_id, value);
		}
	};


	private timers: any;

	private rangeDeletes: Array<number> = [];
	public isManufacturer: boolean = true;
	public copyAlarmsButtons: wbcMenuButton[] = [];
	public addRangeButtons: wbcMenuButton[] = [];
	public copyRangeButtons: wbcMenuButton[] = [];


	constructor(private glService: GlobalService, private alarmService: AlarmService) {
		super();

		this.alarmvalues = [];
		this.dropdownOptions = [
			new wbcOption(new wbcAny(10), this.GetText('content.math.greater')),
			new wbcOption(new wbcAny(20), this.GetText('content.math.greater_or_equal')),
			new wbcOption(new wbcAny(30), this.GetText('content.math.equal')),
			new wbcOption(new wbcAny(40), this.GetText('content.math.less_or_equal')),
			new wbcOption(new wbcAny(50), this.GetText('content.math.less')),
			new wbcOption(new wbcAny(60), this.GetText('content.math.not_equal')),
		];

		this.timers = {};

		this.copyAlarmModalShown = new wbcToggler(false);
	};
	ngOnInit() {
		this.isManufacturer = this.glService.isManufacturer();
		if (this.alarmtriggers != null) {
			for (let i = 0; i < this.alarmtriggers.length; i++) {
				this.copyAlarmsButtons.push(new wbcMenuButton(this.GetText('content.blocks.alarmtrigger_editlist.copy_value'),
					() => { this.showModel('alarmCopy', i) }, null, true, this.isManufacturer));
				this.addRangeButtons.push(new wbcMenuButton(this.GetText('content.blocks.alarmtrigger_editlist.add_timerange'),
					() => { this.AddRange(i, this.alarmtriggers[i]) }, 'secondary', false, this.isManufacturer));
				this.copyRangeButtons.push(new wbcMenuButton(this.GetText('content.blocks.alarmtrigger_editlist.copy_timerange'),
					() => { this.CopyRanges(i, this.alarmtriggers[i]) }, null, true, this.isManufacturer));
			}
		}
	}

	public GetIcon(icon: string) {
		return this.glService.GetIconClass(icon);
	};


	public updateAlarmSeconds(id: number, alarm: AlarmTrigger, $ev: any) {
		alarm.actualSeconds = parseInt((<string>$ev).replace(/[^0-9]/g, ''));
		if (isNaN(alarm.actualSeconds)) { alarm.actualSeconds = 0; }
		this.POST(id);
	};

	public updateAlarmMinutes(id: number, alarm: AlarmTrigger, $ev: any) {
		alarm.minutes = parseInt((<string>$ev).replace(/[^0-9]/g, ''));
		if (isNaN(alarm.minutes)) { alarm.minutes = 0; }
		this.POST(id);
	};

	public updateAlarmHours(id: number, alarm: AlarmTrigger, $ev: any) {
		alarm.hours = parseInt((<string>$ev).replace(/[^0-9]/g, ''));
		if (isNaN(alarm.hours)) { alarm.hours = 0; }
		this.POST(id);
	};

	public selectOption(id: number) {
		this.alarmtriggers[id].operator = this.dropdownValues[id].value;
		this.POST(id);
	};

	public copyAlarmValue() {
		if (this.toBeCopiedAlarm != null && this.toBeCopiedAlarm.id > 0) {
			let list: AlarmTrigger[] = [];
			let alarmIndex: number = -1;

			for (let ind = 0; ind < this.alarmtriggers.length; ind++) {
				if (this.alarmtriggers[ind].id == this.toBeCopiedAlarm.id) {
					alarmIndex = ind;
					break;
				}
			}

			if (alarmIndex > -1) {
				for (let i = 0; i < this.possAlarmTogglers.length; i++) {
					if (this.possAlarmTogglers[i].isToggled) {
						for (let j = 0; j < this.alarmtriggers.length; j++) {
							if (this.alarmtriggers[j].id == this.possibleAlarms[i].id) {
								/* copy */
								this.alarmtriggers[j].actualSeconds = this.toBeCopiedAlarm.actualSeconds;
								this.alarmtriggers[j].minutes = this.toBeCopiedAlarm.minutes;
								this.alarmtriggers[j].hours = this.toBeCopiedAlarm.hours;

								this.dropdownValues[j].value = this.dropdownValues[alarmIndex].value;
								this.typeOverride[j] = this.dropdownValues[alarmIndex].value;
							}
						}
					}
				}

				this.hideModel('alarmCopy');
			}
		}
	};
	//public copyAlarmValue(alarmIndex: number, alarm: AlarmTrigger) {
	//    if (this.alarmtriggers != null) {
	//        for (var i in this.alarmtriggers) {
	//            if (this.alarmtriggers[i] !== alarm) {
	//				this.alarmtriggers[i].actualSeconds = alarm.actualSeconds;
	//                this.alarmtriggers[i].minutes = alarm.minutes;
	//                this.alarmtriggers[i].hours = alarm.hours;

	//                this.dropdownValues[i].value = this.dropdownValues[alarmIndex].value;
	//                this.typeOverride = this.dropdownValues[alarmIndex].value;
	//                setTimeout(()=>{
	//                    this.typeOverride = null;
	//                }, 100);
	//            }
	//        }
	//    }

	//    this.POST(alarmIndex);
	//};

	public CopyRanges(alarmIndex: number, alarm: AlarmTrigger) {
		if (this.alarmtriggers != null) {
			for (var i in this.alarmtriggers) {
				if (this.alarmtriggers[i] !== alarm) {
					this.alarmtriggers[i].ranges = [];

					if (alarm.ranges != null && alarm.ranges.length > 0) {
						for (var r in alarm.ranges) {
							this.alarmtriggers[i].ranges.push({
								id: 0,
								start: alarm.ranges[r].start,
								end: alarm.ranges[r].end
							});
						}
					}

				}
			}

			this.POST(alarmIndex);
		}
	};

	public hideModel(type: string) {
		switch (type) {
			case 'alarmCopy':
				this.copyAlarmModalShown = new wbcToggler(false);
				break;
		}
	};

	public showModel(type: string, index: number) {
		switch (type) {
			case 'alarmCopy':
				this.possAlarmTogglers = [];
				this.possibleAlarms = [];

				for (let i = 0; i < this.alarmtriggers.length; i++) {
					if (i == index) {
						this.toBeCopiedAlarm = AlarmTrigger.Convert(this.alarmtriggers[i]);
					}
					else {
						this.possAlarmTogglers.push(new wbcToggler(false));
						this.possibleAlarms.push(AlarmTrigger.Convert(this.alarmtriggers[i]));
					}
				}

				this.copyAlarmModalShown = new wbcToggler(true);
				break;
		}
	};

	public AddRange(alarmIndex: number, alarm: AlarmTrigger) {
		if (alarm == null) {
			return;
		}

		if (alarm.ranges == null) {
			alarm.ranges = [];
		}

		alarm.ranges.push({ id: 0, start: '', end: '' });

		this.POST(alarmIndex);
	}

	public RemoveRange(alarmIndex: number, alarm: AlarmTrigger, index: number) {
		if (alarm == null || alarm.ranges == null || index < 0 || index >= alarm.ranges.length) {
			return;
		}

		var a = alarm.ranges.splice(index, 1);
		this.addToRemove(a[0].id);

		this.POST(alarmIndex);
	}

	public updateAlarmActiveStart(alarm_id: number, alarm: AlarmTrigger, range_id: number, value: string) {
		try {
			alarm.ranges[range_id].start = value;
		}
		catch (e) { }
		//alarm.activeStart = $ev;
		this.POST(alarm_id);
	};

	public updateAlarmActiveEnd(alarm_id: number, alarm: AlarmTrigger, range_id: number, value: string) {
		try {
			alarm.ranges[range_id].end = value;
		}
		catch (e) { }
		//alarm.activeEnd = $ev;
		this.POST(alarm_id);
	};

	private addToRemove(id: number) {
		if (id != null && id > 0) {
			for (var i in this.rangeDeletes) {
				if (this.rangeDeletes[i] == id) {
					return;
				}
			}

			this.rangeDeletes.push(id);
		}
	};



	private buildAlarmValues() {
		this.alarmvalues = [];

		if (this.alarmtriggers != null && this.alarmtriggers.length > 0) {
			for (var i = 0; i < this.alarmtriggers.length; i++) {
				this.alarmvalues.push(new wbcNumberText('' + this.alarmtriggers[i].seconds /*, (id: number) => {
					this.update(id);
        }, i*/));
      }
    }
	};

	private buildDropdowns() {
		this.dropdownValues = [];
		this.typeOverride = [];

		var func = (id: number) => { this.selectOption(id); };
    if (this.alarmtriggers) {
      for (var i = 0; i < this.alarmtriggers.length; i++) {
        this.dropdownValues.push(new wbcAny(this.alarmtriggers[i].operator, func, i));
        this.typeOverride.push(null);
      }
    }
	};

	private POST(id: number) {
		this.alarmtriggers[id].seconds = 0;

		/* update validator */
		if (this.alarmtriggers[id].actualSeconds < 0) {
			this.alarmtriggers[id].actualSeconds = 0;
		}

		if (this.alarmtriggers[id].minutes < 0) {
			this.alarmtriggers[id].minutes = 0;
		}

		if (this.alarmtriggers[id].hours < 0) {
			this.alarmtriggers[id].hours = 0;
		}

		if (!this.isManufacturer) {
			if (this.submitted != null && this.submitted != undefined) {
				this.submitted(this.alarmtriggers[id], true, false, this.rangeDeletes);
			}
		}
	};

	private tempFix(alarms: AlarmTrigger[]): AlarmTrigger[] {
		if (alarms != null && alarms.length > 0) {
			for (var a in alarms) {
				alarms[a].nameValue = alarms[a].name;
				if (alarms[a].value != alarms[a].name) {
					alarms[a].nameValue += ': ' + alarms[a].value;
				}
			}
		}
		return alarms;
	}
}
