<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let tenant of tenants" [attr.data-tenant-consumer-id]="tenant?.tenantConsumerId">
				<td class="col-xs-4">
					<div class="ng_top">
						<span>{{tenant.tenantOwnerName}}</span>
					</div>
				</td>
				<td class="col-xs-1">
					<button *ngIf="!tenant.isActive" class="button primary" (click)="switchTenant(tenant.tenantConsumerId)">Selecteer</button>
					<span *ngIf="tenant.isActive">Huidig</span>
					<!-- Knop of actief text -->
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>