import { Directive, ElementRef, Input } from '@angular/core';

import { SETTINGS } from '../../../../../settings';

@Directive({
	selector: '[wb-modal-timepicker]',
})
export class wbModalTimepickerDirective {
	@Input() onUpdate: Function;
	@Input() onUpdateProps: any;

	constructor(private _el: ElementRef) {
		SETTINGS.jquery(this._el.nativeElement).clockpicker({
			autoclose: true,
			afterDone: (a: any, b: any) => {
				if (this.onUpdate != null && typeof this.onUpdate == 'function') {
					this.onUpdate(this._el.nativeElement.value, this.onUpdateProps);
				}
			}
		});
	};
}