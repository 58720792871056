export class wbcToggler {
	public updateFunction?: Function;
	public id?: number;

	public get isToggled(): boolean { return this._isToggled; };
	public set isToggled(value: boolean) { this._isToggled = value; if (this.updateFunction != undefined) { this.updateFunction(this.id); } };


	private _isToggled: boolean;
	public isDisabled: boolean;


	constructor(isToggled: boolean, updateFunction?: Function, id?: number, isDisabled?: boolean) {
		this.isToggled = isToggled === true;
		this.isDisabled = isDisabled === true;

		this.id = id;
		this.updateFunction = updateFunction;
	};
}