import { NgForm, FormGroup } from '@angular/forms';

import { LANGUAGE } from '../language';


export interface IForms {
	form: NgForm;

	onSubmit() :boolean;
}


export class BaseForm {
	public form: FormGroup;

	//constructor() {
	//	this.form = new FormGroup();
	//}


	onSubmit() :boolean {
		if (this.form != null && this.form.valid) {
			return true;
		}

		return false;
	}


	public GetText(key:string, lang?: string) :string {
		return LANGUAGE.GetText(key, lang);
	};
}