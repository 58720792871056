import { PostTeamEmployee } from './../classes/api-models/post-team-employee.class';
import { PutTeamModel } from './../classes/api-models/put-team.class';
import { SETTINGS } from './../settings';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TeamService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient) {
	}

	public getAllTeamsByGroup(groupId: number): Observable<any> {
		return this._http.get(`${this.baseUrl}Teams`, { headers: {'group': groupId.toString()} });
	}

	public getTeamById(groupId: number, teamId: number): Observable<any> {
		return this._http.get(`${this.baseUrl}Team/${teamId}`, { headers: {'group': groupId.toString()} });
	}

	public getTeamByPatientGroupId(groupId: number, ptgId: number): Observable<any> {
		return this._http.get(`${this.baseUrl}Team/ByPatientGroupId?ptgid=${ptgId}`, { headers: {'group': groupId.toString()} });
	}

	public getEmployeesFromTeam(groupId: number, teamId: number): Observable<any> {
		return this._http.get(`${this.baseUrl}v2/Employees?id=${teamId}&type=Team`);
	}

	public postTeam(model: PutTeamModel): Observable<any> {
		return this._http.post(`${this.baseUrl}Team`, model);
	}

	public putTeam(model: PutTeamModel): Observable<any> {
		return this._http.put(`${this.baseUrl}Team/${model.teamId}`, model);
	}

	public postEmployeeToTeam(model: PostTeamEmployee): Observable<any> {
		return this._http.post(`${this.baseUrl}Team/${model.teamId}/Employees`, model);
	}

	public deleteEmployeeFromTeam(teamId: number, employeeId: number): Observable<any> {
		return this._http.delete(`${this.baseUrl}Team/${teamId}/Employees/${employeeId}`);
  }

  public deleteTeam(teamId: number): Observable<any> {
    return this._http.delete(`${this.baseUrl}Team/${teamId}`);
  }

	// public deleteTeam(teamId: number): Observable<any> {
	// 	return this._http.request(`${this.baseUrl}Team`, { body: JSON.stringify({teamId: teamId}) });
	// }

}
