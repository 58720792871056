import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SortingOption } from 'app/classes/sorting-option.class';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() rows: Array<any> = [];
  @Input() columns: Array<any> = [];
  @Input() tableId: string;
  @Input() page = 1;
  @Input() itemsPerPage = 10;
  @Input() maxSize = 9999;
  @Input() numPages = 1;
  @Input() length = 0;
  @Input() config: any;
  @Input() searchText: string;
  @Input() data: Array<any> = [];
  @Input() placeholder: string;
  @Input() allText: string;

  @Output() refreshTable: EventEmitter<any> = new EventEmitter();
  @Output() tableCellClicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.onChangeTable(this.config);
  }

  ngOnChanges() {
    this.onChangeTable(this.config);
  }

  public changeTableSize(amount: number): void {
    this.itemsPerPage = amount;
    this.onChangeTable(this.config);
  }

  public onCellClick(data: any): void {
    this.tableCellClicked.emit(data);
  }

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    const filteredData = this.changeFilter(this.data, this.config);
    const sortedData = this.changeSort(filteredData, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    this.length = sortedData.length;
    this.refreshTable.emit([this.rows, this.data, this.length]);
  }

  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    const start = (page.page - 1) * page.itemsPerPage;
    const end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    const columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting

    data.sort(Intl.Collator().compare);

    if (this.tableId) {
      this._setStoredSortingOptions(sort, columnName);
    }

    return data;
  }

  public changeFilter(data: any, config: any): any {
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          return item[column.name].toLowerCase().match(column.filtering.filterString.toLowerCase());
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].toLowerCase().match(this.config.filtering.filterString.toLowerCase()));
    }

    const tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name].toString().toLowerCase().match(this.config.filtering.filterString.toLowerCase())) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  private _setStoredSortingOptions(sort: string, columnName: string): void {
    let user = JSON.parse(localStorage['user']);
    let sortingOptions = new SortingOption(user.id, this.tableId, sort, columnName);

    let storedOptions = new Array<SortingOption>();
    let storedOptionsData = localStorage['sortingOptions'];
    if (storedOptionsData) {
      storedOptions = JSON.parse(storedOptionsData);
    }

    // are there any stored options? If not, returns -1
    let storageIndex: number = storedOptions.findIndex(sortingOptions => sortingOptions.userId === user.id);

    if (storageIndex === -1) {
      // this user does not have any options stored yet; set the storedOptions to sortingOptions
      storedOptions.push(sortingOptions);
    } else {
      // overwrite the storedOptions with the new sortingOptions
      storedOptions[storageIndex] = sortingOptions;
    }

    // stringify sortingOptions because localStorage only takes a string
    localStorage["sortingOptions"] = JSON.stringify(storedOptions);
  }
}
