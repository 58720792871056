import { Setting } from './setting.class';

export class CompanySettings {
	public id: number;

	public alarmReminderTime?: number;
	public alarmTakenReminderTime?: number;


	public get canAlarmReminderTime() : boolean { if (this._getcanAlarmReminderTime == null) { this._getcanAlarmReminderTime = this.canSetById(1); } return this._getcanAlarmReminderTime; };
	public get canAlarmTakenReminderTime() : boolean { if (this._getcanAlarmTakenReminderTime == null) { this._getcanAlarmTakenReminderTime = this.canSetById(3); } return this._getcanAlarmTakenReminderTime; };

	private _settings: Setting[];
	private _getcanAlarmReminderTime?: boolean = null;
	private _getcanAlarmTakenReminderTime?: boolean = null;


	constructor(settings?: Setting[]) {
		if (settings == null) {
			this._settings = [];
		}
		else {
			this._settings = settings;
			this.id = this._settings[0].entityId;

			this.alarmReminderTime = this.parseNullableNumber(this.getValueById(1), true);
			this.alarmTakenReminderTime = this.parseNullableNumber(this.getValueById(3), true);
		}
	};


	public static Convert(obj: any) : CompanySettings {
		let result: CompanySettings = new CompanySettings();

		try { result.id = obj.id; } catch(e){}
		try { result.alarmReminderTime = obj.alarmReminderTime; } catch(e){}
		try { result.alarmTakenReminderTime = obj.alarmTakenReminderTime; } catch(e){}

		try { result._settings = obj._settings; } catch(e){}
		
		return result;
	};

	public getSettings() : Setting[] {
		if (this._settings == null) {
			return [];
		}

		this.setValueById(1, (this.alarmReminderTime == null ? null : '' + this.alarmReminderTime));
		this.setValueById(3, (this.alarmTakenReminderTime == null ? null : '' + this.alarmTakenReminderTime));

		return this._settings;
	};


	private getValueByKey(key: string) : string {
		if (this._settings != null && this._settings.length > 0) {
			for (let i = 0; i < this._settings.length; i ++) {
				if (this._settings[i].entityType == 'com' && this._settings[i].settingType == key) {
					return this._settings[i].settingValue;
				}
			}
		}

		return null;
	};

	private getValueById(settingId: number) : string {
		if (this._settings != null && this._settings.length > 0) {
			for (let i = 0; i < this._settings.length; i ++) {
				if (this._settings[i].entityType == 'com' && this._settings[i].settingId == settingId) {
					return this._settings[i].settingValue;
				}
			}
		}

		return null;
	};

	private canSetByKey(key: string) : boolean {
		if (this._settings != null && this._settings.length > 0) {
			for (let i = 0; i < this._settings.length; i ++) {
				if (this._settings[i].entityType == 'com' && this._settings[i].settingType == key) {
					return this._settings[i].configurable;
				}
			}
		}

		return false;
	};

	private canSetById(settingId: number) : boolean {
		if (this._settings != null && this._settings.length > 0) {
			for (let i = 0; i < this._settings.length; i ++) {
				if (this._settings[i].entityType == 'com' && this._settings[i].settingId == settingId) {
					return this._settings[i].configurable;
				}
			}
		}

		return false;
	};

	private setValueByKey(key: string, value: string) {
		if (this._settings != null && this._settings.length > 0) {
			for (let i = 0; i < this._settings.length; i ++) {
				if (this._settings[i].entityType == 'com' && this._settings[i].settingType == key) {
					if (this._settings[i].configurable) {
						this._settings[i].settingValue = value;
					}
					break;
				}
			}
		}
	}

	private setValueById(settingId: number, value: string) {
		if (this._settings != null && this._settings.length > 0) {
			for (let i = 0; i < this._settings.length; i ++) {
				if (this._settings[i].entityType == 'com' && this._settings[i].settingId == settingId) {
					if (this._settings[i].configurable) {
						this._settings[i].settingValue = value;
					}
					break;
				}
			}
		}
	}

	private parseNullableNumber(value: string, rounded: boolean) : number {
		if (value != null && value != '') {
			try {
				let double = parseFloat(value);

				if (double == NaN) {
					return null
				}
				else if (rounded) {
					return parseInt('' + double);
				}
				else {
					return double;
				}
			}
			catch (e) {

			}
		}

		return null;
	}
}