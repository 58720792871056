import { HttpHeaders, HttpRequest } from '@angular/common/http';

export class wbcDaHelper {
  public static fetchFromObject(obj, prop) {
    //property not found
    if (typeof obj === 'undefined') return false;

    //index of next property split
    var _index = prop.indexOf('.');

    //property split found; recursive call
    if (_index > -1) {
      //get object at property (before split), pass on remainder
      return this.fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
    }

    //no split; get property
    return obj[prop];
  }

  public static addHeaders(
    req: HttpRequest<any>,
    requiresAuth: boolean,
    groupId?: any
  ): HttpRequest<any> {
    const tokenType = localStorage.getItem('tokenType');
    const accessToken = localStorage.getItem('token');
    const language = localStorage.getItem('lang');

    let newReq = null;
    
    if (requiresAuth) {
      let headers = new HttpHeaders()
        .append('Cache-Control', 'no-cache')
        .append('Pragma', 'no-cache')
        .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .append('If-Modified-Since', '0')
        .append('Content-Type', 'application/json');

      if (tokenType && accessToken) {
        headers = headers.append('Authorization', `${tokenType} ${accessToken}`);
      }

      if (groupId) {
        headers = headers.append('group', groupId.toString());
      }

      if (language) {
        headers = headers.append('language', language);
      }

      newReq = req.clone({ headers });
    } else {
      const headers = new HttpHeaders()
        .append('Cache-Control', 'no-cache')
        .append('Pragma', 'no-cache')
        .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .append('If-Modified-Since', '0')
        .append('Content-Type', 'application/json');
      newReq = req.clone({ headers });
    }
    
    return newReq;
  }

  public static fixPrintText(GetTextFn: (key: string, lang?: string) => string): void {
    setTimeout(() => {
      const el = document.querySelector('.print-button span') as any;
      if (el) {
        el.innerText = GetTextFn('reports.print');
      }
    });
  }
}
