import { Component, Input } from '@angular/core';
import { InfoBlock } from '../classes/info.class';

@Component({
	selector: 'info-block',
	templateUrl: './info-block.html',
	styleUrls: [
		'./info-block.css'
	]
})
export class InfoBlockComponent {
	@Input() block?: InfoBlock;


	constructor() {
		//this.block.innerBlocks
	}


	public HasInnerBlocks() :boolean {
		return (this.block.innerBlocks != null && this.block.innerBlocks.length > 0);
	}

	public HasLines() :boolean {
		return (this.block.lines != null && this.block.lines.length > 0);
	}
}