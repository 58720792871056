import { AccountService } from './../services/account.service';
import { TenantService } from './../services/tenant.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PagingExtension } from '../classes/extensions/paging.extension';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'tenants-list',
	templateUrl: './tenant-list.html',
	styleUrls: [
		'./alarm-list.css'
	]
})
export class TennantListComponent extends PagingExtension {
	@Input() tenants: any[];
	@Output() isLoading: any = new EventEmitter();

	constructor(
		private _tenantService: TenantService,
    private _accountService: AccountService,
    private _toastrService: ToastrService
  ) {
		super(12);
	}

	public switchTenant(tenantConsumerId: number): void {
		this.isLoading.emit(true);
		this._tenantService.setActive(tenantConsumerId).subscribe(res => {
      localStorage.clear();
			this._accountService.logout();
		},
    error => {
      this.isLoading.emit(false);
      if (error.status == 409) {
        this._toastrService.error(this.GetText('error.no_switch_tenant'));
      }
    });
	}
}
