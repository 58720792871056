import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EmployeeService } from '../services/employee.service';

@Injectable()
export class EmployeesResolver implements Resolve<any> {
	constructor(
		private service: EmployeeService,
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		return this.service.getEmployees();
	}
}