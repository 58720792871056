<table>
	<thead *ngIf="headers">
		<tr>
			<th *ngFor="let header of headers">{{header}}</th>
		</tr>
	</thead>
	<tbody *ngIf="items != null && items.length > 0">
		<tr *ngFor="let item of items | wbfilterbasic:filter">
			<td *ngFor="let prop of properties">
				<div *ngIf="!isClickable(prop)">{{getValue(item, prop)}}</div>
				<a *ngIf="isClickable(prop)" routerLink="{{getLink(item, prop)}}">{{getValue(item, prop)}}</a>
			</td>
		</tr>
		
	</tbody>
	<tbody *ngIf="items == null || items.length == 0">
		<tr >
			<td *ngFor="let i of emptyColumns()"></td>
			<td>{{onEmpty.message}}</td>
		</tr>
	</tbody>
</table>