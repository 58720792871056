import { Component, Input } from '@angular/core';
import { PagingExtension } from '../classes/extensions/paging.extension';
import { GlobalService } from '../services/global.service';
import { DisconnectedDeviceResult } from '../classes/results.class';

@Component({
	selector: 'disconnectedDevice-list',
	templateUrl: './disconnected-device-list.html',
	styleUrls: [
		'./alarm-list.css'
	]
})
export class DisconnectedDeviceListComponent extends PagingExtension {
	@Input('devices')
	public get devices() :DisconnectedDeviceResult[] { return <DisconnectedDeviceResult[]>this.pageItems; };
	public set devices(value: DisconnectedDeviceResult[]) { this.items = value; this.Build(); }


	constructor(
		private glService: GlobalService,
	) {
		super();
	}
}