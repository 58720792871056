import { Component, Input, ElementRef, Output, OnChanges, EventEmitter, Renderer2 } from '@angular/core';
import { wbcAny } from '../../../classes/wbcAny.class';
import { wbcOption } from '../../../classes/wbcOption.class';


@Component({
	selector: 'wb-dropdownButton',
	templateUrl: './dropdownButton.html',
	styleUrls: [
		'./dropdownButton.css'
	],
	host: {
		'(document:click)': 'globalShow(false, $event)',
		'(window:resize)': 'update($event)',
		'(window:scroll)': 'update($event); blur(-1)'
	},
})
export class wbDropdownButtonComponent implements OnChanges {
	ngOnChanges(): void {
		this.onValueChange.emit(false);
	}

	@Input() model: wbcAny;
	@Input() options: wbcOption[];
	@Input() firstOnEmpty: boolean = true;

	@Output() onValueChange?= new EventEmitter<boolean>();

	@Input('override')
	get override(): number | string { return this._override; };
	set override(value: number | string) {
		if (value != null && value != this._override) {
			if (this.blaTimer != null) {
				clearTimeout(this.blaTimer);
			}
			this._override = value;
			for (var o in this.options) {
				if (this.options[o].value.value == this._override) {
					this.Select(this.options[o]);
					this.forcedClose = false;
					break;
				}
			}

			this.blaTimer = setTimeout(() => {
				this.blaTimer = null
				this._override = null;
			}, 1);
		}
	};


	public isVisible: boolean = false;
	public bla: any = {};

	public get value(): wbcOption {
		if (this._value != null) {
			return this._value;
		}

		if (this.options != null && this.options.length > 0) {
			if (this.model != null && this.model.value != null) {
				for (var i in this.options) {
					if (this.options[i].value != null && this.options[i].value.value == this.model.value) {
						this._value = this.options[i];
						return this._value;
					}
				}
			}
			else if (this.firstOnEmpty == true) {
				for (var i in this.options) {
					if (this.options[i].value != null) {
						this._value = this.options[i];
						return this._value;
					}
				}
			}
		}

		return null;
	};

	private _value: wbcOption;
	private timer: any;
	private forcedClose: boolean = false;
	private __interval: any = null;

	private _override?: number | string = null;

	private blaTimer: any = null;
	private globalTimer: any = null;


	constructor(private _el: ElementRef, private _renderer: Renderer2) {
		this.update();
	};


	public Select(value: wbcOption) {
		for (var i in this.options) {
			this.options[i].selected = false;
		}
		value.selected = true;
		this._value = value;

		if (this.model == null) {
			this.model = new wbcAny(null);
		}

		this.model.value = value.value.value;

		this.isVisible = false;
		this.forcedClose = true;
		this.onValueChange.emit(false);
	};

	public blur(time?: number) {
		if (time == -1) {
			this.timer = null;
			this.isVisible = false;
		}

		if (time == null) {
			time = 500;
		}

		if (this.timer != null) {
			clearTimeout(this.timer);
		}

		this.timer = setTimeout(() => {
			this.timer = null;
			this.isVisible = false;
		}, time);
	};

	public globalShow(bool?: boolean, $event?: any) {
		if (this.globalTimer != null) {
			clearTimeout(this.globalTimer);
		}

		var $parent = $event.target;

		setTimeout(() => {
			this.globalTimer = null;
			if (this.forcedClose) {
				this.forcedClose = false;
				return;
			}

			while ($parent != null) {
				$parent = $parent.parentNode;

				try {
					if ($parent.isSameNode(this._el.nativeElement)) {
						this.isVisible = true;
						this.update();
						return;
					}
				}
				catch (ex) {
					//do nothing;
				}
			}
			this.isVisible = false;
		}, 1);
	};

	public update(redo?: boolean) {
		if (this.__interval != null) {
			clearTimeout(this.__interval);
			this.__interval = null;
		}

		if (!this.isVisible) {
			return;
		}

		var rect = this._el.nativeElement.getBoundingClientRect();
		var doc = this._el.nativeElement.ownerDocument;
		var winTop = this._el.nativeElement.scrollHeight;
		var winTop2 = this._el.nativeElement.scrollTop;
		var docElem = doc.documentElement;
		var min_height = 0;
		var height = 55;
		var max_height = null;
		var is_hover = false;
		var is_scroller = false;

		if (this.options != null && this.options.length > 0) {
			height = 55 * this.options.length;
		}

		try {
			min_height = this._el.nativeElement.firstChild.clientHeight;
		}
		catch (e) {

		}

		if (redo == true) {
			max_height = this.bla.max_height;
			is_scroller = this.bla.is_scroller;

			try {
				var children = this._el.nativeElement.firstChild.children;
				for (var c in children) {
					if (children[c].attributes != null) {
						for (var a in children[c].attributes) {
							if (children[c].attributes[a].name == 'id' && children[c].attributes[a].nodeValue == 'ng_dropper') {
								height = children[c].clientHeight;
							}
						}
					}

				}
			}
			catch (e) {

			}
		}

		var width = 0;
		try {
			width = this._el.nativeElement.firstChild.offsetWidth;
		}
		catch (e) {

		}

		var bottomcheck = rect.top + winTop2 - docElem.clientTop;
		var max = docElem.clientHeight;

		if (height > docElem.clientHeight - 16) {
			height = docElem.clientHeight - 16;
			max_height = height;
			is_scroller = true;
		}



		if (bottomcheck + height + min_height + 8 > max) {
			bottomcheck = (max - min_height - height - 8) + winTop2 - docElem.clientTop;
			is_hover = true;
		}

		this.bla = {
			top: bottomcheck,
			min_height: min_height,
			width: width,
			max_height: (max_height != null ? max_height + 'px' : null),
			is_hover: is_hover,
			is_scroller: is_scroller
		};

		if (redo != true) {
			this.__interval = setTimeout(() => {
				this.update(true);
			}, 1);
		}
	};
}