<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let employee of employees; let i = index" [attr.data-employee-id]="employee?.id">
				<td class="col-xs-12 col-lg-8">
					<div (click)="onEmployeeClick(employee)" class="clickable">
						<div class="ng_top ellipsis">
							<span>{{employee.name}}</span>
						</div>
						<div class="ng_bottom ellipsis">
							<span>{{employee.email}}</span>
						</div>
					</div>
					<div class="hidden-lg">
						<wb-slider-toggler [toggler]="employeeToggle[i]" class="fl_left" style="margin-right: 60px;"></wb-slider-toggler>
						<span class="fl_left" style="color: #BDBDBD; font-weight: 600" [hidden]="IsActive(i)">{{GetText('modules.webbeat.toggler_absent')}}</span>
						<span class="fl_left" [style.color]="isManufacturer ? '#BDBDBD' : '#5FA788'" style="font-weight: 600" [hidden]="!IsActive(i)">{{GetText('modules.webbeat.toggler_present')}}</span>
					</div>
				</td>
				<td class="col-lg-4 hidden-xs hidden-sm hidden-md">
					<div class="w_12 fl_right">
						<wb-slider-toggler [isDisabled]="isManufacturer" [(toggler)]="employeeToggle[i]" class="fl_right"></wb-slider-toggler>
						<span class="fl_right" style="margin-right: 60px; color: #BDBDBD; font-weight: 600" [hidden]="IsActive(i)">{{GetText('modules.webbeat.toggler_absent')}}</span>
						<span class="fl_right" [style.color]="isManufacturer ? '#BDBDBD' : '#5FA788'" style="margin-right: 60px; font-weight: 600" [hidden]="!IsActive(i)">{{GetText('modules.webbeat.toggler_present')}}</span>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>
