import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PatientGroupService } from 'app/services/patientgroups.service';
import { RoleService } from 'app/authorization/services/role.service';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class PatientGroupsResolver implements Resolve<any> {
	constructor(
    private _patientGroupService: PatientGroupService,
    private _roleService: RoleService
	) { }

	resolve(): any {
    return this._roleService.isManufacturer().pipe(
      mergeMap(isManufacturer => {
        if (isManufacturer) return of([])
        else {
          return this._patientGroupService.getAllPatientGroups();
        }
      })
    );
	}
}
