import { Component, OnInit } from '@angular/core';
import { hcHighChartService } from '../../services/highchart.service';
import { hcChartBaseComponent } from '../../charts/base/chart-base.component';

@Component({
	selector: 'hc-chart-spline',
	templateUrl: './chart-spline.html',
	styleUrls: [
		'../base/chart-base.css',
		'./chart-spline.css'
	],
})
export class hcChartSplineComponent extends hcChartBaseComponent implements OnInit {
	constructor(chartService: hcHighChartService) {
		super(chartService);
	}

	ngOnInit() {
		super.ngOnInit();
	}
}