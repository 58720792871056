<form (ngSubmit)="onSubmit()" (change)="formUpdate()" onsubmit="return false;" >
	<div class="row">
		<div class="col-xs-8">
      <label>{{GetText('common.employee_name')}}</label>
      <div class="employee-error" [ngClass]="{ 'employee-touched': nameInput.touched }">{{violations && violations.name}}</div>
      <input type="text" name="name" [(ngModel)]="model.name" [disabled]="disableForm" #nameInput="ngModel">
		</div>
	</div>
	<div class="row">
		<div class="col-xs-8">
      <label>{{GetText('common.email')}}</label>
      <div class="employee-error" [ngClass]="{ 'employee-touched': emailInput.touched }">{{violations && violations.email}}</div>
      <input type="text" name="email" [(ngModel)]="model.email" [disabled]="disableForm" #emailInput="ngModel" [readonly]="model.id != 0">
		</div>
  </div>
  <div class="row">
		<div class="col-xs-8">
      <div class="overview-block_header">
        <label>{{GetText('common.teams')}}</label>
        <div class="employee-error" [ngClass]="{ 'employee-touched': teamsInput.touched }">{{violations && violations.teams}}</div>
        <ng-select
          name="teams"
          #teamsInput="ngModel"
					[items]="employeeTeams"
          [multiple]="true"
          [disabled]="disableForm"
          [searchable]="false"
					[closeOnSelect]="false"
					placeholder="{{GetText('common.teams')}}"
          [ngModel]="model.teamIds"
          (ngModelChange)="model.teamIds = $event; formUpdate()"
          bindValue="id">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div>{{item.teamName}}</div>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <b>{{item.teamName}}</b>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </ng-template>
					</ng-select>
      </div>
		</div>
  </div>
  <div class="row">
		<div class="col-xs-8">
      <div class="overview-block_header">
        <label>{{GetText('common.role')}}</label>
        <div class="employee-error" [ngClass]="{ 'employee-touched': roleInput.touched }">{{violations && violations.role}}</div>
        <select class="form-control" name="role" [(ngModel)]="model.role" #roleInput="ngModel" [disabled]="this.isManufacturer && (this.glService.letManufacturerEditUsers || this.glService.letManufacturerCreateUsers)">
          <option value="10" selected>{{GetText('common.employee')}}</option>
          <option value="20">{{GetText('employee.role_team_lead')}}</option>
        </select>
      </div>
		</div>
  </div>
  <div class="row">
		<div class="col-xs-8">
      <div class="overview-block_header">
        <label>{{GetText('settings.language')}}</label>
        <div class="employee-error" [ngClass]="{ 'employee-touched': languageInput.touched }">{{violations && violations.language}}</div>
        <select class="form-control" name="language" [(ngModel)]="model.language" [disabled]="disableForm" #languageInput="ngModel">
          <option value="en">{{GetText('common.english')}}</option>
          <option value="nl">{{GetText('common.dutch')}}</option>
          <option value="de">{{GetText('common.german')}}</option>
        </select>
      </div>
		</div>
  </div>

</form>
