import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { CompanySettings } from './../classes/company.class';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Setting } from '../classes/setting.class';
import { SETTINGS } from '../settings';

@Injectable()
export class CompanyService {
	private _baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient){
	}

	public getSettings(company: number): Observable<Setting[]> {
		return this._http.get<Setting[]>(`${this._baseUrl}v1/Settings?entityType=com&entityId=${company}`);
	}

	public updateSettings(companySettings: CompanySettings): Observable<boolean> | any {
		const settings = companySettings.getSettings();
		let observables: Observable<boolean>[] = [];

		for (let i = 0; i < settings.length; i ++) {
			observables.push(this.updateSetting(settings[i]));
		}

		return observableForkJoin(observables);
	}

	private updateSetting(setting: Setting): Observable<boolean> {
		return this._http.put<boolean>(`${this._baseUrl}Settings/${setting.settingId}`, {settingValue: setting.settingValue});
	}
}
