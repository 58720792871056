import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { wbcMenuButton } from '../../modules/webbeat/classes/wbcButton.class';
import { GlobalService } from '../../services/global.service';
import { PatientService } from '../../services/patient.service';
import { PatientIndexBlock } from '../../classes/block.class';
import { Patient } from '../../classes/patient.class';
import { ServiceListResult } from '../../classes/results.class';
import { Page } from '../pages.parent';
import { SETTINGS } from '../../settings';
import { RoleService } from '../../authorization/services/role.service';
import { SortingOption } from 'app/classes/sorting-option.class';

@Component({
  selector: 'page-patients',
  templateUrl: './patients-index.html',
  styleUrls: ['./patients-index.component.css'],
})
export class PagePatientsIndexComponent extends Page implements OnInit {
  public patientBlocks: PatientIndexBlock[];
  public patients: Patient[];
  public services: ServiceListResult[];

  public timer: number = 86400; //24hrs

  public rows: Array<any> = [];
  public columns: any = [
    {
      title: this.GetText('patient.index_table_name'),
      name: 'name',
      className: 'buttonRole',
      sort: 'asc',
    },
    {
      title: this.GetText('patient.index_table_location'),
      name: 'room',
      className: 'buttonRole',
      sort: 'asc',
    },
    {
      title: this.GetText('device.device_key'),
      name: 'device_key',
      className: 'buttonRole',
      sort: 'asc',
    },
    {
      title: this.GetText('patient.index_table_status_update'),
      name: 'statusUpdate',
      className: 'buttonRole',
      sort: 'asc',
    },
    {
      title: this.GetText('patient.index_table_status'),
      name: 'status',
      className: 'buttonRole',
      sort: 'asc',
    },
  ];
  public tableId: string = 'patientIndexTable';
  public data: Array<any> = [];
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-hover', 'buttonRole'],
  };
  public length = 0;
  public searchText = this.GetText('patient.index_table_search');
  public placeholderTable = this.GetText('patient.index_table_search_text');
  public allText = this.GetText('patient.index_table_all');
  private isManufacturer: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    glService: GlobalService,
    private patientService: PatientService,
    private roleService: RoleService
  ) {
    super(glService, 'Patients');

    this.SetTitle(this.GetText('common.patients'));
    this.glService.mainButton = new wbcMenuButton(
      this.GetText('patient.index_button_main'),
      '../patients/edit',
      null,
      false,
      true
    );
  }

  private loadUserSortingOptions(): void {
    let storedOptions = new Array<SortingOption>();
    let storedOptionsData = localStorage.getItem('sortingOptions');
    if (storedOptionsData) {
      storedOptions = JSON.parse(storedOptionsData);
    }

    let user: any;
    let userData = localStorage.getItem('user');
    if (userData) {
      user = JSON.parse(userData);
    }
    let userId: number = user.id;

    let userSortingOptions: any;

    if (storedOptions) {
      storedOptions.forEach((storedOption) => {
        if (storedOption.userId === userId) {
          userSortingOptions = storedOption;
        }
      });
    }

    if (!userSortingOptions) {
      userSortingOptions = new SortingOption();
      userSortingOptions.columnName = 'name';
      userSortingOptions.sort = 'asc';
    }

    let sortingColumns: any = this.config.sorting.columns;
    sortingColumns.forEach((sortingColumn) => {
      if (sortingColumn.name === userSortingOptions.columnName) {
        sortingColumn.sort = userSortingOptions.sort;
      } else {
        sortingColumn.sort = '';
      }
    });
  }

  ngOnInit() {
    this.isManufacturer = this.glService.isManufacturer();
    this.glService.mainButton.isDisabled.isToggled = this.isManufacturer;

    this.preloadTimer = SETTINGS.AddInterval(
      () => {
        if (this.glService.preload) {
          SETTINGS.ClearInterval(this.preloadTimer);

          this.route.data.subscribe((result: any) => {
            this.patients = result.patients;
            this.enrichTable(this.patients);
            this.length = result.patients.length;
          });
        }
      },
      1,
      this.preloadTimer
    );

    this.loadUserSortingOptions();
  }

  public onCellClick(data: any): any {
    const id = data.row.name.split('id="')[1].split('"')[0];
    this.router.navigateByUrl('/patients/' + id);
  }

  public updateTable(event: any): void {}

  private enrichTable(response: any): void {
    for (let i = 0; i < response.length; i++) {
      let id = response[i].id !== null ? response[i].id : 0;
      let displayname = response[i].displayName !== null ? response[i].displayName : ' ';
      let room = response[i].room !== null ? response[i].room : ' ';
      let statusUpdate =
        response[i].alarmStatusCreated !== null
          ? this.createDateFormat(response[i].alarmStatusCreated)
          : ' ';
      let statusColor = response[i].alarmStatusColor !== null ? response[i].alarmStatusColor : ' ';
      let alarmText = response[i].alarmStatusText !== null ? response[i].alarmStatusText : ' ';
      let deviceKey =
        response[i].device && response[i].device.key != null ? response[i].device.key : ' ';
      let iconHtml = '<span class="icon-{{1}}" style="padding-right:5px;"></span>';

      switch (response[i].alarmStatusIconId) {
        case 90: //disconnected
          iconHtml = iconHtml.replace('{{1}}', 'warning');
          break;
        case 110: //picked up
        case 120: //alarm
          iconHtml = iconHtml.replace('{{1}}', 'alarm');
          break;
        case 130: //muted
          iconHtml = iconHtml.replace('{{1}}', 'pause-circle');
          break;
        case 100: //ok
        default:
          iconHtml = iconHtml.replace('{{1}}', '');
          break;
      }

      this.data.push({
        name: displayname + `<p [attr.data-displayname]="${displayname}" id="${id}"></p>`,
        room: room,
        device_key: deviceKey,
        statusUpdate: statusUpdate,
        status: '<p style="color:' + statusColor + '">' + iconHtml + alarmText + '</p>',
      });
    }
  }

  private createDateFormat(date: string): string {
    let format: Date;
    let correctDate;
    format = new Date(date);
    correctDate =
      format.getDate() +
      '-' +
      (format.getMonth() + 1) +
      '-' +
      format.getFullYear() +
      ' ' +
      format.toTimeString().split('GMT')[0];
    return correctDate;
  }
}
