<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let alarm of alarmtriggers; let ii = index">
				<td class="col-xs-12">
					<div class="row" style="margin-bottom: 30px;">
						<div class="col-xs-12">
							<div class="alarm_icon left" [style.margin-right]="'30px'" [style.border-color]="'#' + alarm.color" [style.color]="'#' + alarm.color">
								<div class="icon" [ngClass]="GetIcon(alarm.icon)"></div>
								<div>{{alarm.value}}</div>
								<div>{{alarm.triggerTimeout}}</div>
							</div>

							<h3 class="inline">{{alarm.nameValue}}</h3>
						</div>
					</div>
					<div class="row" style="margin-bottom: 10px;">
						<div class="col-xs-12">
							<label>{{GetText('common.value')}}</label>
						</div>
						<div class="col-xs-12">
							<wb-dropdownButton [options]="dropdownOptions" [model]="dropdownValues[ii]" [override]="typeOverride[ii]"></wb-dropdownButton>
						</div>

					</div>
					<div class="row" style="margin-bottom: 30px;">
						<div class="col-xs-4">
							<input type="text" [disabled]="isManufacturer" [ngModel]="alarm.hours | numberText:' '+GetText('content.time.hour_tiny')"
							 (ngModelChange)="updateAlarmHours(ii, alarm, $event)">
						</div>
						<div class="col-xs-4">
							<input type="text" [disabled]="isManufacturer" [ngModel]="alarm.minutes | numberText:' '+GetText('content.time.minute_small')"
							 (ngModelChange)="updateAlarmMinutes(ii, alarm, $event)">
						</div>
						<div class="col-xs-4">
							<input type="text" [disabled]="isManufacturer" [ngModel]="alarm.actualSeconds | numberText:' '+GetText('content.time.second_small')"
							 (ngModelChange)="updateAlarmSeconds(ii, alarm, $event)">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<wb-actionButton id="copyAlarmsButtons" [button]="copyAlarmsButtons[ii]"></wb-actionButton>
							<!-- <div class="button text" (click)="showModel('alarmCopy', ii)">{{GetText('content.blocks.alarmtrigger_editlist.copy_value')}}</div> -->
						</div>
					</div>
					<hr class="dashed" />
					<div class="row" *ngFor="let range of alarm.ranges; let jj = index" style="margin-bottom: 10px;">
						<div class="col-xs-12">
							<div class="row">
								<div class="col-xs-12 col-sm-3 col-md-5">
									<label>{{GetText('content.time.start_time')}}</label>
								</div>
								<div class="col-xs-12 col-sm-3 col-md-5">
									<label>{{GetText('content.time.end_time')}}</label>
								</div>
							</div>
							<div class="row">
								<div class="col-xs-12 col-sm-3 col-md-5">
									<input wb-modal-timepicker type="text" [disabled]="isManufacturer" [ngModel]="range.start | timePipe" [onUpdateProps]="{ alarm_id:ii, alarm:alarm, range_id:jj }"
									 [onUpdate]="updateAlarmActiveStartB" (ngModelChange)="updateAlarmActiveStart(ii, alarm, jj, $event)">
								</div>
								<div class="col-xs-12 col-sm-3 col-md-5">
									<input wb-modal-timepicker type="text" [disabled]="isManufacturer" [ngModel]="range.end | timePipe" [onUpdateProps]="{ alarm_id:ii, alarm:alarm, range_id:jj }"
									 [onUpdate]="updateAlarmActiveEndB" (ngModelChange)="updateAlarmActiveEnd(ii, alarm, jj, $event)">
								</div>
								<div class="col-xs-12 col-sm-6 col-md-2" *ngIf="!isManufacturer">
									<div class="button alt1 text inline" (click)="RemoveRange(ii, alarm, jj)">
										<div class="icon icon-close" style="font-size: 21px; margin: 7px -15px;"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-6">
							<wb-actionButton id="addRangeButtons" [button]="addRangeButtons[ii]"></wb-actionButton>
							<!-- <div class="button alt2" (click)="AddRange(ii, alarm)">{{GetText('content.blocks.alarmtrigger_editlist.add_timerange')}}</div> -->
						</div>
						<div class="col-xs-12">
							<wb-actionButton id="copyRangeButtons" [button]="copyRangeButtons[ii]"></wb-actionButton>
							<!-- <div class="button text inline" (click)="CopyRanges(ii, alarm)">{{GetText('content.blocks.alarmtrigger_editlist.copy_timerange')}}</div> -->
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>

<wb-modal-basic [width]="6" [offset]="3" [show]="copyAlarmModalShown">
	<div modal-closer>
		<div class="icon icon-close" style="color: white;"></div>
	</div>
	<div modal-content>
		<wb-grid-headerBlocks gridWidth="12">
			<div block-header>
				<div id="colorBlock" class="color_red"></div>
				<h2>{{GetText('patient.edit_copy_alarm_modal_title')}}</h2>
				<p class="color_red">{{GetText('patient.edit_copy_alarm_modal_subtitle')}}</p>
			</div>
			<div block-body style="margin-top:40px;">
				<table class="bordered _first paged">
					<tr *ngFor="let alarm of possibleAlarms; let kk = index">
						<td class="col-xs-8">
							<div class="ng_top">{{alarm.name}}</div>
							<div class="ng_bottom">{{alarm.value}}</div>
						</td>
						<td class="col-xs-4">
							<wb-button-toggler [toggler]="possAlarmTogglers[kk]" class="fl_right"></wb-button-toggler>
						</td>
					</tr>
				</table>
			</div>
			<div block-footer>
				<div id="footer">
					<div id="footer_content">
						<div class="button reversed" (click)="copyAlarmValue()">{{GetText('patient.edit_button_confirm_copy')}}</div>
						<div class="button text reversed" (click)="hideModel('alarmCopy')">{{GetText('common.cancel')}}</div>
					</div>
				</div>
			</div>
		</wb-grid-headerBlocks>
	</div>
</wb-modal-basic>
