import { TableComponent } from '../../modules/table/table.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared.module';

import { PageDeviceDisconnectedComponent } from './devices-disconnected.component';

import { WebbeatModule } from '../../modules/webbeat/webbeat.module';

import { wbLoginGuard } from '../../guards/login-guard.service';

import { ContentModule } from '../../content/content.module';

import { DisconnectedDevicesResolver } from 'app/resolvers/disconnectedDevices-resolver';
import { DatePipe } from '@angular/common';
import { IsNotManufacturerGuard } from '../../authorization/guards/not-manufacturer-guard.service';
import { UserRolesResolver } from '../../resolvers/userRoles-resolver';

import { isManufacturerOrDealerGuard } from 'app/authorization/guards/dealer-or-manufacturer-guard.service';
import { isNotManufacturerOrDealerGuard } from 'app/authorization/guards/not-manufacturer-or-dealer-guard.service';

@NgModule({
	imports: [
		WebbeatModule,
		RouterModule.forChild([
			{
				path: 'devices/disconnected',
				canActivate: [wbLoginGuard],
				component: PageDeviceDisconnectedComponent,
				resolve: {
					devices: DisconnectedDevicesResolver,
					rememberUserRoles: UserRolesResolver,
				}
			},
		]),
		SharedModule,
		ContentModule,
	],
	declarations: [
		PageDeviceDisconnectedComponent,
	],
	providers: [
		DatePipe,
		wbLoginGuard,
    IsNotManufacturerGuard,
    isNotManufacturerOrDealerGuard,
    isManufacturerOrDealerGuard,
		UserRolesResolver
	],
	exports: [
	]
})
export class PageDevicesModule {
}
