<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let alarm of alarms">
				<td class="col-xs-8">
					<div class="ng_top">{{alarm.alarmName}}</div>
					<div class="ng_bottom">
						<div>{{alarm.alarmCreated | dateTimeText}}</div>
						<!--<div *ngIf="!IsEmpty(alarm.takenUserName)" style="color: #EDB13D;">{{GetText('common.picked_up')}}: {{alarm.takenUserName}}</div>-->
						<div *ngIf="!IsEmpty(alarm.subLogText)" [ngStyle]="{'color': alarm.subLogColor}">{{alarm.subLogText | rename:toLocal(alarm.created)}}</div>
					</div>
				</td>
				<td class="col-xs-4">
					<div class="alarm_icon" [style.border-color]="'#' + alarm.color" [style.color]="'#' + alarm.color">
						<div class="icon" [ngClass]="GetIcon(alarm.icon)"></div>
						<div>{{alarm.alarmTriggerValue}}</div>
						<div>{{alarm.triggerTimeout}}</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>