import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ResolveStart, ActivationStart } from '@angular/router';
import { wbBrowserStorage } from './modules/webbeat/service/browserStorage.service';
import { wbcToggler } from './modules/webbeat/classes/wbcToggler.class';
import { GlobalService } from './services/global.service';
import { Group } from './classes/group.class';
import { SETTINGS } from './settings';
import { LANGUAGE } from './language';
import { ProbeService } from 'app/services/probe.service';
import { CommonService } from 'app/services/common.service';
import { RoleService } from './authorization/services/role.service';
import { TenantService } from './services/tenant.service';
import { LoadTestingService } from './services/loadtesting.service';
import { AccountService } from './services/account.service';
import { DateTimeAdapter } from 'ng-pick-datetime-ex';
1

@Component({
	selector: 'app-root',
	templateUrl: './app.html',
	styleUrls: [
		'./app.css'
	],
	host: {
		'(window:resize)': 'onResize($event)',
		'(document:click)': 'GlobalClick()',
		'(document:scroll)': 'GlobalEvent()',
		'(document:mousemove)': 'GlobalEvent()',
		'(document:keypress)': 'GlobalEvent()',
		'(document:wheel)': 'GlobalEvent()'
	}
})
export class AppComponent implements OnInit {
	@ViewChild('mainbody') private _mainBody: ElementRef;
	
	public isMaintenance = false;
	
	name = 'Angular';
	
	window_size: number;
	
	private _sizeTimer: any = null;
	
	public groupModalShown: boolean;
	
	public isManufacturer: boolean;
	
	public isTeamLead: boolean = false;
	
	public get LastRefresh(): string {
		return this.globalService.LastRefresh;
	};
	
	public get HasToken(): boolean {
		if (this._storage == null) {
			return false;
		}
		
		var t = this._storage.getStorageItem('token');
		
		return (t != null && t != '' && t.length > 0);
	}
	
	public get Name(): string {
		if (this._storage == null) {
			return ' '; //this is not a space, but HEX:2002
		}
		
		let name = this._storage.getStorageItem('user.name');
		
		if (name == null || name == '') {
			name = ' '; //this is not a space, but HEX:2002
		}
		
		return name;
	};
	
	public get Company(): void {
		let groups = this._storage.getStorageItem('user.groups');
		if(groups) return groups[0].company;
	};
	
	public get isOverriden(): boolean {
		return this.globalService.menuHide == true;
	};
	
	public get Email(): string {
		if (this._storage == null) {
			return '';
		}
		
		return this._storage.getStorageItem('user.email');
	};
	
	public get Group(): string {
		if (this.globalService.currentGroup == null) {
			return ' '; //this is not a space, but HEX:2002
		}
		
		let name = this.globalService.currentGroup.name;
		
		if (name == null || name == '') {
			name = ' ';
		}
		
		return name;
	}
	
	public get Groups(): Group[] {
		return this.globalService.groups;
	}
	
	public get pageLoading(): boolean {
		return !this.globalService.preload;
	}
	
	public version = '';
	public interval: any;
	public showManufacturerPanelLink = true;
	
	private _menuToggle: boolean;
	private groupTimer: any;
	
	public get menuToggle(): boolean { return this._menuToggle; };
	public set menuToggle(value: boolean) { this._menuToggle = value; };
	
	constructor(private _storage: wbBrowserStorage,
		public globalService: GlobalService,
		private router: Router,
		private probeService: ProbeService,
		private roleService: RoleService,
		private tenantService: TenantService,
		private loadTestingService: LoadTestingService,
		private _accountService: AccountService,
		private _dateTimeAdapter: DateTimeAdapter<any>,
		private commonService: CommonService) {
			setTimeout(() => {
				this.globalService.preload = true;
				
			}, 500);
			
			if (!SETTINGS.USE_DEBUG_LOG) {
				console.log = function () { };
				console.warn = function () { };
				console.debug = function () { };
				console.error = function () { };
			}
			
			var lang = this._storage.getStorageItem('lang');
			
			if (lang == null || lang == '') {
				lang = 'EN';
				this._storage.setStorageItem('lang', lang);
			}
			
			LANGUAGE.SetLanguage(lang);
			
			/** this function fires when a soft-redirect is done (which is fired AFTER the resolvers are done) **/
			this.router.events.subscribe((event) => {
				if (event instanceof ActivationStart) {
					this.loadTestingService.routeEnabledLoadTesting = !!event.snapshot.data.loadTesting;
				}
				
				if (event instanceof NavigationStart) {
					this.loadTestingService.reset();
					this.loadTestingService.startLoading();
					this.loadTestingService.startTimeout();
					
					this.globalService.menuToggled = new wbcToggler(true);
					SETTINGS.RemoveAllIntervals();
					
					var langNew = this._storage.getStorageItem('lang');
					if (langNew == null || langNew == '') {
						langNew = 'EN';
						this._storage.setStorageItem('lang', langNew);
					}
					
					if (LANGUAGE.GetLanguage() != langNew) {
						LANGUAGE.SetLanguage(langNew);
					}
				}
				
				if (event instanceof ResolveStart) {
					this.globalService.isCurrentlyResolving = true;
				} else {
					this.globalService.isCurrentlyResolving = false;
				}
				
				if (event instanceof NavigationEnd) {
					if (!this.showManufacturerPanelLink) {
						this.getTennantStatus();
					}
					this.globalService.toSendAPIBenchmarks = [];
				}
			});
			
			var $this = this;
			
			setTimeout(function () {
				$this.onResize({
					target: {
						innerWidth: SETTINGS.jquery(window).innerWidth()
					}
				});
				
			}, 10);
		};
		
		ngOnInit() {
			this.getVersion();
			this.isManufacturer = true;
			
			this.checkToken();
			
			this.interval = setInterval(() => {
				this.callProbeService();
				this._dateTimeAdapter.setLocale(LANGUAGE.GetLanguage());
			}, 10000);
			this.globalService.mainBody = this._mainBody;
			this.initActivityTimeout();
		}
		
		public checkToken(): void {
			if (localStorage.getItem('token')) {
				this.roleService.isManufacturerOrDealer().subscribe(response => {
					this.isManufacturer = response;
					if (response) {
						this.getTennantStatus(true);
					}
				});
				
				this.roleService.isTeamLead().subscribe(response => {
					this.isTeamLead = response;
				});
				return;
			} else {
				setTimeout(() => {
					this.checkToken();
				}, 500);
			}
		}
		
		public IsCurrentGroup(id: number): boolean {
			var curGrp = this.globalService.currentGroup;
			
			if (curGrp == null) {
				return false;
			}
			
			return id == curGrp.id;
		};
		
		public SelectGroup(group: Group) {
			this.globalService.currentGroup = group;
			window.location.reload();
		};

		public GlobalEvent() {
			this.resetInactivityTimeout();
		}
		
		public GlobalClick() {
			this.groupModalShown = false;
			this.resetInactivityTimeout();
		};
		
		public GroupClick() {
			if (this.groupTimer != null) {
				clearTimeout(this.groupTimer);
			}
			
			this.groupTimer = setTimeout(() => {
				this.groupTimer = null;
				
				this.groupModalShown = true;
			}, 0);
		}

		private _timeout;
		private _inactivityTimeInMins = 15;
		public initActivityTimeout(): void {
			this._timeout = setTimeout(() => {
				this._accountService.logout();
			}, (this._inactivityTimeInMins * 60 * 1000));
		}

		public resetInactivityTimeout(): void {
			clearTimeout(this._timeout);
			this.initActivityTimeout();
		}
		
		onResize(event: any) {
			const w = event.target.innerWidth;
			
			if (w < 1200) {
				if (w < 992) {
					if (w < 768) {
						this.globalService.isScreenXS = true;
						this.globalService.isScreenSM = false;
						this.globalService.isScreenMD = false;
						this.globalService.isScreenLG = false;
					}
					else {
						this.globalService.isScreenXS = false;
						this.globalService.isScreenSM = true;
						this.globalService.isScreenMD = false;
						this.globalService.isScreenLG = false;
					}
				}
				else {
					this.globalService.isScreenXS = false;
					this.globalService.isScreenSM = false;
					this.globalService.isScreenMD = true;
					this.globalService.isScreenLG = false;
				}
			}
			else {
				this.globalService.isScreenXS = false;
				this.globalService.isScreenSM = false;
				this.globalService.isScreenMD = false;
				this.globalService.isScreenLG = true;
			}
			
			if (this._sizeTimer != null) {
				clearTimeout(this._sizeTimer);
			}
			
			this._sizeTimer = setTimeout(() => {
				this._sizeTimer = null;
				this.window_size = event.target.innerWidth;
			}, 10);
		};
		
		updater(bool: boolean) {
			this.menuToggle = bool;
		};
		
		getMenuToggle() {
			return this.menuToggle;
		}
		
		public GetText(key: string, lang?: string): string {
			return LANGUAGE.GetText(key, lang);
		}
		
		private getVersion(): void {
			this.commonService.getVersions()
			.subscribe(response => {
				this.version = response.functionalVersion;
				this.globalService.versions = response;
			}, error => {
			});
		}
		
		private getTennantStatus(redirect?: boolean): void {
			this.tenantService.userStatus().subscribe(res => {
				let redirectTo;
				switch(res) {
					case "ActiveUsers":
					redirectTo = '/dashboard';
					break;
					case "NoVerifiedUsers":
					this.globalService.letManufacturerEditUsers = true;
					this.globalService.letManufacturerCreateUsers = false;
					this.showManufacturerPanelLink = false;
					redirectTo = '/employees';
					break;
					case "NoUsers":
					this.globalService.letManufacturerEditUsers = false;
					this.globalService.letManufacturerCreateUsers = true;
					this.showManufacturerPanelLink = false;
					redirectTo = '/employees/edit/0';
					break;
				}
				if (redirect) {
					this.router.navigateByUrl(redirectTo);
				}
			}, err => {
				this.router.navigateByUrl('/dashboard');
			});
		}
		
		private callProbeService(): void {
			this.probeService.probe()
			.subscribe(() => {
				SETTINGS.jquery('#sidepanel').show();
				SETTINGS.jquery('#sidefooter').show();
				SETTINGS.jquery('#panel_content').css('background-color', '#05659f');
				const url = this.router.url;
				if (url.indexOf('maintenance') > - 1) {
					this.router.navigateByUrl('/dashboard');
				}
			}, error => {
				if (error.status === 503) {
					this.router.navigateByUrl('/maintenance');
					SETTINGS.jquery('#sidepanel').hide();
					SETTINGS.jquery('#sidefooter').hide();
					SETTINGS.jquery('#panel_content').css('background-color', '#f5f5f5');
				} else if (error.message === 'Unexpected end of JSON input') {
					SETTINGS.jquery('#sidepanel').show();
					SETTINGS.jquery('#sidefooter').show();
					SETTINGS.jquery('#panel_content').css('background-color', '#05659f');
					const url = this.router.url;
					if (url.indexOf('maintenance') > - 1) {
						this.router.navigateByUrl('/dashboard');
					}
				} else {
					SETTINGS.jquery('#sidepanel').show();
					SETTINGS.jquery('#sidefooter').show();
					SETTINGS.jquery('#panel_content').css('background-color', '#05659f');
					const url = this.router.url;
					if (url.indexOf('maintenance') > - 1) {
						this.router.navigateByUrl('/dashboard');
					}
				}
			});
		}
	}
	