import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaintenanceGuard } from 'app/guards/maintenance-guard.service';
import { AlarmHistoryComponent } from './alarm-history/alarm-history.component';
import { wbLoginGuard } from '../../guards/login-guard.service';
import { AlarmHistoryResolver } from '../../resolvers/alarm-history-resolver.service';
import { UserRolesResolver } from '../../resolvers/userRoles-resolver';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'alarms/alarm-history/:endTime',
        component: AlarmHistoryComponent,
        canActivate: [MaintenanceGuard, wbLoginGuard],
        resolve: {
          alarms: AlarmHistoryResolver,
          rememberUserRoles: UserRolesResolver,
        }
      }
    ])
  ],
  providers: [UserRolesResolver],
  exports: [RouterModule],
})
export class AlarmRoutingModule {}
