<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let alarm of alarmtriggers; let i = index">
				<td class="col-xs-6">
					<div class="ng_top">{{alarm.name}}</div>
					<div style="padding-top:5px;">
						<div class="alarmRange">
							<p>
								<samp *ngIf="alarm.ranges && alarm.ranges.length > 0">
									{{GetText('common.timeframe')}}:
								</samp>
								<samp *ngFor="let alarmRange of alarm.ranges; let j = index"> {{alarmRange.start | date: 'HH:mm'}}-{{alarmRange.end | date: 'HH:mm' }}
									<samp *ngIf="alarm.ranges && alarm.ranges.length > 1 && j !== (alarm.ranges.length - 1)">|</samp>
								</samp>
							</p>
						</div>
					</div>
        </td>
				<td class="col-xs-4">
          <div *ngIf="alarmActives[i].isToggled" class="alarm_icon" [style.border-color]="'#' + alarm.color"
          [style.color]="'#' + alarm.color">
          <div class="icon" [ngClass]="GetIcon(alarm.icon)"></div>
          <div>{{alarm.value}}</div>
          <div>{{alarm.triggerTimeout}}</div>
        </div>
        <div *ngIf="!alarmActives[i].isToggled" class="alarm_icon" [style.border-color]="'#EEEEEE'" [style.color]="'#EEEEEE'">
          <div class="icon-pause-circle" style="font-size:36px;"></div>
        </div>
      </td>
      <td class="col-xs-2 text-center">
        <span>
          <div class="toggler-container">
            <wb-slider-toggler [toggler]="alarmActives[i]" [isDisabled]="disabled === true"></wb-slider-toggler>
          </div>
        </span>
      </td>
    </tr>
  </tbody>
</table>
</div>
<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>
