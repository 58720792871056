import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ServicesModule } from '../services/services.module';
import { FormInputTextComponent } from './form-input.component';
import { PatientFormComponent } from './patient-form.component';
import { CompanySettingsFormComponent } from './companysettings-form.component';
import { EmployeeFormComponent } from './employee-form.component';
import { SharedModule } from 'app/shared.module';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
	imports: [
    SharedModule,
		FormsModule,
		ReactiveFormsModule,
		ServicesModule,
    CommonModule,
    SharedModule,
	],
	declarations: [
		FormInputTextComponent,
    PatientFormComponent,
    EmployeeFormComponent,
		CompanySettingsFormComponent
	],
	bootstrap: [],
	exports: [
    PatientFormComponent,
    EmployeeFormComponent,
		CompanySettingsFormComponent,
		CommonModule,
	],
	providers: [
		ServicesModule
	]
})
export class FormModule { }
