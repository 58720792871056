import * as HighchartsMore from 'highcharts-more';

declare var Highcharts: any;

export class HighchartsLib {
	public highcharts: any;


	constructor() {
		this.highcharts = Highcharts;
		HighchartsMore(this.highcharts);
	}
}