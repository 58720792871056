import { Component, Input } from '@angular/core';
import { PagingExtension } from '../classes/extensions/paging.extension';
import { GlobalService } from '../services/global.service';
import { Patient } from '../classes/patient.class';

@Component({
	selector: 'bedPatient-list',
	templateUrl: './bedPatient-list.html',
	styleUrls: [
		'./alarm-list.css'
	]
})
export class BedPatientListComponent extends PagingExtension {
	@Input('patients')
	public get patients() :Patient[] { return <Patient[]>this.pageItems; };
	public set patients(value: Patient[]) { this.items = value; this.Build(); }


	constructor(private glService: GlobalService) {
		super(12);
	}
}