import { Observer ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CompanySettings } from '../classes/company.class';
import { CompanyService } from '../services/company.service';
import { GlobalService } from '../services/global.service';

@Injectable()
export class CompanySettingsResolver implements Resolve<CompanySettings> {
	constructor(
		private service: CompanyService,
		private glService: GlobalService
	) { }

	resolve(): Observable<CompanySettings> | any {
		return Observable.create((observer: Observer<any>) => {
			this.service.getSettings(this.glService.currentCompanyId).subscribe(value => {
				observer.next(new CompanySettings(value));
				observer.complete();
			});
		});
	}
}