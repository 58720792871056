import { Group } from "./group.class";

export class User {
	public id: number;
	public email: string;
	public name: string;
	public active: boolean;
	public groups: Group[];

	public language: string;

	constructor() {

	}

	public static Convert(obj: any) : User {
		let result: User = new User();

		try { result.id = obj.id; } catch(e){}
		try { result.email = obj.email; } catch(e){}
		try { result.name = obj.name; } catch(e){}
		try { result.active = obj.active; } catch(e){}
		try { result.groups = obj.groups; } catch(e){}
		try { result.language = obj.language; } catch(e){}
		
		return result;
	}
}