import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Page } from '../pages.parent';

@Component({
	selector: 'page-employees-detail',
	templateUrl: './employees-detail.html',
})
export class PageEmployeesDetailComponent extends Page implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		glService: GlobalService
	) {
		super(glService, 'EmployeesDetail');
	}

	ngOnInit() {
		//this.route.data.subscribe((result: any) => { /* it should be getting patient:Patient, however, it gets a object with the patient in the resolver attribute... (bug of Angular?) */
		//	this.patient = result.PatientResolver.patient;
//
		//	this.glService.title = this.patient.displayName;
		//	this.glService.mainButton = new wbcMenuButton('Cliënt wijzigen', '../index');
		//	this.glService.crumble = new wbcUrlLocation('Cliënten', 'Cliënten', '../patients', new wbcUrlLocation('PatientsDetail'));
		//});
	}
}