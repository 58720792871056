import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { RoleService } from '../authorization/services/role.service';
import { GlobalService } from '../services/global.service';
import { Observable, Observer } from 'rxjs';


@Injectable()
export class UserRolesResolver implements Resolve<any> {
	constructor(
		private roleService: RoleService,
		private glService: GlobalService
	) { }

	resolve(): any {
		return Observable.create((observer: Observer<any>) => {
			this.roleService.isManufacturer().subscribe(res => {
				observer.next(this.glService.SetIsManufacturer(res));
				observer.complete();
			});
		});
	}
}