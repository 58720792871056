import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { wbBrowserStorage } from '../modules/webbeat/service/browserStorage.service';
import { GlobalService } from '../services/global.service';
import { AccountService } from '../services/account.service';
import { Page } from './pages.parent';
import { RoleService } from '../authorization/services/role.service';

@Component({
  selector: 'page-login',
  templateUrl: './login.html',
})
export class PageLoginComponent extends Page implements OnInit {
  public username: string;
  public password: string;

  constructor(
    private accService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: wbBrowserStorage,
    private roleService: RoleService,
    glService: GlobalService
  ) {
    super(glService, 'Login');
  }
  ngOnInit(): void {
    var na = this.storage.getStorageItem('no_access');

    if (na != null && na != '') {
      this.router.navigate(['/no_access'], { queryParams: {} });
      return;
    }

    this.SetTitle(this.GetText('common.log_in'));

    this.route.queryParams.subscribe((params: Params) => {
      let code = params['code'];

      if (code == null || code == undefined || code == '') {
        this.accService.clearLocalStorage();
        this.storage.setStorageItem('no_access', 'no_access');
        this.router.navigate(['/no_access'], { queryParams: {} });
        return;
      } else {
        this.accService.login(code).subscribe(
          (result) => {
            const lastLoggedInUserId = parseFloat(
              this.storage.getStorageItem('lastLoggedInUserId')
            );
            this.storage.setStorageItem('token', result.accessToken);
            this.storage.setStorageItem('refreshToken', result.refreshToken);
            this.storage.setStorageItem('expiresOn', result.expiresOn);
            this.storage.setStorageItem('tokenType', result.tokenType);
            this.storage.setStorageItem('user', result.user);
            this.storage.setStorageItem('lang', result.user.language);
            this.glService.Language = result.user.language;

            const currentGroupStorage = JSON.parse(this.storage.getStorageItem('currentGroup'));
            if (currentGroupStorage) {
              const groupExists =
                result.user.groups.filter((group) => group.id == currentGroupStorage.id).length ===
                1
                  ? true
                  : false;
              if (lastLoggedInUserId === result.user.id && groupExists) {
                this.glService.currentGroup = currentGroupStorage;
              } else {
                this.glService.currentGroup = result.user.groups[0];
              }
            } else {
              this.glService.currentGroup = result.user.groups[0];
            }

            this.storage.setStorageItem('lastLoggedInUserId', result.user.id);
            this.roleService.isManufacturer().subscribe((userIsManufacturer) => {
              this.glService.SetIsManufacturer(userIsManufacturer);
              this.router.navigateByUrl('/dashboard');
            });
          },
          () => {
            this.accService.clearLocalStorage();
            this.storage.setStorageItem('no_access', 'no_access');
            this.router.navigate(['/no_access']);
          }
        );
      }
    });
  }
}
