import { Component } from '@angular/core';
import { Page } from '../pages.parent';
import { GlobalService } from '../../services/global.service';
import { wbBrowserStorage } from '../../modules/webbeat/service/browserStorage.service';
import { SETTINGS } from '../../settings';

@Component({
	selector: 'page-error:no_access',
	template: `<p>{{GetText('error.no_access')}}</p><br><br><a routerLink="/login" (click)="ToLogin()">{{GetText('common.tologin')}}</a>`
})
export class PageErrorNoAccessComponent extends Page {
	constructor(glService: GlobalService, private storage: wbBrowserStorage) {
		super(glService, 'NoAccess');

		this.SetTitle(this.GetText('common.no_access'));
		this.storage.clearStorage();
	}

	public ToLogin() {
    this.storage.clearStorage();
    window.location.href = SETTINGS.LOGIN_URL;
		return false;
	};
}
