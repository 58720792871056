import { Pipe, PipeTransform  } from '@angular/core';

import { wbPropertyService } from '../../../service/property.service';
import { wbcBasicFilter, wbcBasicSubFilter } from '../../../classes/wbcFilter.class';



@Pipe({
	name: 'wbfilterbasic',
	pure: false
})
export class wbFilterBasic implements PipeTransform {
	constructor(private service: wbPropertyService) {
  }

	filter(item:any, filter:wbcBasicSubFilter): boolean {
		if (filter.showEmptyFilter && (filter.value == null || typeof(filter.value) == 'undefined' || filter.value == '')) {
				return true;
			}

			if (filter.canBeEmpty && this.service.hasNotPropertyOrEmpty(item, filter.property)) {
				return true;
			}

			var value = this.service.getFromObject(item, filter.property);

			if (filter.matchEntire) {
				if (!filter.caseSensitive) {
					if (('' + value).toLowerCase() == ('' + filter.value).toLowerCase()) {
						return true;
					}
				}
				else {
					if (value == filter.value) {
						return true;
					}
				}
			}
			else {
				if (!filter.caseSensitive) {
					var reg = new RegExp(('' + filter.value).toLowerCase(), 'g');
					return reg.test(('' + value).toLowerCase());
				}
				else {
					var reg = new RegExp(filter.value, 'g');
					return reg.test('' + value);
				}
			}

			return false;
	}

	transform(items: any[], filter: wbcBasicFilter): any {
		if (filter == null || (
				(filter.filters == null || filter.filters.length == 0) &&
				(filter.value == null || filter.value == '' || filter.anyProperties == null || filter.anyProperties.length == 0))) {
			return items;
		}

		var $this = this;

		if (filter.filters != null && filter.filters.length > 0) {
			return items.filter(function(item){
				for (var i = 0; i < filter.filters.length; i ++) {
					var _filter = filter.filters[i];

					if (!$this.filter(item, _filter)) {
						return false;
					}
				}

				return true;
			});
		}
		else if (filter.anyProperties != null && filter.anyProperties.length > 0) {
			return items.filter(function(item){
				var _filter:wbcBasicSubFilter = {
					property: null,
					value: filter.value,
					canBeEmpty: filter.canBeEmpty,
					matchEntire: filter.matchEntire,
					showEmptyFilter: filter.showEmptyFilter,
					caseSensitive: filter.caseSensitive
				};

				for (var i = 0; i < filter.anyProperties.length; i ++) {
					_filter.property = filter.anyProperties[i];

					if ($this.filter(item, _filter)) {
						return true;
					}
				}

				return false;
			});
		}

		return [];
	}
}
