import { ChartType, ChartZoomType } from './hcEnums.class';
import { hcSeries } from './hcSeries.class';
import { hcToJson } from './hcIToJson.interface';
import { BehaviorSubject } from 'rxjs';

export class hcOptions implements hcToJson {
	public chart: hcOchart;
	public title: hcOtitle;
	public subtitle: hcOsubTitle;
	public series: hcSeries[];
	public xAxis: hcOaxis;
	public yAxis: hcOaxis;
	public credits: Object = {
		enabled: false
	};

	public tooltipHtml: string;
	public colors: string[];
	public hasLegend: boolean = true;
	public hasFunction = false;

	public routeSubscription: BehaviorSubject<string>;

	/* <modules> */
	public get exportable(): boolean { if (this.exporting == null) { return false; } return this.exporting.enabled === true; };
	public set exportable(value: boolean) {
		if (value == true) {
			this.exporting = new hcOexporting(true);
		}
		else {
			this.exporting = new hcOexporting(false);
		}
	};

	private exporting: hcOexporting;
	/* </modules> */

	constructor(type: ChartType, name?: string, xAxisMin?: number, xAxisMax?: number, yAxisMin?: number, yAxisMax?: number, public useUTC?: boolean, public zoomable?: boolean, public useFunction?: boolean) {
		this.routeSubscription = new BehaviorSubject('returnString');
		if (zoomable === true) {
			this.chart = new hcOchart(type, ChartZoomType.x, true, 'shift', null, null, 'x');
		} else {
			this.chart = new hcOchart(type);
		}
		this.title = new hcOtitle(name);
		this.subtitle = new hcOsubTitle();


		this.xAxis = new hcOaxis(xAxisMin, xAxisMax);
		this.yAxis = new hcOaxis(yAxisMin, yAxisMax);

		if (useFunction) {
			this.hasFunction = true;
		}

		this.exporting = new hcOexporting(null);
	}

	ToJSON() {
		var result: any = {
			chart: this.chart.ToJSON(),
			title: this.title.ToJSON(),
			subtitle: this.subtitle.ToJSON(),
			series: (() => {
				if (this.series == null) { return null; }

				var result = [];
				for (var i = 0; i < this.series.length; i++) {
					result.push(this.series[i].ToJSON())
				}
				return result;
			})(),
			xAxis: this.xAxis.ToJSON(),
      yAxis: this.yAxis.ToJSON(),
      exporting: {
        enabled: this.exporting.enabled,
        buttons: {
          contextButton: {
            enabled: false
          }
        }
      },
			// exporting: this.exporting.ToJSON(),
			colors: this.colors,
			credits: this.credits,
		};

		if (this.hasFunction === true) {
			const self = this;
			result.plotOptions = {
				series: {
					cursor: 'pointer',
					point: {
						events: {
							click: function (){
								const date = new Date(this.category).toISOString();
								const returnString = `alarms/alarm-history/${date}`;
								self.routeSubscription.next(returnString);
							}
						}
					}
				}
			}
		}

		if (!this.hasLegend) {
			result.legend = {
				enabled: false
			}
		}

		if (this.tooltipHtml != null && this.tooltipHtml != '') {
			result.tooltip = {
        pointFormat: this.tooltipHtml,
        xDateFormat: '%H:%M:%S %d-%m-%Y',
				shared: true
			}
		}

		if (this.useUTC == false) {
			result.global = {
				useUTC: false
			}
		}

		return result;
	};

}

export class hcOchart implements hcToJson {
	public get type(): string { return ChartType[this._typeEnum]; };
	public set typeEnum(value: ChartType) { this._typeEnum = value; };

	public get zoomType(): string { return ChartZoomType[this._zoomTypeEnum]; }
	public set zoomTypeEnum(value: ChartZoomType) { this._zoomTypeEnum = value; }


	constructor(private _typeEnum: ChartType, private _zoomTypeEnum?: ChartZoomType, public panning?: boolean, public panKey?: string, public marginLeft?: number, public isAnimated?: boolean, public pinchType?: string) {
		if (this.isAnimated == null) {
			this.isAnimated = true;
		}
	};

	ToJSON() {
		var result: any = {};

		if (this.type != null) { result.type = this.type; }
		if (this.zoomType != null) { result.zoomType = this.zoomType; }
		if (this.panning != null) { result.panning = this.panning; }
		if (this.panKey != null) { result.panKey = this.panKey; }
		if (this.marginLeft != null) { result.marginLeft = this.marginLeft; }
		if (!this.isAnimated) { result.animation = false; }
		if (this.pinchType !== null) { result.pinchType = this.pinchType; }

		return result;
	}
}

export class hcOtitle implements hcToJson {

	constructor(public text?: string) {

	};

	ToJSON() {
		if (this.text == null) { return null; }

		return {
			text: this.text
		};
	}
}

export class hcOsubTitle implements hcToJson {

	constructor(public text?: string) {

	};

	ToJSON() {
		if (this.text == null) { return null; }

		return {
			text: this.text
		};
	}
}

export class hcOaxis implements hcToJson {
	public minRange?: number;

	constructor(public min?: number, public max?: any, public type?: string, public title?: string, public categories?: string[], public tickAmount?: number, public MinTickInterval?: number) {
	};

	ToJSON() {
		var result: any = {
			//minRange: 0.1
			reserveSpace: false
		};

		if (this.min != null) { result.min = this.min; }
		if (this.max != null) { result.max = this.max; }
		if (this.type != null) { result.type = this.type; }
		if (this.title != null) { result.title = { text: this.title }; }
		if (this.categories != null && this.categories.length > 0) { result.categories = this.categories; }
		if (this.tickAmount != null) { result.tickAmount = this.tickAmount; }
		if (this.MinTickInterval != null) { result.MinTickInterval = this.MinTickInterval; }
		if (this.minRange != null && this.minRange > 0) { result.minRange = this.minRange; }

		return result;
	}
}


/* <modules> */
export class hcOexporting implements hcToJson {

	constructor(public enabled: boolean, public serverFallback?: boolean) {
		if (this.serverFallback == null) {
			this.serverFallback = false;
		}
	};

	ToJSON() {
		if (this.enabled == null) {
			return null;
		}

		return {
			chartOptions: {
				plotOptions: {
					series: {
						dataLabels: {
							enabled: false
						}
					}
				},
			},
			fallbackToExportServer: false
		};
	}
}
/* </modules> */
