<form (ngSubmit)="onSubmit()" #patientForm="ngForm" [formGroup]="form" onsubmit="return false;">
	<div class="row" *ngIf="model.canAlarmReminderTime || model.canAlarmTakenReminderTime">
		<div *ngIf="model.canAlarmReminderTime" class="col-xs-12 col-md-6 col-lg-4">
			<form-input-text
				formControlName="alarmReminderTime"
				[title]="GetText('forms.companysettings.alarm_reminder_time')"
				[model]="model"
				[property]="'alarmReminderTime'"
				[isRequired]="false"
				[errorMessage]="GetText('forms.companysettings.error.alarm_reminder_time')"
				[trigger_keyDown]="updateTrigger"
				[trigger_blur]="updateTrigger"
			></form-input-text>
		</div>

		<div *ngIf="model.canAlarmTakenReminderTime" class="col-xs-12 col-md-6 col-lg-4">
			<form-input-text
				formControlName="alarmTakenReminderTime"
				[title]="GetText('forms.companysettings.alarm_taken_time')"
				[model]="model"
				[property]="'alarmTakenReminderTime'"
				[isRequired]="false"
				[errorMessage]="GetText('forms.companysettings.error.alarm_taken_time')"
				[trigger_keyDown]="updateTrigger"
				[trigger_blur]="updateTrigger"
			></form-input-text>
		</div>
	</div>
</form>