import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from 'app/modules/table/table.component';
import { Ng2TableModule } from './modules/ng2-table/components/ng-table-module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule } from '@angular/forms';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
} from 'ng-pick-datetime-ex';
import { NgSelectModule } from '@ng-select/ng-select';
import { WebbeatModule } from './modules/webbeat/webbeat.module';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule.forRoot(),
    ButtonsModule.forRoot(),
    TabsModule.forRoot(),
    WebbeatModule,
    Ng2TableModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  declarations: [
    TableComponent,
    LoaderComponent
  ],
  bootstrap: [],
  providers: [{ provide: OWL_DATE_TIME_LOCALE, useValue: 'en' }],
  exports: [
    CommonModule,
    FormsModule,
    PaginationModule,
    ButtonsModule,
    TabsModule,
    Ng2TableModule,
    TableComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    LoaderComponent
  ],
})
export class SharedModule {}
