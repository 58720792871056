<div>
	<wb-grid-headerBlocks gridWidth="8" gridOffset="2">
		<div block-header>
			<div id="colorBlock" class="color_green"></div>
			<h2>{{GetText('info_page.title')}}</h2>
			<p>{{GetText('info_page.header_block')}}</p>
		</div>
		<div block-body>
			<div class="col-xs-12">
				<!--<div class="infoBlock bordered">-->
				<div class="row" style="margin-bottom: 15px;margin-top: 15px;">
					<div class="col-xs-12 col-sm-6">
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<span class="icon icon-factory2" style="font-size: 24px;"></span>
							</div>
							<div class="col-xs-11">
								<div>Connect Foam Care B.V.</div>
								<div>Willem Alexanderstraat 5</div>
								<div>6691 EE GENDT</div>
								<div>The Netherlands</div>
							</div>
						</div>
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<span class="icon icon-email" style="font-size: 24px;"></span>
							</div>
							<div class="col-xs-11">
								<a href="mailto:info@hallow.eu">info@connectfoamcare.com</a>
							</div>
						</div>
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<span class="icon icon-website" style="font-size: 24px;"></span>
							</div>
							<div class="col-xs-11">
								<a href="https://connectfoamcare.com">www.connectfoamcare.com</a>
							</div>
						</div>
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<span class="icon icon-phone" style="font-size: 24px;"></span>
							</div>
							<div class="col-xs-11">
								<a href="tel:+31481451344">+31 (0)85 - 0404 846</a>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-6">
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<img src="/Content/img/fab-deron.png" />
							</div>
							<div class="col-xs-11">
								<div>Deron B.V.</div>
								<div>Willem Alexanderstraat 5</div>
								<div>6691 EE GENDT</div>
								<div>The Netherlands</div>
							</div>
						</div>
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<span class="icon icon-email" style="font-size: 24px;"></span>
							</div>
							<div class="col-xs-11">
								<a href="mailto:info@hallow.eu">info@deron.nl</a>
							</div>
						</div>
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<span class="icon icon-website" style="font-size: 24px;"></span>
							</div>
							<div class="col-xs-11">
								<a href="https://connectfoamcare.com">www.deron.nl</a>
							</div>
						</div>
						<div class="row" style="margin-bottom: 15px;">
							<div class="col-xs-1">
								<span class="icon icon-phone" style="font-size: 24px;"></span>
							</div>
							<div class="col-xs-11">
								<a href="tel:+31481451344">+31 (0)481 45 13 44</a>
							</div>
						</div>
					</div>
				</div>
				<div class="row" style="margin-bottom: 15px;">
					<div class="col-xs-offset-1 col-xs-11">
						<div class="row">
							<div class="col-xs-12">
								<span>{{GetText('info_page.portal_version')}}</span>
								<span> {{functionalVersion}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col-xs-12">
								<span>{{GetText('info_page.api_version')}}</span>
								<span> {{apiReleaseVersion}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col-xs-12">
								<span>{{GetText('info_page.production_date')}}</span>
								<span> 01-01-2017</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row" style="margin-bottom: 15px;">
					<div class="col-xs-1">
						<span class="icon icon-warning1" style="font-size: 24px;"></span>
					</div>
					<div class="col-xs-11">
						<strong>{{GetText('info_page.warning')}}</strong> {{GetText('info_page.line_one')}}
					</div>
				</div>
				<div class="row" style="margin-bottom: 15px;">
					<div class="col-xs-1">
						<span class="icon icon-info" style="font-size: 24px;"></span>
					</div>
					<div class="col-xs-11">
						{{GetText('info_page.line_two')}}
					</div>
				</div>
				<div class="row" style="margin-bottom: 15px;">
					<div class="col-xs-1">
						<!--<span class="icon icon-book" style="font-size: 24px;"></span>-->
					</div>
					<div class="col-xs-11">{{GetText('info_page.line_three')}}</div>
				</div>
				<div class="row" style="margin-bottom: 15px;">
					<div class="col-xs-offset-1 col-xs-11">
						<a href="{{PrivacyUrl}}">{{GetText('info_page.privacy')}}</a>
					</div>
				</div>
				<div class="row" style="margin-bottom: 15px;">
					<div class="col-xs-offset-1 col-xs-11">{{GetText('info_page.made_in')}}</div>
					<div class="col-xs-offset-1 col-xs-11">
						<span class="icon icon-conform-eu" style="font-size:24px;"></span>
					</div>
				</div>
				<!--</div>-->
			</div>
		</div>
	</wb-grid-headerBlocks>
</div>