<div id="container" *ngIf="items != null && items.length > 0">
	<div *ngFor="let item of items | wbfilterbasic:filter; let id = index" [class]="getCssClass(item)">
		<div *ngFor="let prop of properties; let i = index" id="block_{{getId(i)}}">
			<div *ngIf="isToggler(i)">
				<wb-button-toggler [toggler]="items[id][prop]"></wb-button-toggler>
			</div>
			<div *ngIf="!isToggler(i)">
				<div *ngIf="!isClickable(prop)">
					<span [innerHtml]="getPrefix(i) | safeHtml"></span>
					<span [innerHtml]="getValue(item, prop) | safeHtml"></span>
				</div>
				<a *ngIf="isClickable(prop)" routerLink="{{getLink(item, prop)}}">
					<span [innerHtml]="getPrefix(i) | safeHtml"></span>
					<span [innerHtml]="getValue(item, prop) | safeHtml"></span>
				</a>
			</div>
		</div>
	</div>
</div>