import { wbcAny } from './wbcAny.class';

export class wbcOption {
	public selected: boolean;
	public value: wbcAny;
	public text?: string;

	public get displayText() :string { if (this.text != null && this.text != '') { return this.text; } return this.value.value; }


	private _value: any;


	constructor(value: wbcAny, text?: string, selected?: boolean) {
		this.value = value;
		this.text = text;
		this.selected = selected == true;
	};
}