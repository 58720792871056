import { Component, Input } from '@angular/core';
import { wbPropertyService } from '../../../service/property.service';
import { wbcBasicFilter } from '../../../classes/wbcFilter.class';
import { wbcTableClickable, wbcTableEmpty } from '../../../classes/wbcTable.class';


@Component({
	selector: 'wb-table-basic',
	templateUrl: './table-basic.html',
	styleUrls: [
		'./table-basic.css'
	]
})
export class wbTableBasicComponent {
	@Input() items: any[];
	@Input() headers: string[];
	@Input() properties: string[];
	@Input() clickable: wbcTableClickable[];
	@Input() onEmpty: wbcTableEmpty;
	@Input() filter: wbcBasicFilter;

	constructor(private propService: wbPropertyService){
		//this.filter = {
		//	property: "name",
		//	value: 'M',
		//	canBeEmpty: false,
		//	//matchEntire: true
		//	caseSensitive: true
		//}
	}


	getValue(item:any, prop:string) {
		return this.propService.getFromObject(item, prop);
	}

	isClickable(prop:string) {
		if (this.clickable != null && this.clickable.length > 0) {
			for (var i = 0; i < this.clickable.length; i++) {
				if (prop == this.clickable[i].property) {
					return true;
				}
			}
		}

		return false;
	};

	getLink(item:any, prop:string) {
		var link = '';

		if (this.clickable != null && this.clickable.length > 0) {
			for (var i = 0; i < this.clickable.length; i++) {
				if (prop == this.clickable[i].property) {
					link = this.clickable[i].href;
					break;
				}
			}

			while (link.search(/\{\{[^}]*\}\}/g) > -1) {
				var m = link.match(/\{\{[^}]*\}\}/)[0];
				var value = this.getValue(item, m.replace(/[{}]/g, ''));

				link = link.replace(m, value);
			}
		}

		return link;
	};

	emptyColumns(){
		var result: any[] = [];

		if (this.onEmpty != null) {
			for (var i = 0; i < this.onEmpty.column; i ++) {
				result.push({});
			}
		}

		return result;
	};
}