import { contentParent } from '../../content/content-parent';

export class PagingExtension extends contentParent {
	public pageItems: any[];
	public page: number = 0;
	public pageSize: number = 5;

	public get totalSize() :number { return this.items.length; }
	public get pageStart() :number { if (this.totalSize == 0) { return 0; } return (this.page * this.pageSize) + 1; }
	public get pageEnd() :number { var max = (this.page + 1) * this.pageSize; if (max > this.totalSize) { return this.totalSize; } return max; }
	public get canGoToNextPage() :boolean { return ((this.page + 1) * this.pageSize < this.totalSize); }
	public get canGoToPreviousPage() :boolean { return this.page > 0; }

	public onUpdate: Function;

	protected items: any[] = [];

	constructor(pageSize?: number) {
		super();

		if (pageSize != null && pageSize > 0) {
			this.pageSize = pageSize;
		}
	}

	public GoToPage(page: number) {
    if (this.totalSize === 0) {
      // This is to prevent the list from going back to page 1 on update. When updating the list gets set to an empty array before filling again.
      return false;
    }

		if ((page * this.pageSize) + this.pageSize >= this.totalSize) {
			page = Math.ceil(this.totalSize / this.pageSize) - 1;
		}

		if (page < 0) {
			page = 0;
		}

		this.page = page;
		if (this.items != null) {
			this.pageItems = this.items.slice(this.page * this.pageSize, (this.page + 1) * this.pageSize);
		}

		if (this.onUpdate != null) {
			this.onUpdate();
		}
	}

	public GoToNextPage() {
		this.GoToPage(this.page + 1);
	}

	public GoToPreviousPage() {
		this.GoToPage(this.page - 1);
	}

	protected Build() {
		if (this.items == null) {
			this.items = [];
		}
    this.GoToPage(this.page);
	}
}
