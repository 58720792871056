import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastrFactoryService {

 constructor(public toastr: ToastrService) {
  }

 public createToast(type: string, message: string, title: string): void {
    switch (type.toLowerCase()) {
      case 'error':
        this.toastr.error(message, title);
        break;
      case 'success': {
        this.toastr.success(message, title);
        break;
      }
      case 'warning': {
        this.toastr.warning(message, title);
        break;
      }
      default: {
        this.toastr.info(message, title);
      }
    }
  }

}