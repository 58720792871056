
import { Component, Input, ElementRef, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { contentParent } from '../../content-parent';
import { LANGUAGE } from 'app/language';
import { AlarmReportGraphData } from 'app/classes/analyze-alarm.class';

//Hicharts imports
import { hcHighChartService } from '../../../modules/highchart/services/highchart.service';

@Component({
	selector: 'alarm-report-graph',
  template: `
    <div #chart id="graph-container"></div>
  `,
	styleUrls: [
		'../graph.css',
		'./alarm-report-graph.component.css'
	]
})
export class AlarmReportGraphComponent extends contentParent implements AfterViewInit {
	@ViewChild('chart', { static: true }) chartTarget: ElementRef;

	@Input('alarmGraphData')
	get alarmGraphData(): AlarmReportGraphData[] {
		return this._alarmGraphData;
	};
	set alarmGraphData(value: AlarmReportGraphData[]) {
    this._alarmGraphData = value;
    if (value != undefined) {
      this._setBiggestValue();
      console.log('set  init');
      this._buildGraph();
    }
	}

  public chart: any;

  private _firstTime = true;
	private _alarmGraphData: any = [];
  private _options: any;
  private _biggestValue: Number;

	constructor(private _chartService: hcHighChartService) {
		super();
  }
  
  ngAfterViewInit(): void {
    console.log('view init');
  }

  private _setBiggestValue(): any {
    let values = this._alarmGraphData;
    values = values.sort(function(a, b) {
      return (b.data[0].value + b.data[1].value) > (a.data[0].value + a.data[1].value) ? 1 : -1;
    });
    if (values && values[0]) {
      this._biggestValue = values[0].data[0].value + values[0].data[1].value;
    }
  };

	private _buildGraph(): void {
    this._options = this._setupOptions();
    this.chart = this._chartService.ChartNormal(this._options, this.chartTarget.nativeElement);
    this._firstTime = false;
	}

	private _setupOptions(): any {
    const formatterCallback = function (y) {
      if ((y <= 0) || (y < (this._biggestValue * 0.1))) {
        return '';
      } else {
        return y;
      }
    }.bind(this);

    const options = {
      chart: {
        type: 'column',
        height: this._calculateGraphHeight(),
        inverted: true,
      },
      title: {
        text: this.GetText('common.alarms')
      },
      credits: {
        enabled: false
      },
      legend: {
        symbolPadding: 0,
        symbolWidth: 0,
        useHTML: true,
        labelFormatter: function () {
          if (this.index > 1) {
            return '';
          }
          if (this.index === 0) {
            return `
              <span style="height: 14px; width: 14px; background-color: #C93958; display: inline-block; border-radius: 7px;"></span>
              <span style="color: #C93958">${LANGUAGE.GetText('common.alarm')}</span>
              `;
            }
            if (this.index === 1) {
              return `
              <span style="height: 14px; width: 14px; background-color: #FF8C00; display: inline-block; border-radius: 7px;"></span>
              <span style="color: #FF8C00">${LANGUAGE.GetText('common.alarm_repeat')}</span>
            `;
          }
        },
      },
      exporting: {
        enabled: false,
        contextButton: {
          enabled: false
        }
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'black'
          }
        },
        scrollbar: {
          enabled: true
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return formatterCallback(this.y);
            },
            color: 'white'
          }
        },
      },
      series: []
    };

    this.alarmGraphData.forEach((graphDataItem, index) => {
      options.xAxis.categories.push(`${graphDataItem.period ? graphDataItem.period : ''} ${LANGUAGE.GetText('content.graph.' + graphDataItem.alarmName.toLowerCase())}`);
      options.series.push({
        name: LANGUAGE.GetText('content.graph.' + graphDataItem.alarmName.toLowerCase()),
        data: [],
        showInLegend: index < 2 ? true : false,
      });

      graphDataItem.data.forEach(dataItem => {
        options.series[index].data.color = dataItem.name.toLowerCase() === 'alarm' ? '#C93958' : '#FF8C00';
        options.series[index].data.push({
          name: LANGUAGE.GetText('content.graph.' + graphDataItem.alarmName.toLowerCase()),
          x: index,
          y: dataItem.value,
          colorIndex: dataItem.name.toLowerCase() === 'alarm' ? 100 : 101
        });
      });
    });
    return options;
  }

  private _calculateGraphHeight(): number {
    return (this._alarmGraphData.length * 50) + 100;
  }
}
