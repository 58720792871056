<wb-sidePanel-basic autohide="xsmall, small" sideWidth="300" [totalWidth]="window_size">
  <div panel-closer>
    <div class="icon icon-menu" style="display: inline; color: #424242;"></div>
  </div>
  <div panel-header>
    <div class="icon icon_logo hidden-xs hidden-sm"></div>
  </div>
  <div id="sidepanel" panel-body [hidden]="isOverriden">
    <a
      class="sideButton"
      *ngIf="showManufacturerPanelLink"
      routerLink="../dashboard"
      [ngClass]="{ 'active': globalService.IsParent('Dashboard') }">
        <span class="icon icon-dashboard"></span>&nbsp;&nbsp;{{GetText('common.dashboard')}}
    </a>
    <a
      class="sideButton"
      *ngIf="showManufacturerPanelLink"
      routerLink="../patients"
      [ngClass]="{ 'active': globalService.IsParent('Patients') }">
        <span class="icon icon-clients"></span>&nbsp;&nbsp;{{GetText('common.patients')}}
    </a>
    <a
      class="sideButton"
      *ngIf="showManufacturerPanelLink"
      routerLink="../bed"
      [ngClass]="{ 'active': globalService.IsParent('Bed') }">
        <span class="icon icon-in-out-bed"></span>&nbsp;&nbsp;{{GetText('common.bed')}}
    </a>
    <a
      class="sideButton"
      *ngIf="showManufacturerPanelLink"
      routerLink="../services"
      [ngClass]="{ 'active': globalService.IsParent('Services') }">
      <span class="icon icon-services"></span>&nbsp;&nbsp;{{GetText('common.services')}}
    </a>
    <a
      class="sideButton"
      routerLink="../employees"
      [ngClass]="{ 'active': globalService.IsParent('Employees') }">
        <span class="icon icon-employees"></span>&nbsp;&nbsp;{{GetText('common.employees')}}
    </a>
    <a
      class="sideButton"
      *ngIf="isTeamLead"
      routerLink="../reports"
      [ngClass]="{ 'active': globalService.IsParent('Reports') }">
        <span class="icon icon-graph"></span>&nbsp;&nbsp;{{GetText('reports.reports')}}
    </a>
    <a
      class="sideButton"
      routerLink="../settings"
      [ngClass]="{ 'active': globalService.IsParent('Settings') }">
        <span class="icon icon-cog"></span>&nbsp;&nbsp;{{GetText('common.settings')}}
    </a>
    <a
      class="sideButton"
      routerLink="../info"
      [ngClass]="{ 'active': globalService.IsParent('Info') }">
        <span class="icon icon-info"></span>&nbsp;&nbsp;{{GetText('common.info')}}
    </a>
    <!-- <a class="sideButton" routerLink="../company" [ngClass]="{ 'active': globalService.IsParent('Company') }"><span class="icon icon-company"></span>&nbsp;&nbsp;{{GetText('common.company')}}</a> -->
  </div>
  <div id="sidefooter" panel-footer [hidden]="isOverriden">
    <div class="footer_content" *ngIf="HasToken">
      <div class="footer_text">{{Name}}</div>
      <div class="footer_text">{{Email}}</div>
      <div *ngIf="!isManufacturer" class="footer_text">{{Company}}</div>
      <div *ngIf="isManufacturer" class="footer_text"><a class="link" routerLink="../switch_tenant">{{Company}}</a></div>
      <hr/>
      <div class="footer_text small">{{GetText('common.last_refresh')}}: {{LastRefresh}}</div>
      <div class="footer_text small">{{GetText('common.version')}}: {{version}}</div>
    </div>
    <div class="footer_buttons">
      <div class="footer_button left" (click)="GroupClick()">
        <span class="icon icon-teams"></span>
        <br/>
        <span>{{Group}}</span>
      </div>
      <a class="linkButton" routerLink="../logout">
        <div class="footer_button right">
          <span class="icon icon-logout"></span>
          <br/>
          <span>{{GetText('common.log_out')}}</span>
        </div>
      </a>
      <div id="groupModal" [hidden]="!groupModalShown">
        <div id="gM_header">
          <div id="colorBlock" class="color_orange"></div>
          <h3>{{GetText('common.teams')}}</h3>
        </div>
        <div id="options">
          <div *ngFor="let option of Groups" class="option" [ngClass]="{ 'selected': IsCurrentGroup(option.id) }" (click)="SelectGroup(option)">
            <span class="fl_left">{{option.name}}</span>
            <span class="fl_right" [hidden]="!IsCurrentGroup(option.id)">
              <span class="icon icon-check"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #mainbody main-body>
    <div id="body" class="container-fluid">
      <div id="sizeAlert" class="hidden-sm hidden-md hidden-lg">
        {{GetText('common.size_alert')}}
      </div>
      <headerItem [autohideB]="'xsmall, small'"></headerItem>
      <router-outlet></router-outlet>
    </div>
  </div>
</wb-sidePanel-basic>

<div class="page_loading" [hidden]="!pageLoading">
  <div class="content">
    <div class="icon icon_loading"></div>
  </div>
</div>
