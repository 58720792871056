import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { wbBrowserStorage } from './modules/webbeat/service/browserStorage.service';
import { Observable } from 'rxjs';
import { wbcDaHelper } from './modules/webbeat/classes/wbcDaHelper.class';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private _wbStorage: wbBrowserStorage) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authLessCalls = [
      '/login',
      '/auth/token',
      '/probe',
      '/version'
    ];
    const authLessCall = authLessCalls.some((endpoint: string) => req.url.includes(endpoint));
    
    let clonedReq = wbcDaHelper.addHeaders(
      req,
      !authLessCall,
      !authLessCall ? this._wbStorage.getStorageItem('currentGroup.id') : null 
    );

    return next.handle(clonedReq);
  }
}
