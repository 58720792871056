import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable ,  Observer } from "rxjs";
import { RoleService } from "../services/role.service";

@Injectable()
export class TeamLeadGuard implements CanActivate {
	constructor(private roleService: RoleService, private router: Router) {
	}

	canActivate(): Observable<boolean> {
		return Observable.create((observer: Observer<boolean>) => {
			this.roleService.isTeamLead().subscribe(response => {
				if (!response) this.router.navigateByUrl('/no_access');
				observer.next(response);
				observer.complete();
			});
		});

	}
}
