import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageLoginComponent } from './pages/login.component';
import { PageLogoutComponent } from './pages/logout.component';
import { PageDashboardComponent } from './pages/dashboard.component';
import { PageInfoComponent } from './pages/info.component';
import { PageMaintenanceComponent } from 'app/pages/maintenance/maintenance.component';
import { PageSwitchTennantComponent } from './pages/switch-tenant.component';
import { PageErrorNotFoundComponent } from './pages/error/not_found.component';
import { PageErrorNoAccessComponent } from './pages/error/no_access.component';
import { wbLoginGuard } from './guards/login-guard.service';
import { wbRedirectGuard } from './guards/redirect-guard.service';
import { MaintenanceGuard } from 'app/guards/maintenance-guard.service';
import { IsNotManufacturerGuard } from './authorization/guards/not-manufacturer-guard.service';
import { PatientsResolver } from './resolvers/patients-resolver.service';
import { EmployeesResolver } from './resolvers/employees-resolver';
import { CompanySettingsResolver } from './resolvers/companySettings-resolver';
import { TenantResolver } from './resolvers/tenant-resolver';
import { DisconnectedDevicesResolver } from 'app/resolvers/disconnectedDevices-resolver';
import { RoleService } from './authorization/services/role.service';
import { UserService } from './services/user.service';
import { wbBrowserStorage } from './modules/webbeat/service/browserStorage.service';
import { UserRolesResolver } from './resolvers/userRoles-resolver';
import { ManufacturerGuard } from './authorization/guards/manufacturer-guard.service';
import { TeamLeadGuard } from './authorization/guards/team-lead-guard.service';
import { isManufacturerOrDealerGuard } from './authorization/guards/dealer-or-manufacturer-guard.service';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: 'login',
        canActivate: [MaintenanceGuard],
        component: PageLoginComponent,
      },
      {
        path: 'logout',
        canActivate: [MaintenanceGuard],
        component: PageLogoutComponent,
      },
      {
        path: 'no_access',
        canActivate: [MaintenanceGuard],
        component: PageErrorNoAccessComponent,
      },
      {
        path: 'maintenance',
        component: PageMaintenanceComponent,
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/app-report/report.module').then(m => m.ReportModule),
        canActivate: [wbLoginGuard, TeamLeadGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/app-settings/settings.module').then(m => m.SettingsModule),
        canActivate: [wbLoginGuard],
      },
      {
        path: 'switch_tenant',
        canActivate: [isManufacturerOrDealerGuard],
        resolve: {
          TenantResolver,
        },
        component: PageSwitchTennantComponent,
      },
      {
        path: 'dashboard',
        data: { loadTesting: true },
        canActivate: [MaintenanceGuard, wbLoginGuard],
        component: PageDashboardComponent,
      },
      {
        path: 'info',
        canActivate: [MaintenanceGuard],
        resolve: {},
        component: PageInfoComponent,
      },

      {
        path: '',
        canActivate: [MaintenanceGuard, wbRedirectGuard],
        component: PageLogoutComponent,
      },
      { path: '**', canActivate: [MaintenanceGuard], component: PageErrorNotFoundComponent },
    ]),
  ],
  providers: [
    UserRolesResolver,
    wbLoginGuard,
    MaintenanceGuard,
    wbRedirectGuard,
    IsNotManufacturerGuard,
    ManufacturerGuard,
    DisconnectedDevicesResolver,
    TenantResolver,
    PatientsResolver,
    EmployeesResolver,
    CompanySettingsResolver,
    RoleService,
    UserService,
    wbBrowserStorage,
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
