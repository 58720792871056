import { SETTINGS } from 'app/settings';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DisconnectedDevice } from '../classes/DisconnectedDevice';
import { IConnectedDevice } from 'app/classes/device.class';

@Injectable()
export class DeviceService {
	private _baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient){
	}

	public getDeviceConnectionLogs(deviceId?: number, from?: string, to?: string): Observable<any> {
		return this._http.get(`${this._baseUrl}v1/device-connection-logs/graph?deviceId=${deviceId}&from=${from}&to=${to}`);
	}

	public getDisconnectedDevices(): Observable<DisconnectedDevice[]> { 
		return this._http.get<DisconnectedDevice[]>(`${this._baseUrl}v1/devices/disconnected`);
	}

	public getConnectedDevices(): Observable<IConnectedDevice[]> {
		return this._http.get<IConnectedDevice[]>(`${this._baseUrl}v1/devices/connected`);
	}
}
