import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Page } from './pages.parent';
import { SETTINGS } from '../settings';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'page-info',
  templateUrl: './info.html',
})
export class PageInfoComponent extends Page implements OnInit {
  public PrivacyUrl: string;
  public TermsOfUseUrl: string;
  public functionalVersion = '';
  public apiReleaseVersion = '';

  constructor(private commonService: CommonService, glService: GlobalService) {
    super(glService, 'Info');
    this.SetTitle(this.GetText('common.info'));
    this.PrivacyUrl = this.GetText('documents.privacy') + '?t=' + Date.now();
    this.TermsOfUseUrl = SETTINGS.BASE_URL + 'content/Gebruikersvoorwaarden-v6.pdf';
  }

  ngOnInit() {
    this.preloadTimer = SETTINGS.AddInterval(
      () => {
        if (this.glService.preload) {
          SETTINGS.ClearInterval(this.preloadTimer);
        }
      },
      1,
      this.preloadTimer
    );

    this.getVersion();
  }

  private getVersion(): void {
    this.commonService.getVersions().subscribe(
      (response) => {
        this.functionalVersion = response.functionalVersion;
        this.apiReleaseVersion = response.apiReleaseVersion;
      },
      () => {}
    );
  }
}
