
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { RoleService } from "../services/role.service";

@Injectable()
export class isNotManufacturerOrDealerGuard implements CanActivate {
	constructor(private roleService: RoleService, private router: Router) {
	}

	canActivate(): Observable<boolean> | boolean {
		return this.roleService.isManufacturerOrDealer().pipe(map(response => {
			const mayContinue = !response;
			if (mayContinue) {
				return true;
			} else {
				this.router.navigateByUrl('/services');
				return false;
			}
		}));
	}
}
