<form (ngSubmit)="onSubmit()" #patientForm="ngForm" [formGroup]="form" onsubmit="return false;">
	<div class="row">
		<div class="col-xs-5">
			<form-input-text
				formControlName="initials"
				[title]="GetText('forms.patient.initials')"
				[model]="model"
				[property]="'initials'"
				[isRequired]="true"
				[errorMessage]="GetText('forms.patient.error.initials')"
				[trigger_keyDown]="updateTrigger"
				[trigger_blur]="updateTrigger"
				[isReadOnly]="isManufacturer"
			></form-input-text>
		</div>
		<div class="col-xs-5">
			<form-input-text
				formControlName="insertion"
				[title]="GetText('forms.patient.insertion')"
				[model]="model"
				[property]="'insertion'"
				[errorMessage]="GetText('forms.patient.error.insertion')"
				[trigger_keyDown]="updateTrigger"
				[trigger_blur]="updateTrigger"
				[isReadOnly]="isManufacturer"
			></form-input-text>
		</div>
		<div class="col-xs-10">
				<form-input-text
					formControlName="lastName"
					[title]="GetText('forms.patient.lastname')"
					[model]="model"
					[property]="'lastName'"
					[isRequired]="true"
					[errorMessage]="GetText('forms.patient.error.lastname')"
					[trigger_keyDown]="updateTrigger"
					[trigger_blur]="updateTrigger"
					[isReadOnly]="isManufacturer"
				></form-input-text>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-5">
			<form-input-text
				formControlName="room"
				[title]="GetText('forms.patient.room')"
				[model]="model"
				[property]="'room'"
				[isRequired]="true"
				[errorMessage]="GetText('forms.patient.error.room')"
				[trigger_keyDown]="updateTrigger"
				[trigger_blur]="updateTrigger"
				[isReadOnly]="isManufacturer"
			></form-input-text>
    </div>
  </div>
  <div class="row" *ngIf="model.id === 0">
    <div class="col-xs-10">
      <p class="title-bold">{{GetText('common.client_groups_settings')}}</p>
      <ng-select
        [ngModel]="model.patientGroups"
        (ngModelChange)="model.patientGroups = $event; onValidateFunc();"
        [ngModelOptions]="{standalone: true}"
        class="custom"
        id="client-group-select"
        name="patientGroups"
        [items]="patientGroups"
        [multiple]="true"
        [searchable]="false"
        [closeOnSelect]="false"
        bindValue="id"
        bindLabel="name"
        placeholder="{{GetText('common.client_groups')}}">
      </ng-select>
    </div>
  </div>
</form>
<!--<pre>{{ patientForm.value | json }}</pre>
<pre>{{ patientForm.valid | json }}</pre>-->
