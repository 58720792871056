import { tap } from 'rxjs/operators';
import { GlobalService } from 'app/services/global.service';
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ApiTimeInterceptor implements HttpInterceptor {
  constructor(
    private _glService: GlobalService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const toIgnoreApiCalls = [
      'auth/login',
      'auth/token',
      'probe',
      'performance'
    ];

    const shouldIgnore = toIgnoreApiCalls.some(toIgnore => req.url.includes(toIgnore));

    if (!shouldIgnore && req.method.toLowerCase() !== 'options') {
      const timeAtStart = performance.now();

      return next.handle(req).pipe(
        tap(() => {
          const apiUrlWithParams = req.url.split('?');
          const toPush = {
            apiCallInResolver: this._glService.isCurrentlyResolving,
            apiCallURL: apiUrlWithParams[0],
            apiCallParams: apiUrlWithParams.length > 1 ? apiUrlWithParams[1] : null,
            apiCallMethod: req.method,
            pageUrl: window.location.hash + window.location.pathname,
            timeInMS: performance.now() - timeAtStart,
          };

          this._glService.addApiBenchmark(toPush);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
