<div class="col-xs-6 col-sm-6 col-md-12 col-lg-12">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header class="row">
			<div id="colorBlock" class="color_orange"></div>
			<div class="col-xs-7">
				<h2>{{(employee && employee.id > 0) ? employee.name : GetText('common.add_employee')}}</h2>
				<p class="color_orange">{{(employee && employee.id > 0) ? GetText('common.edit_employee') : GetText('common.add_employee')}}</p>
			</div>
		</div>
		<div block-body>
      <employee-form [model]="employee" [submitted]="updateEmployee" [violations]="violations" [employeeTeams]="employeeTeams"></employee-form>
		</div>
	</wb-grid-headerBlocks>
