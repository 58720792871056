import { Component, Input } from '@angular/core';

import { wbPropertyService } from '../../../service/property.service';
import { wbcBasicFilter } from '../../../classes/wbcFilter.class';
import { wbcTableClickable, wbcTableEmpty } from '../../../classes/wbcTable.class';
import { wbcToggler } from '../../../classes/wbcToggler.class';


@Component({
	selector: 'wb-table-blocks',
	templateUrl: './table-blocks.html',
	styleUrls: [
		'./table-blocks.css'
	]
})
export class wbTableBlocksComponent {
	@Input() items: any[];
	@Input() properties: Array<string | Function>;
	@Input() propertyIds: string[];
	@Input() prefixes: string[];
	@Input() togglers?: boolean[];
	@Input() clickable: wbcTableClickable[];
	@Input() onEmpty: wbcTableEmpty;
	@Input() filter: wbcBasicFilter;
	@Input() cssClass: string | Function;


	public toggleThings: wbcToggler[];

	constructor(private propService: wbPropertyService){
		//this.filter = {
		//	property: "name",
		//	value: 'M',
		//	canBeEmpty: false,
		//	//matchEntire: true
		//	caseSensitive: true
		//}

		this.toggleThings = [
			new wbcToggler(false),
			new wbcToggler(false),
			new wbcToggler(false),
		];
	}


	getValue(item:any, prop:string | Function) {
		if (typeof(prop) == 'function') {
			return prop(item);
		}

		return this.propService.getFromObject(item, prop);
	}

	getId(i: number) :string {
		if (this.propertyIds != null && this.propertyIds.length > i) {
			return this.propertyIds[i];
		}

		return '' + i;
	}

	getPrefix(i: number) {
		if (this.prefixes != null && this.prefixes.length > i) {
			return this.prefixes[i];
		}

		return '';
	}

	getCssClass(item:any) :string {
		if ( typeof(this.cssClass) == 'string') {
			return 'row ' + this.cssClass;
		}
		else if (this.cssClass != null && typeof(this.cssClass) == 'function') {
			return 'row ' + this.cssClass(item);
		}

		return 'row';
	};

	isClickable(prop:string) {
		if (this.clickable != null && this.clickable.length > 0) {
			for (var i = 0; i < this.clickable.length; i++) {
				if (prop == this.clickable[i].property) {
					return true;
				}
			}
		}

		return false;
	};

	isToggler(index: number) {
		if (this.togglers != null && this.togglers.length > index) {
			return this.togglers[index];
		}

		return false;
	}

	getLink(item:any, prop:string) {
		var link = '';

		if (this.clickable != null && this.clickable.length > 0) {
			for (var i = 0; i < this.clickable.length; i++) {
				if (prop == this.clickable[i].property) {
					link = this.clickable[i].href;
					break;
				}
			}

			while (link.search(/\{\{[^}]*\}\}/g) > -1) {
				var m = link.match(/\{\{[^}]*\}\}/)[0];
				var value = this.getValue(item, m.replace(/[{}]/g, ''));

				link = link.replace(m, value);
			}
		}

		return link;
	};

	emptyColumns(){
		var result: any[] = [];

		if (this.onEmpty != null) {
			for (var i = 0; i < this.onEmpty.column; i ++) {
				result.push({});
			}
		}

		return result;
	};
}