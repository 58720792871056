<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let patient of patients" [attr.data-patient-id]="patient?.id">
				<td class="col-xs-8">
					<div class="ng_top">
						<a routerLink="/patients/{{patient.id}}" class="invisible_link">{{patient.displayName}}</a>
						<a routerLink="/patients/{{patient.id}}" class="invisible_link" *ngIf="patient.device?.key">|{{patient.device?.key}}</a>
					</div>
					<div class="ng_bottom">{{patient.room}}&nbsp;</div>
				</td>
				<td class="col-xs-4">
					<span class="fl_right" [style.color]="'#D13C5A'" [hidden]="!patient.isConnected || patient.isInBed">{{GetText('content.base.status_outbed')}}</span>
					<span class="fl_right" [style.color]="'#5FA788'" [hidden]="!patient.isConnected || !patient.isInBed">{{GetText('content.base.status_inbed')}}</span>
					<span class="fl_right" [style.color]="'#D13C5A'" [hidden]="patient.isConnected">{{GetText('content.base.status_malfunction')}}</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>