import { Device } from "./device.class";

export class Patient {
	public id: number;
	public type: number;
	public lastName: string;
	public initials: string;
	public insertion: string;
	public firstName: string;
	public displayName: string;
	public room: string;
	public status: number;
	public alarmStatusText: string;
	public alarmStatusCreated: string;
	public alarmStatusColor: string;
	public device: Device;
  public alarmStatusIconId: number;
  public patientGroups: number[];

	public isOk: boolean;
	public isMuted: boolean;

	public alarmStatus: string;

	public serviceStatus: number;
	public get isInBed(): boolean { return this.serviceStatus == 10 }
	public get isConnected(): boolean { if (this.device != null && this.device.isConnected) { return true; } return false; }

	private _displayName?: string;


	constructor() {
    this.patientGroups = [];
	}

	public static Convert(obj: any) : Patient {
		let result: Patient = new Patient();

		try { result.id = obj.id } catch(e){}
		try { result.type = obj.type } catch(e){}
		try { result.lastName = obj.lastName } catch(e){}
		try { result.initials = obj.initials } catch(e){}
		try { result.insertion = obj.insertion } catch(e){}
		try { result.firstName = obj.firstName } catch(e){}
		try { result.displayName = obj.displayName } catch(e){}
		try { result.room = obj.room } catch(e){}
		try { result.status = obj.status } catch(e){}
		try { result.isOk = obj.isOk } catch(e){}
		try { result.isMuted = obj.isMuted } catch(e){}
		try { result.serviceStatus = obj.serviceStatus } catch(e){}
		try { result.alarmStatusText = obj.alarmStatusText } catch(e){}
		try { result.alarmStatusCreated = obj.alarmStatusCreated } catch(e){}
		try { result.alarmStatusColor = obj.alarmStatusColor } catch(e){}
		try { result.alarmStatusIconId = obj.alarmStatusIconId; } catch(e){}

		try { result.device = Device.Convert(obj.device); } catch(e){}

		return result;
	}
}
