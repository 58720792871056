import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';

export function requiredValidator(value: any) {
	return (c: FormControl) => {
		let err = {
			requiredError: true
		};

		return err;
	}
}

export class FormInputError {
	required: string;
}

@Component({
	selector: 'form-input-text',
	templateUrl: './form-input-text.html',
	styleUrls: ['./forms.css'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormInputTextComponent), multi: true },
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => FormInputTextComponent), multi: true }
	]
})
export class FormInputTextComponent implements ControlValueAccessor, OnChanges {
	@Input() model: any;
	@Input() property: string;
	@Input() title?: string;
	@Input() isRequired?: boolean;
	@Input() isReadOnly?: boolean;
	@Input() errorMessage?: string;
	@Input() trigger_keyDown?: Function;
	@Input() trigger_blur?: Function;
	@Input() trigger_init?: Function;

	@Input('init')
	get init(): wbcToggler { return this._init; }
	set init(value: wbcToggler) {
		if (value != null && value.isToggled == true) {
			this.OnInit();
		}
		this._init = value;
	}



	propagateChange: any = () => { };
	validateFn: any = () => { };

	public get item(): any { if (this.model == null) { return null; } return this.model[this.property]; };
	public set item(value: any) { if (this.model != null) { this.model[this.property] = value; this.propagateChange(value); } };

	public errors: FormInputError;


	public onKeyDown() {
		if (this.trigger_keyDown != null) {
			this.trigger_keyDown();
		}
	}

	public onBlur() {
		if (this.trigger_blur != null) {
			this.trigger_blur();
		}
	}

	public OnInit() {
		if (this.trigger_init != null) {
			this.trigger_init();
		}
	};



	private _init: wbcToggler;

	constructor() {
	}


	ngOnChanges(value: any) {
		this.propagateChange(this.item);
	}

	writeValue(value: any) {
	}

	registerOnChange(fn: Function) {
		this.propagateChange = fn;
	}

	registerOnTouched() {
	}

	validate(c: FormControl) {
		this.errors = new FormInputError();

		if (!this.isReadOnly) {
			if (this.isRequired) {
				if (this.item == null || this.item == undefined || this.item == '') {
					this.errors.required = this.errorMessage;
				}
			}
		}

		return this.errors;
	}
}