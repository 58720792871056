import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../classes/patient.class';
import { Page } from '../pages.parent';
import { SETTINGS } from '../../settings';

@Component({
	selector: 'page-bed-index',
	templateUrl: './bed-index.html',
})
export class PageBedIndexComponent extends Page implements OnInit {
	public patients: Patient[];

	private interval: number;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		glService: GlobalService,
		private service: PatientService
	) {
		super(glService, 'Bed');

		this.SetTitle(this.GetText('common.bed'));


	}


	ngOnInit() {
		this.preloadTimer = SETTINGS.AddInterval(()=> {
				if (this.glService.preload) {
					SETTINGS.ClearInterval(this.preloadTimer);

					this.route.data.subscribe((result: any ) => {
						this.patients = result.bedPatients;
					});

					this.interval = SETTINGS.AddInterval(()=> {
						this.Refresh();
					}, 15000, this.interval);
				}
		}, 1, this.preloadTimer);
	};


	private Refresh() {
		this.service.getBedPatients().subscribe((patients: Patient[])=>{
			this.patients = patients;
		});
	}
}
