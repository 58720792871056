import { SETTINGS } from './../settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class TenantService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient) {
	}

	public getTennants(): Observable<any> {
		return this._http.get(`${this.baseUrl}v1/users/me/tenant-consumers`);
	}

	public setActive(tenantConsumerId: number): Observable<any> {
		return this._http.post(`${this.baseUrl}v1/users/me/tenant-consumers/${tenantConsumerId}/set-active`, {});
  }

  public userStatus(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this._http.get<any>(`${this.baseUrl}v1/users/me/tenant-consumers/UserStatus`, {headers: headers, responseType: 'text' as 'json'});
  }
}
