import { AccountService } from './../../../services/account.service';
import { Injectable, Inject } from '@angular/core';
import { CookieService } from 'ng2-cookies';


@Injectable()
export class wbBrowserStorage {
	private _storageType: string;
  private _cookieService: CookieService;

	constructor(@Inject(CookieService) cookieService: CookieService){
    this._cookieService = cookieService;

		if (this.checkForLocalStorage()) {
			this._storageType = 'local';
		}
		else if (this.checkForCookies()) {
			this._storageType = 'cookie';
		}
	}

	getStorageItem(key: string): any {
		var result = null;
		var prop = '';

		if (key.indexOf('.') > -1) {
			var a = key.split('.')[0];
			prop = key.substring(a.length + 1);
			key = a;
		}

		if (key.indexOf('[') > -1) {
			var a = key.split('[')[0];
			prop = key.substring(a.length + 1);
			key = a;
		}

		switch(this._storageType) {
			case 'local':
				result = localStorage.getItem(key);
				break;
			case 'cookie':
				result = this._cookieService.get(key);
				break;
			default:
				console.warn('no storage method!');
				return null;
		}

		if (result == '') {
			result = null;
		}

		if (result != null && prop != '') {
			try {
				var parsed = JSON.parse(result);
				var props = prop.split('.');

				for (var i = 0; i < props.length; i++) {
					if (props[i].indexOf('[') > 0) {
						var attr = props[i].substr(0, props[i].indexOf('['));
						var id = props[i].substr(props[i].indexOf('[') + 1).replace(']', '');

						try {
							parsed = parsed[attr][id];
						}
						catch (e) {
							parsed = null;
						}
					}
					else {
						try {
							parsed = parsed[props[i]];
						}
						catch (e) {
							parsed = null;
						}
					}

					if (parsed == null) {
						throw 'no prop';
					}
				}

				return parsed;
			}
			catch (e) {
				console.warn('could not get the property "' + key + '.' + prop + '"');
				return null;
			}
		}

		return result;
	};

	setStorageItem(key: string, data:any) {
		if (data != null && typeof (data) === 'object') {
			data = JSON.stringify(data);
		}

		switch (this._storageType) {
			case 'local':
				if (data == '' || data == null) {
					localStorage.removeItem(key);
				}
				else {
					localStorage.setItem(key, data);
				}
				break;
			case 'cookie':
				if (data == '' || data == null) {
					this._cookieService.delete(key);
				}
				else {
					this._cookieService.set(key, data);
				}
				break;
			default:
				console.warn('no storage method!');
				break;
		}
	};

	removeStorageItem(key: string) {
		switch (this._storageType) {
			case 'local':
				localStorage.removeItem(key);
				break;
			case 'cookie':
				this._cookieService.delete(key);
				break;
			default:
				console.warn('no storage method!');
				break;
		}
	};

	clearStorage() {
		switch (this._storageType) {
			case 'local':
				localStorage.clear();
				break;
			case 'cookie':
				this._cookieService.deleteAll();
				break;
			default:
				console.warn('no storage method!');
				break;
		}
	};

	private checkForCookies() {
		try {
			this._cookieService.set('cookie_test', 'cookie test');
			var exists = (this._cookieService.get('cookie_test') == 'cookie test');
			this._cookieService.delete('cookie_test');
			return exists;
		}
		catch (e) {
			return false;
		}
	}

	private checkForLocalStorage() {
		try {
			localStorage.setItem('storage_test', 'storage test');
			var exists = (localStorage.getItem('storage_test') == 'storage test');
			localStorage.removeItem('storage_test');

			return exists;
		}
		catch (e) {
			return false;
		}
	};
}
