import { ApiTimeInterceptor } from './api-time.interceptor';
import { GlobalService } from 'app/services/global.service';
import { ApiInterceptor } from './http.interceptor';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ng2-cookies';
import { SharedModule } from './shared.module';
import { RoutingModule } from './router.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header.component';
import { PagesModule } from './pages/pages.module';
import { ServicesModule } from './services/services.module';
import { WebbeatModule } from './modules/webbeat/webbeat.module';
import { HighchartModule } from './modules/highchart/highchart.module';
import { ToastrModule } from 'ngx-toastr';
import { ToastrFactoryService } from './services/toaster.service';
import { PageMaintenanceComponent } from 'app/pages/maintenance/maintenance.component';
import { ProbeService } from 'app/services/probe.service';
import { CommonService } from 'app/services/common.service';
import { LoadTestingService } from './services/loadtesting.service';
import { ErrorHandlingInterceptor } from './error-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { isManufacturerOrDealerGuard } from './authorization/guards/dealer-or-manufacturer-guard.service';

// Locales
import { defineLocale } from 'ngx-bootstrap/chronos';
import { nlBeLocale } from 'ngx-bootstrap/locale';
defineLocale('nl', nlBeLocale);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    WebbeatModule,
    HighchartModule,
    PagesModule,
    ServicesModule,
    SharedModule,
    RoutingModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      extendedTimeOut: 2000,
      positionClass: 'toast-top-right',
      closeButton: true,
    }),
  ],
  declarations: [AppComponent, HeaderComponent, PageMaintenanceComponent],
  bootstrap: [AppComponent, HeaderComponent],
  providers: [
    LoadTestingService,
    CookieService,
    ToastrFactoryService,
    CommonService,
    GlobalService,
    ProbeService,
    isManufacturerOrDealerGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTimeInterceptor,
      multi: true,
    },
  ],
  exports: [RoutingModule],
})
export class AppModule {}
