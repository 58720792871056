/*
 * Example use
 *		Basic Array of single type: *ngFor="#todo of todoService.todos | orderBy : '-'"
 *		Multidimensional Array Sort on single column: *ngFor="#todo of todoService.todos | orderBy : ['-status']"
 *		Multidimensional Array Sort on multiple columns: *ngFor="#todo of todoService.todos | orderBy : ['status', '-title']"
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'rename', pure: false})
export class RenamePipe implements PipeTransform {
    transform(): any{
        if (arguments == null) {
            return '';
        }

        if (arguments.length == 1) {
            return arguments[0];
        }

        let result = '' + arguments[0];
        for (let i = 1; i < arguments.length; i ++) {
            while (result.indexOf('{' + (i-1) + '}') > -1) {
                result = result.replace('{' + (i-1) + '}', arguments[i]);
            }
        }

        return result;
    }
}