import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Page } from './../pages.parent';
import { SETTINGS } from '../../settings';
import { ProbeService } from 'app/services/probe.service';

@Component({
	selector: 'page-maintenance',
	templateUrl: './maintenance.html',
	styleUrls: [
		'maintenance.css',
	]
})
export class PageMaintenanceComponent extends Page implements OnInit {
	public title = this.GetText('maintenance.title');
	public message = this.GetText('maintenance.message');;
	public interval: any;

	constructor(
		private router: Router,
		private probeService: ProbeService,
		glService: GlobalService) {
		super(glService, 'Login');
	}

	ngOnInit() {
		this.SetTitle(this.GetText('common.maintenance'));
		SETTINGS.jquery('#sidepanel').hide();
		SETTINGS.jquery('#panel_content').css('background-color', '#f5f5f5');
		SETTINGS.jquery('#sidefooter').hide();
		this.interval = setInterval(() => {
			this.callProbeService();
		}, 10000);
	}

	private callProbeService(): void {
		this.probeService.probe()
			.subscribe(() => {
				SETTINGS.jquery('#sidepanel').show();
				SETTINGS.jquery('#sidefooter').show();
				SETTINGS.jquery('#panel_content').css('background-color', '#05659f');
				this.router.navigateByUrl('/dashboard');
				clearInterval(this.interval);
			}, error => {
				if (error.status === 503) {
					SETTINGS.jquery('#sidepanel').hide();
					SETTINGS.jquery('#sidefooter').hide();
					SETTINGS.jquery('#panel_content').css('background-color', '#f5f5f5');
				} else if (error.error.message === 'Unexpected end of JSON input') {
					SETTINGS.jquery('#sidepanel').show();
					SETTINGS.jquery('#sidefooter').show();
					SETTINGS.jquery('#panel_content').css('background-color', '#05659f');
					this.router.navigateByUrl('/dashboard');
					clearInterval(this.interval);
				}
			});
	}
}
