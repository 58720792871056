<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false" class="personalia-block">
		<div block-header class="row">
			<div id="colorBlock" class="color_green"></div>
			<div class="col-xs-7">
				<h2>{{GetText('patient.edit_title_personalia')}}</h2>
				<p class="color_green">{{GetText('patient.edit_subtitle_personalia')}}</p>
			</div>
			<div class="col-xs-5" [hidden]="!isEditpatient">
				<wb-actionButton id="deletePatientButton" [button]="deletePatientButton"></wb-actionButton>
				<!-- <div id="deletePatientButton" class="button alt1 inline fl_right" (click)="deletePatient()">{{GetText('patient.edit_button_delete')}}</div> -->
			</div>
		</div>
		<div block-body>
      <patient-form [model]="patient" [submitted]="personaliaSubmit" [validate]="personaliaValidate" [patientGroups]="patientGroups"></patient-form>
		</div>
	</wb-grid-headerBlocks>

	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header class="row">
			<div id="colorBlock" class="color_blue"></div>
			<div>
				<div class="col-xs-7">
					<h2>{{GetText('common.services')}}</h2>
					<p class="color_blue">{{GetText('patient.edit_subtitle_services')}}</p>
				</div>
				<div class="col-xs-5" [hidden]="hasServices">
					<wb-actionButton id="addServiceButton" [button]="addServiceButton"></wb-actionButton>
					<!-- <div class="button inline fl_right" (click)="showModel('service')">{{GetText('patient.edit_button_service_add')}}</div> -->
				</div>
				<div class="col-xs-5" [hidden]="!hasServices">
					<wb-actionButton id="editServiceButton" [button]="editServiceButton"></wb-actionButton>
					<!-- <div class="button inline fl_right" (click)="showModel('service')">{{GetText('patient.edit_button_service_adjust')}}</div> -->
				</div>
			</div>
		</div>
		<div block-body>
			<div *ngIf="!hasServices" style="padding-bottom:40px;">
				<h2 class="fl_center">{{GetText('patient.edit_message_noservice')}}</h2>
				<div *ngIf="patient.id > 0 && !isManufacturer" class="fl_center">
					<div class="button center" (click)="showModel('service')">{{GetText('patient.edit_button_service_add')}}</div>
				</div>
			</div>
			<div *ngIf="hasServices">
				<wb-table-blocks [items]="services" [properties]="serviceList.table_properties"></wb-table-blocks>
			</div>
		</div>
  </wb-grid-headerBlocks>

  <div *ngIf="patient.id > 0 && !isManufacturer">
    <wb-grid-headerBlocks gridWidth="12" [hasBody]="false" [hasFooter]="false">
      <div block-header class="row">
        <div id="colorBlock" class="color_green"></div>
        <div>
          <div class="col-xs-12 col-sm-7">
            <h2>{{GetText('common.client_groups_settings')}}</h2>
          </div>
          <div class="col-xs-12 col-sm-5">
            <wb-actionButton id="navManageClientGroupsBtn" [button]="navManageClientGroupsBtn"></wb-actionButton>
          </div>
        </div>
      </div>
    </wb-grid-headerBlocks>
  </div>
</div>


<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_red"></div>
			<h2>{{GetText('common.alarms')}}</h2>
			<p class="color_red">{{GetText('patient.edit_subtitle_alarms')}}</p>
		</div>
		<div block-body>
			<div *ngIf="!hasServices" style="padding-bottom:40px;">
				<h2 class="fl_center">{{GetText('patient.edit_message_noservice')}}</h2>
				<div *ngIf="patient.id > 0 && !isManufacturer" class="fl_center">
					<div class="button center" (click)="showModel('service')">{{GetText('patient.edit_button_service_add')}}</div>
				</div>
			</div>
			<div *ngIf="hasServices">
				<alarmTriggerEdit-list [alarmtriggers]="alarms" [submitted]="alarmTriggerSubmit"></alarmTriggerEdit-list>
			</div>
		</div>
	</wb-grid-headerBlocks>
</div>

<wb-modal-basic id="patient_edit_page" [width]="6" [offset]="3" [show]="serviceModalShown">
	<div modal-closer>
		<div class="icon icon-close" style="color: white;" (click)="hideModel('service')"></div>
	</div>
	<div modal-content>
		<wb-grid-headerBlocks gridWidth="12">
			<div block-header>
				<div id="colorBlock" class="color_blue"></div>
				<h2 [hidden]="!hasServices">{{GetText('patient.edit_button_service_adjust')}}</h2>
				<h2 [hidden]="hasServices">{{GetText('patient.edit_button_service_add')}}</h2>
				<p [hidden]="!hasServices" class="color_blue">{{GetText('patient.edit_subtitle_servicemodal_adjust')}}</p>
				<p [hidden]="hasServices" class="color_blue">{{GetText('patient.edit_subtitle_servicemodal_add')}}</p>
			</div>
			<div block-body>
				<table class="bordered _first paged">
					<tr *ngFor="let service of possibleServices; let i = index">
						<td class="col-xs-7">
							<div class="ng_top">{{service.sensor.key}}</div>
							<div class="ng_bottom">{{service.service.name}}</div>
						</td>
						<td class="col-xs-5">
							<wb-button-toggler [toggler]="serviceTogglers[i]" class="fl_right"></wb-button-toggler>
						</td>
					</tr>
				</table>
			</div>
			<div block-footer>
				<div id="footer">
					<div id="footer_content">
						<div [hidden]="hasServices" class="button reversed" (click)="updateServices()">{{GetText('patient.edit_button_service_add')}}</div>
						<div [hidden]="!hasServices" class="button reversed" (click)="updateServices()">{{GetText('patient.edit_button_service_adjust')}}</div>
						<div class="button text reversed" (click)="hideModel('service')">{{GetText('common.cancel')}}</div>
					</div>
				</div>
			</div>
		</wb-grid-headerBlocks>
	</div>
</wb-modal-basic>
