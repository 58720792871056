export class Device {
	public id: number;
	public key: string;
	public isConnected?: boolean;


	public static Convert(obj: any): Device {
		let result: Device = new Device();

		try { result.id = obj.id; } catch (e) { }
		try { result.key = obj.key; } catch (e) { }
		try { result.isConnected = obj.isConnected; } catch (e) { }

		return result;
	}
}

export interface IConnectedDevicePatient {
	patientId?: number;
	patientDisplayName?: string;
}

export interface IConnectedDevice {
	deviceKey?: string;
	patient?: IConnectedDevicePatient;
}