<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_red"></div>
			<div class="row">
				<div class="col-xs-12">
					<h2>{{GetText('dashboard.index_title_alarm')}}</h2>
					<p class="color_red">{{GetText('dashboard.index_subtitle_alarm')}}</p>
				</div>
			</div>
		</div>
		<div block-body>
			<activeAlarm-list [alarms]="activeAlarms"></activeAlarm-list>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>

	<wb-grid-headerBlocks *ngIf="devices && devices.length > 0" [loading]="!devices" gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_red"></div>
			<div class="row">
				<div class="col-xs-12">
					<h2>{{GetText('dashboard.index_title_disconnected_devices')}}</h2>
				</div>
			</div>
		</div>
		<div block-body>
			<disconnectedDevice-list [devices]="devices"></disconnectedDevice-list>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>

	<wb-grid-headerBlocks gridWidth="12" [loading]="!patients" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_green"></div>
			<div class="row">
				<div class="col-xs-12">
					<h2>{{GetText('dashboard.index_title_checked')}}</h2>
					<p class="color_green">{{GetText('dashboard.index_subtitle_checked')}}</p>
				</div>
			</div>
		</div>
		<div block-body>
			<patient-list [patients]="patients"></patient-list>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>
</div>

<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false" [loading]="!alarmLogs">
		<div block-header>
			<div id="colorBlock" class="color_red"></div>
			<h2>{{GetText('dashboard.index_title_alarmhistory')}}</h2>
			<p class="color_red">{{GetText('dashboard.index_subtitle_alarmhistory')}}</p>
		</div>
		<div block-body>
			<alarmHistory-graph [minDateTime]="timer" [data]="alarmLogs"></alarmHistory-graph>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>

	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false" [loading]="!employees">
		<div block-header>
			<div id="colorBlock" class="color_orange"></div>
			<h2>{{GetText('common.employees')}}</h2>
			<p class="color_orange">{{GetText('dashboard.index_subtitle_employee')}}</p>
		</div>
		<div block-body>
			<employee-list [employees]="employees"></employee-list>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>
</div>