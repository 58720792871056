<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let device of devices">
				<td class="col-xs-8">
					<div class="ng_top">
						<span>
							<a routerLink="/devices/disconnected" class="invisible_link">{{device?.deviceKey}}  </a>
						</span>
					</div>
					<div class="ng_bottom">
							{{device?.patient?.patientDisplayName}}
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>