import { Component, Input } from '@angular/core';
import { PagingExtension } from '../classes/extensions/paging.extension';
import { GlobalService } from '../services/global.service';
import { Alarm } from '../classes/alarm.class';

@Component({
	selector: 'alarm-list',
	templateUrl: './alarm-list.html',
	styleUrls: [
		'./alarm-list.css'
	]
})
export class AlarmListComponent extends PagingExtension {
	@Input('alarms')
	public get alarms() :Alarm[] { return <Alarm[]>this.pageItems; };
	public set alarms(value: Alarm[]) { this.items = value; this.Build(); }


	constructor(private glService: GlobalService) {
		super();
	}


	public GetIcon(icon: string) {
		return this.glService.GetIconClass(icon);	
	}

	public toLocal(utc: string) :string {
		if (utc == null || utc == '') {
			return '';
		}

		var date = new Date(utc);

		var ye = date.getFullYear();
		var mo = date.getMonth() + 1;
		var da = date.getDate();
		var ho = date.getHours();
		var mi = date.getMinutes();
		//var se = date.getSeconds();

		return (da <= 9 ? '0' + da : da) + '-' + (mo <= 9 ? '0' + mo : mo) + '-' + (ye <= 9 ? '0' + ye : ye) + ' ' +
			(ho <= 9 ? '0' + ho : ho) + ':' + (mi <= 9 ? '0' + mi : mi);
	}


	
}