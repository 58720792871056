<div id="content" class="{{hideOn}}" [ngClass]="{ 'animate': animated, 'closed':closed.isToggled }">
	<div id="panel">
		<div id="panel_content" [style.right]="'-' + panelSize + 'px'"  [style.width.px]="panelSize">
			<div id="closer" (click)="toggle()">
				<ng-content select="[panel-closer]"></ng-content>
			</div>
			<div id="header">
				<ng-content select="[panel-header]"></ng-content>
			</div>
			<div id="body">
				<ng-content select="[panel-body]"></ng-content>
			</div>
			<div id="footer">
				<ng-content select="[panel-footer]"></ng-content>
			</div>
		</div>
	</div>
	<div id="main" [style.margin-left]="panelSize + 'px'">
		<ng-content select="[main-body]"></ng-content>
	</div>
</div>