<div class="col-xs-12 table-style">
  <table class="table table-responsive">
    <thead>
      <tr>
        <th>{{GetText('common.alarm')}}</th>
        <th>{{GetText('common.status')}}</th>
        <th>{{GetText('common.patient')}}</th>
        <th>{{GetText('content.time.time_of_day')}}</th>
        <th>{{GetText('content.time.date')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let alarm of alarms">
        <td>{{alarm.alarmName}}</td>
        <td [ngStyle]="{'color': alarm.alarmStatusColor}">{{alarm.alarmStatusText}}</td>
        <td>{{alarm.patientDisplayName}}</td>
        <td>{{alarm.alarmLogCreated | date: 'HH:mm:ss'}}</td>
        <td>{{alarm.alarmLogCreated | date: 'dd/MM/yyyy'}}</td>
      </tr>
    </tbody>
  </table>
</div>