import { NgModule } from '@angular/core';

import { PatientService } from './patient.service';
import { AccountService } from './account.service';
import { AlarmService } from './alarm.service';
import { EmployeeService } from './employee.service';
import { ServiceService } from './service.service';
import { SensorService } from './sensor.service';
import { CompanyService } from './company.service';

import { WebbeatModule } from '../modules/webbeat/webbeat.module';
import { DeviceService } from 'app/services/device.service';
import { TenantService } from './tenant.service';


@NgModule({
	imports: [
		WebbeatModule
	],
	declarations: [
	],
	providers: [
		DeviceService,
		PatientService,
		AccountService,
		AlarmService,
		EmployeeService,
		ServiceService,
		TenantService,
		SensorService,
		CompanyService
	]
})
export class ServicesModule {
}
