import { Component, Input, OnInit/*, ChangeDetectorRef*/ } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { wbcMenuButton } from '../../modules/webbeat/classes/wbcButton.class';
import { wbcUrlLocation } from '../../modules/webbeat/classes/wbcUrlLocation.class';
//import { wbcFunctionLinker } from '../../modules/webbeat/classes/wbcFunctionLink.class';
import { wbcToggler } from '../../modules/webbeat/classes/wbcToggler.class';

import { Page } from '../pages.parent';
import { Patient } from '../../classes/patient.class';
import { AlarmTrigger, AlarmTriggerRange } from '../../classes/alarm.class';
import { Block } from '../../classes/block.class';
import { ServiceListResult } from '../../classes/results.class';
import { GlobalService } from '../../services/global.service';
import { PatientService } from '../../services/patient.service';
import { ServiceService } from '../../services/service.service';
import { AlarmService } from '../../services/alarm.service';
import { SETTINGS } from '../../settings';
import { RoleService } from '../../authorization/services/role.service';
import { PatientGroup } from 'app/classes/patient-group.class';

@Component({
	selector: 'page-patients-edit',
	templateUrl: './patients-edit.html'
})
export class PagePatientsEditComponent extends Page implements OnInit {
	public patient: Patient;
	public alarms: AlarmTrigger[];
	public services: ServiceListResult[];
	public modalServiceList: Block;
	public serviceList: Block;
  public serviceModalShown = new wbcToggler(false);
	public personaliaSubmit: Function = (patient: Patient, isValid: boolean, isPristine: boolean) => {
    this.pagePristine = isPristine;
    if (patient != null) {
      this.patient = patient;
    }
    this.canSubmitPersonalia = isValid;
  };
	public alarmTriggerSubmit: Function = (alarm: AlarmTrigger, isValid: boolean, isPristine: boolean, deleteList?: Array<number>) => { this.pagePristine = isPristine; this.validateAlarm(alarm); this.addRangesToRemove(deleteList); };
	public serviceTogglers: wbcToggler[];
	public baseAlarms: AlarmTrigger[];
	public patientAlarms: AlarmTrigger[];
	public canSubmitAlarmTriggers: boolean;

	public personaliaValidate: wbcToggler = new wbcToggler(false);

	public copyAlarmModalShown: wbcToggler;
	public possibleAlarms: AlarmTrigger[];
	public possAlarmTogglers: wbcToggler[];


	public get pagePristine(): boolean { return this._pagePristine; };
	public set pagePristine(value: boolean) { if (!value) { this._pagePristine = false; this.checkCanSubmit(); } };

  public get isEditpatient(): boolean { return (this.patient != null && this.patient.id > 0); }

	public get canSubmitPersonalia(): boolean { return this._canSubmitPersonalia; };
	public set canSubmitPersonalia(value: boolean) { this._canSubmitPersonalia = value; this.checkCanSubmit(); };

	public get saveDisabled(): boolean { return this.glService.mainButton.isDisabled.isToggled; }
	public set saveDisabled(value: boolean) { this.glService.mainButton.isDisabled = new wbcToggler(value); }

	public get hasServices(): boolean { return (this.services != null && this.services.length > 0) }

	public get possibleServices(): ServiceListResult[] { return this._services; };
	public set possibleServices(value: ServiceListResult[]) { this._services = value; this.BuildServiceTogglers(); };

	public get canSubmit(): boolean { return this._canSubmit; };
	public set canSubmit(value: boolean) { this._canSubmit = value; this.checkCanSubmit(); };

	public deleteTimer?: number = null;

	private _services: ServiceListResult[];
	//private calls: any[];
	private _canSubmitPersonalia: boolean;
	private _pagePristine: boolean = true;

	//private get canSubmit() :boolean { return this.glService.mainButton.isDisabled; };
	//private set canSubmit(value: boolean) { this.glService.mainButton.isDisabled = value; };
	private _canSubmit: boolean;

	private rangeDeleteList: Array<number> = [];
	public isManufacturer: boolean = true;
	public deletePatientButton: wbcMenuButton;
	public addServiceButton: wbcMenuButton;
  public editServiceButton: wbcMenuButton;
  public patientGroups: PatientGroup[];
	public navManageClientGroupsBtn = new wbcMenuButton(this.GetText('common.manage'), () => {this.router.navigateByUrl('settings/patient-group/overview')})

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		glService: GlobalService,
		private servService: ServiceService,
		private alarmService: AlarmService,
		private roleService: RoleService,
		private patientService: PatientService//,
		//private ref: ChangeDetectorRef
	) {
		super(glService, 'PatientsEdit');
		this.deletePatientButton = new wbcMenuButton(this.GetText('patient.edit_button_delete'), () => { this.deletePatient() }, 'danger', null, false);
		this.addServiceButton = new wbcMenuButton(this.GetText('patient.edit_button_service_add'), () => { this.showModel('service') }, null, null, false);
		this.editServiceButton = new wbcMenuButton(this.GetText('patient.edit_button_service_adjust'), () => { this.showModel('service') }, null, null, false);
		this.glService.mainButton = new wbcMenuButton(this.GetText('patient.index_button_main'), () => { this.savePatient() }, null, null, false);
		this.patient = new Patient();
		this.alarms = [];
		this.baseAlarms = [];
		this.patientAlarms = [];
		this.services = [];
		this.serviceModalShown = new wbcToggler(false);
		this.copyAlarmModalShown = new wbcToggler(false);
		this.modalServiceList = new Block('', '', ['externalId', 'toggler', 'name']);
		this.serviceList = new Block('', '', ['sensor.key', null, 'service.name']);
		this.serviceTogglers = [];

		//this.glService.mainButton = new wbcMenuButton(this.GetText("patient.edit_button_main"), () => { /* save */ });
	};

	ngOnInit() {
		this.isManufacturer = this.glService.isManufacturer();
		this.glService.mainButton.isDisabled.isToggled = this.isManufacturer
		this.deletePatientButton.isDisabled.isToggled = this.isManufacturer
		this.addServiceButton.isDisabled.isToggled = this.isManufacturer
		this.editServiceButton.isDisabled.isToggled = this.isManufacturer

		this.preloadTimer = SETTINGS.AddInterval(() => {
			if (this.glService.preload) {
				SETTINGS.ClearInterval(this.preloadTimer);

				this.saveDisabled = false;
				this.canSubmitAlarmTriggers = true;
				this.canSubmitPersonalia = false;
				this.canSubmit = true;

				setTimeout(() => {
					this.personaliaValidate = new wbcToggler(true);
				}, 0);

				this.route.data.subscribe((result: any) => {
					this.updatePatient(result.patient.patient);
					this.services = result.patient.services;
					this.patientAlarms = this.convertAlarmTriggerTimes(result.alarmTriggers);
          this.alarms = result.alarmTriggers;
          this.patientGroups = result.patientGroups;

					this.showAlarmTriggers();
				});
			}
    }, 1, this.preloadTimer);
	};


	public checkCanSubmit() {
		this.saveDisabled = (this.pagePristine || !this.canSubmit || !this.canSubmitPersonalia || !this.clientGroupsCheck() || !this.canSubmitAlarmTriggers);
	};

	public isSubmit(): boolean {
		return this.canSubmit;
	};

	public updateServices() {
		this.pagePristine = false;
		this.services = [];
		var sensors: number[] = [];

		if (this.possibleServices != null && this.possibleServices.length > 0) {
			for (var i in this.possibleServices) {
				if (this.serviceTogglers[i].isToggled) {
					this.services.push(this.possibleServices[i]);
					sensors.push(this.possibleServices[i].sensor.id);
				}
			}
		}

		//this.servService.setPatientServices(this.patient.id, sensors);
		this.showAlarmTriggers();

		this.hideModel('service');
	};

	public updatePatient(patient: Patient) {
		this.patient = patient;

		if (this.patient != null) {
			if (this.patient.id < 1) {
				this.SetTitle(this.GetText('patient.edit_maintitle_add'));
				this.glService.mainButton = new wbcMenuButton(this.GetText('patient.edit_maintitle_add'), () => { this.savePatient(); });
				this.glService.secondButton = new wbcMenuButton(this.GetText('common.cancel'), '../patients', null, true);
        this.glService.crumble = new wbcUrlLocation('Patients', this.GetText('common.patients'), '../patients',
          new wbcUrlLocation('Patients_edit', this.GetText('patient.edit_maintitle_add')));
			}
			else {
				this.SetTitle(this.GetText('patient.edit_maintitle_edit'));
				this.glService.mainButton = new wbcMenuButton(this.GetText('patient.edit_maintitle_edit'), () => { this.savePatient(); });
				this.glService.secondButton = new wbcMenuButton(this.GetText('common.cancel'), '../patients/' + this.patient.id, null, true);
				this.glService.crumble = new wbcUrlLocation('Patients', this.GetText('common.patients'), '../patients',
					new wbcUrlLocation('Patients_detail', this.patient.displayName, '../patients/' + this.patient.id,
						new wbcUrlLocation('Patients_edit', this.GetText('patient.edit_maintitle_edit'))));
			}
		}
	}

	public hideModel(type: string) {
		switch (type) {
			case 'alarmCopy':
				this.copyAlarmModalShown = new wbcToggler(false);
				break;
			default:
				this.serviceModalShown = new wbcToggler(false);
				break;
		}
	};

	public showModel(type: string) {
		switch (type) {
			case 'alarmCopy':
				this.copyAlarmModalShown = new wbcToggler(true);
				break;
			default:
				this.GetFreeServices();
				this.serviceModalShown = new wbcToggler(true);
				break;
		}
	};

	public deletePatient() {
		if (confirm(this.GetText('patient.edit_deletemsg'))) {
			this.patientService.deletePatient(this.patient).subscribe(() => {
				this.router.navigate(['/patients'], {});
			});
		}

	};

	public savePatient() {
		if (this.deleteTimer != null) {
			SETTINGS.ClearInterval(this.deleteTimer);
		}

		this.canSubmit = false;
		var success = true;
		var calls = 0;
		var deleteCalls = 0;
		var sensors: number[] = [];


		for (var s in this.services) {
			sensors.push(this.services[s].sensor.id);
		}

    /* save patient */
		this.patientService.updatePatient(this.patient).subscribe((id: number) => {
			this.patient.displayName = '' + (this.patient.lastName == null ? '' : this.patient.lastName.trim()) + ' '
				+ (this.patient.insertion == null ? '' : this.patient.insertion.trim()) + ', '
				+ (this.patient.initials == null ? '' : this.patient.initials.trim());

			if (this.patient.id < 1) {
        this.patient.id = id;
			}

			if (this.patient.id > 1) {
        this.patient.id = id;
			}

			/* save services */
			this.servService.setPatientServices(this.patient.id, sensors).subscribe(() => {
				/* save alarmtriggers */
				if (this.alarms != null && this.alarms.length > 0) {
					for (var i in this.alarms) {
						calls++;

						this.alarms[i].seconds = (((this.alarms[i].hours * 60) + this.alarms[i].minutes) * 60) + this.alarms[i].actualSeconds;
						this.updateAlarm(this.alarms[i]).then((res: boolean) => {
							if (!res) {
								success = false;
							}

							calls--;

							if (calls < 1) {
								/* delete remove-ranges */
								if (this.rangeDeleteList != null && this.rangeDeleteList.length > 0) {
									while (this.rangeDeleteList.length > 0) {
										deleteCalls++;
										const blaat = this.rangeDeleteList.splice(0, 1)[0];

										this.alarmService.deletePatientAlarmTriggerRange(blaat).subscribe((del: boolean) => {
											deleteCalls--;

											if (!del) {
												success = false;
											}

											if (deleteCalls < 1) {
												this.rangeDeleteList = [];
												if (this.patient.id > 0) {
													this.router.navigate(['/patients/' + this.patient.id]);
												}
											}
										}, error => {
											deleteCalls--;
											success = false;
										});
									}
								}
								else {
									if (success) {
										if (this.patient.id > 0) {
											this.router.navigate(['/patients/' + this.patient.id]);
										}
									}
								}
							}
						});
					}
				}
				else {
					if (this.patient.id > 0) {
						this.router.navigate(['/patients/' + this.patient.id]);
					}
				}
			}, () => {
				success = false;
				this.canSubmit = true;
			});
		}, () => {
			success = false;
			this.canSubmit = true;
		});
	};

  private clientGroupsCheck(): boolean {
    if (this.patient.id > 0) {
      return true;
    } else if (this.patient.id < 1 && this.patient.patientGroups.length > 0) {
      return true;
    } else {
      return false;
    }
  }

	private addRangesToRemove(ids: Array<number>) {
		if (ids != null && ids.length > 0) {
			for (var id in ids) {
				if (ids[id] != null && ids[id] > 0) {
					for (var i in this.rangeDeleteList) {
						if (this.rangeDeleteList[i] == ids[id]) {
							break;
						}
					}

					this.rangeDeleteList.push(ids[id]);
				}
			}
		}
	};

	private BuildServiceTogglers() {
		this.serviceTogglers = [];

		for (var i in this.possibleServices) {
			var toggled = false;

			for (var j in this.services) {
				if (this.services[j].sensor.id == this.possibleServices[i].sensor.id) {
					toggled = true;
					break;
				}
			}

			this.serviceTogglers.push(new wbcToggler(toggled));
		}
	};

	private GetFreeServices() {
		this.servService.getFreePatientServices(this.patient.id).subscribe(result => {
			this.possibleServices = result.sensorsAndServices;
			this.baseAlarms = this.convertAlarmTriggerTimes(result.alarmTriggers);
		});
	};

	private convertAlarmTriggerTimes(alarms: AlarmTrigger[]): AlarmTrigger[] {
		for (var i in alarms) {
			if (alarms[i].seconds != null) {
				var time: number = alarms[i].seconds;
				var minutes: number = time % 60;
				var hours: number = Math.floor(time / 3600);
				var minutes: number = Math.floor((time - (hours * 3600)) / 60);
				var secs: number = time % 60;

				alarms[i].actualSeconds = secs;
				alarms[i].minutes = minutes;
				alarms[i].hours = hours;
			}
			else {
				alarms[i].actualSeconds = 0;
				alarms[i].minutes = 0;
				alarms[i].hours = 0;
			}
		}

		return alarms;
	};

	private validateAlarm(alarm: AlarmTrigger) {
		this.canSubmitAlarmTriggers = true;

		this.checkCanSubmit();
	};

	private showAlarmTriggers() {
		var ids: string = ','; /* this will contain all the ids of the alarms that will be in newAlarms */

		this.alarms = [];

		/* go through all selected/active services of patient to display the alarmtriggers */
		if (this.services != null && this.services.length > 0 && (
			(this.patientAlarms != null && this.patientAlarms.length > 0) ||
			(this.baseAlarms != null && this.baseAlarms.length > 0))
		) {
			for (var s in this.services) {
				/* check current alarmtriggers from patient */
				if (this.patientAlarms != null && this.patientAlarms.length > 0) {
					for (var p in this.patientAlarms) {
						if (this.patientAlarms[p].desId == this.services[s].sensor.id) {
							if (ids.indexOf(',' + this.patientAlarms[p].id + ',') == -1) {
								ids += this.patientAlarms[p].id + ',';
								this.alarms.push(this.patientAlarms[p]);
							}
						}
					}
				}

				/* check base alarmtriggers */
				if (this.baseAlarms != null && this.baseAlarms.length > 0) {
					for (var b in this.baseAlarms) {
						if (this.baseAlarms[b].desId == this.services[s].sensor.id) {
							if (ids.indexOf(',' + this.baseAlarms[b].id + ',') == -1) {
								ids += this.baseAlarms[b].id + ',';
								this.alarms.push(this.baseAlarms[b]);
							}
						}
					}
				}
			}

			for (var i = 0; i < this.alarms.length; i++) {
				this.getRanges(this.alarms[i]);
			}
		}
	};

	private getRanges(alarm: AlarmTrigger) {
		this.alarmService.getPatientAlarmTriggerRange(alarm.alarmId).subscribe((res: Array<AlarmTriggerRange>) => {
			alarm.ranges = res;

			if (alarm.ranges != null && alarm.ranges.length > 0) {
				for (var j in alarm.ranges) {
					var start = new Date(alarm.ranges[j].start);
					var end = new Date(alarm.ranges[j].end);

					var h = start.getHours();
					var m = start.getMinutes();
					alarm.ranges[j].start = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);

					h = end.getHours();
					m = end.getMinutes();
					alarm.ranges[j].end = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
				}
			}
		});
	};

	private updateAlarm(alarm: AlarmTrigger): Promise<boolean> {
		return new Promise<boolean>((resolve: Function, reject: Function) => {
			this.alarmService.updatePatientAlarmTrigger(alarm).subscribe((newId: number) => {
				if (newId > 0) {
					if (alarm.id < 1) {
						alarm.id = newId;
					}

					if (alarm.ranges != null && alarm.ranges.length > 0) {
						var success = true;
						var counter = 0;

						for (var i in alarm.ranges) {
							counter++;
							this.updateAlarmRange(alarm.alarmId, alarm.ranges[i]).then((res: boolean) => {
								if (!res) {
									success = false;
								}

								counter--;

								if (counter < 1) {
									resolve(success);
								}
							});
						}
					}
					else {
						resolve(true);
					}
				}
				else {
					resolve(false);
				}
			}, () => {
				resolve(false);
			});
		});
	};

	private updateAlarmRange(alarmId: number, alarmrange: AlarmTriggerRange): Promise<boolean> {
		return new Promise<boolean>((resolve: Function, reject: Function) => {
			this.alarmService.updatePatientAlarmTriggerRange(alarmId, alarmrange).subscribe((id: number) => {
				alarmrange.id = id;
				resolve(true);
			}, () => {
				resolve(false);
			});
		});
	};
}
