import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { Page } from '../pages/pages.parent';
import { RoleService } from "../authorization/services/role.service";

@Component({
	selector: 'page-switch-tenant',
	templateUrl: './switch-tenant.html',
})
export class PageSwitchTennantComponent extends Page implements OnInit {
	public isLoading = false;

	private tenants: any[];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		glService: GlobalService,
		private roleService: RoleService) {
		super(glService, 'Dashboard');

		this.SetTitle(this.GetText('common.dashboard'));
	}

	ngOnInit() {
		this.route.data.subscribe(data => {
			this.tenants = data.TenantResolver;
		});
	};
}