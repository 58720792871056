<div class="icon icon_alarm" style="height: 0; width: 0; padding: 0; margin: 0; opacity: 0;"></div>
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_blue"></div>
			<div class="row">
				<div class="col-xs-12">
					<span class="fl_right">
						<wb-slider-toggler [toggler]="showAnalyzeGraph" (click)="toggleAnalyzeGraph()" style="margin-right: 45px;"></wb-slider-toggler>
					</span>
					<span class="fl_right" style="margin-right: 60px; color: #5FA788; font-weight: 600;" [hidden]="!showAnalyzeGraph.isToggled">{{GetText('common.alarm_status')}}</span>
					<span class="fl_right" style="margin-right: 60px; color: #BDBDBD;" [hidden]="showAnalyzeGraph.isToggled">{{GetText('common.alarm_status')}}</span>
					<span class="fl_right" style="margin-right: 30px;">
						<wb-slider-toggler [toggler]="showAlarms" [isDisabled]="showAnalyzeGraph.isToggled" (click)="triggerAlarmUpdate()" style="margin-right: 45px;"></wb-slider-toggler>
					</span>
					<span class="fl_right" style="margin-right: 90px; color: #5FA788; font-weight: 600;" [hidden]="!showAlarms.isToggled">{{GetText('common.alarm_show')}}</span>
					<span class="fl_right" style="margin-right: 90px; color: #BDBDBD;" [hidden]="showAlarms.isToggled">{{GetText('common.alarm_show')}}</span>
				</div>
				<div class="col-xs-6 col-md-3 marginTopMediumNegative">
					<h2 [hidden]="showAnalyzeGraph.isToggled">{{bed}}</h2>
					<h2 [hidden]="!showAnalyzeGraph.isToggled">{{GetText('common.alarm_status')}}</h2>
					<p [hidden]="showAnalyzeGraph.isToggled || !patientWeight">
            <span class="bold">{{GetText('biometrics.weight')}}:</span>
            <span class="patient-weight bold">{{patientWeight}} kg</span>
          </p>
					<p class="color_blue" [hidden]="!showAnalyzeGraph.isToggled"></p>
        </div>
				<div class="col-xs-6 col-md-4 col-md-offset-5 marginTopMedium">
					<wb-dropdownButton [options]="dropdownOptions" [model]="graphOption" [override]="_dropdownOverride" (onValueChange)="resetWithFilterTime()"></wb-dropdownButton>
				</div>
				<div class="col-xs-12 noPaddingLeft marginTopMedium">
					
					<div class="col-xs-6 col-md-5">
						<label for="startDate">{{GetText('content.time.start_date')}}</label>
						<input [(ngModel)]="startDate"
							[owlDateTimeTrigger]="dt" [owlDateTime]="dt"
							[max]="maxDate"
							readonly />
						<owl-date-time #dt></owl-date-time>
					</div>
					<div class="col-xs-6 col-md-5">
						<label for="endDate">{{GetText('content.time.end_date')}}</label>
						<input [(ngModel)]="endDate"
							[owlDateTimeTrigger]="dt2" [owlDateTime]="dt2"
							[max]="maxDate"
							readonly />
						<owl-date-time #dt2></owl-date-time>
					</div>
					<div class="col-xs-6 col-md-1 marginTopMedium">
						<button class="button" (click)="filterGraph()">
							<p>filter</p>
						</button>
					</div>
					<div class="col-xs-6 col-md-1 marginTopMedium">
						<button class="button" (click)="resetFilters()">
							<p>reset</p>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div block-body>
			<patientLay-graph *ngIf="!showAnalyzeGraph.isToggled" [data]="graphData" [showAlarms]="showAlarms.isToggled" [minDateTime]="graphOption.value"
			 [zoom]="showZoom" [reset]="forceBuild"></patientLay-graph>
			<deviceStatus-graph *ngIf="showAnalyzeGraph.isToggled" [data]="deviceGraphData" [alarmGraphData]="graphAlarmData"></deviceStatus-graph>
		</div>
	</wb-grid-headerBlocks>
</div>

<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_red"></div>

			<h2>{{GetText('patient.detail_title_alarmhistory')}}</h2>

			<p class="color_red">{{GetText('patient.detail_subtitle_alarmhistory')}}</p>
		</div>
		<div block-body>
			<alarm-list [alarms]="alarms"></alarm-list>
		</div>
	</wb-grid-headerBlocks>
</div>

<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_red"></div>
			<div class="row">
				<div class="col-xs-8">
					<h2>{{GetText('common.alarms')}}</h2>

					<p class="color_red">{{GetText('patient.detail_subtitle_alarm')}}</p>
				</div>
				<div class="col-xs-4">
					<div class="row" style="margin-bottom: 15px">
						<div class="col-xs-12">
							<span class="fl_right">
								<wb-slider-toggler [toggler]="patientMute" [isDisabled]="isManufacturer" style="margin-right: 45px;"></wb-slider-toggler>
							</span>
							<span class="fl_right" [style.color]="isManufacturer?'#BDBDBD':'#5FA788'" style="margin-right: 60px; font-weight: 600;" [hidden]="!patientMute.isToggled">{{GetText('common.on')}}</span>
							<span class="fl_right" style="margin-right: 60px; color: #BDBDBD;" [hidden]="patientMute.isToggled">{{GetText('common.off')}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<!--<div class="fl_right button small" (click)="ShowModal('case')">{{GetText('patient.detail_button_case')}}</div>-->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div block-body>
			<alarmTrigger-list [alarmtriggers]="alarmTriggers" [disabled]="isManufacturer" (onChangeTrigger)="onChangeAlarmTrigger($event)"></alarmTrigger-list>
		</div>
	</wb-grid-headerBlocks>
</div>

<wb-modal-basic id="patient_details_page" [width]="6" [offset]="3" [show]="caseModalVisible">
	<div modal-closer>
		<div class="icon icon-close" style="color: white;"></div>
	</div>
	<div modal-content>
		<wb-grid-headerBlocks gridWidth="12">
			<div block-header>
				<div id="colorBlock" class="color_red"></div>
				<h2>{{GetText('patient.detail_casemodal_title')}}</h2>
				<!--<p class="color_blue">{{GetText('patient.detail_casemodal_subtitle')}}</p>-->
			</div>
			<div block-body>
				<!-- <h2>{{bed}}</h2> -->
				<label for="caseM_text">{{GetText('patient.detail_casemodal_textlabel')}}</label>
				<textarea id="caseM_text" style="width: 100%; height: 150px; resize: none;" (change)="caseModalComment = $event.target.value;">{{caseModalComment}}</textarea>
			</div>
			<div block-footer>
				<div id="footer">
					<div id="footer_content">
						<div [ngClass]="isSubmittingCase ? 'disabled' : ''" class="button reversed" (click)="SubmitCase()">{{GetText('patient.detail_casemodal_send')}}</div>
						<div class="button text reversed" (click)="HideModal('case')">{{GetText('common.cancel')}}</div>
					</div>
				</div>
			</div>
		</wb-grid-headerBlocks>
	</div>
</wb-modal-basic>
