import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { wbBrowserStorage } from '../modules/webbeat/service/browserStorage.service';
import { SETTINGS } from '../settings';

@Injectable()
export class wbRedirectGuard implements CanActivate {
	constructor(private _router: Router, private _storage: wbBrowserStorage) {

	}

	canActivate(): boolean {
		window.location.href = SETTINGS.LOGIN_URL;
		return false;
	};

	CanDeactivate(): boolean {
		//this._storage.removeStorageItem('token');
		return true;
	};
}