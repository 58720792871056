import { AlarmReportGraphComponent } from './alarm-report-graph/alarm-report-graph.component';
import { DeviceStatusGraphComponent } from './device-status-graph/deviceStatus-graph.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HighchartModule } from '../../modules/highchart/highchart.module';
import { WebbeatModule } from '../../modules/webbeat/webbeat.module';

import { AlarmHistoryGraphComponent } from './alarm-history-graph/alarmHistory-graph.component';
import { PatientLayGraphComponent } from './patient-lay-graph/patientLay-graph.component';
import { ReportSensorGraph } from './report-sensor-graph/report-sensor-graph.component';

@NgModule({
	imports: [
		CommonModule,
		WebbeatModule,
    HighchartModule,
	],
	declarations: [
		AlarmHistoryGraphComponent,
		PatientLayGraphComponent,
	  DeviceStatusGraphComponent,
    AlarmReportGraphComponent,
    ReportSensorGraph
	],
	bootstrap: [
	],
	providers: [
	],
	exports: [
		AlarmHistoryGraphComponent,
		PatientLayGraphComponent,
		DeviceStatusGraphComponent,
    AlarmReportGraphComponent,
    ReportSensorGraph
	]
})
export class GraphsModule {
	constructor() {}
}
