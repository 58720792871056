
import { Observable, Observer, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Patient } from '../classes/patient.class';
import { PatientDetailResult } from '../classes/results.class';
import { PatientService } from '../services/patient.service';

@Injectable()
export class PatientResolver implements Resolve<PatientDetailResult> {
	constructor(
		private service: PatientService,
		private router: Router
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PatientDetailResult> | any {
		let id = route.params['id'];

		/* if 'id' = 0, return new patient */
		if (id == 0 || id == '0') {
			let patient = new Patient();
			let res = new PatientDetailResult();

			patient.id = 0;
			res.patient = patient;
			res.alarmTriggers = [];
			res.services = [];

			return observableOf(res);
		}

		/* check if patient exists */
		return Observable.create((observer: Observer<any>) => {
			this.service.getPatient(id).subscribe(patientResult => {
				if (patientResult != null && patientResult.patient != null && patientResult.patient.id != null) {
					observer.next(patientResult);
					observer.complete();
				}
				else {
					this.router.navigate(['/patients']);
					observer.next({
						patient: null,
						services: [],
						alarmTriggers: []
					});
					observer.complete();
				}
			});
		});
	}
}