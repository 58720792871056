import { Component, Input } from '@angular/core';
import { wbcToggler } from '../../../classes/wbcToggler.class';


@Component({
	selector: 'wb-slider-toggler',
	templateUrl: './slider-toggler.html',
	styleUrls: [
		'./slider-toggler.css'
	],
})
export class wbSliderTogglerComponent {
	@Input() toggler: wbcToggler;
	@Input() isDisabled = false;

	constructor() {
	};


	public toggle() {
		if (!this.isDisabled) {
			if (this.toggler == null) {
				this.toggler = new wbcToggler(false);
			}
	
			var t = this.toggler.isToggled;
	
			setTimeout(()=>{
				this.toggler.isToggled = !t;
			}, 0);
		}
	}
}