import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { UserRoleEnum } from '../../enums/user-role.enum';
import { UserService } from '../../services/user.service';
import { wbBrowserStorage } from '../../modules/webbeat/service/browserStorage.service';

@Injectable()
export class RoleService {
  constructor(private userService: UserService, private storageService: wbBrowserStorage) {}

  public isDefault(): Observable<boolean> {
    return this.getRole(UserRoleEnum.default);
  }
  public isTeamLead(): Observable<boolean> {
    return this.getRole(UserRoleEnum.teamLead);
  }
  public isAudit(): Observable<boolean> {
    return this.getRole(UserRoleEnum.audit);
  }
  public isAnalyse(): Observable<boolean> {
    return this.getRole(UserRoleEnum.analyse);
  }
  public isAdmin(): Observable<boolean> {
    return this.getRole(UserRoleEnum.admin);
  }
  public isManufacturer(): Observable<boolean> {
    return this.getRole(UserRoleEnum.manufacturer);
  }
  public isDealer(): Observable<boolean> {
    return this.getRole(UserRoleEnum.dealer);
  }
  // This is because the role of manufacturer === the role dealer, rights wise.
  public isManufacturerOrDealer(): Observable<boolean> {
    const isManufacturer = this.isManufacturer();
    const isDealer = this.isDealer();

    return new Observable<boolean>((observer) => {
      forkJoin([isManufacturer, isDealer]).subscribe((results) => {
        observer.next(results[0] === true || results[1] === true);
      });
    });
  }

  public isTeamleadOrManufacturerOrDealer(): Observable<boolean> {
    const isManufacturer = this.isManufacturer();
    const isDealer = this.isDealer();
    const isTeamLead = this.isTeamLead();

    return new Observable<boolean>((observer) => {
      forkJoin([isManufacturer, isDealer, isTeamLead]).subscribe((results) => {
        observer.next(results[0] === true || results[1] === true || results[2] === true);
      });
    });
  }

  private getRole(roleEnum: UserRoleEnum): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.userService.getUser().subscribe(
        (response) => {
          let hasRole = false;
          const currentGroup = this.storageService.getStorageItem('currentGroup.id');
          response.groups.forEach((group) => {
            if (group.id === currentGroup) {
              group.roles.forEach((role) => {
                const userRole = this.determineRole(role);
                if (userRole === roleEnum) {
                  hasRole = true;
                }
              });
            }
          });
          observer.next(hasRole);
          observer.complete();
        },
        (error) => {}
      );
    });
  }

  private determineRole(role: number): UserRoleEnum {
    switch (role) {
      case 20:
        return UserRoleEnum.teamLead;
      case 30:
        return UserRoleEnum.audit;
      case 40:
        return UserRoleEnum.analyse;
      case 50:
        return UserRoleEnum.admin;
      case 60:
        return UserRoleEnum.manufacturer;
      case 70:
        return UserRoleEnum.dealer;
      default:
        return UserRoleEnum.default;
    }
  }
}
