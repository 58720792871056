import { Employee } from './employee.class';


export class Alarm {
	public id: number;
	public alarmId: number;
	public status?: number;
	public userId?: number;
	public created: string;
	public alarmCreated: string;
	public alarmTriggerValue: string;
	public alarmName: string;
	public color: string;
	public icon: string;
	public triggerTimeout: string;
	public takenUserName: string;

	public pickedUp: boolean;
	public muted: boolean;
	public subLogText: string;
	public subLogColor: string;

	constructor() {

	}

	public static Convert(obj: any) : Alarm {
		let result: Alarm = new Alarm();

		try { result.id = obj.id } catch(e){}
		try { result.alarmId = obj.alarmId } catch(e){}
		try { result.status = obj.status } catch(e){}
		try { result.userId = obj.userId } catch(e){}
		try { result.created = obj.created } catch(e){}
		try { result.alarmCreated = obj.alarmCreated } catch(e){}
		try { result.alarmTriggerValue = obj.alarmTriggerValue } catch(e){}
		try { result.alarmName = obj.alarmName } catch(e){}
		try { result.color = obj.color } catch(e){}
		try { result.icon = obj.icon } catch(e){}
		try { result.triggerTimeout = obj.triggerTimeout } catch(e){}
		try { result.takenUserName = obj.takenUserName } catch(e){}
		try { result.pickedUp = obj.pickedUp } catch(e){}
		try { result.muted = obj.muted } catch(e){}
		try { result.subLogText = obj.subLogText } catch(e){}
		try { result.subLogColor = obj.subLogColor } catch(e){}

		return result;
	}
}

export class AlarmLog {
	public alarmLogId: number;
	public patientDisplayName: string;
	public alarmName: string;
	public alarmStatus: number;
	public alarmStatusText: string;
	public alarmStatusColor: string;
	public alarmCreated: string;

	constructor() {

	}

	public static Convert(obj: any) : AlarmLog {
		let result: AlarmLog = new AlarmLog();

		try { result.alarmLogId = obj.alarmLogId } catch(e){}
		try { result.patientDisplayName = obj.patientDisplayName } catch(e){}
		try { result.alarmName = obj.alarmName } catch(e){}
		try { result.alarmStatus = obj.alarmStatus } catch(e){}
		try { result.alarmStatusText = obj.alarmStatusText } catch(e){}
		try { result.alarmStatusColor = obj.alarmStatusColor } catch(e){}
		try { result.alarmCreated = obj.alarmCreated } catch(e){}

		return result;
	}
}


export class AlarmTrigger {
	public id: number;
	public name: string;
	public alarmStatus?: number;
	public value: string;
	public seconds?: number;
	public alarmId: number;
	public desId?: number;
	public status?: number;
	public operator: number;
	public externalId: string;
	public color: string;
	public icon: string;
	public triggerTimeout: string;
	public muted: boolean;

	public hours: number;
	public minutes: number;
	public actualSeconds: number;

	public ranges: AlarmTriggerRange[];

	public nameValue: string //TEMP

	constructor() {
	}


	public static Convert(obj: any) : AlarmTrigger {
		let result: AlarmTrigger = new AlarmTrigger();

		try { result.id = obj.id; } catch(e){}
		try { result.name = obj.name; } catch(e){}
		try { result.alarmStatus = obj.alarmStatus; } catch(e){}
		try { result.value = obj.value; } catch(e){}
		try { result.seconds = obj.seconds; } catch(e){}
		try { result.alarmId = obj.alarmId; } catch(e){}
		try { result.desId = obj.desId; } catch(e){}
		try { result.status = obj.status; } catch(e){}
		try { result.operator = obj.operator; } catch(e){}
		try { result.externalId = obj.externalId; } catch(e){}
		try { result.color = obj.color; } catch(e){}
		try { result.icon = obj.icon; } catch(e){}
		try { result.triggerTimeout = obj.triggerTimeout; } catch(e){}
		try { result.muted = obj.muted; } catch(e){}
		try { result.hours = obj.hours; } catch(e){}
		try { result.minutes = obj.minutes; } catch(e){}
		try { result.actualSeconds = obj.actualSeconds; } catch(e){}
		try {
			result.ranges = [];
			if (obj.ranges != null && obj.ranges.length > 0) {
				obj.ranges.foreach(r => {
					result.ranges.push(AlarmTriggerRange.Convert(r));
				});
			}
		} catch(e){}
		try { result.nameValue = obj.nameValue; } catch(e){}


		return result;
	}
}

export class AlarmTriggerRange {
	public id: number;
	public alarmId?: number;
    public start: string;
    public end: string;

    constructor() {
        this.id = 0;
	}


	public static Convert(obj: any) : AlarmTriggerRange {
		let result: AlarmTriggerRange = new AlarmTriggerRange();

		try { result.id = obj.id; } catch(e){}
		try { result.alarmId = obj.alarmId; } catch(e){}
		try { result.start = obj.start; } catch(e){}
		try { result.end = obj.end; } catch(e){}

		return result;
	}
}

export class AlarmHistory {
	public amountPerMinuut: number;
	public timeStamp: string;
}
