<div id="ng_dropdown" tabindex="0" (blur)="blur()">
	<!-- (click)="show(true)" -->
	<div id="ng_title" [ngClass]="{ 'dropped': isVisible }">
		<span id="ng_value">{{value?.displayText}}</span>
		<div id="ng_icon" class="icon icon-chevron-down"></div>
	</div>
	<div id="ng_dropper" [ngClass]="{ 'hovering': bla.is_hover, 'scroller': bla.is_scroller }" [hidden]="!isVisible" [style.top]="(bla.top + bla.min_height) + 'px'"
	 [style.width]="bla.width + 'px'" [style.maxHeight]="bla.max_height">
		<div *ngFor="let option of options" (click)="Select(option)" class="option" [ngClass]="{ 'selected':option.selected }">
			{{option?.displayText == null? "":option?.displayText}}
		</div>
	</div>
</div>