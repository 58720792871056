import * as moment from 'moment';
import { Component, Input, OnInit, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { contentParent } from '../../content-parent';

//Hicharts imports
import { hcHighChartService } from '../../../modules/highchart/services/highchart.service';
import { hcOaxis } from '../../../modules/highchart/classes/hcOptions.class';

@Component({
	selector: 'deviceStatus-graph',
	templateUrl: './deviceStatus-graph.html',
	styleUrls: [
		'../graph.css',
		'./deviceStatus-graph.component.css'
	]
})
export class DeviceStatusGraphComponent extends contentParent implements OnInit, OnChanges {
	@ViewChild('chartTarget', { static: true }) chartTarget: ElementRef;

	@Input('data')
	get data(): any {
		return this._data;
	};
	set data(value: any) {
		this._data = value;
	}

	@Input('alarmGraphData')
	get alarmGraphData(): any {
		return this._alarmGraphData;
	};
	set alarmGraphData(value: any) {
		this._alarmGraphData = value;
	}

	public chart: any;

	private _data: any = [];
	private _alarmGraphData: any = [];
	private _minTime: number;
	private _maxTime: number;
	private _options: any;

	constructor(private _chartService: hcHighChartService) {
		super();
	};

	ngOnInit(): void {
		setTimeout(() => {
			this._buildGraph();
		}, 100);
	}

	ngOnChanges(): void {
		setTimeout(() => {
			this._buildGraph();
		}, 100);
	}

	private _buildGraph(): void {
		this._options = this._setupOptions();
		this.chart = this._chartService.ChartNormal(this._options, this.chartTarget.nativeElement);
	}

	private _setupOptions(): any {
		const options: any =
		{
			chart: {
				type: 'columnrange',
				inverted: true,
				zoomType: 'y'
			},
			tooltip: {
				formatter: function () {
					return `${this.series.name}: ${moment(this.point.low).utc().format('HH:mm:ss DD-MM-YYYY')} - ${moment(this.point.high).utc().format('HH:mm:ss DD-MM-YYYY')}`;
				}
			},
			title: '',
			xAxis: {
				categories: [this.GetText('common.alarm_status'), this.GetText('common.connectivity')]
			},
			legend: {
				reversed: true
			},
			series: [],
			plotOptions: {
				columnrange: {
					grouping: false,
					maxPointWidth: 64
				},
				series: {
					showInLegend: false,
				}
			},
			credits: false,
		};

		/* Filling Connectivity column */
		if (this._data && this._data.length > 0) {
			options.yAxis = <any>new hcOaxis(this._minTime, this._maxTime, 'datetime', null, null, 10, 36000000);
			this._data.forEach(dataElement => {
				const newSeries: any = {
					name: '',
					data: [],
					colorIndex: -1,
				};
				newSeries.name = dataElement.deviceConnected ? this.GetText('common.active_graph') : this.GetText('common.inactive_graph');
				newSeries.data.push({ x: 1, low: this._getDate(dataElement.periodFrom), high: this._getDate(dataElement.periodTo) });
				newSeries.colorIndex = dataElement.deviceConnected ? 116 : 117;
				options.series.push(newSeries);
			});
			options.yAxis.min = this._getDate(this._data[0].periodFrom);
			options.yAxis.max = this._getDate(this._data[this._data.length - 1].periodTo);
		}

		/* Filling Alarm Status column */
		if (this._alarmGraphData && this._alarmGraphData.length > 0) {
			this._alarmGraphData = this._alarmGraphData.filter(dataElement => dataElement.alarmId === this._alarmGraphData[0].alarmId);
			this._alarmGraphData.forEach(dataElement => {
				const newSeries: any = {
					name: '',
					data: [],
					colorIndex: -1,
				};
				newSeries.name = dataElement.alarmMuted ? this.GetText('common.off') : this.GetText('common.on');
				newSeries.data.push({ x: 0, low: this._getDate(dataElement.periodFrom), high: this._getDate(dataElement.periodTo) });
				newSeries.colorIndex = dataElement.alarmMuted ? 118 : 116;
				options.series.push(newSeries);
			});
    }
		return options;
	}

	private _getDate(jsonTimeStamp: string, addSeconds?: number, useOffset: boolean = true): number {
		var result = new Date(jsonTimeStamp).getTime();

		if (addSeconds != null) {
			result += (addSeconds * 1000);
		}

		if (!useOffset) {
			return result;
		}

		var timeStamp = new Date(jsonTimeStamp);
		var offset = timeStamp.getTimezoneOffset() * 60000;
		return result - offset;
	};
}
