import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ProbeService } from 'app/services/probe.service';
import { Observable } from 'rxjs';

@Injectable()
export class MaintenanceGuard implements CanActivate {
	constructor(private _router: Router, private probeService: ProbeService) {
	}

	canActivate(): Observable<boolean> {
		this.probeService.probe()
			.subscribe(() => {
				return new Observable(observer => {
					observer.next(true);
				});
			}, error => {
				if (error.status === 503) {
					this._router.navigateByUrl('/maintenance');
					localStorage.setItem('errorMessage', JSON.stringify(error.error));
				} return new Observable(observer => {
					observer.next(false);
				});
			});
		return new Observable(observer => {
			observer.next(true);
		});
	};

	CanDeactivate(): boolean {
		return true;
	};
}