import { Sensor } from './sensor.class';
import { Service } from './service.class';
import { Alarm } from './alarm.class';


export class LayGraphData {
	public data: LayGraphData_sensor[];
	public uniqueDataValue: string[];
	public sensorService: LayGraphData_sensorService;
	public alarmLogs: Alarm[]


	public index?: number;

	public start?: Date;
	public end?: Date;
}

export class LayGraphData_sensor {
	public sensorId: number;
	public timeStamp: string;
  public value: string;
  public untranslatedValue: string;
	public color: string;
}

export class LayGraphData_sensorService {
	public sensor: Sensor;
	public service: Service;
}
