import { hcToJson } from './hcIToJson.interface';


export class hcDataPoint implements hcToJson {
	public colorIndex?: number;

	constructor(public x: number, public y: number, public name?: string, public cssClass?: string, public description?: string) {

	}

	ToJSON() {
		var json = {
			x: this.x,
			y: this.y,
			name: this.name,
			className: this.cssClass,
			description: this.description,
			colorIndex: this.colorIndex
		};

		return json;
	};

	Compare(other: any) : boolean {
		if (other == null ||
			other.x != this.x ||
			other.y != this.y ||
			other.name != this.name ||
			other.className != this.cssClass ||
			other.description != this.description) {
			return false;
		}


		return true;
	}
};

export class hcSeriesDataPoint {

	constructor(public series: number, public x: number, public y: number, public name?: string, public cssClass?: string, public description?: string) {

	}

	Convert() :hcDataPoint {
		return new hcDataPoint(this.x, this.y, this.name, this.cssClass, this.description);
	}
};