import { HttpClient } from '@angular/common/http';
import { SETTINGS } from './../settings';
import { Injectable } from "@angular/core";
import { User } from "../classes/user.class";
import { Observable } from "rxjs";

@Injectable()
export class UserService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient) {
	}

	getUser(): Observable<User> {
		return this._http.get<User>(`${this.baseUrl}Users`);
	}
}
