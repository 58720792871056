<div class="col-xs-12 col-md-6 col-md-offset-2" style="margin-top: 15% !important;">
		<wb-grid-headerBlocks gridWidth="12" [hasBody]="false" [hasFooter]="false">
			<div block-header>
				<div id="colorBlock" class="color_red"></div>
				<div class="row">
					<div class="col-xs-12">
						<h2>{{ title }}</h2>
						<p class="color_red">{{ message }}</p>
					</div>
				</div>
			</div>
		</wb-grid-headerBlocks>
	</div>