import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { Page } from '../../pages.parent';
import { LoadTestingService } from 'app/services/loadtesting.service';
import { DeviceService } from 'app/services/device.service';
import { Observable, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'page-services',
  templateUrl: './services-index.html',
  styleUrls: ['./services-index.component.scss']
})
export class PageServicesIndexComponent extends Page implements OnInit, OnDestroy {
  public timer: number = 43200;
  public data = null;

  public rows: any = [];
  public columns: any = [
    { title: this.GetText('device.disconnected.table.device_key'), name: 'deviceKey' },
    { title: `${this.GetText('common.patient')} ${this.GetText('device.disconnected.table.client')}`, name: 'patientDisplayName', className: 'buttonRole' },
	];
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: false,
    className: ['table-hover', 'buttonRole']
	};
	public length = 0;
	public placeholderTable = this.GetText('patient.index_table_search_text');
  public allText = this.GetText('patient.index_table_all');
  public isLoading = false;
  public interval = null;

  constructor(
    public glService: GlobalService,
    private _loadTestingService: LoadTestingService,
    private _deviceService: DeviceService,
    private _router: Router
  ) {
    super(glService, 'Services');
    this.SetTitle(this.GetText('dashboard.index_title_connected_devices'));
  }

  ngOnInit(): void {
    this.getData();
    
    this.interval = setInterval(() => {
      this.getData();
    }, 30000);

    this._watchDataLoaded().subscribe(() => {
      this._loadTestingService.stopLoading();
    });
  }

  public tableCellClicked(event: any) {
    if (event.row.patientId) {
      this._router.navigateByUrl(`patients/${event.row.patientId}`);
    }
  }

  public getData(): any {
    this.isLoading = true;
    this._deviceService.getConnectedDevices().subscribe((res) => {
      const data = [];

      res.forEach(element => {
        data.push({
          deviceKey: element.deviceKey,
          patientId: element?.patient?.patientId ?? '-',
          patientDisplayName: element?.patient?.patientDisplayName ?? '-'
        });
      });
        
      this.data = data;
      this.isLoading = false;
    }, () => {
      this.data = [];
      this.isLoading = false;
    });
  }

  private _watchDataLoaded(): Observable<void> {
    if (!this.data) {
      return of(null).pipe(
        delay(500),
        mergeMap(() => {
          return this._watchDataLoaded();
        })
      );
    } else {
      this._loadTestingService.stopLoading();
      return of(null);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
