import { Component, Input } from '@angular/core';
import { PagingExtension } from '../classes/extensions/paging.extension';
import { GlobalService } from '../services/global.service';
import { ActiveAlarmResult } from '../classes/results.class';

@Component({
	selector: 'activeAlarm-list',
	templateUrl: './activeAlarm-list.html',
	styleUrls: [
		'./alarm-list.css'
	]
})
export class ActiveAlarmListComponent extends PagingExtension {
	@Input('alarms')
	public get alarms() :ActiveAlarmResult[] { return <ActiveAlarmResult[]>this.pageItems; };
	public set alarms(value: ActiveAlarmResult[]) { this.items = this.filterMuted(value); this.Build(); }


	constructor(
		private glService: GlobalService
	) {
		super();
	}


	public GetIcon(icon: string) {
		return this.glService.GetIconClass(icon);
	}

	public HasRoom(room: string) {
		return (room != null && room != '');
	}



	private filterMuted(value: ActiveAlarmResult[]) :ActiveAlarmResult[] {
		let result = [];

		if (value != null && value.length > 0) {
			value.forEach(a => {
				if (!a.alarm.muted) {
					result.push(ActiveAlarmResult.Convert(a));
				}
			})
		}

		return result;
	}
}