export class PutAlarmTrigger {
  public Id: number;
  public Seconds: number;
  public AlarmId: number;
  public Status: number;
  public Operator: number;

  constructor(id?: number, seconds?: number, alarmId?: number, operator?: number, status?: number) {
      this.Id = id;
      this.Seconds = seconds;
      this.AlarmId = alarmId;
      this.Operator = operator;
      this.Status = status;
  }
}
