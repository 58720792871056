import { APIService } from './api.service';
import { SETTINGS } from './../settings';
import { Injectable } from '@angular/core';
import { ActiveAlarmResult } from '../classes/results.class';
import { Alarm, AlarmTrigger, AlarmHistory, AlarmTriggerRange, AlarmLog } from '../classes/alarm.class';
import { HttpClient } from '@angular/common/http';
import { PutAlarmTrigger } from 'app/classes/api-models/put-alarmTrigger.class';
import { Observable, of as observableOf } from 'rxjs';

@Injectable()
export class AlarmService extends APIService {
	private _baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient){
    super();
	};

	/** GET **/

	public getActiveAlarms(): Observable<ActiveAlarmResult[]> {
		return this._http.get<ActiveAlarmResult[]>(`${this._baseUrl}Alarm/getActiveAlarms`);
	};

	public getPatientHistory(patient: number): Observable<Alarm[]> {
		return this._http.get<Alarm[]>(`${this._baseUrl}Patient/GetAlarmLog?patientId=${patient}`);
	};

	public getServiceHistory(service: number): Observable<Alarm[]> {
		return this._http.get<Alarm[]>(`${this._baseUrl}Service/GetAlarmLog?serviceId=${service}`);
	};

	public getPatientAlarmTriggers(patient: number): Observable<AlarmTrigger[]> {
		return this._http.get<AlarmTrigger[]>(`${this._baseUrl}Patient/GetAlarmTriggers?patientId=${patient}`);
	};

	public getAlarmLogs(startDate: string, endDate: string): Observable<AlarmLog[]> {
		return this._http.get<AlarmLog[]>(`${this._baseUrl}v1/alarm-logs?begin=${startDate}&end=${endDate}`);
	}

	public getAlarmHistory(length_time?: number): Observable<AlarmHistory[]> {
		if (length_time == null || length_time < 60) {
			length_time = 60;
		}

		var end = new Date();
		var start = new Date(end.getTime() - (length_time * 1000));

		return this._http.get<AlarmHistory[]>(`${this._baseUrl}Alarm/GetAlarmLogsAmount?begin=${start.toJSON()}&end=${end.toJSON()}`);
  }

  public getAlarmRoutes(): Observable<any> {
    return this._http.get<Object[]>(`${this._baseUrl}AlarmSystems`);
  }

  public updateRoute(route): Observable<any> {
    if (route != null) {
      if (route.isActive) {
        return this._http.get<Array<Number>>(`${this._baseUrl}AlarmSystems/${route.id}/ToggleActive?active=true`, {
        });
      } else {
        return this._http.get<Array<Number>>(`${this._baseUrl}AlarmSystems/${route.id}/ToggleActive?active=false`, {
        });
      }
    }
  }

	/** POST **/

	public toggleMute(alarm: number, muted: boolean): Observable<Alarm> {
		return this._http.post<Alarm>(`${this._baseUrl}Alarm/MuteAlarm?alarmId=${alarm}&value=${muted}`, {});
	};

	public toggleMuteAll(patientId: number, muted: boolean): Observable<any> {
		return this._http.post(`${this._baseUrl}Alarm/MuteAllAlarms?patientId=${patientId}&value=${muted}`, {});
	}

	public updatePatientAlarmTrigger(alarm: AlarmTrigger): Observable<number> {
    const postModel = new PutAlarmTrigger(alarm.id, alarm.seconds, alarm.alarmId, alarm.operator, alarm.status);
		return this._http.post<number>(`${this._baseUrl}Patient/EditAlarmTrigger`, postModel);
	}

	public updatePatientAlarmTriggerRange(alarm: number, range: AlarmTriggerRange): Observable<number> {
    var start = range.start;
    var end = range.end;

    if (start == null || start == '') { start = '00:00'; }
    if (end == null || end == '') { end = '00:00'; }

    var d = new Date();
    var split = start.split(':');
    var h = parseInt(split[0]);
    var m = parseInt(split[1]);
    d.setHours(h);
    d.setMinutes(m);
    start = d.toJSON();

    d = new Date();
    split = end.split(':');
    h = parseInt(split[0]);
    m = parseInt(split[1]);
    d.setHours(h);
    d.setMinutes(m);
    end = d.toJSON();

    return this._http.post<number>(`${this._baseUrl}Alarm/SetAlarmActive`, {
      id: range.id,
      alarmId: alarm,
      start: start,
      end: end
    });
  }

  public getPatientAlarmTriggerRange(alarmId: number): Observable<AlarmTriggerRange[]> {
    return this._http.get<AlarmTriggerRange[]>(`${this._baseUrl}Alarm/GetAlarmActive?alarmId=${alarmId}`);
  }

  public deletePatientAlarmTriggerRange(range: number): Observable<boolean> {
    return this._http.delete<boolean>(`${this._baseUrl}Alarm/DeleteAlarmActive/${range}`);
	}

	public getAlarmMuteLogsGraph(patientId: number, from: string, to: string): Observable<any> {
		return this._http.get<any>(`${this._baseUrl}v1/alarm-mute-logs/graph?patientId=${patientId}&from=${from}&to=${to}`);
  }
}
