import { Component, Input } from '@angular/core';
import { wbcToggler } from '../../../classes/wbcToggler.class';

@Component({
	selector: 'wb-button-toggler',
	templateUrl: './button-toggler.html',
	styleUrls: [
		'./button-toggler.css'
	],
})
export class wbButtonTogglerComponent {
	@Input() toggler: wbcToggler;
	@Input() isDisabled: boolean;

	@Input() clickFunc?: Function;


	//public get isToggled() :boolean { if (this.toggler == null) { this.toggler = new wbcToggler(false); } return this.toggler.isToggled; }


	constructor() {

	};


	public toggle() {
		if (this.clickFunc != null && this.clickFunc != undefined) {
			this.clickFunc();
		}

		if (this.toggler == null) {
			this.toggler = new wbcToggler(false);
		}

		var t = this.toggler.isToggled;

		setTimeout(()=>{
			this.toggler.isToggled = !t;// = new wbcToggler(!t);
		}, 0);
		
	}
}