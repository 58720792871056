import { Component, Input, OnInit } from '@angular/core';
import { wbcUrlLocation } from '../../../classes/wbcUrlLocation.class';
import { GlobalService } from '../../../../../services/global.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'wb-crumblePath-basic',
	templateUrl: './crumblePath-basic.html',
	styleUrls: [
		'./crumblePath-basic.css'
	],
})
export class wbCrumblePathBasicComponent implements OnInit {
	@Input() path?: wbcUrlLocation;

	public hideText = false;

	constructor(private glService: GlobalService, private router: Router) {
	}

	ngOnInit() {
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				if (this.router.url.indexOf('/devices/disconnected') > -1) {
					this.hideText = true;
				}
				else {
					this.hideText = false;
				}
      }
		});
	}

	hasText(noUrl?: boolean): boolean {
		if (this.path != null && this.path.text == null) {
			this.path.text = this.glService.title.value;
		}

		if (this.path != null && this.path.text != null && this.path.text != '') {
			if (noUrl === true && this.hasUrl()) {
				return false;
			}
			return true;
		}

		return false;
	};

	hasUrl(): boolean {
		if (this.hasText() && this.path.url != null && this.path.url != '') {
			return true;
		}

		return false;
	};

	hasChild(): boolean {
		if (this.path != null && this.path.child != null) {
			return true;
		}

		return false;
	};

	getLink(): string {
		if (this.hasUrl()) {
			return this.path.url;
		}
		return '';
	}
}
