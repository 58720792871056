import { User } from "./user.class";
import { Alarm, AlarmTrigger } from "./alarm.class";
import { Patient } from "./patient.class";
import { Sensor } from "./sensor.class";
import { Service } from "./service.class";
import { Employee } from "./employee.class";

export class LoginResult {
	public token: string;
	public user: User;


	constructor() {

	}
}

export class DisconnectedDeviceResult {
	public patientName: string;
	public deviceKey: string;
	public disconnectTime: string;
	public disconnectDuration: string;


	constructor() {

	}
}

export class ActiveAlarmResult {
	public alarm: Alarm;
	public patient: Patient;
	public takenUser: User;
	public alarmTrigger: AlarmTrigger;


	constructor() {

	}


	public static Convert(obj: any) : ActiveAlarmResult {
		let result: ActiveAlarmResult = new ActiveAlarmResult();

		try { result.alarm = Alarm.Convert(obj.alarm); } catch(e){}
		try { result.patient = Patient.Convert(obj.patient); } catch(e){}
		try { result.takenUser = User.Convert(obj.takenUser); } catch(e){}
		try { result.alarmTrigger = AlarmTrigger.Convert(obj.alarmTrigger); } catch(e){}

		return result;
	}
}

export class PatientDetailResult {
	public patient: Patient;
	public services: PatientDetailServiceResult[];
	public alarmTriggers: AlarmTrigger[];


	constructor() {

	}
}

export class PatientDetailServiceResult {
	public service: Service;
	public sensor: Sensor;


	constructor() {

	}
}

export class ServiceListResult {
	public sensor: Sensor;
	public service: Service;


	constructor() {}
}

export class ServiceAlarmTriggerListResult {
	public alarmTriggers: AlarmTrigger[];
	public sensorsAndServices: ServiceListResult[];
}
