import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { PageEmployeesIndexComponent } from './employees-index.component';
import { PageEmployeesDetailComponent } from './employees-detail.component';
import { WebbeatModule } from '../../modules/webbeat/webbeat.module';
import { wbPropertyService } from '../../modules/webbeat/service/property.service';
import { wbLoginGuard } from '../../guards/login-guard.service';
import { MaintenanceGuard } from 'app/guards/maintenance-guard.service';
import { EmployeesResolver } from '../../resolvers/employees-resolver';
import { EmployeeResolver } from './../../resolvers/employee-resolver';
import { TeamsResolver } from './../../resolvers/teams-resolver';
import { EmployeeService } from './../../services/employee.service';
import { TeamService } from './../../services/team.service';
import { ContentModule } from '../../content/content.module';
import { FormModule } from '../../forms/forms.module';
import { IsNotManufacturerGuard } from '../../authorization/guards/not-manufacturer-guard.service';
import { UserRolesResolver } from '../../resolvers/userRoles-resolver';
import { PageEmployeesEditComponent } from './employees-edit.component';
import { isNotManufacturerOrDealerGuard } from 'app/authorization/guards/not-manufacturer-or-dealer-guard.service';
import { isManufacturerOrDealerGuard } from 'app/authorization/guards/dealer-or-manufacturer-guard.service';

@NgModule({
  imports: [
    WebbeatModule,
    RouterModule.forChild([
      {
        path: 'employees',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        component: PageEmployeesIndexComponent,
        resolve: {
          employees: EmployeesResolver,
          rememberUserRoles: UserRolesResolver,
        },
      },
      {
        path: 'employees/edit/:id',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        component: PageEmployeesEditComponent,
        resolve: {
          employee: EmployeeResolver,
          employeeTeams: TeamsResolver,
        },
      },
      {
        path: 'employees/edit',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        redirectTo: 'employees/edit/0',
      },
      {
        path: 'employees/:id',
        canActivate: [wbLoginGuard],
        component: PageEmployeesDetailComponent,
        resolve: {
          rememberUserRoles: UserRolesResolver,
        },
      },
    ]),
    SharedModule,
    FormModule,
    ContentModule,
  ],
  declarations: [
    PageEmployeesIndexComponent,
    PageEmployeesDetailComponent,
    PageEmployeesEditComponent,
  ],
  providers: [
    EmployeesResolver,
    EmployeeResolver,
    EmployeeService,
    IsNotManufacturerGuard,
    isNotManufacturerOrDealerGuard,
    isManufacturerOrDealerGuard,
    wbPropertyService,
    wbLoginGuard,
    UserRolesResolver,
    TeamsResolver,
    TeamService,
  ],
})
export class PageEmployeesModule {}
