export class Group {
	public id: number;
	public name: string;
	public externalId: string;
	public company: string;
	public companyId: number;
	public companyExternalId: string;
	public userActive: boolean;
	public roles: number[];


	constructor() {

	}


	public static Convert(obj: any) : Group {
		let result: Group = new Group();
		
		if (obj == null) {
			return null;
		}

		try { result.id = obj.id } catch(e){}
		try { result.name = obj.name } catch(e){}
		try { result.externalId = obj.externalId } catch(e){}
		try { result.company = obj.company } catch(e){}
		try { result.companyId = obj.companyId } catch(e){}
		try { result.companyExternalId = obj.companyExternalId } catch(e){}
		try { result.userActive = obj.userActive } catch(e){}
		try {
			result.roles = [];
			for (let i = 0; i < obj.roles.length; i ++) {
				result.roles.push(obj.roles[i]);
			}
		} catch(e){}

		
		return result;
	}
}