import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee, EmployeePOST } from '../classes/employee.class';
import { Observable, of } from 'rxjs';
import { SETTINGS } from '../settings';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class EmployeeService extends APIService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient) {
    super();
  }

  public getAllEmployees(): Observable<any> {
    return this._http.get(`${this.baseUrl}v2/Employees`);
  }

	public getEmployees(): Observable<Employee[]> {
		return this._http.get<Employee[]>(`${this.baseUrl}v2/Employees`);
  };

  public getEmployee(id): Observable<EmployeePOST> {
    return this._http.get<Employee>(`${this.baseUrl}v2/Employees/${id}`).pipe(
      switchMap(employee => {
        return of(new EmployeePOST(employee))
      })
    )
  };

	public toggleActive(employee: number, active: boolean): Observable<boolean> {
		return this._http.post<boolean>(`${this.baseUrl}User/SetActive?active=${active}&userId=${employee}`, {});
  }

  public deleteEmployee(id: number): any {
    return this._http.delete(`${this.baseUrl}v2/Employees/${id}`);
  }

  public createEmployee(employee: EmployeePOST): Observable<number> {
		return this._http.post<number>(`${this.baseUrl}v2/Employees`, {
      name: employee.name,
      email: employee.email,
      teamIds: employee.teamIds,
      role: employee.role,
      language: employee.language,
      username: employee.email,
		});
  };

  public editEmployee(employee: EmployeePOST): Observable<number> {
		return this._http.put<number>(`${this.baseUrl}v2/Employees/${employee.id}`, {
      name: employee.name,
      email: employee.email,
      teamIds: employee.teamIds,
      role: employee.role,
      language: employee.language,
      username: employee.email,
		});
	};
}
