import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DeviceService } from 'app/services/device.service';
import { DisconnectedDevice } from '../classes/disconnectedDevice';

@Injectable()
export class DisconnectedDevicesResolver implements Resolve<DisconnectedDevice[]> {
	constructor(
		private service: DeviceService,
	) { }

	resolve(): DisconnectedDevice[] | any {
		return this.service.getDisconnectedDevices();
	}
}