<div class="inputElement form-group" [ngClass]="{ 'has-error': (_item.touched && _item.invalid) }">
	<label>
		<div [ngClass]="{ 'mandatory': isRequired }">{{title}}</div>
		<div class="error" [hidden]="_item.untouched || _item.valid">{{errorMessage}}</div>
		<input
			type="text"
			class="form-control transferrable"
			[id]="property"
			[required]="isRequired ? 'true' : null"
			[readOnly]="isReadOnly ? 'true' : null"
			[name]="property"
			#_item="ngModel"
			[(ngModel)]="item"
			(keydown)="onKeyDown()"
			(blur)="onBlur()"
		/>
	</label>
</div>