<div id="ng_modal" [hidden]="!show.isToggled">
	<div id="ng_modal_content" class="{{GetWidth()}} {{GetOffset()}}">
		<div id="ng_modalCloser" (click)="closeModal()">
			<ng-content select="[modal-closer]"></ng-content>
		</div>
		<div id="ng_modalBlock">
			<ng-content select="[modal-content]"></ng-content>
			<!--<div id="ng_header" [ngClass]="{ 'toggled': isToggled }">
				<ng-content select="[block-header]"></ng-content>
			</div>
			<div id="ng_body">
				<ng-content select="[block-body]"></ng-content>
			</div>
			<div id="ng_footer">
				<ng-content select="[block-footer]"></ng-content>
			</div>-->
		</div>
	</div>
</div>