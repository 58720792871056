import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WebbeatModule } from '../../modules/webbeat/webbeat.module';
import { SharedModule } from '../../shared.module';
import { ContentModule } from '../../content/content.module';

import { AlarmHistoryComponent } from './alarm-history/alarm-history.component';
import { AlarmHistoryResolver } from '../../resolvers/alarm-history-resolver.service';
import { AlarmRoutingModule } from './alarm-routing.module';

@NgModule({
	imports: [
		AlarmRoutingModule,
		WebbeatModule,
		SharedModule,
		ContentModule
	],
	declarations: [
		AlarmHistoryComponent
	],
	providers: [
		AlarmHistoryResolver
	]
})
export class PageAlarmHistoryModule {
}