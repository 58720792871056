<div class="col-xs-{{block.column}}">
	<div [ngClass]="{ 'bordered': block.border }" class="infoBlock">
		<div *ngIf="HasInnerBlocks()">
			<info-block *ngFor="let inner of block.innerBlocks" [block]="inner"></info-block>
		</div>
		<div *ngIf="!HasInnerBlocks() && HasLines()">
			<div *ngFor="let line of block.lines" class="col-xs-{{line.column}}">
				<div *ngFor="let span of line.spans" style="display: inline;">
					<h2 *ngIf="span.type == 'header'">{{span.text}}</h2>
					<strong *ngIf="span.type == 'bold_text'">{{span.text}} </strong>
					<span *ngIf="span.type == 'text'">{{span.text}} </span>
					<a *ngIf="span.type == 'email'" href="mailto:{{span.extra}}">{{span.text}}</a>
					<a *ngIf="span.type == 'link'" href="{{span.extra}}">{{span.text}}</a>
					<span *ngIf="span.type == 'icon'" class="icon icon-{{span.extra}}"></span>
					<br *ngIf="span.type == 'break'" />
				</div>
			</div>
		</div>
	</div>		
</div>