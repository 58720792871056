import { Component, Input, OnInit, Renderer2, ElementRef } from '@angular/core';

import { hcOptions } from '../../../modules/highchart/classes/hcOptions.class';
import { ChartType } from '../../../modules/highchart/classes/hcEnums.class';
import { hcSeries } from '../../../modules/highchart/classes/hcSeries.class';
import { hcDataPoint } from '../../../modules/highchart/classes/hcDataPoint.class';
import { wbcToggler } from '../../../modules/webbeat/classes/wbcToggler.class';
import { wbcAny } from '../../../modules/webbeat/classes/wbcAny.class';
import { LayGraphData } from '../../../classes/layGraphData.class';
import { contentParent } from '../../content-parent';
import { SETTINGS } from '../../../settings';
import { SensorService } from 'app/services/sensor.service';
import { Observable } from 'rxjs';


@Component({
	selector: 'patientLay-graph',
	templateUrl: './patientLay-graph.html',
	styleUrls: [
		'../graph.css',
	]
})
export class PatientLayGraphComponent extends contentParent implements OnInit {
	@Input('reset') reset: wbcToggler;
	@Input('zoom') showZoom: boolean;
	@Input() showAlarms?: boolean;
	@Input('data')
	get data(): LayGraphData {
		return this._data;
	};
	set data(value: LayGraphData) {
		setTimeout(() => {
      this._data = value;
      this.BuildGraph();
		}, 0);
	}

	@Input('minDateTime')
	set minDateTime(value: Date | number) {
		setTimeout(() => {
			if (value != null) {
				if (value instanceof Date) {
					this._minDateTime = this.getDate((<Date>value).toJSON());

					var aaa = new Date().getTime();
					this._rangeMS = aaa - this._minDateTime;
				}
				else {
					this._rangeMS = value;
					this._minDateTime = this.getDate((new Date()).toJSON(), 0 - value);
				}

				if (this._minDateTime != this.options.xAxis.min) {
					this.options.xAxis.min = this._minDateTime;
					this.update = new wbcToggler(true);
				}
			}
		}, 0);
	}

	public options: hcOptions;
	public graph: hcSeries[];
	public update: wbcToggler;
	public rebuild: wbcToggler;
	public chartId: wbcAny = new wbcAny('no id', () => { this.updateYAxis(); });
  public isClear: wbcToggler = new wbcToggler(false);
  public styleElement: any;

  private classIndexBinding: any = {};
	private _data: LayGraphData;
	private _minDateTime?: number;
	private _rangeMS: number;

	private gracePeriod: boolean = true;

	constructor(private _renderer: Renderer2, private _el: ElementRef, private _sensorService: SensorService) {
		super();
	}

	ngOnInit() {
		this.update = new wbcToggler(false);

		this.options = new hcOptions(ChartType.area, '', null, null, null, null, null, this.showZoom);

		this.options.tooltipHtml = '{series.name}';
		//this.options.useUTC = false;

		this.options.chart.marginLeft = 100;
		this.options.chart.isAnimated = false;

		this.options.xAxis.type = 'datetime';
		this.options.xAxis.max = this.getDate(new Date().toJSON());

		this.options.yAxis.min = 0;
		//this.options.yAxis.max = 4;
		this.options.hasLegend = false;
		this.options.yAxis.title = '';

    this._createColorClasses().subscribe(() => {
      this.BuildGraph();
    });

		setTimeout(() => {
			this.gracePeriod = false;
		}, 100);
	}


	private updateYAxis() {
		if (this.data != null && this.data.uniqueDataValue != null) {
			if (this.chartId != null && this.chartId.value != null && this.chartId.value != 'no id') {
				var options = this.data.uniqueDataValue;

				var interval = SETTINGS.AddInterval(() => {
					var $span = SETTINGS.jquery('#' + this.chartId.value + ' .highcharts-yaxis-labels tspan');

					if ($span.length > 0) {
						SETTINGS.ClearInterval(interval);

						$span.each(function () {
							var $this = SETTINGS.jquery(this);
							var value = parseInt($this.text());

							if (!isNaN(value)) {
								if (value < 1 || value > options.length) {
									$this.text('');
								}
								else {
									$this.text(options[value - 1]);
								}
							}
						});
					}
				}, 1);
			}
		}
  }

	private BuildGraph() {
		if (this.data == null || this.data.data == null || this.data.data.length == 0 || this.data.uniqueDataValue == null || this.data.uniqueDataValue.length == 0) {
			if (!this.gracePeriod) {
				this.isClear = new wbcToggler(true);
			}
			return;
		}

		var minnnDate = null;

		var graph_min = null;
		var graph_max = null;

		if (this.data.start != null) {
			graph_min = this.getDate(this.data.start.toJSON());
		}
		if (this.data.end != null) {
			graph_max = this.getDate(this.data.end.toJSON());
		}

		if (graph_min != null) {
			this._minDateTime = graph_min;
		}
		else {
			if (this._rangeMS != null && this._rangeMS > 0) {
				var new_min_date = this.getDate(new Date().toJSON(), 0 - (this._rangeMS / 1000), false);//(new Date().getTime()) - this._rangeMS;

				if (new_min_date != this._minDateTime) {
					this._minDateTime = new_min_date;
					minnnDate = new_min_date;
				}
			}
		}

		var force: boolean = false;

		if (this.reset != null && this.reset.isToggled) {
			force = true;
			this.reset = new wbcToggler(false);
		}

		var yaxis = this.data.uniqueDataValue;

		this.options.yAxis.max = yaxis.length;
		this.options.yAxis.tickAmount = yaxis.length + 1;

		var graph: Array<hcSeries> = [];

		/* add all graph-groups */
		for (var aa = 0; aa < yaxis.length; aa++) {
			graph.push(new hcSeries([], ChartType.area, yaxis[aa], false, false));
		}
		var lastDate;

		if (graph_max != null) {
			lastDate = graph_max;
		}
		else {
			lastDate = this.getDate(new Date().toJSON(), null);
		}
		var firstDate = null;

		var starting = 0;
		var previous = -1;

		/* set first value */
		if (this._minDateTime != null && this._minDateTime > 0) {
			firstDate = this._minDateTime;

			var firstValueDate = this.getDate(this.data.data[0].timeStamp);

			if (firstValueDate > firstDate) {
				var y = this.getValue(this.data.data[0].value);

				if (y > 0 && y <= graph.length) {
					graph[y - 1].data.push(new hcDataPoint(firstDate, y));
				}
			}
			else {
				firstDate = firstValueDate;
      }
    }

		for (var i = 0; i < this.data.data.length; i++) {
			var colorClass = this.classIndexBinding[this.data.data[i].untranslatedValue.toLowerCase()];
			var point = new hcDataPoint(this.getDate(this.data.data[i].timeStamp), this.getValue(this.data.data[i].value));
			if (firstDate == null) {
				firstDate = point.x;
			}

			/* update previous node */
			if (previous >= 0 && previous != point.y) {
				if (previous > 0 && previous <= graph.length) {
					graph[previous - 1].data.push(new hcDataPoint(point.x - 1, previous));
					graph[previous - 1].data.push(new hcDataPoint(point.x, -1));

				}
			}

			/* set node */
			if (point.y > 0 && point.y <= graph.length) {
				if (previous >= 0 && previous != point.y) {
					graph[point.y - 1].data.push(new hcDataPoint(point.x - 1, -1));
				}
				graph[point.y - 1].data.push(point);
				if (colorClass != -1) {
					graph[point.y - 1].colorIndex = colorClass;
				}
			}

			previous = point.y;
		}

		/* set last node */
		if (previous == 0) {
			for (var g = 0; g < graph.length; g++) {
				graph[g].data.push(new hcDataPoint(point.x, -1));
			}
		}
		else if (previous > 0) {
			var point = new hcDataPoint(lastDate, previous);
			if (previous < graph.length + 1) {
				graph[previous - 1].data.push(point);
			}
		}

		/* add alarm-group */
		if (this.data.alarmLogs != null && this.data.alarmLogs.length > 0) {
			let alarms_1 = [];
			let alarms_2 = [];

			for (let a = this.data.alarmLogs.length - 1; a >= 0; a--) {
				let date = this.getDate(this.data.alarmLogs[a].created);

				let point_a = new hcDataPoint(date - 1, -1);
				let point_b = new hcDataPoint(date, this.getValue(this.data.alarmLogs[a].alarmTriggerValue));
				let point_c = new hcDataPoint(date + 1, -1);

				if (this.data.alarmLogs[a].status == 24 || this.data.alarmLogs[a].status == 25 || this.data.alarmLogs[a].status == 34 || this.data.alarmLogs[a].status == 35) { /* TODO: change the variable 'type' to correct name + check for correct value */
					alarms_2.push(point_a);
					alarms_2.push(point_b);
					alarms_2.push(point_c);
				}
				else if (this.data.alarmLogs[a].status == 10 || this.data.alarmLogs[a].status == 20) {
					alarms_1.push(point_a);
					alarms_1.push(point_b);
					alarms_1.push(point_c);
				}
			}

			if (this.showAlarms === true) {
				if (alarms_1.length > 0) {
					graph.push(new hcSeries(alarms_1, ChartType.line, this.GetText('common.alarm'), false, false, null, '/Content/img/png/Melding.png', 100));
				}
				if (alarms_2.length > 0) {
					graph.push(new hcSeries(alarms_2, ChartType.line, this.GetText('common.alarm_repeat'), false, false, null, '/Content/img/png/Melding_oranje.png', 101));
				}
			}
		}

		graph.unshift(new hcSeries([]));

		if (graph_max != null) {
			this.options.xAxis.max = graph_max;
		}
		else {
			this.options.xAxis.max = lastDate;
		}

		if (graph_min != null) {
			this.options.xAxis.min = graph_min;
		}
		else {
			if (minnnDate == null) {
				this.options.xAxis.min = lastDate - (lastDate - this._minDateTime);
			}
			else {
				this.options.xAxis.min = minnnDate;
			}
		}
		this.options.series = new Array<hcSeries>();
		this.graph = graph;

		if (force) {
			this.rebuild = new wbcToggler(true);
		}
		else {
			this.update = new wbcToggler(true);
		}

		this.updateYAxis();
	}

	private getValue(text: string): number {
		if (text == null || text == '' || this.data.uniqueDataValue == null) {
			return -1;
		}

		text = text.toLowerCase();

		for (var i = 0; i < this.data.uniqueDataValue.length; i++) {
			if (this.data.uniqueDataValue[i].toLowerCase() == text) {
				return i + 1;
			}
		}

		return 0;
	}

	private getDate(jsonTimeStamp: string, addSeconds?: number, useOffset: boolean = true): number {
		var result = new Date(jsonTimeStamp).getTime();

		if (addSeconds != null) {
			result += (addSeconds * 1000);
		}

		if (!useOffset) {
			return result;
		}

		var timeStamp = new Date(jsonTimeStamp);
		var offset = timeStamp.getTimezoneOffset() * 60000;
		return result - offset;
	}

  private _createColorClasses(): Observable<boolean> {
    return new Observable(observer => {
      this._sensorService.getStatusColors().subscribe(res => {
        let styling = '';
        const classesArray = [];
        this.classIndexBinding = {};
        res.forEach((item, index) => {
          let colorIndex = 200;
          classesArray.push(`
            .highcharts-color-${colorIndex + index} {
              fill: #${item.color} !important;
              stroke: #${item.color} !important;
            }
          `);
          this.classIndexBinding[item.status.toLowerCase()] = colorIndex + index;
        });

        styling = classesArray.join(' ');

        if (this.styleElement) {
          this._renderer.removeChild(this._renderer.parentNode(this.styleElement), this.styleElement);
        }

        this.styleElement = this._renderer.createElement('style');
        this.styleElement.appendChild(this._renderer.createText(styling));

        this._el.nativeElement.appendChild(this.styleElement);
        observer.next(true);
      }, error => {
        observer.next(true);
      });
    });
  }
}
