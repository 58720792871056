import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlarmService } from '../services/alarm.service';

@Injectable()
export class AlarmTriggersResolver implements Resolve<any> {
	constructor (private service: AlarmService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		let id = route.params['id'];

		/* if 'id' = 0, return empty list */
		if (id == 0 || id == '0') {
			return [];
		}

		return this.service.getPatientAlarmTriggers(id);
	}
}