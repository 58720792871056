import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { wbcMenuButton } from './../../modules/webbeat/classes/wbcButton.class';
import { wbcUrlLocation } from './../../modules/webbeat/classes/wbcUrlLocation.class';
import { wbBrowserStorage } from './../../modules/webbeat/service/browserStorage.service';
import { Page } from './../pages.parent';
import { GlobalService } from './../../services/global.service';
import { RoleService } from './../../authorization/services/role.service';
import { EmployeePOST } from 'app/classes/employee.class';
import { EmployeeService } from 'app/services/employee.service';
import { Team } from 'app/classes/team.class';
import { AccountService } from './../../services/account.service';

@Component({
	selector: 'page-employees-edit',
	templateUrl: './employees-edit.html'
})
export class PageEmployeesEditComponent extends Page implements OnInit {
  public employee: EmployeePOST;
  public employeeTeams: Team[];
  public violations;
  private _isTeamLead: boolean;

	constructor(
		public glService: GlobalService,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private acctService: AccountService,
    private roleService: RoleService,
    private router: Router,
    private storage: wbBrowserStorage,
	) {
		super(glService, 'EmployeesEdit');
  };

  public updateEmployee = (employee: EmployeePOST) => {
    employee.language = employee.language.toLowerCase();
		this.employee = employee;
    this.violations = this.validateEmployee(employee);

    setTimeout(() => {
      if (this.employee != null) {
        if (this.employee.id < 1) {
          this.glService.mainButton = new wbcMenuButton(this.GetText('common.add_employee'), () => {
            this.saveEmployee();
          }, 'primary', false, (this.violations != null));
          this.SetTitle(this.GetText('common.add_employee'));
          this.glService.crumble = new wbcUrlLocation('Employees', this.GetText('common.employees'), '../employees',
            new wbcUrlLocation('Employees_edit', this.GetText('common.add_employee')));
        }
        else {
          this.SetTitle(this.GetText('common.edit_employee'));
          this.glService.mainButton = new wbcMenuButton(this.GetText('common.edit_employee'), () => {
            this.saveEmployee();
          }, 'primary', false, (this.violations != null));
          this.glService.crumble = new wbcUrlLocation('Employees', this.GetText('common.employees'), '../employees',
            new wbcUrlLocation('Employees_edit', this.GetText('common.edit_employee')));
        }
      }
    });
  }

  public validateEmployee(employee: EmployeePOST) {
    let violations = {
      name: '',
      email: '',
      language: '',
      teams: '',
      role: ''
    };

    let areViolations: boolean = false;

    if (!employee.name) {
      areViolations = true;
      violations.name = this.GetText('forms.patient.error.name');
    }

    if (!employee.email) {
      areViolations = true;
      violations.email = this.GetText('forms.patient.error.email');
    }

    if (!employee.language) {
      areViolations = true;
      violations.language = this.GetText('forms.patient.error.language');
    }

    if (!employee.teamIds || employee.teamIds.length == 0) {
      areViolations = true;
      violations.teams = this.GetText('forms.patient.error.team');
    }

    if (!employee.role) {
      areViolations = true;
      violations.role = this.GetText('forms.patient.error.role');
    }

    if (areViolations) {
      return violations;
    } else {
      return null;
    }
  }

	ngOnInit() {
    this.route.data.subscribe(results => {
      this.updateEmployee(results.employee);
      this.employeeTeams = results['employeeTeams'];

      this.roleService.isTeamLead().subscribe(res => {
        this._isTeamLead = res;
        const lastLoggedInUserId = parseFloat(
          this.storage.getStorageItem('lastLoggedInUserId')
        );
        if (this._isTeamLead && results.employee.id !== 0 && results.employee.id !== lastLoggedInUserId) {
          this.glService.secondButton = new wbcMenuButton(this.GetText('common.delete_employee'), () => {
            if (confirm(this.GetText('common.delete_employee_msg'))) {
              this.deleteEmployee();
            }            
          }, 'danger', false, false);
        }
      });
    });
  }

  private deleteEmployee() {
    return this.employeeService.deleteEmployee(this.employee.id).subscribe(() => {
      this.router.navigateByUrl('/employees');
    });
  }

  public saveEmployee = () => {
    if (this.employee.id < 1) {
      return this.employeeService.createEmployee(this.employee)
        .subscribe((result) => {
          this.router.navigateByUrl('/employees');
        });
    } else {
      let user: any;
      let userData = localStorage.getItem('user');
      if (userData) {
        user = JSON.parse(userData);
      }
      const userId: number = user.id;

      if (this.employee.id === userId) {
        this.acctService.updateSessionLanguage(this.employee.language);
      }

      return this.employeeService.editEmployee(this.employee)
      .subscribe((result) => {
        this.router.navigateByUrl('/employees');
      });
    }
  }
}
