<div *ngIf="!isLoading" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_green"></div>
			<div class="row">
				<div class="col-xs-12">
					<h2>{{GetText('company.overview')}}</h2>
					<p class="color_green">{{GetText('company.overview_subtitle')}}</p>
				</div>
			</div>
		</div>
		<div block-body>
			<tenants-list (isLoading)="isLoading = $event" [tenants]="tenants"></tenants-list>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>
</div>