import { LANGUAGE } from '../language';


export class contentParent {

	public GetText(key: string, lang?: string) :string {
		return LANGUAGE.GetText(key, lang);
	}

	public IsEmpty(text: string) :boolean {
		if (text == null) {
			return true
		}
		if (text === '') {
			return true;
		}

		return false;
	}
}