export enum ChartType {
	area,
	arearange,
	areaspline,
	areasplinerange,
	bar,
	boxplot,
	bubble,
	column,
	columnrange,
	errorbar,
	funnel,
	gauge,
	heatmap,
	line,
	pie,
	polygon,
	pyramid,
	scatter,
	series,
	solidgauge,
	spline,
	treemap,
	waterfall
}

export enum ChartZoomType {
	x,
	y,
	xy
}


export class hcEnumClass {

}