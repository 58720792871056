<div class="{{GetWidth()}} {{GetOffset()}}">
  <div id="headerBlock" class="headerBlock">
    <div id="header" #header [ngClass]="{ 'toggled': isToggled }">
      <ng-content select="[block-header]"></ng-content>
    </div>
    <div *ngIf="hasBody" [hidden]="loading" id="body" #body>
      <ng-content select="[block-body]"></ng-content>
    </div>
    <div *ngIf="loading" class="loading-indicator" id="body" #body>
      <img src="Content/img/loading-spinner.gif" />
    </div>
    <div *ngIf="hasFooter" id="footer" #footer>
      <ng-content select="[block-footer]"></ng-content>
    </div>
  </div>
</div>
