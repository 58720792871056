
import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { contentParent } from '../../content-parent';
import * as moment from 'moment';

//Hicharts imports
import { hcHighChartService } from '../../../modules/highchart/services/highchart.service';
import { SensorReportDataItem } from 'app/classes/analyze-alarm.class';

@Component({
	selector: 'report-sensor-graph',
  template: `
  <div #chartTarget id="graph-container"></div>
  `,
	styleUrls: [
		'../graph.css',
		'./report-sensor-graph.css'
	]
})
export class ReportSensorGraph extends contentParent {
	@ViewChild('chartTarget', { static: true }) chartTarget: ElementRef;

	@Input('alarmGraphData')
	get alarmGraphData(): SensorReportDataItem[] {
		return this._alarmGraphData;
	};
	set alarmGraphData(value: SensorReportDataItem[]) {
    this._alarmGraphData = value;
    if (value != undefined) {
      this._buildGraph();
    }
	}

	public chart: any;
	private _alarmGraphData: SensorReportDataItem[] = [];
	private _options: any;

	constructor(private _chartService: hcHighChartService) {
		super();
  };

	private _buildGraph(): void {
		this._options = this._setupOptions();
		this.chart = this._chartService.ChartNormal(this._options, this.chartTarget.nativeElement);
	}

	private _setupOptions(): any {
    const options = {
      chart: {
        type: 'columnrange',
        height: this._calculateGraphHeight(),
        inverted: true
      },
      title: {
        text: this.GetText('reports.status')
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false,
        allowHTML: true,
        contextButton: {
          enabled: false
        }
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        title: {
          text: ''
        },
        type: 'datetime'
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function() {
          return moment.utc(this.point.low).format('HH:mm:ss DD/MM/YYYY') +' - '+ moment.utc(this.point.high).format('HH:mm:ss DD/MM/YYYY');
        },
      },
      series: [{
        name: 'Sensor graph',
        data: [
        ]
      }]
    };

    this.alarmGraphData.forEach((graphDataItem, index) => {
      options.xAxis.categories.push(`${graphDataItem.deviceKey} ${graphDataItem.sensorValue}`);
      options.series[0].data.push([
        moment.utc(graphDataItem.startTime).valueOf(),
        moment.utc(graphDataItem.endTime).valueOf()
      ])
    });
    return options;
  }

  private _calculateGraphHeight(): number {
    return (this._alarmGraphData.length * 50) + 100;
  }
}
