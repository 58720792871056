import { Component, Input } from '@angular/core';
import { GlobalService } from '../../../../../services/global.service';
import { SETTINGS } from '../../../../../settings';
import { wbcToggler } from '../../../classes/wbcToggler.class';

@Component({
	selector: 'wb-sidePanel-basic',
	templateUrl: './sidePanel-basic.html',
	styleUrls: [
		'./sidePanel-basic.css'
	],
})
export class wbSidePanelBasicComponent {
	private _autohide: any;
	private _sideWidth: number;
	private _sizeTimer: any = null;
	private _windowSize: number;
	private _panelSizePercent: number;
	private _panelSizePixel: number;



	public hideOn: string = '';
	public panelSize: number = 0;


	@Input('autohide')
	get autohide(): any {
		return this._autohide;
	}
	set autohide(value: any) {
		this._autohide = value;
		this.hideOnCheck();
	}

	//@Input('sideWidth')
	//get sideWidth(): number {
	//	return this._sideWidth;
	//}
	//set sideWidth(value: number) {
	//	if (this.minSideWidth != null) {
	//		if (value < this.minSideWidth) {
	//			value = this.minSideWidth;
	//		}
	//	}
	//
	//	this._sideWidth = value;
	//}

	@Input('panelSizePercent')
	get panelSizePercent(): number {
		return this._panelSizePercent;
	}
	set panelSizePercent(value: number) {
		this._panelSizePercent = parseFloat('' + value);
		this.resizer();
	}

	@Input('panelSizePixel')
	get panelSizePixel(): number {
		return this._panelSizePixel;
	}
	set panelSizePixel(value: number) {
		this._panelSizePixel = parseFloat('' + value);
		this.resizer();
	}

	@Input('totalWidth')
	get totalWidth(): number {
		return this._windowSize;
	}
	set totalWidth(value: number) {
		this._windowSize = parseFloat('' + value);
		this.resizer();
	}

	@Input('closed')
	get closed(): wbcToggler { return this.glService.menuToggled; }
	set closed(value: wbcToggler) { this.glService.menuToggled = value; }

	@Input() sideWidth?: number;
	@Input() minSideWidth?: number;
	@Input() maxSideWidth?: number;
	@Input() animated: boolean = false;

	//@Input() toggleFuntion?: Function | string;
	//@Input() toggleObject?: any;
	//@Input() toggleBool?: boolean;

	//private _closed: wbcToggler;


	constructor(public glService: GlobalService) {
		var $this = this;

		setTimeout(function () {
			$this._windowSize = SETTINGS.jquery(window).innerWidth();
			//$this.onResize({
			//	target: {
			//		innerWidth: $(window).innerWidth()
			//	}
			//});

			setTimeout(function () {
				$this.animated = true;
			}, 500);
		}, 0);
	}

	//onResize(event: any) {
	//	if (this._sizeTimer != null) {
	//		clearTimeout(this._sizeTimer);
	//	}
	//
	//	var $this = this;
	//
	//	this._sizeTimer = setTimeout(function () {
	//		$this._sizeTimer = null;
	//		$this._windowSize = event.target.innerWidth;
	//		$this.resizer();
	//	}, 10);
	//
	//}

	resizer() {
		if (this.sideWidth != null) {
			this.panelSize = this.sideWidth;
		}
		else {
			if (this._panelSizePixel == null && this._panelSizePercent != null) {
				this.panelSize = this._windowSize * (this._panelSizePercent / 100);
			}
			else if (this._panelSizePixel != null) {
				this.panelSize = this._panelSizePixel;
			}
			else {
				this.panelSize = 0;
			}
		
			if (this.maxSideWidth != null) {
				if (this.panelSize > this.maxSideWidth) {
					this.panelSize = this.maxSideWidth;
				}
			}
		
			if (this.minSideWidth != null) {
				if (this.panelSize < this.minSideWidth) {
					this.panelSize = this.minSideWidth;
				}
			}
		}

		//if (this.toggleFuntion != null && typeof(this.toggleFuntion) == 'string' && this.toggleObject != null) {
		//	try {
		//		this.toggleObject[(<string>this.toggleFuntion)](this.closed);
		//	}
		//	catch(e) {}
		//}
		//else if (this.toggleFuntion != null && typeof(this.toggleFuntion) == 'function') {
		//	this.toggleFuntion(this.closed);
		//}

		//if (this.toggleBool != undefined) {
		//	this.toggleBool = this.closed;
		//}
		//this.glService.menuToggled = this.closed;
	}

	hideOnCheck() {
		var result = '';

		if (this.autohide != null && this.autohide.length > 0) {
			var arr = [];

			if (typeof this.autohide == 'string') {
				arr = this.autohide.split(',');

			}
			else if (this.autohide instanceof Array) {
				arr = this.autohide;
			}

			for (var i = 0; i < arr.length; i++) {
				switch (arr[i].trim()) {
					case 'xsmall':
						result += ' hxs';
						break;
					case 'small':
						result += ' hsm';
						break;
					case 'medium':
						result += ' hmd';
						break;
					case 'large':
						result += ' hlg';
						break;
				}
			}
		}

		this.hideOn = result.trim();
	}

	toggle() {
		this.closed = new wbcToggler(!this.closed.isToggled);
	}
}