export class wbcUrlLocation {
	public text: string;
	public url: string;
	public child: wbcUrlLocation;
	public id: string;


	constructor(id: string, text?: string, url?: string, child?: wbcUrlLocation) {
		this.id = id;
		this.text = text;
		this.url = url;
		this.child = child;
	}
}