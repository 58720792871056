import { Patient } from './patient.class';
import { ServiceListResult } from './results.class';
import { Alarm } from './alarm.class';
import { Sensor } from './sensor.class';
import { Service } from './service.class';

import { PatientService } from '../services/patient.service';
import { ServiceService } from '../services/service.service';
import { SensorService } from '../services/sensor.service';

import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';

import { SETTINGS } from '../settings';


import { LayGraphData, LayGraphData_sensor, LayGraphData_sensorService } from './layGraphData.class';


export class Block {
	public title: string;
	public subTitle: string;

	public table_data: any[];
	public table_properties: Array<string | Function>;

	constructor(title: string, subTitle?: string, properties?: Array<string | Function>) {
		this.table_properties = properties;
		this.title = title;
		this.subTitle = subTitle;
	}
}

export class SwitchableBlock extends Block {


	constructor(title: string, subTitle?: string, properties?: Array<string | Function>) {
		super(title, subTitle, properties);
	}
}

export class PatientIndexBlock extends SwitchableBlock {
	private intervalTime: number = 15000;

	public tableAlarms: Alarm[];
	public graphData: LayGraphData;

	public get showGraph() :boolean { return this._showGraph; };
	public set showGraph(value: boolean) {
		if (this._showGraph != value) {
			if (value == false) {
				this.GetLog();

				this.interval = SETTINGS.AddInterval(()=> {
					if (!this.showGraph) {
						this.GetLog();
					}
					else {
						SETTINGS.ClearInterval(this.interval);
					}
				}, this.intervalTime, this.interval);
			}
			else if (value == true) {
				if (this.sensors != null && this.sensors.length > 0) {
					this.GetGraph();

					this.interval = SETTINGS.AddInterval(()=> {
						if (this.showGraph) {
							this.GetGraph();
						}
						else {
							SETTINGS.ClearInterval(this.interval);
						}
					}, this.intervalTime, this.interval);
				}
				else {
					console.warn('no sensors!');
				}
			}
		}

		this._showGraph = value;
	};


	private _showGraph: boolean = null;
	private interval: number;


	constructor(public patient: Patient, public sensors: Sensor[], public time: number, private service: PatientService, showGraph?: boolean) {
		super('', '', new Array<string | Function>('', ''));

		if (showGraph != null) {
			this.showGraph = showGraph;
		}
		else {
			this.showGraph = true;
		}
	}


	private GetGraph() {
		this.service.getGraph(this.patient.id, this.sensors[0].id, this.time).subscribe(result => {
			this.graphData = result;
		});
	};

	private GetLog() {
		this.service.getAlarmLog(this.patient.id).subscribe(result => {
			this.tableAlarms = result;
		});
	};
}

export class ServiceIndexBlock extends SwitchableBlock {
  private intervalTime: number = 15000;
  private clearIntervalAfterFirstUse: boolean = false;

	public tableAlarms: Alarm[];
  public graphData: LayGraphData;
  public loadedOnce: boolean = false;

	public get showGraph() :boolean { return this._showGraph; };
	public set showGraph(value: boolean) {
		if (this._showGraph != value) {
			if (value == false) {
				this.GetLog();

				this.interval = SETTINGS.AddInterval(()=> {
					if (!this.showGraph) {
						this.GetLog();
					}
					else {
						SETTINGS.ClearInterval(this.interval);
          }
				}, this.intervalTime, this.interval);
			}
			else if (value == true) {
				this.GetGraph();

				this.interval = SETTINGS.AddInterval(()=> {
					if (this.showGraph) {
						this.GetGraph();
					}
					else {
						SETTINGS.ClearInterval(this.interval);
					}
				}, this.intervalTime, this.interval);
      }
       // TODO: When we start developing again this hack should be resolved.
       if (this.clearIntervalAfterFirstUse) {
        SETTINGS.ClearInterval(this.interval);
      }
		}

		this._showGraph = value;
	};


	private _showGraph: boolean = null;
	private interval: number;

	public timer: number;

	constructor(public service: ServiceListResult, private servicer: SensorService, timer: number, showGraph?: boolean, clearIntervalAfterFirstUse?: boolean) {
		super('', '', new Array<string | Function>('', ''));
    this.timer = timer;
    this.clearIntervalAfterFirstUse = clearIntervalAfterFirstUse;
		if (showGraph != null) {
			this.showGraph = showGraph;
		}
		else {
			this.showGraph = true;
		}
	}


	public GetGraph() {
		if (this.service != null && this.service.sensor != null) {
			this.servicer.getGraph(this.service.sensor.id, 0, this.timer).subscribe(result => {
        this.graphData = result;
        this.loadedOnce = true;
			}, () => this.loadedOnce = true);
		}
	};

	private GetLog() {
		this.servicer.getAlarmLog(this.service.sensor.id).subscribe(result => {
			this.tableAlarms = result;
		});
	};
}
