<div id="searchBar" class="col-xs-4 noPaddingLeft">
  <label for="search">{{searchText}}</label>
  <input id="search" *ngIf="config.filtering" [placeholder]="placeholder"
        [ngTableFiltering]="config.filtering"
        class="form-control"
        (tableChanged)="onChangeTable(config)"
        style="border: 1px solid #BEBEBE !important" />
</div>
<ng-table [config]="config"
          (tableChanged)="onChangeTable(config)"
          (cellClicked)="onCellClick($event)"
          [rows]="rows"
          [columns]="columns">
</ng-table>
<pagination *ngIf="config.paging" class="pagination-sm"
            [(ngModel)]="page"
            [totalItems]="length"
            [itemsPerPage]="itemsPerPage"
            [maxSize]="maxSize"
            [boundaryLinks]="true"
            [rotate]="false"
            (pageChanged)="onChangeTable(config, $event)"
            (numPages)="numPages = $event"
            [customPreviousTemplate]="prevTemplate"
            [customNextTemplate]="nextTemplate"
            [customFirstTemplate]="firstTemplate"
            [customLastTemplate]="lastTemplate">
</pagination>
<div *ngIf="config.paging" class="btn-group paddingTopXMedium selected-btn" style="float: right" data-toggle="buttons">
  <label id="radio5" class="btn btn-default btn-sm btn-table-amount" (click)="changeTableSize(5)">
    <input type="radio" name="options" id="option1"> 5
  </label>
  <label id="radio10" class="btn btn-default btn-sm btn-table-amount active" (click)="changeTableSize(10)">
    <input type="radio" name="options" id="option2" checked> 10
  </label>
  <label id="radio15" class="btn btn-default btn-sm btn-table-amount" (click)="changeTableSize(15)">
    <input type="radio" name="options" id="option3"> 15
  </label>
  <label id="radio25" class="btn btn-default btn-sm btn-table-amount" (click)="changeTableSize(25)">
    <input type="radio" name="options" id="option4"> 25
  </label>
  <label id="radioInfinite" class="btn btn-default btn-sm btn-table-amount" (click)="changeTableSize(9999)">
    <input type="radio" name="options" id="option5"> {{allText}}
  </label>
</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class="icon icon_2x  icon-chevron-right"></i>
</ng-template>
 
<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class="icon icon_2x  icon-chevron-left"></i>
</ng-template>
 
<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class="icon icon_2x  icon-chevron-right"></i><i class="icon icon_double icon-chevron-right"></i>
</ng-template>
 
<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class="icon icon_2x  icon-chevron-left"></i><i class="icon icon_double icon-chevron-left"></i>
</ng-template>
