import { Component, Input } from '@angular/core';

import { GlobalService } from './services/global.service';


/**
 * TODO: note to self: for some reason, this object keeps getting reset, so it doesn't work properly... quick fix => hard coded stuff
 * **/

@Component({
	selector: 'headerItem',
	templateUrl: './header.html',
	//styleUrls: [],
})
export class HeaderComponent {
	private _autohide: any;
	private _isClosed: boolean;
	private array: string[];

	@Input('isClosed')
	get isClosed() :boolean { return this.glService.menuToggled.isToggled; };

	@Input('autohideB')
	get autohideB(): any {
		return this._autohide;
	}
	set autohideB(value: any) {
		this._autohide = value;
		this.hideOnCheck();
	}

	public hideOn: string = '';

	public animated: boolean = false;

	constructor(public glService: GlobalService) {
		setTimeout(() => {
				this.animated = true;
			}, 500);
	};

	hideOnCheck() {
		var result = 'row';

		if (this.autohideB != null && this.autohideB.length > 0) {
			this.array = [];

			if (typeof this.autohideB == 'string') {
				this.array = this.autohideB.split(',');

			}
			else if (this.autohideB instanceof Array) {
				this.array = this.autohideB;
			}

			if (this.array != null) {
				for (var i = 0; i < this.array.length; i++) {
					switch (this.array[i].trim()) {
						case 'xsmall':
							result += ' hxs';
							break;
						case 'small':
							result += ' hsm';
							break;
						case 'medium':
							result += ' hmd';
							break;
						case 'large':
							result += ' hlg';
							break;
					}
				}
			}
		}

		this.hideOn = result.trim();
	};

	isToggle(type: string) {
		if (this.array != null) {
			for (var i in this.array) {
				if (this.array[i].trim() == type) {
					return true;
				}
			}
		}

		return false;
	};
}
