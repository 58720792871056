import { GlobalService } from '../services/global.service';

import { hcOverride } from '../modules/highchart/highchart.override';

import { wbcUrlLocation } from '../modules/webbeat/classes/wbcUrlLocation.class';
import { wbcAny } from '../modules/webbeat/classes/wbcAny.class';

import { LANGUAGE } from '../language';


export class Page {
	public get isScreenXS(): boolean { return this.glService.isScreenXS; }
	public get isScreenSM(): boolean { return this.glService.isScreenSM; }
	public get isScreenMD(): boolean { return this.glService.isScreenMD; }
	public get isScreenLG(): boolean { return this.glService.isScreenLG; }

	protected glService: GlobalService;
	protected preloadTimer: number = null;

	constructor(glService: GlobalService, pageId: string) {
		this.glService = glService;

		this.SetTitle('');
		this.glService.mainButton = null;
		this.glService.secondButton = null;
		this.glService.crumble = new wbcUrlLocation(pageId);
	}

	public GetText(key: string, lang?: string): string {
		return LANGUAGE.GetText(key, lang);
	}

	public SetTitle(title: string) {
		this.glService.title = new wbcAny(title);
	}
}