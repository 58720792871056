<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_orange"></div>
			<h2>{{GetText('common.employees')}}</h2>
			<p class="color_orange">{{GetText('employee.index_subtitle_employee')}}</p>
		</div>
		<div block-body>
      <employee-list [employees]="employees"></employee-list>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>
</div>
