import { Team } from 'app/classes/team.class';
import { Group } from './group.class';

export class Employee {
	public id: number;
	public externalId: string;
	public email: string;
  public name: string;
  public teams: Team[];
  public language: string;
  public role: number;
	public isActive: boolean;
	public groups: Group[]; // This is an outdated member that is in the process of being deleted from the API
}

export class EmployeePOST {
	public id?: number = 0;
	public email: string = '';
  public name: string = '';
  public language: string = 'en'; // Sets default language for new user
  public teamIds: number[] = [];
  public role: number = 10;

  constructor(employee?: Employee) {
    if (employee) {
      this.id = employee.id;
      this.email = employee.email;
      this.name = employee.name;
      this.language = employee.language;
      this.teamIds = employee.teams.map(team => team.id);
      this.role = employee.role;
    }
  }
}
