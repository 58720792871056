import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AlarmLog } from '../classes/alarm.class';
import { AlarmService } from '../services/alarm.service';


@Injectable()
export class AlarmHistoryResolver implements Resolve<any> {
	constructor(
		private service: AlarmService
	) { }

	resolve(snapshot: ActivatedRouteSnapshot): any {
		let endTime = snapshot.params['endTime'];
		let startTime = new Date(endTime);
		
		startTime.setDate(startTime.getDate() - 1);

		return this.service.getAlarmLogs(startTime.toISOString(), endTime);
	}
}