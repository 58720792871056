export class wbcNumberText {
	public get number() :number { return parseInt(this._value.replace(/[^\d]/g, '')); }
	public set number(value: number) { this._value = '' + value; this.update(); }

	public get value() :string { return this._value; }
	public set value(value: string) { this._value = value; this.update(); }


	private id?: number;
	private updater?: Function;

	private _value: string;


	constructor(value: string, updateFunction?: Function, id?: number) {
		this.value = value;
		this.id = id;
		this.updater = updateFunction;
	}


	public update() {
		if (this.updater != undefined) {
			this.updater(this.id);
		}
	}

	public Dupe(value: string) :wbcNumberText {
		return new wbcNumberText(value, this.updater, this.id);
	}

	public toString() :string {
		return this.value;
	}
}