export class Info {
	public title: string;
	public subtitle: string;
	public blocks: InfoBlock[];


	constructor() {
		this.blocks = [];	
	}
}

export class InfoBlock {
	public lines: InfoLine[];
	public icon: string;
	public column: number;
	public innerBlocks: InfoBlock[];
	public border: boolean;


	constructor(column: number = 12, border: boolean = false) {
		this.column = column;
		this.lines = [];
		this.innerBlocks = [];
		this.border = border;
	}
}

export class InfoLine {
	public column: number;
	public spans: InfoSpan[];


	constructor(column: number = 12, spans?: InfoSpan[]) {
		this.column = column;
		this.spans = spans;
	}
}

export class InfoSpan {
	public text: string;
	public type: string;
	public extra: string;


	constructor(text?: string, type: string = 'text', extra: string = '') {
		this.text = text;
		this.type = type;
		this.extra = extra;
	}
}