import { Component, Input } from '@angular/core';
import { wbcToggler } from '../../../classes/wbcToggler.class';


@Component({
	selector: 'wb-modal-basic',
	templateUrl: './modal-basic.html',
	styleUrls: [
		'./modal-basic.css'
	],
})
export class wbModalBasicComponent {
	@Input() width?: number;
	@Input() widthXS?: number;
	@Input() widthSM?: number;
	@Input() widthMD?: number;
	@Input() widthLG?: number;

	@Input() offset?: number;
	@Input() offsetXS?: number;
	@Input() offsetSM?: number;
	@Input() offsetMD?: number;
	@Input() offsetLG?: number;

	//@Input('show')
	//get show() :boolean { return this.isVisible; };
	//set show(value: boolean) { if (value == true) { this.isVisible = value; } };

	@Input() show: wbcToggler;


	private isVisible: boolean;


	constructor() {
		this.show = new wbcToggler(false);
	};


	public GetOffset(): string {
		if (this.offset != null) {
			return 'col-xs-offset-' + this.offset + ' col-sm-offset-' + this.offset + ' col-md-offset-' + this.offset + ' col-lg-offset-' + this.offset;
		}
		
		var result = '';

		if (this.offsetXS != null) {
			result += ' col-xs-offset-' + this.offsetXS;
		}
		if (this.offsetSM != null) {
			result += ' col-sm-offset-' + this.offsetSM;
		}
		if (this.offsetMD != null) {
			result += ' col-md-offset-' + this.offsetMD;
		}
		if (this.offsetLG != null) {
			result += ' col-lg-offset-' + this.offsetLG;
		}

		return result;
	};

	public GetWidth(): string {
		if (this.width != null) {
			return 'col-xs-' + this.width + ' col-sm-' + this.width + ' col-md-' + this.width + ' col-lg-' + this.width;
		}
		
		var result = '';

		if (this.widthXS != null) {
			result += ' col-xs-' + this.widthXS;
		}
		if (this.widthSM != null) {
			result += ' col-sm-' + this.widthSM;
		}
		if (this.widthMD != null) {
			result += ' col-md-' + this.widthMD;
		}
		if (this.widthLG != null) {
			result += ' col-lg-' + this.widthLG;
		}

		return result;
	};

	public closeModal() {
		this.show = new wbcToggler(false);
	};
}