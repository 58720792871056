import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { GlobalService } from '../services/global.service';

import { Page } from './pages.parent';

import { SETTINGS } from '../settings';


@Component({
	selector: 'page-home',
	template: `
	<div id="page">
		<a [href]="url">log in</a>
	</div>`,
	//styleUrls: [
	//]
})
export class PageHomeComponent extends Page {
	public get url() :string { return SETTINGS.LOGIN_URL; }

	constructor(
			private route: ActivatedRoute,
			private router: Router,
			glService: GlobalService){
		super(glService, 'Home');

		this.SetTitle('Home');


		//https://eu6.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG98_Psg5cppyaq_hB.u.iCNE4lcK1pjqNulStPUM9du9XBRxZh2jJZAK1K3mB8AdD3KXs1RVZntXIqnEpo&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&display=page&immediate=False&scope=
	}
}