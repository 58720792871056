import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { AccountService } from '../services/account.service';
import { Page } from './pages.parent';

@Component({
  selector: 'page-logout',
  templateUrl: './logout.html',
})
export class PageLogoutComponent extends Page implements OnInit {
  constructor(private _glService: GlobalService, private _accountService: AccountService) {
    super(_glService, 'Logout');
  }
  ngOnInit(): void {
    this.SetTitle(this.GetText('common.log_out'));
    this._accountService.clearLocalStorage();
    this._glService.menuHide = true;
  }
}
