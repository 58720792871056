import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { wbcMenuButton } from '../../../classes/wbcButton.class';

@Component({
	selector: 'wb-actionButton',
	templateUrl: './actionButton.html',
	styleUrls: [
		'./actionButton.css'
	],
})
export class wbActionButtonComponent {
	@Input() button: wbcMenuButton;

	//public get isDisabled() :boolean { if (this.button == null || this.button.isDisabled == null) return false; else return this.button.isDisabled.isToggled; }

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		//private ref: ChangeDetectorRef
	) {

	}

	public get isLink() :boolean {
		if (this.button.url != null && this.button.url != '') {
			return true;
		}

		return false;
	}

	public get exists() :boolean {
		if (this.button != null && this.button.text != null && this.button.text != '') {
			return true;
		}

		return false;
	}

	clicked() {
		if (!this.button.isDisabled.isToggled) {
			if (this.button.action != null && typeof(this.button.action) == 'function') {
				this.button.action();
			}
		}
	}

	getLink() :string {
		if (this.isLink) {
			return this.button.url;
		}
		return '';
	}

	isDisabled() :boolean {
		if (this.button == null || this.button.isDisabled == null)
			return false;
		else
			return this.button.isDisabled.isToggled;
	}
}