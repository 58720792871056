<div id="ngContent">
	<div #chartTarget></div>
	<div class="legend-container">
		<ul>
			<li class="color-one">
				<span>{{GetText('common.on')}}</span>
			</li>
			<li class="color-two">
				<span>{{GetText('common.off')}}</span>
			</li>
			<li class="color-three">
				<span>{{GetText('common.inactive_graph')}}</span>
			</li>
		</ul>
	</div>
</div>