import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { WebbeatModule } from '../../modules/webbeat/webbeat.module';
import { HighchartModule } from '../../modules/highchart/highchart.module';
import { ServicesResolver } from '../../resolvers/services-resolver.service';
import { wbLoginGuard } from '../../guards/login-guard.service';
import { ContentModule } from '../../content/content.module';
import { PageServicesIndexComponent } from './index/services-index.component';
import { GraphsModule } from '../../content/graphs/graphs.module';
import { UserRolesResolver } from '../../resolvers/userRoles-resolver';
import { HttpClientModule } from '@angular/common/http';
import { TeamLeadGuard } from 'app/authorization/guards/team-lead-guard.service';

@NgModule({
  imports: [
    WebbeatModule,
    RouterModule.forChild([
      {
        path: 'services',
        data: { loadTesting: true },
        canActivate: [wbLoginGuard],
        component: PageServicesIndexComponent,
      },
    ]),
    HighchartModule,
    SharedModule,
    ContentModule,
    GraphsModule,
    HttpClientModule,
  ],
  declarations: [PageServicesIndexComponent],
  providers: [ServicesResolver, wbLoginGuard, TeamLeadGuard, UserRolesResolver],
  exports: [],
})
export class PageServicesModule {}
