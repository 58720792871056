import { Component, Input, OnInit } from '@angular/core';
import { BaseForm } from '../classes/iforms.interface';
import { EmployeePOST } from './../classes/employee.class';
import { Team } from 'app/classes/team.class';
import { GlobalService } from 'app/services/global.service';
import { RoleService } from 'app/authorization/services/role.service';

@Component({
  selector: 'employee-form',
  templateUrl: './employee-form.html',
  styleUrls: ['./forms.css', './employee-form.css']
})
export class EmployeeFormComponent extends BaseForm implements OnInit {
  @Input() model: EmployeePOST;
  @Input() employeeTeams: Team[];
  @Input() submitted?: Function;
  @Input() violations?;

  public disableForm: boolean = false;
  public isManufacturer: boolean;

  constructor(public glService: GlobalService, private roleService: RoleService) {
    super();
  }


  formUpdate(): void {
    if (this.submitted != null && this.submitted != undefined) {
      this.submitted(this.model);
    }
  }

  ngOnInit() {
    this.roleService.isManufacturer().subscribe(res => {
      this.isManufacturer = res;
      if (this.isManufacturer && this.glService.letManufacturerCreateUsers) {
        this.model.role = 20;
      }
      if (this.isManufacturer && this.glService.letManufacturerEditUsers) {
        this.disableForm = true;
      }
    });
  }
}
