export class wbcBasicSubFilter {
	public property: string;
	public value: any;
	public canBeEmpty?: boolean = true;
	public matchEntire?: boolean = false;
	public showEmptyFilter?: boolean = true;
	public caseSensitive?: boolean = false;
}

export class wbcBasicFilter {
	public value?: any = null;
	public canBeEmpty?: boolean = true;
	public matchEntire?: boolean = false;
	public showEmptyFilter?: boolean = true;
	public caseSensitive?: boolean = false;
	public anyProperties?: string[] = [];
	public filters?: wbcBasicSubFilter[] = [];
}