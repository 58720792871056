import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BaseForm } from '../classes/iforms.interface';
import { CompanySettings } from '../classes/company.class';
import { CompanyService } from '../services/company.service';
import { wbBrowserStorage } from '../modules/webbeat/service/browserStorage.service';
import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';

@Component({
	selector: 'companysettings-form',
	templateUrl: './companysettings-form.html',
	styleUrls: ['./forms.css']
})
export class CompanySettingsFormComponent extends BaseForm {
	@Input() model: CompanySettings;
	@Input() submitted?: Function;

	@Input('submit')
	get submit(): wbcToggler { return this._submit; };
	set submit(value: wbcToggler) { var a = (this._submit != null && this._submit.isToggled); this._submit = value; if (!a && value.isToggled) { this.onSubmit(); } };

	@Input('validate')
	get validate(): wbcToggler { return this._validate; };
	set validate(value: wbcToggler) { var a = (this._validate != null && this._validate.isToggled); this._validate = value; if (!a && value.isToggled) { this.onValidate(); } };


	public get updateTrigger(): Function { return () => { this.update(); }; }


	private canSubmit: boolean;
	private submitTimer: any = null;
	private inputTimer: any = null;
	private _submit: wbcToggler;
	private _validate: wbcToggler;


	constructor(private compService: CompanyService, private _storage: wbBrowserStorage, private router: Router, private fb: FormBuilder) {
		super();

		this.canSubmit = true;

		this.submit = new wbcToggler(false);
	}

	onValidate() {
		this.submitted(null, super.onSubmit(), this.form.untouched);
		this.validate = new wbcToggler(false);
	};

	onSubmit(): boolean {
		if (this.submitted != null && this.submitted != undefined) {
			this.submitted(null, false);
		}
		if (this.canSubmit) {
			if (super.onSubmit()) {
				this.canSubmit = false;

				if (this.submitted != null && this.submitted != undefined) {
					this.submitted(this.model, true, this.form.untouched);
				}

				this.canSubmit = true;
			}
			else {
				this.submitted(null, false, this.form.untouched);
			}
		}

		return false;
	}

	update() {
		if (this.inputTimer != null) {
			clearTimeout(this.inputTimer);
		}

		this.inputTimer = setTimeout(() => {
			this.inputTimer = null;
			this.onSubmit();
		}, 1);
	};


	ngOnInit() {
		this.form = this.fb.group({
			id: this.model.id,
			alarmReminderTime: this.model.alarmReminderTime,
			alarmTakenReminderTime: this.model.alarmTakenReminderTime
		});
	}
}