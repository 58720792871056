import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Patient } from '../classes/patient.class';
import { PatientService } from '../services/patient.service';

@Injectable()
export class PatientsResolver implements Resolve<Patient[]> {
	constructor(
		private service: PatientService
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Patient[]> {
		return this.service.getPatients();
	}
}