import { Observer ,  Observable } from 'rxjs';
import { SETTINGS } from 'app/settings';
import { Injectable } from '@angular/core';
import { Alarm } from '../classes/alarm.class';
import { LayGraphData } from '../classes/layGraphData.class';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SensorService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient){
	}


	public getAlarmLog(id: number): Observable<Alarm[]> {
		return this._http.get<Alarm[]>(`${this.baseUrl}Sensor/GetAlarmLog?sensorId=${id}`);
	}

	public getGraph(sensor: number, patient?: number, length_time?: number): Observable<LayGraphData> {
		if (length_time == null || length_time < 60) {
			length_time = 60;
		}

		if (patient == null || patient < 1) {
			patient = 0;
		}

		var end = new Date();
		var start = new Date(end.getTime() - (length_time * 1000));
		return Observable.create((observer: Observer<any>) => {
			this._http.get(`${this.baseUrl}Sensor/GetData?begin=${start.toJSON()}&end=${end.toJSON()}&patientId=${patient}&sensorId=${sensor}`).subscribe((res: LayGraphData) => {
				res.start = start;
				res.end = end;
				observer.next(res);
				observer.complete();
			});
		});
	};

	public GetIndexedGraph(index: number, sensor: number, patient?: number, length_time?: number): Observable<LayGraphData> {
		if (length_time == null || length_time < 60) {
			length_time = 60;
		}

		if (patient == null || patient < 1) {
			patient = 0;
		}

		var end = new Date();
		var start = new Date(end.getTime() - (length_time * 1000));

		return Observable.create((observer: Observer<any>) => {
			this._http.get(`${this.baseUrl}Sensor/GetData?begin=${start.toJSON()}&end=${end.toJSON()}&patientId=${patient}&sensorId=${sensor}`).subscribe((res: LayGraphData) => {
				res.index = index;
				observer.next(res);
				observer.complete();
			});
		});
	};

	public AddCase(id: number, subject: string, description: string): Observable<any> {
		return this._http.post(`${this.baseUrl}Sensor/AddCase`, {
			sensorId: id,
			subject: subject,
			description: description
		});
  }

  public getStatusColors(): Observable<any> {
    return this._http.get(`${this.baseUrl}Sensor/StatusColors`);
  }
}
