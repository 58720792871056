import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SETTINGS } from 'app/settings';
import { GlobalService } from './global.service';
import { Observable, Observer, of } from 'rxjs';

@Injectable()
export class ProbeService extends APIService {
  constructor(private _http: HttpClient, private _glService: GlobalService) {
    super();
  }

  public probe(): Observable<any> {
    if (this._glService.isProbing) return of();
    this._glService.isProbing = true;
    return Observable.create((observer: Observer<any>) => {
      return this._http
        .get(SETTINGS.BASE_API_URL + 'probe', { responseType: 'text' })
        .subscribe((res) => {
          observer.next(res ? res : {});
          observer.complete();
          this._glService.isProbing = false;
        });
    });
  }
}
