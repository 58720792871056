import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { EmployeeService } from '../../services/employee.service';
import { wbcMenuButton } from '../../modules/webbeat/classes/wbcButton.class';
import { wbcToggler } from '../../modules/webbeat/classes/wbcToggler.class';
import { Employee } from '../../classes/employee.class';
import { Page } from '../pages.parent';
import { SETTINGS } from '../../settings';
import { RoleService } from 'app/authorization/services/role.service';

@Component({
	selector: 'page-employees-index',
	templateUrl: './employees-index.html',
})
export class PageEmployeesIndexComponent extends Page implements OnInit {
	public employees: Employee[];

	public employeeTogglers: wbcToggler[];
	public groupModalShown: wbcToggler;

	public canSave: wbcToggler = new wbcToggler(false);

	public formInit: wbcToggler = new wbcToggler(false);

	public formValidate: wbcToggler = new wbcToggler(false);
	public formReset: wbcToggler = new wbcToggler(false);


	public pagePristine: wbcToggler = new wbcToggler(true);

	public pristineFunc: Function = () => { this.pagePristine.isToggled = false; };

  private interval: number;
  private hasEditRights: boolean;
	public isManufacturer: boolean = true;

	constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roleService: RoleService,
		private empService: EmployeeService,
		public glService: GlobalService,
	) {
		super(glService, 'Employees');
    this.SetTitle(this.GetText('common.employees'));
	}

	ngOnInit() {
		this.isManufacturer = this.glService.isManufacturer();
		this.preloadTimer = SETTINGS.AddInterval(() => {
			if (this.glService.preload) {
				SETTINGS.ClearInterval(this.preloadTimer);

				this.route.data.subscribe((result: any) => {
					this.employees = this.OrderEmployees(result.employees);
				});

				this.interval = SETTINGS.AddInterval(() => {
					this.Refresh();
				}, 15000, this.interval);
			}
    }, 1, this.preloadTimer);
    this.roleService.isTeamLead().subscribe(isTeamLead => {
      if (isTeamLead || this.glService.letManufacturerCreateUsers) {
        this.glService.mainButton = new wbcMenuButton(this.GetText('common.add_employee'), '../employees/edit', null, false, false);
      }
    });
	};

	public hideModel(type: string) {
		switch (type) {
			default:
				this.groupModalShown = new wbcToggler(false);
				break;
		}

		this.interval = SETTINGS.AddInterval(() => {
			this.Refresh();
		}, 15000, this.interval);
	};

	public showModel(type: string, id?: number) {
		SETTINGS.ClearInterval(this.interval);

		switch (type) {
			default:
				this.pagePristine = new wbcToggler(true);
				this.formReset = new wbcToggler(true);

				setTimeout(() => {
					setTimeout(() => {
						this.formValidate = new wbcToggler(true);
					}, 0);
				}, 0);

				this.groupModalShown = new wbcToggler(true);
				break;
		}
  };

	private OrderEmployees(employees: Employee[]): Employee[] {
		let result: Employee[] = [];

		if (employees != null && employees.length > 0) {
			result = employees.sort((a: Employee, b: Employee) => {
				var aa = a.name;
				if (aa == null) { aa = ''; }
				aa = aa.toLowerCase();

				var bb = b.name;
				if (bb == null) { bb = ''; }
				bb = bb.toLowerCase();

				if (aa > bb) { return 1 }
				if (aa < bb) { return -1 }
				else {
					if (a.id > b.id) { return 1; }
					if (a.id < b.id) { return -1; }
				}
				return 0;
			});
		}

		return result;
	};

	private Refresh() {
		this.empService.getEmployees().subscribe((employees) => {
			this.employees = [];
			setTimeout(() => {
				if (employees) {
					this.employees = this.OrderEmployees(employees);
				}

			}, 250);
		})
	}

}
