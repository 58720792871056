import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { wbBrowserStorage } from './service/browserStorage.service';
import { wbSidePanelBasicComponent } from './content/sidePanel/basic/sidePanel-basic.component';
import { wbActionButtonComponent } from './content/buttons/actionButton/actionButton.component';
import { wbDropdownButtonComponent } from './content/buttons/dropdown/dropdownButton.component';
import { wbButtonTogglerComponent } from './content/buttons/toggler/button-toggler.component';
import { wbSliderTogglerComponent } from './content/buttons/toggler/slider-toggler.component';
import { wbCrumblePathBasicComponent } from './content/crumblePath/basic/crumblePath-basic.component';
import { wbGridHeaderBlocksComponent } from './content/grid/headerBlocks/grid-headerBlocks.component';
import { wbModalBasicComponent } from './content/modal/basic/modal-basic.component';
import { wbModalTimepickerDirective } from './content/modal/timepicker/modal-timepicker.directive';
import { wbTableBasicComponent } from './content/tables/basic/table-basic.component';
import { wbTableBlocksComponent } from './content/tables/blocks/table-blocks.component';
import { wbPropertyService } from './service/property.service';
import { wbNumberText } from './pipes/other/numberText/numberText.pipe';
import { wbFilterBasic } from './pipes/filters/basic/filter-basic.pipe';
import { wbSafeHtml } from './pipes/other/safeHtml/safeHtml.pipe';
import { wbDateTimeText } from './pipes/other/dateTime/dateTimeText.pipe';
import { wbTimePipe } from './pipes/other/time/time.pipe';
import { HtmlDisableSanitizerPipe } from './pipes/other/html.pipe';
import { wbSearchPipe } from './pipes/filters/any-filter.pipe';

@NgModule({
  imports: [RouterModule, CommonModule],
  declarations: [
    wbSidePanelBasicComponent,
    wbNumberText,
    wbFilterBasic,
    wbSafeHtml,
    wbDateTimeText,
    wbTimePipe,
    wbSearchPipe,
    HtmlDisableSanitizerPipe,
    wbActionButtonComponent,
    wbDropdownButtonComponent,
    wbButtonTogglerComponent,
    wbSliderTogglerComponent,
    wbCrumblePathBasicComponent,
    wbGridHeaderBlocksComponent,
    wbModalBasicComponent,
    wbModalTimepickerDirective,
    wbTableBasicComponent,
    wbTableBlocksComponent,
  ],
  bootstrap: [],
  providers: [wbBrowserStorage, wbPropertyService],
  exports: [
    CommonModule,
    wbSidePanelBasicComponent,
    wbNumberText,
    wbFilterBasic,
    wbSafeHtml,
    wbDateTimeText,
    wbTimePipe,
    HtmlDisableSanitizerPipe,
    wbSearchPipe,
    wbActionButtonComponent,
    wbDropdownButtonComponent,
    wbButtonTogglerComponent,
    wbSliderTogglerComponent,
    wbCrumblePathBasicComponent,
    wbGridHeaderBlocksComponent,
    wbModalBasicComponent,
    wbModalTimepickerDirective,
    wbTableBasicComponent,
    wbTableBlocksComponent,
  ]
})
export class WebbeatModule {}
