<div class="col-xs-12 table-style">
  <app-table [rows]="rows" [columns]="columns"
  [tableId]="tableId"
	[config]="config" [data]="data"
	[searchText]="searchText" [length]="length"
	[placeholder]="placeholderTable"
	[allText]="allText"
	(refreshTable)="updateTable($event)"
	(tableCellClicked)="onCellClick($event)"></app-table>
</div>
