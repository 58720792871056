<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
	<wb-grid-headerBlocks gridWidth="12" [hasFooter]="false">
		<div block-header>
			<div id="colorBlock" class="color_green"></div>
			<div class="row">
				<div class="col-xs-12">
					<h2>{{GetText('common.bed')}}</h2>
					<p class="color_green">{{GetText('dashboard.index_subtitle_checked')}}</p>
				</div>
			</div>
		</div>
		<div block-body>
			<bedPatient-list [patients]="patients"></bedPatient-list>
		</div>
		<div block-footer></div>
	</wb-grid-headerBlocks>
</div>