import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Version } from '../classes/version';
import { SETTINGS } from '../settings';
import { APIService } from './api.service';


@Injectable()
export class CommonService extends APIService {
	private _baseUrl = SETTINGS.BASE_API_URL
	constructor(private _http: HttpClient){
    super();
	}

	public getVersions(): Observable<Version> {
		return this._http.get<Version>(`${this._baseUrl}v1/common/version`);
	}
	
	public postBenchmarks(benchmarks: any[]): Observable<any> {
		return this._http.post(`${this._baseUrl}v1/performance`, benchmarks);
	}
}
