declare var $: any;
import { environment } from '../environments/environment';

export class SETTINGS {
	public static get USE_DEBUG_LOG(): boolean { return environment.debug_log; }

	public static get jquery(): any {
		return $;
	};

	public static get BASE_API_URL(): string {
		return environment.base_url + 'api/';
	}

	public static get BASE_URL(): string {
		return environment.base_url;
	}

  public static get LOGIN_URL(): string {
    if (environment.return_override != null && environment.return_override != '') {
    return environment.login_url + '&redirect_uri=' + encodeURIComponent(environment.return_override) + '%2Flogin&display=page&immediate=False&prompt=login&scope=';
    }

    return environment.login_url + '&redirect_uri=' + encodeURIComponent(document.location.protocol + '//' + document.location.host) + '%2Flogin&response_type=code&prompt=login&scope=openid';
  };

	public static get MAINTENANCE_URL(): string {
		return '/maintenance';
	}

	public static get API_LOG(): boolean {
		return environment.log_apis;
	}

	public static get NO_API(): boolean { return false; } /* temporary, for in case the API is not working => debug only */


	private static interval_ids: any[] = [];
	private static interval_key: number = 0;


	public static AddInterval(intervalFunc: Function, time: number, key?: number): number {
		if (intervalFunc == null || typeof intervalFunc != 'function' || time == null || time < 0) {
			return key;
		}

		if (key != null) {
			if (key >= 0) {
				for (var i in this.interval_ids) {
					if (this.interval_ids[i].key == key) {
						if (this.interval_ids[i].interval != null) {
							clearInterval(this.interval_ids[i].interval);
						}

						this.interval_ids[i].interval = setInterval(intervalFunc, time);
						return key;
					}
				}
			}

			console.warn('interval not found!');
		}
		else {
			key = this.interval_key;
			var v_interval = setInterval(intervalFunc, time);
			this.interval_ids.push({
				key: key,
				interval: v_interval
			})

			this.interval_key++;
		}
		return key;
	};

	public static ClearInterval(key: number): number {
		if (this.interval_ids != null && this.interval_ids.length > 0) {
			for (var i in this.interval_ids) {
				if (this.interval_ids[i].key == key) {
					clearInterval(this.interval_ids[i].interval);
					this.interval_ids[i].interval = null;
					return key;
				}
			}
		}

		return -1;
	};

	public static RemoveAllIntervals() {
		if (this.interval_ids != null && this.interval_ids.length > 0) {
			for (var i in this.interval_ids) {
				if (this.interval_ids[i].interval != null) {
					clearInterval(this.interval_ids[i].interval);
				}
			}
		}

		this.interval_ids = [];
	};




	public static _console_log: Function;
	public static _console_warn: Function;
	public static _console_error: Function;
	public static _console_debug: Function;
}
