import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlarmService } from '../services/alarm.service';

@Injectable()
export class PatientAlarmLogResolver implements Resolve<any> {
	constructor(
		private service: AlarmService,
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		const id = route.params['id'];
		return this.service.getPatientHistory(id);
	}
}