import { Component, Input } from '@angular/core';
import { PagingExtension } from '../classes/extensions/paging.extension';
import { GlobalService } from '../services/global.service';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../classes/employee.class';
import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';
import { Router } from '@angular/router';
import { RoleService } from 'app/authorization/services/role.service';

@Component({
	selector: 'employee-list',
	templateUrl: './employee-list.html',
	styleUrls: [
		'./employee-list.css'
	]
})
export class EmployeeListComponent extends PagingExtension {
	@Input('employees')
	public get employees(): Employee[] { return <Employee[]>this.pageItems; };
  public set employees(value: Employee[]) { this.items = value; this.Build(); }

	public employeeToggle: wbcToggler[];
	public isManufacturer: boolean = true;


	public Update(id: number) {
		this.employees[id].isActive = this.employeeToggle[id].isToggled;
    this.employeeService.toggleActive(this.employees[id].id, this.employeeToggle[id].isToggled).subscribe(() => {
    }, () => {
      this.employeeToggle[id].isToggled = !this.employeeToggle[id].isToggled;
      this.employees[id].isActive = this.employeeToggle[id].isToggled;
    });
	};

	public IsActive(id: number) {
		return this.employees[id].isActive;
  };

	constructor(private glService: GlobalService, private employeeService: EmployeeService, private router: Router, private roleService: RoleService) {
		super(12);
		this.isManufacturer = this.glService.isManufacturer();
		this.employeeToggle = [];
		this.onUpdate = () => { this.buildTogglers() };
	};


	private buildTogglers() {
		this.employeeToggle = [];

		for (var i = 0; i < this.employees.length; i++) {
			this.employeeToggle.push(new wbcToggler(this.employees[i].isActive, (id: number) => { this.Update(id); }, i, this.isManufacturer));
		}
  };

  public onEmployeeClick(employee) {
    this.roleService.isTeamLead().subscribe(isTeamLead => {
      if (isTeamLead || this.glService.letManufacturerEditUsers) {
        this.router.navigateByUrl(`/employees/edit/${employee.id}`);
      }
    });
  }
}
