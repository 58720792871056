import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Page } from '../pages.parent';
import { DisconnectedDevice } from 'app/classes/disconnectedDevice';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'devices-disconnected',
	templateUrl: './devices-disconnected.html',
	styleUrls: ['./devices-disconnected.component.css']
})
export class PageDeviceDisconnectedComponent extends Page implements OnInit {
	public rows: Array<any> = [];
	public columns: any = [
		{ title: this.GetText('device.disconnected.table.device_key'), name: 'deviceKey', className: '', sort: 'asc' },
		{ title: this.GetText('device.disconnected.table.client'), name: 'client', className: '', sort: 'asc' },
		{ title: this.GetText('device.disconnected.table.disconnect_time'), name: 'disconnectTime', className: '', sort: 'asc' },
		{ title: this.GetText('device.disconnected.table.disconnect_duration'), name: 'disconnectDuration', className: '' ,sort: 'asc' }
	];
	public data: Array<any> = [];
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: '' },
		className: ['table-hover']
	};
	public length = 0;
	public searchText = this.GetText('device.disconnected.table.search_bar');
	public placeholderTable = this.GetText('patient.index_table_search_text');
	public allText = this.GetText('patient.index_table_all');
	public devices: Array<DisconnectedDevice> = new Array<DisconnectedDevice>();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private datePipe: DatePipe,
		glService: GlobalService,
	) {
		super(glService, 'Services');

		this.SetTitle(this.GetText('device.disconnected.title'));
	};


	ngOnInit() {
		this.route.data.subscribe((result: any) => {
			this.devices = result.devices;
			if (this.devices.length === 0) {
				this.router.navigateByUrl('/dashboard');
			} else {
				this.enrichTable(this.devices);
			}
		});
	};

	public onCellClick(data: any): any {
		// const id = data.row.name.split('id="')[1].split('"')[0];
		// this.router.navigateByUrl('/patients/' + id);
	}

	public updateTable(event: any): void {
	}

	private calculateDateDifference(date: Date): number {
		return Math.round((((new Date()).valueOf() - (new Date(date)).valueOf()) / 1000) / 60)
	}

	private enrichTable(response: Array<DisconnectedDevice>): void {
		response.forEach(element => {
			this.data.push({
				client: (element.patient ? element.patient.patientDisplayName : 'geen cliënt naam'),
				deviceKey: element.deviceKey,
				disconnectTime: this.datePipe.transform(element.disconnectedOn, 'dd/MM/yyyy HH:mm:ss'),
				disconnectDuration: this.calculateDateDifference(element.disconnectedOn) + ' ' + this.GetText('device.disconnected.table.minute')
			});

		});
	}
}