import { wbcToggler } from './wbcToggler.class';

export class wbcMenuButton {
	public text :string;
	public url :string;
	public action :Function;
	public type :string;
	public noButton: boolean;


	public get isDisabled() :wbcToggler { return this._isDisabled; };
	public set isDisabled(value: wbcToggler) {
		this._isDisabled = value;
	};


	private _isDisabled: wbcToggler;


	//constructor(text: string, action: string | Function);
	constructor(text: string, action: string | Function, type?: string, noButton?: boolean, disabled?: boolean) {
		this.text = text;
		this.type = type || 'primary';

		if (typeof(action) == 'function') {
			this.action = action;
		}
		else {
			this.url = action;
		}

		this.noButton = noButton == true;

		this._isDisabled = new wbcToggler(disabled == true);
	}
}