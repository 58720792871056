import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { wbBrowserStorage } from '../modules/webbeat/service/browserStorage.service';
import { SETTINGS } from '../settings';

@Injectable()
export class wbLoginGuard implements CanActivate {
	constructor(private _router: Router, private _storage: wbBrowserStorage) {
	}

	canActivate(): boolean {
		var token = this._storage.getStorageItem('token');
		if (token == null || token == '') {
			window.location.href = SETTINGS.LOGIN_URL;
			return false;
		}
		return true;
	};

	CanDeactivate(): boolean {
		this._storage.clearStorage();
		return true;
	};
}

@Injectable()
export class wbLogoutGuard implements CanActivate {
	constructor(private _storage: wbBrowserStorage) {
	}

	canActivate(): boolean {
		this._storage.clearStorage();
		return true;
	};
}
