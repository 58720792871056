<div>
	<table class="paged">
		<tbody>
			<tr *ngFor="let alarm of alarms" [attr.data-alarm-id] = "alarm?.alarm?.id">
				<td class="col-xs-8">
					<div class="ng_top">
						<span>
							<a routerLink="/patients/{{alarm.patient.id}}" class="invisible_link">{{alarm.patient.displayName}}</a>
						</span>
					</div>
					<div class="ng_bottom">
						<span>{{alarm.alarm.name}} - {{alarm.alarm.created | dateTimeText}}</span>
						<span *ngIf="HasRoom(alarm.patient.room)">, {{alarm.patient.room}}</span>

						<!--<div>
							<span [ngStyle]="{'color': subLogColor}">{{alarm.subLogText}}</span>
						</div>-->
						<div *ngIf="!IsEmpty(alarm.subLogText)" [ngStyle]="{'color': alarm.subLogColor}">
							<span>{{alarm.subLogText | rename:toLocal(alarm.created)}}</span>
						</div>
						


						<!--<div *ngIf="alarm.alarm.pickedUp">
							<span style="color: #EDB13D;">{{GetText('common.picked_up')}}: {{alarm.takenUser.name}}</span>
						</div>-->
					</div>
				</td>
				<td class="col-xs-4">
					<div class="alarm_icon" [style.border-color]="'#' + alarm.alarmTrigger.color" [style.color]="'#' + alarm.alarmTrigger.color">
						<div class="icon" [ngClass]="GetIcon(alarm.alarmTrigger.icon)"></div>
						<div>{{alarm.alarmTrigger.value}}</div>
						<div>{{alarm.alarmTrigger.triggerTimeout}}</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="paging">
	<div class="icon icon-chevron-left" [ngClass]="{ 'active': canGoToPreviousPage }" (click)="GoToPreviousPage()"></div>
	<div>{{pageStart}} - {{pageEnd}} {{GetText('content.base.page_of')}} {{totalSize}}</div>
	<div class="icon icon-chevron-right" [ngClass]="{ 'active': canGoToNextPage }" (click)="GoToNextPage()"></div>
</div>