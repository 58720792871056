import { NgModule } from '@angular/core';

import { hcHighChartService } from './services/highchart.service';

import { hcChartBaseComponent } from './charts/base/chart-base.component';
import { hcChartSplineComponent } from './charts/spline/chart-spline.component';

import { ServicesModule } from '../../services/services.module';

@NgModule({
	imports: [
		ServicesModule
	],
	declarations: [
		hcChartBaseComponent,
		hcChartSplineComponent,
	],
	bootstrap: [
	],
	providers: [
		hcHighChartService,
	],
	exports: [
		hcChartBaseComponent,
		hcChartSplineComponent,
	]
})
export class HighchartModule { }



/* ENUMS */

