import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PagingExtension } from '../classes/extensions/paging.extension';
import { GlobalService } from '../services/global.service';
import { AlarmService } from '../services/alarm.service';
import { AlarmTrigger } from '../classes/alarm.class';
import { wbcToggler } from '../modules/webbeat/classes/wbcToggler.class';

@Component({
	selector: 'alarmTrigger-list',
	templateUrl: './alarmTrigger-list.html',
	styleUrls: [
		'./alarm-list.css'
	]
})
export class AlarmTriggerListComponent extends PagingExtension {
	@Input('alarmtriggers')
	public get alarmtriggers() :AlarmTrigger[] { return <AlarmTrigger[]>this.pageItems; };
  public set alarmtriggers(value: AlarmTrigger[]) { this.items = value; this.Build(); this.buildMutes(); }

  @Input('disabled') disabled = null;

  @Output() onChangeTrigger = new EventEmitter<AlarmTrigger>();

	public alarmActives: wbcToggler[];


	constructor(public glService: GlobalService, private _alarmService: AlarmService) {
		super();
	};


	public GetIcon(icon: string) {
		return this.glService.GetIconClass(icon);
	};

	public toggleActive(i: number) {
		//if (i != null && i >= 0 && i < this.alarmActives.length) {
		//}
	}

	public update(id?: number) {
		if (id != null) {
			this._alarmService.toggleMute(this.alarmtriggers[id].alarmId, !this.alarmActives[id].isToggled).subscribe(() => {
        this.alarmtriggers[id].muted = !this.alarmActives[id].isToggled;
        this.onChangeTrigger.emit(this.alarmtriggers[id]);
			});
		}
	};


	private buildMutes() {
		this.alarmActives = [];
    if (this.alarmtriggers) {
      for (var i = 0; i < this.alarmtriggers.length; i ++) {
        this.alarmActives.push(new wbcToggler(!this.alarmtriggers[i].muted, (id?: number)=>{ this.update(id); }, i, this.glService.isManufacturer()));
      }
    }
	};
}
