import { Pipe, PipeTransform } from '@angular/core'


@Pipe({ name: 'dateTimeText' })
export class wbDateTimeText implements PipeTransform {
	constructor() { }
	transform(value?: string) {
		if (value == null || value == '') {
			return '';
		}

		var date = new Date(value);

		var ye = date.getFullYear();
		var mo = date.getMonth() + 1;
		var da = date.getDate();
		var ho = date.getHours();
		var mi = date.getMinutes();
		//var se = date.getSeconds();

		return (da <= 9 ? '0' + da : da) + '-' + (mo <= 9 ? '0' + mo : mo) + '-' + (ye <= 9 ? '0' + ye : ye) + ' ' +
			(ho <= 9 ? '0' + ho : ho) + ':' + (mi <= 9 ? '0' + mi : mi);// + ':' + (se <= 9 ? '0' + se : se);
	}
}