import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ServiceListResult } from '../classes/results.class';
import { ServiceService } from '../services/service.service';
import { Observable } from 'rxjs';


@Injectable()
export class ServicesResolver implements Resolve<ServiceListResult[]> {
	constructor(
		private service: ServiceService,
		private router: Router
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceListResult[]> {
		return this.service.getServices();
	}
}