import { PatientBiometrics } from './../../classes/patient-biometrics.class';
import { DeviceService } from '../../services/device.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { AlarmService } from '../../services/alarm.service';
import { PatientService } from '../../services/patient.service';
import { SensorService } from '../../services/sensor.service';
import { wbcMenuButton } from '../../modules/webbeat/classes/wbcButton.class';
import { wbcUrlLocation } from '../../modules/webbeat/classes/wbcUrlLocation.class';
import { wbcToggler } from '../../modules/webbeat/classes/wbcToggler.class';
import { wbcOption } from '../../modules/webbeat/classes/wbcOption.class';
import { wbcAny } from '../../modules/webbeat/classes/wbcAny.class';
import { Patient } from '../../classes/patient.class';
import { Alarm, AlarmTrigger, AlarmTriggerRange } from '../../classes/alarm.class';
import { ServiceListResult } from '../../classes/results.class';
import { Page } from '../pages.parent';
import { LayGraphData } from '../../classes/layGraphData.class';
import { SETTINGS } from '../../settings';
import { ToastrFactoryService } from '../../services/toaster.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { LoadTestingService } from 'app/services/loadtesting.service';
import * as moment from 'moment';

@Component({
  selector: 'page-patients-detail',
  templateUrl: './patients-detail.html',
})
export class PagePatientsDetailComponent extends Page implements OnInit {
  public patient: Patient;
  public services: ServiceListResult[];
  public alarms: Alarm[];
  public alarmTriggers: AlarmTrigger[];
  public graphData: LayGraphData;
  public deviceGraphData: any;
  public dropdownOptions: wbcOption[];
  public graphOption: wbcAny;
  public _dropdownOverride?: number = null;
  public forceBuild: wbcToggler;
  public caseModalVisible: wbcToggler = new wbcToggler(false);
  public caseModalComment: string;
  public showZoom = true;
  public maxDate = new Date();
  public startDate = new Date();
  public startTime = new Date();
  public endDate = new Date();
  public endTime = new Date();
  public withFilterTime = false;
  public graphAlarmData;
  public patientWeight: string;

  public patientMute: wbcToggler;
  public showAlarms: wbcToggler;
  public showAnalyzeGraph: wbcToggler;

  public get bed(): string {
    if (this.services != null && this.services.length > 0) {
      return this.services[0].service.name;
    }

    return this.GetText('common.unknown');
  }
  public isManufacturer: boolean = true;
  public isSubmittingCase: boolean = false;

  private locale = 'nl';
  private interval: number;
  private timer: any;
  private canBuild: boolean = true;

  get isDoneLoading() {
    return (
      this.patient &&
      this.services &&
      this.alarms &&
      this.graphData &&
      this.deviceGraphData &&
      this.graphAlarmData &&
      this.patientWeight
    );
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    glService: GlobalService,
    private patientService: PatientService,
    private alarmService: AlarmService,
    private sensorService: SensorService,
    private toastrFactory: ToastrFactoryService,
    private localeService: BsLocaleService,
    private deviceService: DeviceService,
    private _loadTestingService: LoadTestingService
  ) {
    super(glService, 'PatientsDetail');

    this.showAnalyzeGraph = new wbcToggler(false);
    this.patientMute = new wbcToggler(true);
    this.showAlarms = new wbcToggler(true);

    this.dropdownOptions = [
      new wbcOption(new wbcAny(3600), this.GetText('content.graph.dropdown.last_hour')),
      new wbcOption(new wbcAny(43200), this.GetText('content.graph.dropdown.last_12_hours')),
      new wbcOption(new wbcAny(86400), this.GetText('content.graph.dropdown.last_24_hours')),
      new wbcOption(new wbcAny(604800), this.GetText('content.graph.dropdown.last_7_days')),
    ];
    this.glService.secondButton = new wbcMenuButton(
      this.GetText('patient.detail_button_case'),
      () => {
        this.ShowModal('case');
      },
      null,
      false,
      true
    );

    this.graphOption = new wbcAny(
      43200,
      () => {
        this.ChooseTime();
      },
      0
    );
    setTimeout(() => {
      this._dropdownOverride = 43200;
    }, 10);
  }

  ngOnInit() {
    // Load Testing
    if (this._loadTestingService.loadTestingEnabled) {
      this._watchDataLoaded().subscribe();
    }

    this.isManufacturer = this.glService.isManufacturer();
    this.glService.secondButton.isDisabled.isToggled = this.isManufacturer;
    this.patientMute.isDisabled = this.isManufacturer;

    this.localeService.use(this.locale);
    this.preloadTimer = SETTINGS.AddInterval(
      () => {
        if (this.glService.preload) {
          SETTINGS.ClearInterval(this.preloadTimer);

          this.route.data.subscribe((result: any) => {
            this.patientService
              .getPatientWeight(result.patient.patient.id)
              .subscribe((data: PatientBiometrics[]) => {
                if (data && data[0] && data[0].reading.toLowerCase() === 'weight') {
                  this.patientWeight = data[0].value;
                }
              });
            this.SetPatientAlarmServiceData(
              result.patient.patient,
              result.patient.alarmTriggers,
              result.patient.services
            );
            this.glService.mainButton = new wbcMenuButton(
              this.GetText('patient.detail_button_main'),
              '../patients/edit/' + this.patient.id
            );
            this.alarms = result.alarmLog;
            this.StartInterval();
            this.glService.crumble = new wbcUrlLocation(
              'Patients',
              this.GetText('common.patients'),
              '../patients',
              new wbcUrlLocation('Patients_detail', this.patient.displayName)
            );
          });
        }
      },
      1,
      this.preloadTimer
    );
  }

  public toggleAnalyzeGraph(): void {
    SETTINGS.ClearInterval(this.interval);
    this.StartInterval(this.withFilterTime, true);
  }

  public filterGraph(): void {
    this.withFilterTime = true;
    SETTINGS.ClearInterval(this.interval);
    this.canBuild = false;
    setTimeout(() => {
      this.graphData = null;
    }, 0);

    if (this.timer != null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.canBuild = true;
      this.timer = null;
      this.StartInterval(true, true);
    }, 50);
  }

  public resetWithFilterTime(): void {
    this.withFilterTime = false;
  }

  public triggerAlarmUpdate(): void {
    if (this.glService.preload) {
      SETTINGS.ClearInterval(this.preloadTimer);

      this.StartInterval(this.withFilterTime, false);
    }
  }

  public ChooseTime() {
    SETTINGS.ClearInterval(this.interval);
    this.canBuild = false;
    setTimeout(() => {
      this.graphData = null;
    }, 0);

    if (this.timer != null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.canBuild = true;
      this.timer = null;
      this.StartInterval(false, false);
    }, 50);
  }

  public toggleMutePatient(): void {
    if (!this.isManufacturer) {
      var isMuted = !this.patientMute.isToggled; //is reversed
      this.alarmService.toggleMuteAll(this.patient.id, isMuted).subscribe(() => {
        this.alarmTriggers.forEach((alarm) => {
          alarm.muted = isMuted;
          alarm.alarmStatus = 70;
        });
        this.GetPatientAlarmServiceData();
      });
    }
  }

  public GetGraphData(withTime?: boolean) {
    if (this.services != null && this.services.length > 0 && this.services[0].sensor != null) {
      if (withTime === true) {
        this.patientService
          .getGraph(this.patient.id, this.services[0].sensor.id, null, true, moment(this.startDate).utc(false).format(), moment(this.endDate).utc(false).format())
          .subscribe((result) => {
            if (this.canBuild) {
              this.graphData = result;
            }
          });
      } else {
        this.patientService
          .getGraph(
            this.patient.id,
            this.services[0].sensor.id,
            this.graphOption.value
          )
          .subscribe((result) => {
            if (this.canBuild) {
              this.graphData = result;
            }
          });
      }
    } else {
      this.graphData = new LayGraphData();

      SETTINGS.ClearInterval(this.interval);
    }
  }

  public getDeviceGraphData(dropdownTime: boolean) {
    if (this.patient && this.patient.device) {
      let start = moment(this.startDate).utc(false).format();
      let end = moment(this.endDate).utc(false).format();

      if (dropdownTime) {
        start = new Date(new Date().getTime() - this.graphOption.value * 1000).toUTCString();
        end = new Date().toUTCString();
      }

      this.deviceService
        .getDeviceConnectionLogs(this.patient.device.id, start, end)
        .subscribe((result) => {
          this.deviceGraphData = result;
        });
    }
  }

  public getMuteGraphData(dropdownTime: boolean) {
    let start = this.returnDateAndTime(this.startDate, this.startTime);
    let end = this.returnDateAndTime(this.endDate, this.endTime);
    if (dropdownTime) {
      start = new Date(new Date().getTime() - this.graphOption.value * 1000).toUTCString();
      end = new Date().toUTCString();
    }

    this.alarmService.getAlarmMuteLogsGraph(this.patient.id, start, end).subscribe((result) => {
      this.graphAlarmData = result;
    });
  }

  public GetAlarmHistory() {
    this.alarmService.getPatientHistory(this.patient.id).subscribe((alarms) => {
      this.alarms = alarms;
    });
  }

  public ShowModal(type: string) {
    switch (type) {
      default:
        if (this.services != null && this.services.length > 0) {
          this.caseModalVisible = new wbcToggler(true);
        }
        break;
    }
  }

  public HideModal(type: string) {
    switch (type) {
      default:
        this.caseModalVisible = new wbcToggler(false);
        this.caseModalComment = '';
        break;
    }
  }

  public SubmitCase() {
    var message = this.caseModalComment;
    if (this.isSubmittingCase !== true) {
      this.isSubmittingCase = true;
      this.sensorService
        .AddCase(this.services[0].sensor.id, this.GetText('content.cases.malfunction'), message)
        .subscribe(
          () => {
            this.HideModal('case');
            this.toastrFactory.createToast(
              'success',
              this.GetText('messages.reportedcase_success'),
              this.GetText('messages.titles.success')
            );
            this.isSubmittingCase = false;
          },
          () => {
            this.toastrFactory.createToast(
              'error',
              this.GetText('messages.reportedcase_error'),
              this.GetText('messages.titles.error')
            );
            this.isSubmittingCase = false;
          }
        );
    }
  }

  public resetFilters(): void {
    this.withFilterTime = false;
    this.startDate = moment().startOf('day').toDate();
    this.endDate = moment().endOf('day').toDate();
    this.StartInterval(this.withFilterTime);
  }

  private returnDateAndTime(date: Date, time: Date): string {
    var dt = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    );
    return dt.toUTCString();
  }

  // Loops through the alarmtriggers to get the time ranges for these alarms
  private setTimeRangesAlarms(): void {
    this.alarmTriggers.forEach((alarm) => {
      this.alarmService.getPatientAlarmTriggerRange(alarm.alarmId).subscribe(
        (response: Array<AlarmTriggerRange>) => {
          alarm.ranges = response;
        },
        (error) => {}
      );
    });
  }

  private StartInterval(withTime?: boolean, forceBuild = false) {
    if (forceBuild) {
      this.forceBuild = new wbcToggler(true);
    }
    this.GetGraphData(withTime);
    this.getDeviceGraphData(!withTime);
    this.getMuteGraphData(!withTime);

    if (!withTime) {
      this.interval = SETTINGS.AddInterval(
        () => {
          this.GetPatientAlarmServiceData();
          this.GetGraphData(withTime);
          this.GetAlarmHistory();
        },
        15000,
        this.interval
      );
    }
  }

  private GetPatientAlarmServiceData() {
    this.patientService.getPatient(this.patient.id).subscribe((patientResult) => {
      if (
        patientResult != null &&
        patientResult.patient != null &&
        patientResult.patient.id != null
      ) {
        this.SetPatientAlarmServiceData(
          patientResult.patient,
          patientResult.alarmTriggers,
          patientResult.services
        );
      } else {
        this.router.navigate(['/patients']);
      }
    });
  }

  public onChangeAlarmTrigger(event: AlarmTrigger): void {
    this.alarmTriggers.forEach((at) => {
      if (at.id === event.id) {
        at = event;
      }
    });

    this._buildMuteToggler();
  }

  private _watchDataLoaded(): Observable<void> {
    if (!this.isDoneLoading) {
      return of(null).pipe(
        delay(500),
        mergeMap(() => {
          return this._watchDataLoaded();
        })
      );
    } else {
      this._loadTestingService.stopLoading();
      return of(null);
    }
  }

  private SetPatientAlarmServiceData(
    patient: Patient,
    alarmtriggers: AlarmTrigger[],
    services: ServiceListResult[]
  ) {
    this.patient = patient;
    this.services = services;
    this.alarmTriggers = alarmtriggers;

    this._buildMuteToggler();
    this.SetTitle(this.patient.displayName);
    this.setTimeRangesAlarms();
  }

  private _buildMuteToggler(): void {
    const notMuted = this.alarmTriggers.filter((at) => !at.muted).length > 0;
    this.patientMute = new wbcToggler(
      notMuted,
      () => {
        this.toggleMutePatient();
      },
      null,
      this.isManufacturer
    );
  }
}
