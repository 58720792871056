export class SortingOption {
  public userId: number;
  public tableId: string;
  public sort: string;
  public columnName: string;

  constructor (userId?: number, tableId?: string, sort?: string, columnName?: string) {
    this.userId = userId ? userId : null;
    this.tableId = tableId ? tableId : null;
    this.sort = sort ? sort : null;
    this.columnName = columnName ? columnName : null;
  }
}
