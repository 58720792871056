import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TeamService } from 'app/services/team.service';
import { wbBrowserStorage } from 'app/modules/webbeat/service/browserStorage.service';

@Injectable()
export class TeamsResolver implements Resolve<any> {
	constructor(private _teamService: TeamService, private _wbStorage: wbBrowserStorage) { }

	resolve(): any {
		return this._teamService.getAllTeamsByGroup(this._wbStorage.getStorageItem('user.groups[0].id'));
	}
}