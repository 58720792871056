import { hcDataPoint } from './hcDataPoint.class';
import { ChartType } from './hcEnums.class';

import { hcToJson } from './hcIToJson.interface';


export class hcSeries implements hcToJson {
	public get type() :string { return ChartType[this._typeEnum]; };
	public set typeEnum(value: ChartType) { this._typeEnum = value; };

	public _colorIndex: number;

	constructor(public data: hcDataPoint[], private _typeEnum?: ChartType, public name?: string, public initialAnimation?: boolean,
		public useMarker?: boolean, public maxPointWidth?: number, public symbolUrl?: string, public colorIndex?: number) {
		if (this.initialAnimation == null) {
			this.initialAnimation = true;
		}

		if (this.useMarker == null) {
			this.useMarker = true;
		}
	}

	ToJSON() {
		var result: any = {
			data: (() => {
				if (this.data == null) { return null; }

				var result = [];

				for (var i = 0; i < this.data.length; i ++) {
					result.push(this.data[i].ToJSON());
				}

				return result;
			})()
		};

		if (this.name != null && this.name != '') {
			result.name = this.name;
		}

		if (this.type != null && this.type != '') {
			result.type = this.type;
		}

		if (!this.initialAnimation) {
			result.animation = false;
		}

		if (this.symbolUrl != null && this.symbolUrl != '') {
			result.marker = {
				enabled: true,
				symbol:'url(' + this.symbolUrl + ')'
			};
		}
		else if (!this.useMarker) {
			result.marker = {
				symbol: 'circle',
			};
		}

		if (this.maxPointWidth != null && this.maxPointWidth > 0) {
			result.maxPointWidth = this.maxPointWidth;
		}

		if (this.colorIndex != null) {
			result.colorIndex = this.colorIndex;
		}


		result.turboThreshold = 0;

		return result;
	};
};