import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { Page } from '../../pages.parent';
import { wbcAny } from '../../../modules/webbeat/classes/wbcAny.class';
import { AlarmLog } from '../../../classes/alarm.class';

@Component({
  selector: 'app-alarm-history',
  templateUrl: './alarm-history.component.html',
  styleUrls: ['./alarm-history.component.css']
})
export class AlarmHistoryComponent extends Page implements OnInit {
  public alarms = new Array<AlarmLog>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    glService: GlobalService
  ) {
    super(glService, 'AlarmHistory');
    this.SetTitle(this.GetText('common.alarm_history'));
  };

  ngOnInit() {
    this.resolveAlarmLogs();
  }

  public SetTitle(title: string) {
    this.glService.title = new wbcAny(title);
  }

  private resolveAlarmLogs(): void {
    this.route.data.subscribe(data => {
      const alarms = data['alarms'];
      this.alarms = alarms;
    }, () => {

    });
  }

}
