import { LayGraphData } from './../classes/layGraphData.class';
import { SETTINGS } from './../settings';
import { HttpClient } from '@angular/common/http';
import { Observable ,  Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { Patient } from '../classes/patient.class';
import { Alarm } from '../classes/alarm.class';
import { PatientDetailResult } from '../classes/results.class';
import { APIService } from './api.service';
import { PatientBiometrics } from 'app/classes/patient-biometrics.class';



@Injectable()
export class PatientService extends APIService {
	private baseUrl = SETTINGS.BASE_API_URL;

	constructor(private _http: HttpClient) {
    super();
	}

	/* GET */

	public getPatients(): Observable<Patient[]> {
		return this._http.get<Patient[]>(`${this.baseUrl}Patient/GetPatients`);
  };

  public getAllPatients(): Observable<Patient[]> {
		return this._http.get<Patient[]>(`${this.baseUrl}v2/patients?active=false`);
  };

	public getAllPatientsByCompany(): Observable<Patient[]> {
		return this._http.get<Patient[]>(`${this.baseUrl}v2/patients/bycompany`);
  };

	public getPatient(id: number): Observable<PatientDetailResult> {
		return this._http.get<PatientDetailResult>(`${this.baseUrl}Patient/GetPatient?patientId=${id}`);
	};

	public getAlarmLog(id: number): Observable<Alarm[]> {
		return this._http.get<Alarm[]>(`${this.baseUrl}Patient/GetAlarmLog?patientId=${id}`);
	};

	public getGraph(patient: number, sensor: number, length_time?: number, overrideDate?: boolean, startDateTime?: string, endDateTime?: string): Observable<LayGraphData> {
		let end;
		let start;

		if (length_time == null || length_time < 60) {
			length_time = 60;
		}

		if (overrideDate === true) {
			start = new Date(startDateTime);
			end = new Date(endDateTime);
		} else {
			end = new Date();
			start = new Date(end.getTime() - (length_time * 1000));
		}

		return Observable.create((observer: Observer<LayGraphData>) => {
			this._http.get(`${this.baseUrl}Patient/GetData?begin=${start.toJSON()}&end=${end.toJSON()}&patientId=${patient}&sensorId=${sensor}`).subscribe((res: LayGraphData) => {
				res.start = start;
				res.end = end;
				observer.next(res);
				observer.complete();
			});
		});
	};

	public getBedPatients(): Observable<Patient[]> {
		return Observable.create((observer: Observer<Patient[]>) => {
			this._http.get(`${this.baseUrl}Patient/GetPatientServiceStatus`).subscribe((data: Patient[]) => {
				let result: Patient[] = [];

				data.forEach((e) => {
					result.push(Patient.Convert(e));
				});

				observer.next(result);
				observer.complete();
			});
		});
  }

  public getPatientWeight(id: number): Observable<PatientBiometrics[]> {
    return this._http.get<PatientBiometrics[]>(`${this.baseUrl}Patient/Biometrics/GetBiometrics/${id}?readings=Weight`);
  }

	/* POST */

	public updatePatient(patient: Patient): Observable<number> {
    const toSendObject: any = {
      id: (patient.id > 0 ? patient.id : 0),
			//type: patient.type,
			lastName: patient.lastName,
			initials: patient.initials,
			insertion: patient.insertion,
			//firstName: patient.firstName,
			//displayName: patient.displayName,
			room: patient.room,
      //status: patient.status
    }
    if (toSendObject.id === 0) {
      toSendObject.patientGroups = patient.patientGroups;
    }

		return this._http.post<number>(`${this.baseUrl}Patient/EditPatient`, toSendObject);
	};

	/* DELETE */

	public deletePatient(patient: Patient): Observable<number> {
		return this._http.delete<number>(`${this.baseUrl}Patient/DeletePatient?patientId=${patient.id}`);
	};
}
