import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WebbeatModule } from '../../modules/webbeat/webbeat.module';
import { SharedModule } from '../../shared.module';
import { ContentModule } from '../../content/content.module';
import { BedPatientResolver } from '../../resolvers/bedPatient-resolver.service';
import { wbLoginGuard } from '../../guards/login-guard.service';
import { MaintenanceGuard } from 'app/guards/maintenance-guard.service';
import { PageBedIndexComponent } from './bed-index.component';
import { IsNotManufacturerGuard } from '../../authorization/guards/not-manufacturer-guard.service';
import { UserRolesResolver } from '../../resolvers/userRoles-resolver';
import { isNotManufacturerOrDealerGuard } from 'app/authorization/guards/not-manufacturer-or-dealer-guard.service';
import { isManufacturerOrDealerGuard } from 'app/authorization/guards/dealer-or-manufacturer-guard.service';

@NgModule({
  imports: [
    WebbeatModule,
    RouterModule.forChild([
      {
        path: 'bed',
        canActivate: [MaintenanceGuard, wbLoginGuard],
        component: PageBedIndexComponent,
        resolve: {
          bedPatients: BedPatientResolver,
          rememberUserRoles: UserRolesResolver,
        },
      },
    ]),
    SharedModule,
    ContentModule,
  ],
  declarations: [PageBedIndexComponent],
  providers: [
    BedPatientResolver,
    wbLoginGuard,
    IsNotManufacturerGuard,
    isNotManufacturerOrDealerGuard,
    isManufacturerOrDealerGuard,
    UserRolesResolver,
  ],
})
export class PageBedModule {}
