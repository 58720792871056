import { EmployeePOST } from './../classes/employee.class';
import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { EmployeeService } from '../services/employee.service';
import { Observable, Observer } from "rxjs";
import { of as observableOf } from 'rxjs';
import { GlobalService } from 'app/services/global.service';

@Injectable()
export class EmployeeResolver implements Resolve<any> {
	constructor(
		private service: EmployeeService,
    private router: Router,
    private glService: GlobalService
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let id = route.params['id'];

		/* If 'id' = 0, return new employee */
		if (id == 0 || id == '0') {
			let employee = new EmployeePOST();

      employee.id = 0;
      employee.teamIds = [];
      employee.language = this.glService.Language;

			return observableOf(employee);
		}

		/* Check if employee exists */
		return Observable.create((observer: Observer<any>) => {
			this.service.getEmployee(id).subscribe(employee => {
				if (employee != null && employee.id != null) {
					observer.next(employee);
					observer.complete();
				}
				else {
					this.router.navigate(['/employees']);
					observer.next({
						employee: null,
					});
					observer.complete();
				}
			});
		});
	}
}
