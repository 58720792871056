import { hcOptions } from './hcOptions.class';
import { hcDataPoint, hcSeriesDataPoint } from './hcDataPoint.class';
import { hcSeries } from './hcSeries.class';

import { SETTINGS } from '../../../settings';


export class hcChart {
	public get series() :hcSeries[] {
		
		if (this.hcChart == null) {
			return [];
		}
		return this.hcChart.series;
	};
	public set series(data: hcSeries[]) {
		this.RemoveAllData(false);
		this.SetAllData(data);
		//this.hcChart.series = data;
	};

	private hcChart: any;

	private width: number;
	private height: number;
	private sizeTimer: any;

	private $container: any;

	private counter: number = 0;


	constructor(public options: hcOptions, dontSupply: any) {
		this.hcChart = dontSupply;
	}


	SetSize(width: number | string, height: number | string) {
		if (this.$container == null) {
			this.$container = SETTINGS.jquery(this.hcChart.container);
		}

		if (typeof(width) == 'string') {
			switch(width) {
				case 'fill':
					width = this.$container.innerWidth();
					break;
				default:
					width = this.width;
			}
		}

		if (typeof(height) == 'string') {
			switch(height) {
				case 'fill':
					height = this.$container.innerHeight();
					break;
				default:
					height = this.height;
			}
		}

		var update = false;
		if (this.width != width) {
			this.width = <number>width;
			update = true;
		}

		if (this.height != height) {
			this.height = <number>height;
			update = true;
		}

		if (update) {
			if (this.sizeTimer != null) {
				clearTimeout(this.sizeTimer);
			}

			this.sizeTimer = setTimeout(() => {
				this.sizeTimer = null;
				this.hcChart.setSize(this.width, this.height);
			}, 10);
		}
	}

	Update(options: hcOptions, series: hcSeries[], redraw: boolean) {
		this.options = options.ToJSON();
		redraw = false;

		this.hcChart.update(this.options, true);

		
		if (this.series == null || this.series.length == 0) {
			//console.warn('empty current series?');
			this.AddSeries(new hcSeries([]), false);
		}

		/* remove series that are not in the new series */
		if (this.series != null && this.series.length > 0) {
			var min = 0;
			if (series != null && series.length > 0) {
				min = series.length;
			}

			while (this.series.length > min) {
				//console.warn('remove series...');
				this.RemoveSeries(this.series.length - 1);
			}
		}

		/* add series */
		if (series != null && series.length > 0) {
			while (this.series.length < series.length) {
				//console.warn('add series...');
				this.AddSeries(series[this.series.length], false);
			}
		}


		var currentSeries = this.series;

		/* update series */
		for (var i = 0; i < currentSeries.length; i ++) {
			var max = 0;
			var reversed = false;

			if (currentSeries[i] !== null && currentSeries[i].data !== null) {
				max = currentSeries[i].data.length;
			}
			if (series[i].data.length < max) {
				max = series[i].data.length;
				reversed = true;
			}

			/* series settings */
			if (series[i].name != null && series[i].name != '') {
				this.UpdateSeriesOptions(i, { name: series[i].name }, true);
			}

			if (series[i].typeEnum != null && series[i].typeEnum != currentSeries[i].typeEnum) {
				this.UpdateSeriesOptions(i, { type: series[i].type }, true);
			}

			if (series[i].colorIndex != null && series[i].colorIndex > 0 && series[i].colorIndex != currentSeries[i].colorIndex) {
				currentSeries[i].colorIndex = series[i].colorIndex;
				this.UpdateSeriesOptions(i, { colorIndex: series[i].colorIndex }, true);

				setTimeout((obj)=>{
					try {
						let seriesId = this.hcChart.series[obj.index].index;
						let colorIndex = this.hcChart.series[obj.index].colorIndex;

						let $graph = SETTINGS.jquery(this.hcChart.renderTo);

						let el = $graph.find(' .highcharts-series-' + seriesId);

						el.each(function(){
							let child = this;
							let $child = SETTINGS.jquery(this);

							child.classList.forEach((str) => {
								if (str.startsWith('highcharts-color-')) {
									$child.toggleClass(str, false);
								}
							});

							$child.toggleClass('highcharts-color-' + colorIndex, true);
						});
					}
					catch(e) {
						//console.log('error:', e);
					}
				}, 0, {index: i});
			}

			if (series[i].useMarker == false) {
				currentSeries[i].useMarker = false;
			}

			/* series data */

			for (var j = 0; j < max; j ++) {
				if (!series[i].data[j].Compare((<any>currentSeries[i].data[j]))) {
					this.UpdatePoint(i, j, series[i].data[j], false);
				}
			}

			if (!reversed) {
				var total = series[i].data.length;

				for (var j = max; j < total; j ++) {
					this.AddPoints(i, series[i].data[j], false);
				}
			}
			else {
				var total = currentSeries[i].data.length;
				this.RemoveLastPoints(i, total - max, false);
			}
		}

		this.hcChart.update(this.options, true);
		this.hcChart.redraw();
	};

	AddSeries(series: hcSeries, redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		this.hcChart.addSeries(JSON.parse(JSON.stringify(series.ToJSON())), redraw);
	};

	UpdateSeriesOptions(series: number, options: any, redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		if (this.hcChart.series != null && this.hcChart.series[series] != null) {
			this.hcChart.series[series].update(options, redraw);
		}
	}

	RemoveSeries(series: number, redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		if (this.hcChart.series != null && this.hcChart.series[series] != null) {
			this.hcChart.series[series].remove(redraw);
		}
	};


	SetData(series: number, data: hcDataPoint[], redraw?: boolean) {
		if (redraw == null) { redraw = true; }
		
		var temp = [];

		if (data != null) {
			for (var i in data) {
				temp.push(data[i].ToJSON());
			}
		}

		if (this.hcChart.series != null && this.hcChart.series[series] != null) {
			this.hcChart.series[series].setData(JSON.parse(JSON.stringify(temp)), redraw);
		}
	};

	SetAllData(data: hcSeries[], redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		for (var i = 0; i < data.length; i ++) {
			this.AddSeries(data[i], false);
		}

		if (redraw === true) {
			this.hcChart.redraw();
		}
	};

	RemoveAllData(redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		if (this.hcChart.series != null) {
			for (var i = this.hcChart.series.length; i > 0; i --) {
				this.RemoveSeries(i, false);
			}

			if (redraw === true) {
				this.hcChart.redraw();
			}
		}
	};

	AddPoints(series: number, points: hcDataPoint | hcDataPoint[], redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		var items = [];

		if (points instanceof hcDataPoint) {
			items.push(points.ToJSON());
		}
		else {
			for (var p in points) {
				items.push(points[p].ToJSON());
			}
		}

		if (this.hcChart.series != null && this.hcChart.series[series] != null) {
			for (var i = 0; i < items.length; i ++) {
				this.hcChart.series[series].addPoint(items[i], false);
			}

			if (redraw) {
				this.hcChart.redraw();
			}
		}
	};

	UpdatePoint(series: number, point: number, options: hcDataPoint, redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		if (this.hcChart.series != null && this.hcChart.series[series] != null) {
			if (this.hcChart.series[series].data != null && this.hcChart.series[series].data[point] != null) {
				this.hcChart.series[series].data[point].update(JSON.parse(JSON.stringify(options.ToJSON())), redraw);
			}
		}
	};

	ShiftPoint(seriesPoint: hcSeriesDataPoint, redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		if (this.hcChart.series != null && this.hcChart.series[seriesPoint.series] != null) {
			this.hcChart.series[seriesPoint.series].addPoint(seriesPoint.Convert(), true, redraw);
		}
	};

	RemovePoint(series: number, point: number, redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		if (this.hcChart.series != null && this.hcChart.series[series] != null) {
			this.hcChart.series[series].removePoint(point, redraw);
		}
	};

	RemoveLastPoints(series: number, range: number, redraw?: boolean) {
		if (redraw == null) { redraw = true; }

		if (range < 0) {
			return;
		}

		if (this.hcChart.series != null && this.hcChart.series[series] != null) {
			var max = this.hcChart.series[series].data.length - 1;
			var min = max - range;

			if (min < 0 || max < 0) {
				//ignore error
			}
			else if (max < min) {
				console.error('killed more than possible!', min, max);
			}
			else {
				for (var i = max; i > min; i --) {
					this.hcChart.series[series].removePoint(i, false);
				}
			}

			if (redraw) {
				this.hcChart.redraw();
			}
		}
	};

	LogChart(){
	};
}